import * as React from 'react';

import PropTypes from 'prop-types';

/**
 * AeroPay (FastLink via Yodlee) widget used to allow customers to select a bank account
 * to use with AeroPay
 * 
 * @param {*} accessToken - provivided by credentials API call
 * @param {*} fastLinkUrl - provivided by credentials API call
 * @param {*} handleEvents - widget returns response.sites[0] with accountId (success) or not on close 
 */
const FastLinkWidget = ({
  accessToken,
  fastLinkURL, 
  handleEvents
}) => {
  
  const loadFastLinkWidget = React.useCallback(() => {
    if (accessToken && fastLinkURL) {
      window.fastlink.open(
        {
          fastLinkURL,
          accessToken,
          forceIframe: true,  /* The non-iframe widget (mobile blows up) */
          params: {
            configName : 'Verification' /* 'Verification' gets you the accountId */
          },
          onSuccess: function (data) {
            // This event occurs early and doesn't include complete data (e.g. account Id)
          },
          onError: function (data) {
            // This will be called on error. For list of possible message, refer to onError(data) Method.
            console.log(data);
            handleEvents(data);
          },
          onClose: function (data) {
            /* Most user actions result in a close event */
            handleEvents(data);
          },
          onEvent: function (data) {
            console.log(data);
            // This will be called on intermittent status update.
          }
        },
        'container-fastlink'
      );
    }
  }, [accessToken, fastLinkURL, handleEvents]); 
  
  React.useEffect(() => {
    console.log('widget render');
    loadFastLinkWidget();
    // unload on unmount
    return () =>  window.fastlink.close(); 
  }, [loadFastLinkWidget]);

  return (
    <div style={{margin: '1rem auto', backgroundColor:'white'}}>
      <div id="container-fastlink"></div>
    </div>
  );
}

FastLinkWidget.propTypes = {
  accessToken: PropTypes.string.isRequired,
  fastLinkURL: PropTypes.string.isRequired,
  handleEvents: PropTypes.func.isRequired,
};

export default FastLinkWidget;
