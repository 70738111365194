import * as React from 'react';

import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

import styles from './Layout.module.css';


// Various page layouts
export const LayoutTypes = {
  Default: "Default",
  Narrow: "Narrow",
  HomeGrid: "HomeGrid", 
  Registration: "Registration",
  FullBleed: "FullBleed"
};

/**
 * In addition to setting content width, the BodyWrapper also determines if we 
 * have a delivery address, and if so, adds additional margin to create space for
 * the address bar to display ( for mobile/tablet ) 
 * 
 * @param pageLayout {string}  sets content width etc.
 */
const BodyWrapper = ({
  pageLayout=LayoutTypes.Default,
  withStyle={}, 
  children
}) => {

  const overrideStyle = pageLayout === LayoutTypes.FullBleed ? styles.fullBleedWrapper : 
                        pageLayout === LayoutTypes.Narrow ? styles.narrowWrapper : 
                        pageLayout === LayoutTypes.HomeGrid ? styles.homeGridWrapper : '';
  return (
    <div className={`${styles.defaultWrapper} ${overrideStyle}`}
         style={withStyle}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </div>
  );
}

BodyWrapper.propTypes = {
  pageLayout: PropTypes.string,
  withStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
}

export default BodyWrapper;