import * as React from "react";

import { storeValue, userAddressKey } from '../storage/storage-utils';
import { trackEvent } from '../analytics/tracking';
import { errMsg } from './registration-errors';
import SearchLocationInput from '../googlemaps/SearchLocationInput';
import AddressUpdaterInline from "./AddressUpdaterInline";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';

import PropTypes from 'prop-types';

import '../registration/MaterialUI.module.css';
import styles from './AddressSearch.module.css';

/**
 * Provide a form to edit the location-search selected address ( minus apartment number ) 
 * 
 * @param {object} location -- The address JSON
 * @param {addressErr} boolean -- There are errors for the current address
 * @param {function} setLocation -- update loocation in the RegistrationProvider
 */
const AddressSearchAndUpdate = ({
  location,
  addressErr, 
  setLocation
}) => {
  
  // Allow the location search to control display/edit state
  const [editAddress, setEditAddress] = React.useState(); 

  const handleAddressSelection = (response) => {
    if (response.address) {
      const { 
        address: street_address,
        city,
        stateShort: state,
        zipCode: zip_code,
      } = response;
      
      // Update Context
      setLocation({
        street_address,
        city,
        state,
        zip_code
      }); 
      
      // Store address for future use. Use session maybe?
      const addrString  = JSON.stringify({ street_address, city, state, zip_code });
      storeValue( userAddressKey, addrString);

    } else {
      trackEvent('error_address_search_inline', 'address not returned');
    }
  };

  return (
    <>
      { location.street_address 
        // If we have an address, show it
         ? <AddressUpdaterInline 
             location={location}
             editAddress={editAddress}
             setEditAddress={setEditAddress} 
             onUpdate={(location) => {
               setLocation(location)
               setEditAddress(false);
              }} />
         // else show location search   
         :  <div className={styles.inputWrap}>
              <div className={styles.pinIcon}>
                <LocationOnRoundedIcon fontSize={'inherit'} style={{color:'#0000008a'}} />
              </div>
              <SearchLocationInput disableAutoFocus onSelection={handleAddressSelection} />
              {/* show validation error if we have one */}
              <div className={styles.errorMsg}>
                {errMsg('address', addressErr)}
              </div>
            </div>
      }      
    </>
  );
}

AddressSearchAndUpdate.propTypes = {
  location: PropTypes.object,
  addressErr: PropTypes.bool, 
  setLocation: PropTypes.func.isRequired,
}

export default AddressSearchAndUpdate;