import * as React from 'react';

import ProductCardForGrid from  './ProductCardForGrid'; 
import PropTypes from 'prop-types';
import { navigateTop } from '../routing/router-utils';

/**
 *  This component displays both both inline/grid product card and the modal product view
 * 
 *  TODO: This wrapper can be removed and ProductCardForGrid can be used directly   
 */
const DispensaryProduct = (
  {
    Id = '-1',  // .id
    name,       // .display_info
    brand,
    category={},
    image_url,
    is_featured,
    cost_usa_cents,
    leaf_logix_pricing_tier,
    thc_lab_value,
    thc_lab_units,
    cbd_lab_value,
    cbd_lab_units,
    cannabis_type,
    grams,
    dispensaryId,
    handleClick,
    quantity, 
    showOrderQuantity,
    isFullWidth
  }) => {

  // Handle this for both views
  const imageInfo = {
    productImage: image_url,
    productCategory: category.display_name 
  };

  // Add to cart and auto-close modal
  const addToCartClick = (quantity) => {
    handleClick(quantity); 
  }; 

  const productDetailClick = () => {
    navigateTop(
      `/menu/${dispensaryId}/product/${Id}`,
      { state: { returnToPath:`/menu/${dispensaryId}/products`}}
    )
  }
  
  return (
    <ProductCardForGrid 
       Id={Id} 
       Name={name}
       categoryName={category.name} 
       imageInfo={imageInfo}
       brand={brand}
       is_featured={is_featured} 
       cost_usa_cents={cost_usa_cents}
       leaf_logix_pricing_tier={leaf_logix_pricing_tier}
       thc_lab_value={thc_lab_value}
       thc_lab_units={thc_lab_units}
       cbd_lab_value={cbd_lab_value}
       cbd_lab_units={cbd_lab_units}
       cannabisType={cannabis_type}
       grams={grams} 
       orderQuantity={quantity}
       dispensaryId={dispensaryId} 
       showOrderQuantity={showOrderQuantity}
       isFullWidth={isFullWidth}
       handleCartClick={(quantity) => addToCartClick(quantity)}
       handleClick={productDetailClick}
     />
  )
}

DispensaryProduct.propTypes = {
  Id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  brand: PropTypes.string,
  category: PropTypes.object,
  image_url: PropTypes.string,
  is_featured: PropTypes.bool.isRequired,
  cost_usa_cents: PropTypes.number.isRequired,
  leaf_logix_pricing_tier: PropTypes.object,
  thc_lab_value: PropTypes.string,
  thc_lab_units: PropTypes.string,
  cbd_lab_value: PropTypes.string,
  cbd_lab_unit: PropTypes.string,
  cannabis_type: PropTypes.string,
  grams: PropTypes.number.isRequired,
  dispensaryId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  showOrderQuantity: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

export default DispensaryProduct;