import { auth, storage } from '../../firebase';
import { addressBlackListEndpoint, customerInfoEndpoint, emailAvailabilityEndpoint, telephoneVerificationEndpoint } from '../../constants/api-endpoints';
import { trackEvent } from '../analytics/tracking';
import { isValidEmail, isValidPassword } from './validation-utils';
import { queueForSessionRestore } from '../providers/provider-utils';
import { navigateTop } from '../routing/router-utils';

const storagePath = 'images/customer_identification/web/';

// localStorage key to identify registered users on the marketing site
export const regFlowCompleteKey = 'registrationComplete';

// Get the page to return to after registration is complete
export const getReturnToPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const returnTo = urlParams.get('returnTo');
  return returnTo || "/";
};

// Get the previous page in the Reg Flow via the backLink param ( for special cases )
export const getBackLink = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('backLink');
};

/**
 * Verify that the email is not already registered
 * 
 * @param {string} emailAddress 
 * @param {function} handleAvailability 
 */
export const checkEmailAvailability = (emailAddress, handleAvailability) => {
  // wait for a valid email address
  if (!isValidEmail(emailAddress)) {
    // callback with handle invalid email messaging
    handleAvailability();
    return;
  }
  
  const encodedEmail = encodeURIComponent(emailAddress);
  const emailVerifyURl = `${emailAvailabilityEndpoint}${encodedEmail}`;
  
  try {
    fetch(emailVerifyURl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(data => {
        if ( data.hasOwnProperty('is_available')) {
          handleAvailability(data['is_available']);
        }  
      })
      .catch(err => { throw(err) }); 
  
  } catch (error) {
    trackEvent('error_email_availability', error);
  } 
};

/**
 * Check password strength 
 *   
 * @param {string} password 
 * @param {function} handlePasswordStrength 
 */
export const checkPasswordStrength = (password, handlePasswordStrength) => {
  
  try {
    // bypass endpoint with regex check for now
    handlePasswordStrength(isValidPassword(password));
    return;  
  
    /**
      const encodedPassword = encodeURIComponent(password);
      const passwordStrengthUrl = `${passwordStrengthEndpoint}${encodedPassword}`;
    
      fetch(passwordStrengthUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          if ( data.hasOwnProperty('is_valid_password')) {
            handlePasswordStrength(data['is_valid_password']);
          }  
        })
        .catch(err => { throw(err) }); 
    */
  
  } catch (error) {
    trackEvent('error_password_strength', error);
  } 
};

/**
 * Store a front or back ID image 
 * 
 * @param file a single image file
 * @param encodedEmail the users base64 encoded email to use as a directory
 * @param onImageComplete the onCompletion callback
 * @param onProgressChange the onProgress callback 
 */
export const uploadImage = (file, encodedEmail, onImageComplete, onProgressChange) => {
  const { name, type:contentType } = file;
  const storageRef = storage.ref();

  // Create the file metadata
  const metadata = {
    contentType
  };

  // full filepath with encodedEmail/filename
  const imagePath = `${storagePath}${encodedEmail}/${name}`;

  // Upload file and metadata to the object 'images/mountains.jpg'
  const uploadTask = storageRef.child(imagePath).put(file, metadata);
  
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',  // or storage.TaskEvent.STATE_CHANGED
    function(snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgressChange(progress);
      /**  
       * optional monitoring
       *   switch (snapshot.state) {
       *     case 'paused': // or storage.TaskState.PAUSED:
       *     console.log('Upload is paused');
       *     break;
       *     case 'running': // or storage.TaskState.RUNNING:
       *     console.log('Upload is running');
       *     break;
       *   }
       */
    }, function(error) {
      /**
       *  A full list of error codes is available at
       *  https://firebase.google.com/docs/storage/web/handle-errors
       *  switch (error.code) {
       *    case 'storage/unauthorized': User doesn't have permission to access the object
       *    case 'storage/canceled': User canceled the upload
       *    case 'storage/unknown': Unknown error occurred, inspect error.serverResponse
       */
      trackEvent('error_image_upload', error.code, window.atob(encodedEmail),[]);
    }, function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        onImageComplete({
          path: imagePath,
          url: downloadURL
        });
      });
    }
  );
}; 

/**
 * Store front and back ID images 
 * 
 * @param images the array of image files
 * @param encodedEmail the users base64 encoded email to use as a directory
 * @param onImageComplete the onCompletion callback
 * @param onProgressChange the onProgress callback 
 */
export const uploadIDImages = (images, encodedEmail, onImageComplete, onProgressChange) => {
  images.forEach(image => {
    uploadImage(image.file, encodedEmail, onImageComplete, onProgressChange);
  });
};

/**
 * Get user info (address, etc.) and update component via passed callback 
 * 
 * @param function callback
 * @param controller the abortController the component will use to cancel request on unmount 
 */
export const fetchUserInfo = async (callback, controller) => {
  /**
   * This may be called while session is being restored (e.g. page reload)   
   * We handle this by queueing re-navigate-to-current-page for after the session is restored 
   */
  if (!auth.currentUser || auth.currentUser.isAnonymous) {
    queueForSessionRestore(() => {
      navigateTop(window.location.pathname, {disableScroll:true}); 
    });
    return;
  }
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(customerInfoEndpoint, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json'
      },
      signal: controller.signal
    })
    .then(response => response.json())
    .then(data => {
      callback(data);
    })
    .catch(error => {
      callback({error});
      console.log(`Error: ${error}`); 
    });
  });   
};

/**
 * Update user address, phone number, ID images 
 * 
 * NOTE: The endpoint returns 204 onSuccess and Error JSON onFailure
 * 
 * @param object updateInfo - the JSON containing updated data
 * @param function callback - runs after update
 * @param controller the abortController the component will use to cancel request on unmount 
 */
export const updateUserInfo = async (updatedInfo, callback, controller) => {
  if (!auth.currentUser) {
    return;
  }
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(customerInfoEndpoint, { 
      method: 'PATCH',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedInfo),
      signal: controller.signal
    })
    .then(response => {
      if (response.status === 204) {
        callback(true);   
      } else {
        response.json().then(json => {
          callback(false, json);
        });
      }
    })
    .catch(error => {
      callback(false, { error: error.message });
      console.log(`Error: ${error.message}`); 
    });
  });
};

/**
 * Send a verification code to the provided phone number via text
 *
 * endpoint also supports voice call (not implemented)
 * 
 * @param verificationInfo {object} the json being sent: { delivery_method: 'sms-text', tel_number:123 } 
 * @param boolean isVoiceCall
 * @param function callback
 * @param controller the abortController the component will use to cancel request on unmount 
 */
export const sendVerificationCode  = async (verificationInfo, isVoiceCall, callback, controller) => {
  // GET will require values to be passed in url
  const { tel_number } = verificationInfo;
 
  const method = isVoiceCall ? 'voice-call' : 'sms-text';
 
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(`${telephoneVerificationEndpoint}?delivery_method=${method}&tel_number=${tel_number}`, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    })
    .then(response => response.json())
    .then(data => {
      callback(data);
      trackEvent('Phone verification code sent', 'sms-text');
    })
    .catch(error => {
      callback({error});
      trackEvent('Phone verification send error', error);
      console.log(`Error: ${error}`); 
    });
  });   
};

/**
 * Submit the verification code response for the code sent to the user's phone number
 * 
 * triggered from a modal
 * 
 * @param codeInfo {object} the json being sent: { code:123, tel_number:123 }
 * @param function callback
 * @param controller the abortController the component will use to cancel request on unmount 
 */
export const verifyCode  = async (codeInfo, callback, controller) => {
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(telephoneVerificationEndpoint, { 
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(codeInfo),
      signal: controller.signal
    })
    .then(response => {
      if (response.status === 204) {
        callback({success: true});   
      } else {
        trackEvent('Incorrect phone verification response', 'sms-text');
        response.json().then(json => {
          callback(json);
        });
      }
    })
    .catch(error => {
      callback({error});
      trackEvent('Phone verification error', error);
    });
  });   
};

/**
 * Check to see if an address is blacklisted for delivery
 * 
 * @param {object} address - { street_address, city, state, zip_code }
 * @param {function} callback - accepts the api response
 * @param {object} controller - the AbortController
 */
export const checkDeliveryAddressBlacklist = async (address, callback, controller) => {
  if (!auth.currentUser) {
    return;
  }
  // street_address, city, zip_code, state
  const params = new URLSearchParams(address).toString();
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(`${addressBlackListEndpoint}?${params}`, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    })
    .then(response => {
      // Address is NOT blacklisted
      if (response.status === 204) {
        callback(false);   
      } else {
        //response.json().then(json => {
        callback(true);
        //});
      }
    })
    .catch(error => {
      callback(false, { error: error.message });
      console.log(`Error: ${error.message}`); 
    });
  });
};
