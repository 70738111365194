import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { FilteredProductsContext, subCategoryAll, SHOW_ALL} from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide sub-category Cannabis Type filters for both Desktop and Mobile layouts
 */ 
const TypeFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  // Current filters context
  const { type:selectedType, subCategoryTypes, setFilters } = React.useContext(FilteredProductsContext);

  // Add the 'All {category}' option 
  const subCategoryTypesWithAll = [subCategoryAll.name, ...subCategoryTypes];
  // Toggle the display
  const [showTypes, setShowTypes] = React.useState(startOpen);

  const toggleShowTypes = () => {
    if (typeRef.current) {
      typeRef.current.setAttribute('data-show-content', !showTypes);
    } 
    setShowTypes(!showTypes);
  };

  const typeClick = (type) => {
    trackEvent('type_filter_click', type); 
    setFilters({type});
  };
  
  const typeRef = React.useRef();
  
  const addlClass = inModal ? styles.inModal : '';

  return (subCategoryTypesWithAll.length > 1
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={typeRef} data-show-content={showTypes} className={styles.sectionHdr} onClick={toggleShowTypes}>
          TYPES <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showTypes &&
          <ul className={styles.filterLinks}>
            { subCategoryTypesWithAll.map(type => {
              const selectedClass = (type === selectedType ||
                                    (!subCategoryTypesWithAll.includes(selectedType) && 
                                      type === SHOW_ALL)) 
                                  ? styles.selected 
                                  : '';
              const label = type === SHOW_ALL
                          ? `All Types`
                          : type;
              return <li key={`t${type}`} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {typeClick(type)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default TypeFilter;