import * as React from 'react';

import { daysOfWeek, getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { fetchPickupWindows } from '../dispensary/dispensary-utils';
import { getMaxDaysOut, getDefaultSlotForToday, getEarliestOrderTime } from './timeslot-utils';
import { CurrentTimeContext } from './CurrentTimeProvider';

export const PickupSlotsContext = React.createContext({});

export const STORE_NOT_OPEN_YET = -999;

const PickupSlotsProvider = ({dispensaryId, children}) => {

  const currentTimeMS = React.useContext(CurrentTimeContext);

  // Valid timeSlots to display (given day of week + days out)
  const [timeSlotsByDay, setTimeSlotsByDay] = React.useState([]);
  // Default time slot, -1 when unset
  const [defaultSlot, setDefaultSlot] = React.useState(-1); 
  
  // Handle ordering not available at the current time
  const [ordersDisabled, setOrdersDisabled] = React.useState();
  const [ordersEnabledTime, setOrdersEnabledTime] = React.useState('');
  const [ordersEnabledDay, setOrdersEnabledDay] = React.useState('');  
  // The user-selected timeslot
  const [selectedPickupSlot, setSelectedPickupSlot] = React.useState();

  const getDayLabel = React.useCallback((offset) => {
    const dayOfWeek = new Date(currentTimeMS).getDay();
    if (offset === 0) {
      return 'TODAY';
    } else if (offset === 1) {
      return 'TOMORROW';
    }
    // Use Monday, Tuesday, etc.
    const weekdayNum = (dayOfWeek + offset) % 6;
    const label = daysOfWeek[weekdayNum];
    return label.charAt(0).toUpperCase() + label.slice(1);
  }, [currentTimeMS]);

  /**
   * Determine the valid/available pickup time slots
   */
   const timeSlotCallback = React.useCallback((response) => {
    // e.g. 2 for Tuesday
    const todayNum = new Date(currentTimeMS).getDay();     
    if (response.pickup_time_slots_for_week) {
      const slotsByDay = response.pickup_time_slots_for_week
      // e.g. starting Tuesday
      const weeklySlotsStartingToday = [ 
        slotsByDay[daysOfWeek[todayNum]],
        slotsByDay[daysOfWeek[(todayNum + 1) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 2) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 3) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 4) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 5) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 6) % 7]]
      ];
      
      // Display only bookable days
      const maxDaysOut = getMaxDaysOut(weeklySlotsStartingToday, currentTimeMS);
      const validSlots =  weeklySlotsStartingToday.slice(0, maxDaysOut);
      setTimeSlotsByDay(validSlots);
     
      // Get first available slot or -1
      const defaultSlot = getDefaultSlotForToday(validSlots[0], currentTimeMS);  
      
      // Handle SAME-DAY ORDERING ONLY when we're outside ordering hours
      if (maxDaysOut <= 1) {
        // Loop thru days until we find a day/timeslot when ordering is available
        let openTime, dayIdx = 0;
        while (!openTime && dayIdx < weeklySlotsStartingToday.length) {
          openTime = getEarliestOrderTime(weeklySlotsStartingToday[dayIdx], dayIdx, currentTimeMS);    
          if (!openTime) {
            dayIdx++;  
          }   
        }
        if ((validSlots.length === 0 && defaultSlot === -1) ||  /* store not open yet */
             dayIdx === 1) {                                    /* store closed for day  */
          const openAtDate = new Date(openTime);
          setOrdersEnabledTime(getFormattedTimeLabel(openAtDate.getHours(), openAtDate.getMinutes()));
          setOrdersEnabledDay(getDayLabel(dayIdx));          
          setOrdersDisabled(true);
        }
      }
      // Store doesn't accept orders outside of biz hours and store is not open yet.
      if (validSlots.length === 0 && defaultSlot === -1) {
        setDefaultSlot(STORE_NOT_OPEN_YET);
      } else {
        setDefaultSlot(defaultSlot);
      }
    }
  }, [currentTimeMS, getDayLabel]);  

  const controllerRef = React.useRef(new AbortController());

  // Fetch Pickup Slots  
  React.useEffect(() => {
    const controller = controllerRef.current || new AbortController();
    if (!timeSlotsByDay.length && defaultSlot !== STORE_NOT_OPEN_YET) { 
      fetchPickupWindows(dispensaryId, timeSlotCallback, controller);
    }
    // cancel fetch on unmount
    return () => controller.abort();
  }, [dispensaryId, defaultSlot, timeSlotsByDay.length, timeSlotCallback]);

  return (
    <PickupSlotsContext.Provider value={{
      ordersDisabled,
      ordersEnabledTime,
      ordersEnabledDay, 
      defaultSlot,
      timeSlotsByDay,
      selectedPickupSlot,
      setSelectedPickupSlot
    }}>
      {children}
    </PickupSlotsContext.Provider>
  );
};

export default PickupSlotsProvider;