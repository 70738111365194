import React, { useState } from "react";

import { auth } from "../../firebase";
import { devLog } from "../util/util";
import { UrlParamsContext } from "../providers/UrlParamsProvider";
import { navigateTop, navigateToSignUp } from "../routing/router-utils";
import { helpText } from './registration-errors';
import { trackEvent } from '../analytics/tracking';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import BackLink from "../common/BackLink";
import TextField from '@material-ui/core/TextField';
import Button from "../styleguide/Button";
import ActionLink from "../styleguide/ActionLink";
import DetourButton from "../styleguide/DetourButton";
import SendResetEmailForm from '../resetPassword/SendResetEmailForm';

import styles from './Registration.module.css';
/* material UI styles */
import './MaterialUI.module.css';

// TODO: remove withHeader, should always be false
const SignIn = ({location}) => {
  
  // For back links etc.
  const { dispensaryId } = React.useContext(UrlParamsContext);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const [showPasswordReset, setShowPasswordReset] = React.useState(false);
  
  // Within the app return user to referrer path
  const [returnToPath, setReturnToPath] = React.useState();
  const [continuePath, setContinuePath] = React.useState();
  
  React.useEffect(() => {
    // Set paths using router state/props
    const { returnToPath:backPath, continuePath:fwdPath } = location?.state || 
      {
        // Default to home page
        returnToPath: `/menu/${dispensaryId}`,
        continuePath: `/menu/${dispensaryId}` 
      };
    if ( backPath !== returnToPath ||
         fwdPath !== continuePath ) {
      devLog(`return path: ${backPath}`);
      devLog(`continue path: ${fwdPath}`);
      setReturnToPath(backPath);
      setContinuePath(fwdPath);
    }
  }, [location, returnToPath, continuePath, dispensaryId]);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    
    // The Modal Login in the PageHeader is pre-router, so we'll use window.location
    const signInPath = window.location.pathname;
    
    auth.signInWithEmailAndPassword(email, password).then(function() {
      
      trackEvent('login', `from_${signInPath}`);
      
      // We may be continuing to /placeOrder etc.
      const successPath = continuePath || returnToPath;
      // replace:true to remove history entry for login form 
      navigateTop(successPath, { replace: true });
      
    }).catch(error => {
      trackEvent('error_invalid_login', signInPath);
      setError(helpText['login']); // Invalid login or password
    });
 
  };
    
  const onChange = event => {
    const {id, value} = event.currentTarget;
    setError(false);
    if(id === 'loginEmail') {
      setEmail(value);
    } else if(id === 'loginPassword'){
      setPassword(value);
    }
  };

  return (
    <>    
      {/* toggle between sign in and forgot password */}
      { showPasswordReset
        ? <SendResetEmailForm handleCancel={() => setShowPasswordReset(false)} />
        : <div className={styles.modalForm}>
            
            <BackLink backToText="Back" backToPath={returnToPath} />
          
            <div className={`${styles.headerWrap} ${styles.nonModal}`}>
              <PrimaryHeader isCentered text="Sign In" />
            </div>

            { error && 
              <div className={styles.formError}>{error}</div>
            }
            
            <form>
              <TextField id="loginEmail" value={email} label="Email" error={false} 
                type="email" variant="filled"  onChange={onChange} />
              <TextField id="loginPassword" value={password} label="Password" error={false}
                type="password" variant="filled" onChange={onChange} />  
                
              <div className={styles.loginForgotPassword}>
                <ActionLink text="Forgot your password?" handleClick={() => setShowPasswordReset(true)} />
              </div>
      
              <div className={styles.signInWrap}>
                <Button
                  text="Sign In"
                  withStyle={{width:'100%', fontSize:'1.25rem', padding:'1rem'}}
                  handleClick = {(event) => {
                    signInWithEmailAndPasswordHandler(event, email, password);
                  }} />
              </div>  
            </form>
              
            <div className={styles.twoCol}>
              <span className={styles.signInLink}>Don't have a Zip Run account?</span>
              <span className={styles.formAlignRight}>
                <DetourButton
                  text="Sign Up" 
                  handleClick={() => {
                    const stateInfo = { state: { returnToPath: window.location.pathname}};
                    navigateToSignUp(dispensaryId, stateInfo);
                  }} />
              </span>
            </div>
          </div>
      }  
    </>
  );  
};

export default SignIn;