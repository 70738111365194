import React from 'react';

import { getFormattedAddress, getStaticMapUrl } from '../googlemaps/google-maps-utils';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import PropTypes from 'prop-types';

import styles from '../checkout/Cart.module.css';

const OrderPickupMap = ({
  dispensaryId,
  dispensaryName,
  dispensaryLocation={}
}) => {

  // display modal map on map click
  const { displayModal } = React.useContext(ModalContext);

  const mapUrl = getStaticMapUrl(dispensaryLocation, 16, [500,290]);
  const formattedAddress = getFormattedAddress(dispensaryLocation);
  
  return (
    <div className={styles.pickupMapWrapper}>
      <div className={styles.pickupMapBox} 
           onClick={() => displayModal(
             ModalType.MAP, 
             {dispensaryName, formattedAddress})}>
        <div className={styles.mapAddress}>
          <div className={styles.addressLabel}>LOCATION:</div> 
          <div>{dispensaryLocation.street_address}, {' '}{dispensaryLocation.city}</div>
        </div>
        <img alt="map" className={styles.mapImage} src={mapUrl} />
      </div>
    </div>
  )
}

OrderPickupMap.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  dispensaryLocation: PropTypes.object.isRequired
};

export default OrderPickupMap;