import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import { PickupSlotsContext } from '../timeSlots/PickupSlotsProvider';

import PropTypes from 'prop-types'; 

const useStyles = makeStyles(theme => ({
  msgWrap: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#54a40a33',
    font: 'var(--zrFont)',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '120%',
    margin: '1rem 0',
    padding: '1.25rem',
    borderRadius: '.5rem',
  },
  msgText: {
    paddingLeft: '1rem'  
  },
  msgPrimary: {
    display: 'inline'  
  },
  msgSecondary: {
    marginLeft: '4px'  
  }
}));

/**
 * Banner to inform users that the dispensary is not accepting orders at this hour
 * 
 * @param {string} dispensaryName - The name of the dispensary
 * @returns 
 */
const StoreNotOpenBanner = ({
  dispensaryName,
}) => {

  const classes= useStyles();

  // Handle dispensary not accepting orders at this hour
  const { ordersEnabledTime, ordersEnabledDay } = React.useContext(PickupSlotsContext);

  return (
    ordersEnabledDay && ordersEnabledTime
    ? <div className={classes.msgWrap}>
        <div><AccessTimeRoundedIcon style={{display:'block', fontSize:'42px'}}/></div>
        <div className={classes.msgText}>
          <span className={classes.msgPrimary}>
            <b>{dispensaryName}</b> is not open for online orders at this hour.
          </span>
          { ordersEnabledTime &&
            <span className={classes.msgSecondary}>
              You can place your order <b>{ordersEnabledDay}</b> after <b>{ordersEnabledTime}</b>.
            </span>
          }     
        </div>
      </div>  
    : null
  )
}

StoreNotOpenBanner.propTypes = {
  dispensaryName: PropTypes.string.isRequired,
} 

export default StoreNotOpenBanner;