import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { handleMissingProductImage } from './product-utils';
import MissingImageLabel from './MissingImageLabel';
import PropTypes from 'prop-types';

import styles from './PreviewProductImage.module.css';

// const cdnPrefix = 'https://ziprun.com/cdn-cgi/image/width=300,height=300/';
export const optimizedImage = (src, maxWidth) => {
  // handle data/uri
  if (src && src.substring(0,4).toLowerCase().startsWith('data')) {
    return src;
  }
  const imageWidth = maxWidth || 400;
  // Handle localhost/dev mode 
  if (src?.startsWith('https')) {
    return `https://ziprun.com/cdn-cgi/image/width=${imageWidth}/${src}`;
  } else {
    return src;
  }
};

/**
 * Displays product image or fallback
 *
 * @param {string} productName - name for alt attribute
 * @param {string} src - image src
 * @param {string} category - for use with fallback image
 */
const PreviewProductImage = ({
  productName, 
  src,
  category
}) => { 
 
  // Sometimes CloudFlare returns 503 or even a 404 during outtages...  
  const handleError = (event) => {
    trackEvent('img_load_error', event.currentTarget.src);
    // Switch to non-CDN image:
    setImgSrc(imgSrcNoCDN);
    setIsFallbackImage(true);
    // we only need to log once
    event.currentTarget.onError = null;
  };
  
  const [imgSrc, setImgSrc] = React.useState();
  const [imgSrcNoCDN, setImgSrcNoCDN] = React.useState();
  const [isFallbackImage, setIsFallbackImage] = React.useState();

  React.useEffect(() => {
    // Handle fallback image, and store fallback in case CDN fails (onError)
    if (!imgSrc) {
      const { imgUrl, fallbackUrl, isFallback } = handleMissingProductImage(src, category);
      setImgSrc(optimizedImage(imgUrl, 400));
      setImgSrcNoCDN(fallbackUrl);
      setIsFallbackImage(isFallback); 
    }
  },[src, category, imgSrc]);

  return ( 
    <div className={styles.imageOuter}>
      <div className={styles.imageWrap}>
        { imgSrc
          ? <>
              <img
                alt={productName}
                className={styles.productImage}
                src={imgSrc}
                onError={(event) => handleError(event)}
                />
          </>
          : null 
        }   
      </div>
      { isFallbackImage &&
        <div className={styles.placeholderMessage}> 
          <MissingImageLabel />
        </div>
      }
    </div>
  )
}

PreviewProductImage.propTypes = {
  productName: PropTypes.string.isRequired,
  src: PropTypes.string,
  category: PropTypes.string
}

export default PreviewProductImage;