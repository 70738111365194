import * as React from 'react';

import { FilteredProductsContext, subCategoryAll, SHOW_ALL } from '../products/FilteredProductsProvider';
import DispensaryProduct from '../products/DispensaryProduct';
import ResetFiltersMessage from './ResetFiltersMessage';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

/**
 * This component displays the dispensary's filtered product data from the provider.
 *  
 * @param {string} dispensaryId - The dispensary ID (key) of the current dispensary
 * @param {function} handleClick - The add-to-cart handler
 */ 
const ProductListing = ({
  dispensaryId, 
  filteredProducts,
  handleClick
}) => {
 
  const { categoryName, setFilters, isFiltered, loading } = React.useContext(FilteredProductsContext);

  return ( loading
    ? <div style={{flexBasis:'100%'}}> 
        <Spinner />
      </div>
    : filteredProducts?.length === 0
      ? isFiltered
        ? <ResetFiltersMessage 
            category={categoryName}
            resetFilters={() => setFilters({
              subCategory:subCategoryAll, 
              brand: SHOW_ALL,
              type: SHOW_ALL
            })} />
        : <div style={{flexBasis:'100%'}}>There are currently no <b>{categoryName}</b> products available.</div>
      : <>
          { filteredProducts
            ? filteredProducts.map(product => {
                return <DispensaryProduct
                      key={product.id}
                      Id={product.id}
                      brand={product.brand}
                      {...product.display_info}
                      is_featured={!!product.is_featured} // value is true or undefined
                      grams={product.grams}
                      leaf_logix_pricing_tier={product.leaf_logix_pricing_tier}
                      dispensaryId={dispensaryId}
                      handleClick={(quantity) => {
                        handleClick(product.id, product, quantity)
                      }} />
              })
            : <div style={{flexBasis:'100%'}}>There are currently no <b>{categoryName}</b> products available.</div>
          }
        </>
  );
}

ProductListing.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  filteredProducts: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default ProductListing;