import { auth } from '../../firebase';

import { queueForSessionReady } from '../providers/provider-utils';
import { dispensaryDetailEndpoint, dispensariesEndpoint, metadataEndpoint, dispensaryProductsEndpoint, pickupWindowsEndpoint, deliveryOptionsEndpoint, dispensaryProductDetailEndpoint,  productCategoriesEndpoint } from '../../constants/api-endpoints';
import { trackEvent } from '../analytics/tracking';

const assetInfo = new Map(Object.entries({
  'berkshire roots':'berkshire-roots',
  'green herbs store':'green-herbs-store'
}));

// TODO: Remove method
export const getDispensaryAsset = (dispensaryName, suffix) => {
  if (assetInfo.has(dispensaryName)) {
    return `${assetInfo.get(dispensaryName)}${suffix}`;
  } else {
    return `${assetInfo.values().next().value}${suffix}`;
  }
};

// Used in setting default widths 
export const DispensaryAsset = {
  LOGO: 'logo',
  HERO: 'hero'
};

// sample cdn url = 'https://ziprun.com/cdn-cgi/image/width=300,height=300/';
export const getOptimizedAsset = (src, assetType) => {
  // handle data/uri
  if (src && src.substring(0,4).toLowerCase().startsWith('data')) {
    return src;
  }
  const imageWidth = assetType === DispensaryAsset.LOGO ? 300 : 1200; 
  return `https://ziprun.com/cdn-cgi/image/width=${imageWidth}/${src}`;
};

// The "All Dispensaries" endpoint is used to request non-product data for all dispensaries
// I'm adding include_inactive=true to help with NEA on-boarding
const allDispensariesRequest = (callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(dispensariesEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error)); 
    });
  }
};

// Used to request dispensary details like delivery windows etc.
const dispensaryDetailRequest = (dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryEndpoint = dispensaryDetailEndpoint.replace(/\[dispId\]/,dispensaryId); 
      fetch(dispensaryEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error)); 
    });
  }
};

// e.g. get the dispensary promo text
export const dispensaryEndpointRequest = (endpoint, dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryEndpoint = endpoint.replace(/\[dispId\]/,dispensaryId); 
      fetch(dispensaryEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error)); 
    });
  }
};

export const fetchPickupWindows = (dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryPickupWindowsEndpoint = pickupWindowsEndpoint.replace(/\[dispId\]/, dispensaryId); 
      
      fetch(dispensaryPickupWindowsEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error)); 
    });
  }
};

export const fetchDeliveryOptions = (dispensaryId, callback, controller) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      const dispensaryDeliveryOptionsEndpoint = deliveryOptionsEndpoint.replace(/\[dispId\]/, dispensaryId); 
      fetch(dispensaryDeliveryOptionsEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => callback(data))
        .catch(error => console.log(error)); 
    });
  }
};

/**
 * Fetch a single dispensary from the all dispensaries endpoint
 * 
 * @param {string} dispensaryId - the desired dispensary
 * @param {function} callback 
 * @param {AbortController} controller 
 */
export const fetchDispensary = async (dispensaryId, callback, controller) => {
  try {
    // get the requested dispensary and run supplied callback
    //const filterAndRunCallback = (data) => {
    //  const [dispensary] = data['data'].filter(disp => disp.id === dispensaryId); 
    //  callback(dispensary);
    //} 
    
    if (typeof auth === 'undefined' || !auth.currentUser) {
      queueForSessionReady(() => {
        dispensaryDetailRequest(dispensaryId, callback, controller);
      });
    } else {
      dispensaryDetailRequest(dispensaryId, callback, controller);
    }
  } catch(error) {
    trackEvent('error_requesting_dispensary', error);
    console.log('dispensary fetch failed');
  }   
};

/**
 * Fetch all dispensaries from the endpoint
 * 
 * @param {function} callback 
 * @param {AbortController} controller 
 */
export const fetchDispensaries = async (callback, controller) => {
  try {
    if (typeof auth === 'undefined' || !auth.currentUser) {
      queueForSessionReady(() => {
        allDispensariesRequest(callback, controller);
      });
    } else {
      allDispensariesRequest(callback, controller);
    }
  } catch(error) {
    trackEvent('error_requesting_dispensaries', error);
    console.log('dispensaries fetch failed');
  }    
};

/**
 * Fetch the total cost calc data
 * 
 * @param {function} callback 
 * @param {AbortController} controller 
 */
export const fetchMetaData = async (callback, controller) => {
  
  const metadataRequest = (callback, controller) => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
        fetch(metadataEndpoint, {
          method: 'GET',
          headers: {
            'Authorization': idToken
          },
          signal: controller.signal
        }).then(response => response.json())
          .then(data => callback(data))
          .catch(error => console.log(error)); 
      });
    }
  };
  
  try {
    if (typeof auth === 'undefined' || !auth.currentUser) {
      queueForSessionReady(() => {
        metadataRequest(callback, controller);
      });
    } else {
      metadataRequest(callback, controller);
    }
  } catch(error) {
    trackEvent('error_requesting_metadata', error);
    console.log('metadata fetch failed');
  }    
};

/**
 * Fetch product categories
 * Executing the callback on a non-200 reponse can cause race conditions so don't do it. 
 *  
 * SessionReady has already been checked
 */
export const fetchProductCategories = async (callback, controller) => {
  const categoryFetch = (callback, controller) => { 
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(productCategoriesEndpoint, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => {
        if (response.status !== 200) {
          trackEvent(`error_requesting_product_categories_${response.status}`);
          console.log(`Error! ${response.status}`);
        } else {
          response.json().then(data => {
            if (callback) { 
              callback(data);
            }  
          });
      }}).catch(error => console.log(error)); 
    });
  };
  if (typeof auth === 'undefined' || !auth.currentUser) {
    queueForSessionReady(() => {
      categoryFetch(callback, controller);
    });
  } else {
    categoryFetch(callback, controller);
  }
};

// For debouncing product fetch
let productFetchTask; 

/**
 * Fetch products for a single dispensary
 * Executing the callback on a non-200 reponse can cause race conditions so don't do it. 
 *  
 * SessionReady has already been checked
 */
export const fetchProducts = async (dispensaryId, callback, controller) => {
  
  const productRequest = (dispensaryId, callback, controller) => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
        const productEndpoint = dispensaryProductsEndpoint.replace(/\[dispId\]/,dispensaryId); 
        /* enable inactive in dev - optional param */ 
        const params = {
          include_inactive: !!window.tests_enabled
        };
        const paramString = new URLSearchParams(params).toString();
        
        fetch(`${productEndpoint}?${paramString}`, {
          method: 'GET',
          headers: {
            'Authorization': idToken
          },
          signal: controller.signal
        }).then(response => {
          if (response.status !== 200) {
            trackEvent(`error_requesting_dispensary_products_${response.status}`);
            console.log(`Error! ${response.status}`);
          } else {
            response.json().then(data => {
              if (callback) {
                callback(data);
              }
            });
        }}).catch(error => console.log(error)); 
      });
    }
  };
  
  try {
    window.clearTimeout(productFetchTask);
    productFetchTask = window.setTimeout(() => {
      console.log('REQUESTING PRODUCT DATA');
      productRequest(dispensaryId, callback, controller);
    }, 100);
  } catch(error) {
    trackEvent('error_requesting_dispensary_products', error);
    console.log('dispensary product fetch failed');
  }    
};

// Fetch a single product
export const fetchProductDetail = async (productId, dispensaryId, callback, controller) => {
  const productDetailRequest = (productId, callback, controller) => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
        const productDetailUrl = dispensaryProductDetailEndpoint
          .replace(/\[dispId\]/, dispensaryId);
        fetch(`${productDetailUrl}?product_id=${productId}`, {
          method: 'GET',
          headers: {
            'Authorization': idToken
          },
          signal: controller.signal
        }).then(response => response.json())
          .then(data => callback(data))
          .catch(error => console.log(error)); 
      });
    }
  };
  
  try {
    // need a user (anonymous ok) for this
    if (typeof auth === 'undefined' || !auth.currentUser) {
      queueForSessionReady(() => {
        productDetailRequest(productId,callback, controller);
      });
    } else {
      productDetailRequest(productId,callback, controller);    
    }
  } catch(error) {
    trackEvent('error_requesting_dispensary_product_detail', error);
    console.log('dispensary product detail fetch failed');
  }    
};
