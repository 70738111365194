import * as React from "react";

import { formatPhoneNumber } from 'react-phone-number-input'
import { verifyCode } from '../registration/registration-utils';
import TextField from '@material-ui/core/TextField';
import Button from '../styleguide/Button';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types'; 

import styles from './EnterVerificationCode.module.css';

const EnterVerificationCode = ({phoneNumber, onVerified, closeModalFn}) => {
  
  const [code, setCode] = React.useState('');
  const [isCodeError, setIsCodeError] = React.useState();
  const [codeErrorMsg, setCodeErrorMsg] = React.useState();
  
  const sendCode = event => {
    event.preventDefault();

    // handle empty input
    if (!code) {
      setIsCodeError(true);
      setCodeErrorMsg('Please enter the code sent to your phone.')
      return;
    }

    const codeInfo = {
      code,
      tel_number: phoneNumber
    }; 
    
    const callback = ((response) => { 
      if ( response.success ) {
        onVerified({
          success: true,
          tel_number: phoneNumber
        });
      } else if(response.error) {
        setIsCodeError(true);
        setCodeErrorMsg(response.error);  // Did not match, try again
      } else {
        // close modal and display error on phone number form
        onVerified({
          error: true
        });
        closeModalFn();
      }
    });
    const controller = new AbortController();
    
    // Send the user response
    verifyCode(codeInfo, callback, controller);
  };
 
  /* Clear errors on second attempt */
  const handleInputOnFocus = () => {
    setIsCodeError(false);        
  };

  const handleInputOnBlur = () => {
    setIsCodeError(false);      
  };

  /**
   * Update email as it's entered
   * @param {event} event 
   */
  const onChange = event => {
    const { name, value:code } = event.currentTarget;
    if (name === "verificationCode") {
      setCode(code);
    } 
  };     

  // Display the phone number without the country code  
  const formattedNumber = formatPhoneNumber(phoneNumber);
 
  return (
    <>
      <div className={styles.verificationHeader}>
        Please enter the code sent to: <span className={styles.phoneNumber}>{formattedNumber}</span></div>
      <form className={`${styles.verificationForm} ${styles.plainInputs}`}>
        <TextField id="verificationCode" 
                   name="verificationCode" 
                   value={code} 
                   label="Enter Verification Code" 
                   error={isCodeError} 
                   onBlur={handleInputOnBlur}
                   onFocus={handleInputOnFocus} 
                   variant={'filled'} onChange={onChange} />
        
        { isCodeError && 
          <div className={styles.codeError}>
            <ErrorIcon style={{verticalAlign:'-6px', color:'#d80303'}} /> 
            <span>{codeErrorMsg}</span>
          </div>
        }
        <div className={styles.buttonWrap}>
          <Button isCentered text="Submit Code" handleClick={sendCode} />
        </div>
      </form>
    </>
  );
};

EnterVerificationCode.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  onVerified: PropTypes.func.isRequired,
  closeModalFn: PropTypes.func.isRequired
};

export default EnterVerificationCode;