import * as React from 'react';

import { sendMessage, navigationMessageHandler } from '../embedMenu/embed-utils';
import { freezeBody, unfreezeBody } from '../modal/modal-utils';
import { CartItemsContext } from './CartItemsProvider';
import closeX from '../../assets/closeX.png';
import CartIcon from './CartIcon';
import EmbeddedCart from '../embedMenu/EmbeddedCart';
import PropTypes from 'prop-types';

import styles from './SlideInCart.module.css'; 

const SlideInCart  = ({forceClose}) => {

  const { cartItems, itemTotal } = React.useContext(CartItemsContext);
  const [isOpen, setOpen] = React.useState();

  // Listen to cart messages from host page url
  React.useEffect(() => {
    const toggleCartMessage = navigationMessageHandler('Cart', () => {
      const cartIsOpen = !!document.querySelector('div[class*="SlideInCart_open"]');
      setOpen(!cartIsOpen);
      document.body.setAttribute('zr-cart-open', !cartIsOpen);
    });
    window.addEventListener("message", toggleCartMessage, false);
    return () =>  window.removeEventListener("message", toggleCartMessage);
  }, []);

  // Update cart icon on footer nav
  React.useEffect(() => {
    sendMessage({cartCount: cartItems.length});
  }, [cartItems]);

  const toggleOpen = () => {
    // Only freeze body on mobile 
    if (window.innerWidth <= 480) {
      if (isOpen) {
        unfreezeBody();
      } else {
        freezeBody();
      }
    }
  
    document.body.setAttribute('zr-cart-open', !isOpen);

    // Make sure that a header/footer slide-in cart is not already open.
    if (!isOpen) {
      const winWidth = window.innerWidth;
      const carts = document.querySelectorAll('#MENU_CART')
      const [alreadyOpen] = [ ...carts].filter(cart => {
        return cart && cart.getBoundingClientRect().x <= winWidth; 
      });  
      if (alreadyOpen) {
        return;
      }
    }
    setOpen(!isOpen); 
  };

  let openStyle = isOpen ? styles.open : ''; 
  
  // If we are forcing cart closed via prop (FooterNav)
  if (forceClose) {
    if (isOpen) {
      setOpen(false);
    }
    openStyle = '';
    unfreezeBody();
  }
  
  return (
    <>
      <span className={styles.iconWrap} onClick={toggleOpen}>
        { itemTotal > 0 &&
          <span className={styles.cartTotal}>${parseFloat(itemTotal/100).toFixed(2)}</span>
        }
        <CartIcon items={cartItems} />
      </span>
      <div className={`${styles.slideInCart} ${openStyle}`}> 
        <div className={styles.cropShadow}>
          <button className={styles.closeIcon} onClick={toggleOpen} type="button" size="16" kind="BUTTON/PLAIN" aria-label="Close">
            <img alt="" className={styles.closeX} src={closeX} />
            <span className={styles.closeText}>Close</span>
          </button>
          <EmbeddedCart 
            isInMenu={true} 
            closeMenuFn={toggleOpen} />
        </div>
      </div>
    </>
  );
}

SlideInCart.propTypes = {
  forceClose: PropTypes.bool
};

export default SlideInCart;