import * as React from 'react';

import Button from './Button';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

// Min order not met or undeliverable zip code
const DisabledButtonWithReason = ({
  text, 
  reason,
  isFullWidth=false
}) => {

  const btnStyle = isFullWidth 
    ? { minWidth: '100%'}
    : {}

  return (
    <>
      <Button 
        text={text}
        isCentered
        withStyle={btnStyle} 
        disabled
        handleClick={e => e.preventDefault()} />
      <div className={styles.disabledMsg}>
        <sup className={styles.disabledMsgSup}>*</sup>
        {reason}
      </div>  
    </>
  );
};

DisabledButtonWithReason.propTypes = {
  text: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  isFullWidth: PropTypes.bool
};

export default DisabledButtonWithReason;