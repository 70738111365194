import * as React from 'react';
import config from '../../config';
import { logWithIntercom } from '../intercom/intercom-utils';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { validateSignUpEntry } from '../registration/validation-utils';

import PropTypes from 'prop-types';

export const GuestCheckoutContext = React.createContext();

const GuestCheckoutProvider = ({children}) => {
  // Guest Checkout Form
  const [name, setName] = React.useState('');
  const [nameErr, setNameErr] = React.useState();
  const [birthDate, setBirthDate] = React.useState('');
  const [birthDateErr, setBirthDateErr] = React.useState();
  // PhoneNumberInline component
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberErr, setPhoneNumberErr] = React.useState();
  // Success Message - for handling post registration message 
  const [showSuccessMessage, setShowSuccessMessage] = React.useState();
  const [validationErrorMsg, setValidationErrorMsg] = React.useState(
    'Please provide your: Full Name, Phone Number, Birth Date.'
  );
  // Referrer - track where the signup came from
  const [referrer, setReferrer] = React.useState(); 

  /**
   * Validate a single form input.
   * 
   * @param {string} inputId - input id
   * @param {string} value - input value
   */
  const isValidEntry = (inputId, value, errorSetter) => {
    const isValid = validateSignUpEntry(inputId, value);
    if (errorSetter) {
      errorSetter(!isValid);
    }
    return isValid;
  }; 

  /**
   * Validate a BirthDate
   *  
   * @param {string} value - MM/DD/YYYY
   * @returns boolean
   */
  const isValidBirthDate = (value, suppressErrors) => {
    /**
     * Validate the date by comparing the Month/Year after setting the values
     * (An invalid Feb 30 date will resolve to March 2)
     */
    const dateItems = typeof value === "undefined" ? [] : value.split('/');
    let isValid = false;
    if (dateItems.length === 3 && dateItems[2].length === 4) {
      const bDay = new Date(`${dateItems[2]}/${dateItems[0]}/${dateItems[1]}`);
      const birthYear = bDay.getFullYear();
      const currentYear = new Date().getFullYear();
      isValid = birthYear === parseInt(dateItems[2], 10) &&
                birthYear > 1900 && birthYear < currentYear && 
                bDay.getMonth() === parseInt(dateItems[0], 10) - 1;
                // The bDay.getMonth() check ensures the user entered a valid day
    }
    if (!suppressErrors) {
      setBirthDateErr(!isValid);
    }
    return isValid;
  };

  const isValidPhoneNumber = (value, suppressErrors) => {
    // This is the less strict validation to reduce false negatives
    // See: https://gitlab.com/catamphetamine/react-phone-number-input#isvalidphonenumbervalue-string-boolean
    const isValid = typeof value === "string" && isPossiblePhoneNumber(value);
    if (!suppressErrors) {
      setPhoneNumberErr(!isValid);
    }
    return isValid;
  }

  /**
   * Flush registration data after sign up (except location)
   */
  const resetFormData = () => {
    // Use empty string for controlled inputs
    setName('');
    setNameErr(false);
    setBirthDate('');
    setBirthDateErr(false);
    setPhoneNumber('');
    setPhoneNumberErr(false); 
  }

  const buildValidationErrorMsg = (validName, validDOB, validPhone) => {
    const invalidFields = [];
    if(!validName) invalidFields.push('Full Name');   
    if(!validPhone) invalidFields.push('Phone Number'); 
    if(!validDOB) invalidFields.push('Birth Date'); 
    return invalidFields.length 
      ? `Please provide your: ${invalidFields.join(', ')}`
      : '';
  }

  /**
   * Validate all required form inputs
   */
  const isValidGuestInfo = () => {
    const validName = isValidEntry('name', name, setNameErr);
    const validDOB = isValidBirthDate(birthDate);
    const validPhone = isValidPhoneNumber(phoneNumber);
    
    if (config.IS_DEV) {
      console.log(`Validating...`);
      console.log(`valid name: ${validName}`);
      console.log(`valid phone is ${validPhone}`);
      console.log(`valid DOB: ${validDOB}`);
    }

    const isValid = validName && validPhone && validDOB;  
    if (!isValid) {
      setValidationErrorMsg(buildValidationErrorMsg(validName, validDOB, validPhone));
    } else {
      // Generate a unique guest+{something}@ziprun.com email
      const letters = ["o", "b", "f", "u", "s", "c", "a", "t", "e", "d"];
      const phoneNumbers = phoneNumber.substring(1).split('').reverse();
      const uniqueId = phoneNumbers.reduce((array, digit) => {
        array.push(letters[parseInt(digit,10) || "!"]);
        return array;
      }, []);
      const fauxEmail = `guest+${uniqueId.join('')}@ziprun.com`;
      const userCreationTS = parseInt(new Date().getTime()/1000, 10);
      const actionObj = {
        Guest_Checkout: true
      };
      // Add user to Intercom dashboard (using fake email)
      logWithIntercom(name, fauxEmail, userCreationTS, actionObj);
      
      setValidationErrorMsg('');
    }
    return isValid; 
  };  

  // Capture the referrer site for logging on reg complete
  React.useEffect(() => {
    if (document.referrer && !referrer) {
      try {
        const parts = document.referrer.split('/');
        const host = parts.length > 1 ? parts[2].split('.') : [];
        const domain = host.length > 2 ? host[1] : host[0];  
        if (domain) {
          setReferrer(domain);
        }
      } catch(e) {}
    }
  },[referrer]);

  let validateRef = React.useRef();
  
  // This is a simple non-empty validation, full validation is on submit. 
  React.useEffect(() => {
    window.clearTimeout(validateRef.current);
    validateRef.current = window.setTimeout(() => {
      // Validate without setting visible errors here:
      const validName = isValidEntry('name', name, ()=>{});
      const validDOB = birthDate?.length > 7;
      const validPhone = phoneNumber?.length > 11;
      const isValid = validName && validPhone && validDOB;  
      if (isValid) {
        setValidationErrorMsg('');
      } else {
        console.log('validate guest info');
        setValidationErrorMsg(buildValidationErrorMsg(validName, validDOB, validPhone));
      }
    }, 300);  
  }, [name, phoneNumber, birthDate, setValidationErrorMsg]);

  return <GuestCheckoutContext.Provider value={{
      name,
      setName,
      nameErr,
      setNameErr,
      birthDate,
      setBirthDate,
      birthDateErr,
      setBirthDateErr,
      phoneNumber,
      setPhoneNumber,
      phoneNumberErr,
      setPhoneNumberErr,
      isValidGuestInfo,
      resetFormData,
      showSuccessMessage, 
      setShowSuccessMessage,
      validationErrorMsg,
      referrer,
      setReferrer
    }}>
    {children}
  </GuestCheckoutContext.Provider>
}

GuestCheckoutProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default GuestCheckoutProvider;