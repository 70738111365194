import * as React from "react";

import useModal from "./useModal";
import DispensaryMapModal from './DispensaryMapModal';
import SignInModal from './SignInModal';

export const ModalContext = React.createContext();

export const ModalType = {
  MAP: 'map',
  LOGIN: 'login',
  NONE: 'none'
};

const ModalProvider = ({ children }) => {
  const { modalType, modalProps, displayModal } = useModal();
  return (
    <ModalContext.Provider value={{ modalType, modalProps, displayModal }}>
      { modalType === ModalType.MAP && 
        <DispensaryMapModal 
          dispensaryName={modalProps.dispensaryName} 
          formattedAddress={modalProps.formattedAddress} 
          displayModal={displayModal} />
      }
      { modalType === ModalType.LOGIN &&
        <SignInModal 
          navigateTo={modalProps.navigateTo}
          displayModal={displayModal} />  
      }
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;