import * as React from 'react';

import { devLog } from '../util/util';
import { navigateTop } from './router-utils';
import useHostDomain from '../messaging/useHostDomain';
import { sendMessage } from '../embedMenu/embed-utils';

// Navigate and message host ( for scrollIntoView on host page )
const useMenuNavigation = () => {
  
  const hostDomain = useHostDomain();
  
  const menuNavigateTop = React.useCallback((path, options={}) => {
    
    /**
     * Passing { page } data to the host page allows us
     * to sync the host page footer state with the current
     * menu page in the iframe
     */
    const pathItems = path.split('/');
    let page = 'new'; // e.g. not relevant for host page footer state
    if (pathItems.length === 3) {
      /* host/menu/id/ */
      page = 'home';
    } else if (pathItems.length === 4) {
      /* host/menu/id/page */
      page = pathItems[3] || 'home';
    } 
    // Message the host
    sendMessage({page}, hostDomain); 
    
    // Navigate seems to want to be last
    devLog(`SENDING PAGE: ${path} to host ${hostDomain}`);
    navigateTop(path, options); 
  
  }, [hostDomain]);

  return menuNavigateTop;
}

export default useMenuNavigation;
