import * as React from 'react';

import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { navigateTop } from '../routing/router-utils';
import { OrderType, getPaymentLabel } from '../checkout/order-utils';
import { OrderContext } from './OrderProvider';
import DisplayModal from '../common/DisplayModal';
import CancelOrderButton from './CancelOrderButton'; 
import Button from '../styleguide/Button';
import OrderStatusHelp from './OrderStatusHelp';
import DispensaryProduct from '../products/DispensaryProduct';
import AddedToCartCheck from '../products/AddedToCartCheck';
import HelpRounded from '@material-ui/icons/HelpRounded';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

import styles from './YourOrders.module.css';
import { trackEvent } from '../analytics/tracking';

/**
 * Display single order details and provide a cancel option
 */
const OrderDetails = ({
  id, 
  type, 
  created_at, 
  status, 
  dispensary_id, 
  items=[], 
  costs={}, 
  delivery_schedule={},
  payment_details={},
  pickup_time_slot
}) => {
 
  const { activeOrders } = React.useContext(OrderContext);
  
  const { addToCart } = React.useContext(CartItemsContext);
  const { dispensary } = React.useContext(DispensaryContext);

  const [orderCanceled, setOrderCanceled] = React.useState();
  const [showStatusHelp, setShowStatusHelp] = React.useState(false);
  const [showAddedCheck, setShowAddedCheck] = React.useState();

  const handleAddToCart = (id, product, quantity) => {
    setShowAddedCheck(true);
    addToCart(id, product, quantity);
  };

  // Cancel Status
  React.useEffect(() => {
    if (!orderCanceled && status === 'canceled') {
      setOrderCanceled(true);
    }  
  }, [status, orderCanceled]);
  
  const orderDate = new Date(created_at * 1000).toDateString();
 
  /** 
   * TODO: Fix after API update
   * 
   * Delivery/Pickup Window Details
   * We are mid-refactor on the api side, so the schedule objects differ
   */
  const schedule = delivery_schedule.window ? delivery_schedule : pickup_time_slot;
  const scheduleDate = schedule.date || schedule;
  const timeslot = schedule.window || schedule.hours;
  
  let deliveryDate = '';
  if (scheduleDate.year) {
    const { year, month, day } = scheduleDate;
    const { start_hour } = timeslot; 
    deliveryDate = new Date(year, month - 1, day, start_hour, 0, 0, 0).toDateString();
  }
  let deliveryWindow = '';
  if (timeslot)  {
    deliveryWindow = getDeliveryWindowLabel(timeslot);
  }
  /**
   * End needs cleanup 
   */

  // on-site versus pre-paid
  const paymentLabel = getPaymentLabel(payment_details);

  // older Oct 2020 test orders have no costs data
  const totalCost = costs.total ? parseFloat(costs.total/100).toFixed(2) : 'XXX.XX'; 
  
  const cancelStyle = orderCanceled ? styles.canceled : '';
  return ( dispensary.id
    ? <div className={`${styles.orderDetails} ${cancelStyle}`}>
        <div className={styles.orderDetailHdr}>
          <div className={`${styles.orderDetailHdrItem} ${styles.col1}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Placed
            </div>
            <div className={styles.orderDetailHdrVal}>
              {orderDate}
            </div>
          </div>

          <div className={`${styles.orderDetailHdrItem} ${styles.col2}`}>
            <div className={styles.orderDetailHdrLbl}>
              Total
            </div>
            <div className={`${styles.orderDetailHdrVal} ${styles.orderTotal}`}>
              ${totalCost}
            </div>
          </div>
        
          <div className={`${styles.orderDetailHdrItem} ${styles.col3}`}>
            <div className={styles.orderDetailHdrLbl}>
              Dispensary
            </div>
            <div className={styles.orderDetailHdrVal}>
              <span className={styles.link} onClick={() => {navigateTop(`/menu/${dispensary_id}`)}}>  
                {dispensary.name}
              </span>
            </div>
          </div>
  
          <div className={`${styles.orderDetailHdrItem} ${styles.col4}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Id
            </div>
            <div className={`${styles.orderDetailHdrVal} ${styles.orderId}`}>
              {id}
            </div>
          </div>
  
          <div className={`${styles.orderDetailHdrItem} ${styles.col1} ${styles.statusRow}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Type
            </div>
            <div className={styles.orderDetailHdrVal}>
              {type}
              <div className={styles.paymentLabel}>{paymentLabel}</div>
            </div>
          </div>
  
          <div className={`${styles.orderDetailHdrItem} ${styles.col2} ${styles.statusRow}`}>
            <div className={styles.orderDetailHdrLbl}>
              { type === OrderType.PICKUP
                ? 'Pickup Window'
                : 'Delivery Window'
              }
            </div>
            <div className={`${styles.orderDetailHdrVal} ${styles.deliveryWindow}`}>
              { deliveryDate &&
                <>{deliveryDate}</>
              }
              { deliveryWindow &&
                <div>{deliveryWindow}</div>
              }
            </div>
          </div>
  
          <div className={`${styles.orderDetailHdrItem} ${styles.col3} ${styles.statusRow}`}>
            <div className={styles.orderDetailHdrLbl}>
              Order Status
            </div>
            <div className={styles.orderDetailHdrVal}>
              <span className={styles.status}>{
                orderCanceled
                  ? 'canceled'
                  : status
              }</span>{' '}
              <HelpRounded style={{
                cursor:'pointer',
                verticalAlign:'text-bottom',
                fontSize: 20
              }}
              onClick={() => setShowStatusHelp(true)} />
            </div>
          </div>
        
          <div className={`${styles.orderDetailHdrItem} ${styles.col4} ${styles.statusRow}`}>
            { status === 'requested'
              ? <CancelOrderButton
                  orderId={id}
                  orderStatus={status}
                  dispensaryId={dispensary_id}
                  onOrderCanceled={(isCanceled) => setOrderCanceled(isCanceled)} />
              : activeOrders && activeOrders.length === 0
                ? <Button text="Reorder" withStyle={{padding:'.75rem 1.75rem'}} handleClick={()=>{
                    navigateTop(`/reorder/${id}`);
                    trackEvent('reorder_from_orders_click');
                  }} />
                : null
            }
          </div>
        </div>

        <div className={styles.orderedProducts}>
          <div className={styles.orderItemsHeader}>Products</div>
          <div className={styles.orderItems}>
            { items.length &&
              items.map(item => {
                return <DispensaryProduct
                  Id={item.id}
                  {...item.display_info}
                  grams={item.grams}
                  leaf_logix_pricing_tier={item.leaf_logix_pricing_tier}
                  quantity={item.quantity_requested}
                  showOrderQuantity
                  imageLeft
                  dispensaryId={dispensary_id}
                  key={`${id}-${item.id}`}
                  handleClick={(quantity)=>handleAddToCart(item.id, item, quantity)} />
              })
            }
          </div>
        </div>
        
        { showStatusHelp &&
          <DisplayModal title="Order Statuses" closeFn={() => setShowStatusHelp(false)}>
            <OrderStatusHelp orderType={type} />
          </DisplayModal>
        }
        { showAddedCheck &&
          <AddedToCartCheck
            key={`chk${dispensary_id}`} 
            closeFn={() => { 
              setShowAddedCheck(false); 
            }} 
          />
        }
      </div>
    : <Spinner />  
  )
}

OrderDetails.propTypes = {
  id: PropTypes.string.isRequired, 
  type: PropTypes.string.isRequired, 
  created_at: PropTypes.number.isRequired, 
  status: PropTypes.string.isRequired, 
  dispensary_id: PropTypes.string.isRequired,  
  items: PropTypes.array.isRequired,  
  costs: PropTypes.object.isRequired,
  delivery_schedule: PropTypes.object,
  payment_details: PropTypes.object,
  pickup_time_slot: PropTypes.object
};

export default OrderDetails;