import * as React from 'react';

import { auth } from '../../firebase';
import { OrderTypeContext } from '../providers/OrderTypeProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { getItemDetails, saveCartItems, clearSavedCartItems } from '../checkout/order-utils';
import CartHeader from '../checkout/CartHeader';
import EmbeddedCartHeader from './EmbeddedCartHeader';
import EmptyCart from '../checkout/EmptyCart';
import CartItem from '../checkout/CartItem';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

import styles from '../checkout/Cart.module.css';

const EmbeddedCart = ({ 
  isInMenu=false, 
  closeMenuFn,
}) => {
  
  // This is used to enable the slideIn cart when the cart is not already visible in the layout
  // Ideally the Cart Icon would just know the current state but it doesn't (yet) 
  const elemId = isInMenu ? 'MENU_CART' : 'LAYOUT_CART';
 
  const { dispensary } = React.useContext(DispensaryContext);
  const { cartItems, itemTotal, itemTotalDisplay, removeItem, increaseQuantity, decreaseQuantity } = React.useContext(CartItemsContext);
  
  const flowerGrams =  0; //useSelector(state => state.flower_equivalent_grams);
  const { isPickup } = React.useContext(OrderTypeContext);

  const abortController = new AbortController();

  // prevent unneeded cvart saving
  const cartDetailsRef = React.useRef();
  
  // cart fingerprint
  const getCartDetails = React.useCallback((items) => {
    if (!cartItems.length) {
      return 'none';
    }
    const prodIds = cartItems.map(item => item.id);
    const prodQuantities = cartItems.map(item => item.quantity);
    return prodIds.concat(prodQuantities).join('|'); 
  },[cartItems]); 

  /**
   * Save cart items for logged in users
   * 
   * Note: Save items are currently retrieved by the UserProvider
   * when a registered user session is started/restored
   * 
   * Clear/Save functions have debounced requests  
   */
  React.useEffect(() => {
    const controller = abortController;
    if (auth.currentUser && !auth.currentUser.isAnonymous && dispensary?.id) {
      const cartDetails = getCartDetails(cartItems);
      if (cartDetailsRef.current !== cartDetails) {
        if (cartItems.length === 0) {
          // clear the Logged-In user's saved cart items
          clearSavedCartItems(controller);
        } else {
          // Save the Logged-In user's cart items
          const cartInfo = {
            order_type: isPickup ? 'pickup' : 'delivery', 
            dispensary_id: dispensary.id, 
            items: getItemDetails(cartItems)
          };
          saveCartItems(cartInfo, controller);
        }
        cartDetailsRef.current = cartDetails;
      }  
    }
    return () => controller.abort();
  }, [cartItems, cartItems.length, isPickup, getCartDetails, dispensary, abortController]);

  return (
    <div id={elemId} className={styles.cart}>
      { cartItems.length && dispensary?.id
        ? <div className={styles.cartInner}>
            { isPickup 
              ? <EmbeddedCartHeader 
                  dispensaryId={dispensary.id}
                  dispensaryName={dispensary.name}
                  dispensaryLocation={dispensary.location}
                  itemTotal={itemTotal}
                  itemTotalDisplay={itemTotalDisplay}
                  flowerGrams={flowerGrams}
                  minOrderPickup={dispensary.minimum_pickup_value_usa_cents}
                  closeMenuFn={closeMenuFn} /> 
            
              : <CartHeader 
                  dispensaryId={dispensary.id}
                  dispensaryName={dispensary.name}
                  flowerGrams={flowerGrams}
                  minOrderDelivery={dispensary.minimum_delivery_value_usa_cents}
                  closeMenuFn={closeMenuFn} /> 
            }

            <ErrorBoundary>
              <ul className={styles.cartItems}>
                { cartItems.map(item => {
                  const productCategory = item.display_info?.category
                  ? item.display_info.category.display_name
                  : item.display_info.category_name;  
                  return <CartItem
                    Id={item.id}
                    key={item.id}
                    dispensaryId={dispensary.id} 
                    quantity={item.quantity}
                    leaf_logix_pricing_tier={item.leaf_logix_pricing_tier} 
                    { ...item.display_info}
                    productFlowerGrams={item.flower_equivalent_grams} 
                    totalFlowerGrams={flowerGrams}
                    category={productCategory}
                    handleRemove = {() => removeItem(item.id)}
                    handleIncrease = {() => increaseQuantity(item.id,1)}
                    handleDecrease = {() => decreaseQuantity(item.id,1)} 
                  />
                })}
              </ul>
            </ErrorBoundary>
          </div>
        : <EmptyCart isInMenu={isInMenu} dispensaryLogo={dispensary?.image_urls?.secondary} />
      }   
    </div>           
  )
}

EmbeddedCart.propTypes = {
  isInMenu: PropTypes.bool,
  closeMenuFn: PropTypes.func,
};

export default EmbeddedCart;