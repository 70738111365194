import React from 'react';

import PropTypes from 'prop-types';
import styles from './PlaceOrder.module.css';

const PlaceOrderItem = ({
  Id,
  name,
  cost_usa_cents,
  quantity,
  removeFn
}) => {
  
  const itemPrice = quantity * cost_usa_cents / 100; 
  const totalPrice = parseFloat(itemPrice).toFixed(2);

  return (
    <li className={styles.cartItem} key={Id}>
      <div className={styles.itemCount}>x{quantity}</div>
      <div className={styles.itemTitle}>
        {name} 
        <span className={styles.removeFromOrder} onClick={removeFn}>(remove)</span>  
      </div>
      <div className={styles.itemPrice}>${totalPrice}</div> 
    </li>
  )
}

PlaceOrderItem.propTypes = {
  Id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cost_usa_cents: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  removeFn: PropTypes.func.isRequired 
};

export default PlaceOrderItem;