import * as React from 'react';

import check from '../../assets/AddedCheck.png';

import styles from './Stylesheet.module.css';

const SuccessMessage = ({text, isInline}) => {
 
  const inlineStyle = isInline ? { marginTop: 0} : {};

  return (
    <div className={styles.successMessage} style={inlineStyle}>
      <img height="16" alt="" src={check} /> {text}    
    </div>
  )
}

export default SuccessMessage;