import * as React from 'react';

import { OrderType } from '../checkout/order-utils';
import { formatDateString } from '../notifications/notification-utils';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';

import styles from '../account/YourAccount.module.css';

const DeliveryCreditNotifications = ({notifications=[], viewedIds=[], recentOrders=[], updateViewedStatus, dateJoined}) => {
  const FreeDeliveryCredit = ({creditId, creditDate}) => {
    const unViewedClass = viewedIds.includes(creditId) ? '' : styles.unViewed;
    return (
      <div className={`${styles.notification} ${unViewedClass}`}>
        <div className={styles.ntfyIcon}>
          <MonetizationOnRoundedIcon style={{fontSize:'20px',verticalAlign:'-6px'}} />
        </div>
        <div className={styles.ntfyText}>
          You've earned a Free Delivery Credit!
          <div className={styles.ntfyDetails}>
            A $2.99 value. Delivery service launching soon.
          </div>
        </div>
        <div className={styles.ntfyDate}>
          {creditDate}
        </div>     
      </div>
    );
  };

  const [creditDates, setCreditDates] = React.useState([]);

  // Credits are valid on date of pickup order completion
  React.useEffect(() => {
    const pickupOrderDates = recentOrders.reduce((arr, order) => {
      if (order.type === OrderType.PICKUP && order.status === 'completed') {
        // 2021-8-2 => Aug 5, 2021
        arr.push(formatDateString(order.order_counts_against_date));
      }
      return arr;  
    }, []);
    if (creditDates.length !== pickupOrderDates.length) {
      setCreditDates(pickupOrderDates);
    }
  }, [recentOrders, creditDates]);

  const listRef = React.useRef();

  // Animate the notification background color to white
  React.useEffect(() => {
    let updateTask;
    if (notifications.length && listRef.current) {
      updateTask = window.setTimeout(() => {
        if (listRef.current) { 
          listRef.current.setAttribute('data-viewed', true);
          window.setTimeout(() => {
            updateViewedStatus();
          }, 1000);
        }
      }, 1000);
    }
    return () => window.clearTimeout(updateTask);  
  }, [notifications.length, updateViewedStatus]);

  return (
    <div className={styles.notificationList} ref={listRef}>
      { creditDates.length > 0 &&
        <>
          { notifications.includes('credit2') && 
            <FreeDeliveryCredit creditId={'credit2'} creditDate={creditDates[creditDates.length - 2]} />
          }
          { notifications.includes('credit1') &&
            <FreeDeliveryCredit creditId={'credit1'} creditDate={creditDates[creditDates.length - 1]} />
          }
        </>
      }
      
      {/* placeholder fallback/welcome content */}
      <div className={styles.notification}>
        <div className={styles.ntfyIcon}>
          <span aria-label="welcome" role="img">&#127881;</span>
        </div>  
        <div className={styles.ntfyText}>
          Welcome to Zip Run!
        </div>
        <div className={styles.ntfyDate}>
          {formatDateString(new Date(parseInt(dateJoined, 10)).toDateString())}
        </div>        
      </div>        
    </div>
  )
}

export default DeliveryCreditNotifications;