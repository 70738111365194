import * as React from 'react';

import { getPaymentLabel } from '../checkout/order-utils';
import { getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import CancelOrderButton from '../orders/CancelOrderButton';

import styles from './OrderConfirmation.module.css';

const OrderDetails = ({
  order={}
}) => {  
  
  const { id, type, status, costs, fulfillment_time_slot={}, dispensary_id } = order;
  const totalCost = costs.total ? parseFloat(costs.total/100).toFixed(2) : 'XXX.XX';

  const scheduleDate = fulfillment_time_slot.date;
  const timeslot = fulfillment_time_slot.hours;
  
  let deliveryDate = '';
  if (scheduleDate.year) {
    const { year, month, day } = scheduleDate;
    const { start_hour } = timeslot; 
    deliveryDate = new Date(year, month - 1, day, start_hour, 0, 0, 0).toDateString();
  }
  let deliveryWindow = '';
  if (timeslot)  {
    deliveryWindow = getDeliveryWindowLabel(timeslot);
  }
  /* End needs cleanup */

  // on-site versus pre-paid
  const paymentLabel = getPaymentLabel(order.payment_details);
  
  return ( order.type 
    ? <div className={styles.detailsGrid}>
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>ORDER TYPE</div>
          <div className={styles.detailValue}>
            {type.toUpperCase()}
            <div className={styles.paymentLabel}>{paymentLabel}</div>
          </div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>PICKUP WINDOW</div>
          <div className={styles.detailValue}>
            <div>{deliveryDate}</div>
            <div>{deliveryWindow}</div>
          </div>
        </div>
        <div className={styles.detailItem} style={{margin:0}}>
          <div className={styles.detailLabel}>TOTAL</div>
          <div className={styles.detailValue}>${totalCost}</div>
        </div>
        <div className={styles.detailItem} style={{margin:0}}>
          <CancelOrderButton
            orderId={id}
            orderStatus={status}
            dispensaryId={dispensary_id}
            />
        </div>
      </div>
    : null
  )    
}

export default OrderDetails;