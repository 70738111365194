import * as React from 'react';

import PropTypes from 'prop-types';

const useResizeObserver = (callback, element) => {

  const observer = React.useRef(null);

  const observe = React.useCallback((observer) => {
    if (element && element.current && observer) {
      observer.observe(element.current, callback);
    }
  }, [element, callback]);

  React.useEffect(() => {
    let currentObserver = observer.current;
    let currentElement = element.current;
    // if we are already observing old element
    if (currentObserver && currentElement) {
      currentObserver.unobserve(currentElement);
    }
    //const resizeObserverOrPolyfill =  ResizeObserver;
    currentObserver = new ResizeObserver(callback);
    observe(currentObserver);
    return () => {
      if (currentObserver && currentElement) {
          currentObserver.unobserve(currentElement);
      }
    };
  }, [observer, element, callback, observe]);
};

useResizeObserver.propTypes = {
  element: PropTypes.object,
  callback: PropTypes.func,
};

export default useResizeObserver;