import * as React from 'react';

import { checkDeliveryAddressBlacklist } from '../registration/registration-utils';
import PropTypes from 'prop-types';

import styles from './DeliveryBlacklistMessage.module.css';

const DeliveryBlacklistMessage = ({
  street_address,
  city,
  state,
  zip_code,
  onDarkBackground=false,
}) => {

  const [showMessage, setShowMessage] = React.useState();

  // Store a ref so we can debounce the api request ( don't request every key stroke )
  const requestTimout = React.useRef();
  const requestDelayMS = 1000;

  React.useEffect(() => {
    const controller = new AbortController();
    const callback = (isBlacklisted) => {
      if(isBlacklisted) {
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    };
    
    if (street_address && city && state && zip_code) {
      window.clearTimeout(requestTimout.current);
      requestTimout.current = window.setTimeout(() => {
        const address = {street_address, city, state, zip_code};
        checkDeliveryAddressBlacklist(address, callback, controller);
      }, requestDelayMS);
    }
    return () => controller.abort();
  }, [street_address, city, state, zip_code]);

  // Either a transparent white (on dark) or black background (on light) will be used
  const bgStyle = onDarkBackground ? styles.onDarkBackground : ''; 

  return ( showMessage 
    ? <div className={`${styles.blacklistWarning} ${bgStyle}`}>
        <span className={styles.warningSymbol}>
          &#9888;
        </span>
          Sorry, but it looks like your address: {street_address} in {city}, 
          is in a restricted zone (college campus, public housing, etc.) and we cannot 
          legally deliver to this address.   
      </div>
    : null    
  )
}

DeliveryBlacklistMessage.propTypes = {
  street_address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip_code: PropTypes.string,
  onDarkBackground: PropTypes.bool    
}

export default DeliveryBlacklistMessage