import * as React from 'react';

import { auth } from '../../firebase';
import { newOrderEndpoint } from '../../constants/api-endpoints';
import { trackEvent } from '../analytics/tracking';
import SpinnerWrapper from '../common/SpinnerWrapper';
import BasicButton from '../styleguide/BasicButton';
import SuccessMessage from '../styleguide/SuccessMessage';
import ErrorMessage from '../styleguide/ErrorMessage';

import PropTypes from 'prop-types';

/**
 * Button with spinner for canceling an order
 * (cancelling orders can take 5 - 10 seconds)
 */
const CancelOrderButton = ({
  orderId, 
  orderStatus, 
  dispensaryId, 
  onOrderCanceled }) => {
 
  const [showButton, setShowButton] =React.useState();  
  // For updating spinner over button when cancel is complete
  const [spinnerComplete, setSpinnerComplete] = React.useState(); 
  const [cancelSuccess, setCancelSuccess] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState();

  const abortController = new AbortController();

  // The cancel payload
  const cancelPayload = {
    current_status: "requested",
    new_status: "canceled",
    reason_for_cancel: "canceled by customer"
  };
  
  // Update state on cancel order complete
  const updateStateOnCancel = (isSuccess, errorMessage) => {
    setShowButton(!isSuccess);
    setSpinnerComplete(true);
    setCancelSuccess(isSuccess); 
    setErrorMsg(errorMessage);
  };

  const cancelOrder = () => {
    const cancelOrderURL = `${newOrderEndpoint}${dispensaryId}/${orderId}/status`;
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(cancelOrderURL, { 
        method: 'PATCH',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cancelPayload),
        signal: abortController.signal
      }).then(response => {
        /**
         * 204: Success no message
         * 200: Success but with dispensary msg - check Leaf Logix, etc.
         */
        if ([200,204].includes(response.status)) {
          updateStateOnCancel(true, ''); 
          trackEvent('order_canceled');
          // update the parent view
          if (onOrderCanceled) {
            onOrderCanceled(true);
          }
        } else {
          response.json().then(body => {
            updateStateOnCancel(false, `${body.error}`); 
          });
      }}).catch(error => {
        updateStateOnCancel(false, `${error.message}`);
      });
    });
  };

  React.useEffect(() => {
    if (orderStatus === "requested") {
      setShowButton(true);
    }      
  }, [orderStatus])  
 
  return ( 
    <>  
      { showButton &&
        <SpinnerWrapper taskComplete={spinnerComplete} handleClick={cancelOrder}>
          <BasicButton text="Cancel Order"  />
        </SpinnerWrapper>
      }
      { cancelSuccess && 
        <SuccessMessage isInline text="Your order has been canceled." />
      }
      { errorMsg && 
        <ErrorMessage text={errorMsg} />
      }
    </>
  )
}

CancelOrderButton.propTypes = {
  orderId: PropTypes.string.isRequired,  
  orderStatus: PropTypes.string.isRequired,  
  dispensaryId: PropTypes.string.isRequired, 
  onOrderCanceled: PropTypes.func
};

export default CancelOrderButton;