import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ResetButton from '../styleguide/BasicButton';

import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  msgWrap: {
    flexBasis: '100%',
    textAlign: 'center',
    padding: '.75rem 0',
    fontWeight: 500  
  },
  btnWrap: {
    marginTop: '1.25rem'
  },
  exclaim: {
    fontStyle: 'italic',
    fontWeight: 700,
    marginRight: '.25rem'  
  }
}));

/**
 * Display the "Reset Filters message"
 */
const ResetFiltersMessage = ({
  category,
  resetFilters
}) => {

  const classes= useStyles();
 
  return (
    <div className={classes.msgWrap}>
      <span className={classes.exclaim}>SORRY!</span>There are currently no <b>{category}</b> products matching your criteria.
      <div className={classes.btnWrap}>
        <ResetButton text="Reset Filters" handleClick={resetFilters} />
      </div>
    </div>
  );
} 

ResetFiltersMessage.propTypes = {
  category: PropTypes.string.isRequired,
  resetFilters: PropTypes.func.isRequired
};

export default ResetFiltersMessage;