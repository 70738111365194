import React from 'react';

import { Link, navigate } from '@reach/router';
import { unfreezeBody } from '../modal/modal-utils';
import { sendMessage } from '../embedMenu/embed-utils';

// Adds data-route attribute to body for Header/Footer styling
export const addRouteToBody = (path) => {
  const route = path?.substring(1) || window.location.pathname.substring(1) || 'home';
  let routeAttribute = route.split('/')[0];
  if (route.startsWith('your')) {
    // your_account
    routeAttribute = route.split('/').join('_');
  }
  document.body.setAttribute('data-route', routeAttribute);
};

// Adds scrollTop to native navigate
export const navigateTop = (path, options={}) => {
  unfreezeBody(); 
  
  // options: { replace: true, state: { ... }}
  navigate(path, options);

  // Unlikely that we need this 
  addRouteToBody(path);
  
  /*
  // Send message to host with new page
  const pathItems = path.split('/');
  // host/menu/id/ 
  if (pathItems.length === 3) {
    sendMessage({page:'home'});  
  // host/menu/id/page 
  } else if(pathItems.length === 4) {
    // handle: /menu/dispensaryId/  (length:4)
    const page = pathItems[3] || 'home';
    sendMessage({page});
  } else {
    sendMessage({page: 'new'}); 
  }
  */
  
  // { state, replace } are the real navigate options
  const { disableScroll=false } = options;
  if (!disableScroll) {
    // need a timeout on this...
    window.setTimeout(() => {
      window.scrollTo(0,0);  
      // handle modal links, unfreeze body
      const bodyStyle = document.body.style;
      bodyStyle.position = '';
      bodyStyle.width = '';
      bodyStyle.top = '';
    }, 50);
  }
} 

/**
 * Navigate to the default Sign Up flow (not the iframed marketing site one)
 *
 * ALL app signup links should use this method for proper backLink/returnTo handling 
 */
export const navigateToSignUp = (dispensaryId, options={}) => {
  navigateTop(
    `/menu/${dispensaryId}/signUp`, options
  );  
};

// Link wrapper that ensures scroll to top on target page
export const LinkTop = ({path, className, tabIndex, onClick, children}) => {
  const handleClick = () => {
    unfreezeBody(); 
    if (onClick) {
      onClick();
    }
    window.setTimeout(() => {
      window.scrollTo(0,0);
    }, 50);    
  }; 
  return (
    <Link to={path} className={className} tabIndex={tabIndex} onClick={handleClick}>
      {children}
    </Link> 
  );
}

/**
 * The purpose of this component is to expose a deep link to Google that
 * is not required for normal App navigation.  For example, if we want to 
 * show product content in a modal but also supply a deep link for SEO.
 *  
 * @param {string} deepLink - The link we want to expose to Google
 * @param {object} styleClass - The CSS class 
 * @param {string} listKey - Unique key used for rendering links in a list 
 * @param {function} handleClick - The function that will handle the user's click and navigate appropriately 
 * @param {*} children = Any wrapped elements  
 */
export const SEOLinkWrapper = ({deepLink, styleClass, listKey, handleClick, children}) => {
  const onClick = (event) => {
    // disregard the SEO deep link
    event.preventDefault();
    // navigate via the click handler
    handleClick();
  }
  const linkStyle = { textDecoration:'none'}
  // a key is required when rendering a list of links/linked content
  return ( listKey 
    ? <a href={deepLink} key={listKey} className={styleClass} style={linkStyle} onClick={onClick}>
        {children}
      </a>
    : <a href={deepLink} className={styleClass} style={linkStyle} onClick={onClick}>
        {children}
      </a>     
  )  
};