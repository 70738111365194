import * as React from 'react';

export const CurrentTimeContext = React.createContext();

// Update the time for use in Time Slot selection every minute or so
const useCurrentTime = () => {
  const [time, setTime] = React.useState(new Date().getTime());
  React.useEffect(() => {
    const interval = window.setInterval(() => {
      // console.log('set new time');
      setTime(new Date().getTime());
    }, 10000);
    return () => window.clearInterval(interval);
  }, []);
  return time;   
};

const CurrentTimeProvider = ({children}) => {
  const currentTimeMS = useCurrentTime();
  return <CurrentTimeContext.Provider value={currentTimeMS}>
    {children}
  </CurrentTimeContext.Provider>
}

export default CurrentTimeProvider;
