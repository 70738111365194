import React from 'react';

import { handleMissingProductImage } from '../products/product-utils';
import PropTypes from 'prop-types';

import styles from './OrderConfirmation.module.css';

// Order item for orderConfirmation view
const OrderItem = ({
  Id,
  name,
  category,
  image_url,
  cost_usa_cents,
  quantity,
 }) => {
  
  const { imgUrl:product_image } = handleMissingProductImage(image_url, category);
  const itemPrice = quantity * cost_usa_cents / 100; 
  const totalPrice = parseFloat(itemPrice).toFixed(2);
  
  return (
    <li className={styles.cartItem} key={Id}>
      <div className={styles.itemDetails}>
        <div className={styles.topDetails}>
          <div className={styles.itemCount}>x{quantity}</div>
          <div className={styles.itemTitle}>
            {name} 
          </div>
        </div>
        <div className={styles.itemPrice}>${totalPrice}</div>
      </div>
      <div className={styles.imageWrap}>
        <img src={product_image} alt={name} className={styles.itemImg}/>
      </div>
    </li>
  )
}

OrderItem.propTypes = {
  Id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  image_url: PropTypes.string.isRequired,
  cost_usa_cents: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired
};

export default OrderItem;