import * as React from "react";

import { uploadImage } from './registration-utils';
import ImageUploading from 'react-images-uploading';
import ProgressCircle from './ProgressCircle';
import UploadButton from './UploadButton'; 
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import PropTypes from 'prop-types';

import styles from './PhotoIdInline.module.css';

/**
 *  Upload/Update a license image to Firebase 
 */
const LicenseImageUploader = ({
  email,
  placeholderLabel,
  placeholderImage,
  setPhotoIdImage
}) => {
  
  // Not wired up with Firebase upload
  const [photoIdErr, setPhotoIdErr] = React.useState();
  // Temporarily store images in a directory that's a base64 encoding of their email 
  const encodedEmail = window.btoa(email.trim());
  // Firebase upload progress
  const [uploadPct, setUploadPct] = React.useState(-1);
 
  const checkIconStyle = {
    color: '#2da700', 
    backgroundColor:'#fff', 
    fontSize:'3rem', 
    verticalAlign:'-6px',
    borderRadius: '50%'
  };  

  // Callback for Firebase upload
  const onUploadComplete = (imageInfo) => {
    console.log('image upload complete');
    if (imageInfo.path && imageInfo.url) {
      setPhotoIdImage(imageInfo);
    }
  };        
  
  const onUploadProgressChange = (pctComplete) => {
    console.log(`upload pct: ${parseInt(pctComplete, 10)}`);
    setUploadPct(parseInt(pctComplete, 10));
  }
  
  // After image selected
  const onSelectImage = imageList => {
    // image array of 1
    const firstImage = imageList[0].file;
    uploadImage(firstImage, encodedEmail, onUploadComplete, onUploadProgressChange); 
  };

  return (
    <div className={styles.imageItem}>
      <ImageUploading 
        onChange={onSelectImage}
        acceptType={['jpg', 'jpeg', 'gif', 'png']} 
        maxNumber={1}>
        {({ imageList, onImageUpload }) => (
          <>
            {/* display initial placeholder image */}
            { imageList.length === 0 
              ? <>
                  <img className={styles.placeholder} src={placeholderImage} alt="" onClick={onImageUpload} />
                  <UploadButton 
                    text={`Select Image (${placeholderLabel})`} 
                    handleClick={onImageUpload} />
                </>
              /* display selected image */
              : imageList.map((image) => {
                return (
                  <>
                    <img className={styles.licenseImage} src={image.dataURL} alt="license" />
                    <UploadButton text={`Update Image (${placeholderLabel})`} handleClick={image.onUpdate} />
                    <div className={styles.progressWrap}>
                      { photoIdErr
                        ? <div className={styles.updateMsg}>{photoIdErr}</div> 
                        : uploadPct > 0 
                          ? uploadPct === 100
                            ? <div><CheckCircleRoundedIcon style={checkIconStyle}/></div> 
                            : <ProgressCircle percentageInt={uploadPct} />     
                          : <></>
                      }
                    </div>
                  </>
                )})   
            }
          </>
        )}     
      </ImageUploading>
    </div>
  );
};  

LicenseImageUploader.propTypes = {
  email: PropTypes.string.isRequired,
  placeholderImage: PropTypes.string.isRequired,
  placeholderLabel: PropTypes.string.isRequired,
  setPhotoIdImage: PropTypes.func.isRequired
};

export default LicenseImageUploader;