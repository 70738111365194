import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

/* Not enough CSS to warrant a module */
const useStyles = makeStyles({
  wrapper: {
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #ccc',
    fontSize: '0.8125rem',
    borderRadius: '.375rem',
    backgroundColor: '#f7f7f7',
    maxWidth: 308,
    boxShadow: '0 0 4px #000c',
    padding: '.25rem .75rem',
    boxSizing:  'border-box' 
  },
  title: {
    fontWeight: '700',
    fontSize: '1rem',
    margin: '.5rem 0 .75rem'
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: '.5rem',
    right: '.5rem',
    cursor:'pointer'
  },
  secondaryHdr: {
    marginBottom: '.5rem'
  },
  details: {
    maxWidth: '600px', 
    margin:'.5rem 0'
  },
  idTypes: {
    padding: '.25rem',
    '& li': {
      fontSize: '.875rem',
      fontWeight: 500,
      fontStyle: 'italic',
      marginBottom: '.25rem'
    }
  },
  footerText: {
    fontSize:'.75rem', 
    lineHeight:'120%',
    paddingTop: '.75rem',
    borderTop: '1px solid #ccc', 
    margin:'.5rem 0'
  }
});

// TODO: Create a configured/re-useable Popper component
const PhotoIdTooltip = () => {
  
  const classes= useStyles();
  const [open, setOpen] = React.useState(false);
  
  const buttonStyle = { cursor:'pointer', minWidth:'1rem', padding:'.25rem', verticalAlign:'-.25rem' };
  const iconStyle = { fontSize: 20, color: 'inherit' };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const anchorRef = React.useRef();
  
  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top"
        style={{zIndex: 1}}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          arrow: {
            enabled: false,
            element: anchorRef.current,
          },
        }}>
        <div className={classes.wrapper}>
          <div className={classes.title}>
            Photo Identification
          </div>
          <div className={classes.closeIcon} onClick={handleClose}>
            <CloseRoundedIcon style={{fontSize:24}} />
          </div>
          <div className={classes.details}>
            <div className={classes.secondaryHdr}>
              The following types of identification are accepted:
            </div>
            <ul className={classes.idTypes}>
              <li>Valid Drivers License (any US State)</li>
              <li>Real ID (any US State)</li>
              <li>Passport Card</li>
            </ul>
          </div>
          <div className={classes.footerText}>
            Photo Identification images (front and back) are required for 
            verification on all delivery orders. You can use your phone to
            take the pictures.   
          </div>
        </div>
      </Popper>
      <Button ref={anchorRef} onClick={handleOpen} style={buttonStyle}>
        <InfoIcon style={iconStyle} />
      </Button>
    </>
  );
}

export default PhotoIdTooltip