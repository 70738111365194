import React from 'react'
import PropTypes from 'prop-types'
import { ZIP_RUN_GREEN } from '../../constants/colors';
import styles from './Spinner.module.css'

/**
 * Show a spinner over an element (button) on click
 * 
 * This component accepts a handleClick function that triggers the spinner display 
 * When the spinner-related task is complete the taskComplete prop is passed as TRUE.
 */
const SpinnerWrapper = ({ 
  size=24, 
  handleClick,
  taskComplete, 
  children}) => {
  
  const [showSpinner, setShowSpinner] = React.useState();

  // For click on child element 
  const elemClick = () => {
    if (handleClick && !showSpinner) {
      setShowSpinner(true);
      handleClick();  
    }
  };

  // Cancel spinner on taskComplete prop
  React.useEffect(() => {
    if (taskComplete) {
      setShowSpinner(false); 
    }
  }, [taskComplete]);

  const circleSize = size * .8;

  /* the individual spinning circles */
  const circles = [...Array(4)].map((_, index) => {
    return (
      <div
        key={`circle${index}`}
        style={{
          borderColor: `${ZIP_RUN_GREEN} transparent transparent transparent`,
          width: circleSize,
          height: circleSize,
          borderWidth: size * 0.15,
          left: 0,
          top: 0
        }}
      ></div>
    )
  })

  return (
    <div className={styles.spinWrap} onClick={elemClick}>
      { showSpinner && 
        <div className={styles.spinWrapMask}>
          <div className={styles.ldsRing} style={{ 
             width: circleSize, 
             height: circleSize, 
             position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)' 
          }}>
          {circles}
         </div>
        </div> 
      }
      {children} {/* the button etc. */}
    </div>
  );
}

SpinnerWrapper.propTypes = {
  size: PropTypes.number,
  handleClick: PropTypes.func,
  taskComplete: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
}

export default SpinnerWrapper;