import * as React from "react";

import AddressFormInline from './AddressFormInline';
import BasicButton from '../styleguide/BasicButton';
import DeliveryBlacklistMessage from '../address/DeliveryBlacklistMessage';
import PropTypes from 'prop-types';

import '../registration/MaterialUI.module.css';
import styles from './AddressSearch.module.css';

/**
 * Provide a form to edit the location-search selected address ( minus apartment number ) 
 */
const AddressUpdaterInline = ({
  location,
  editAddress,
  setEditAddress,
  onUpdate
}) => {
  
  // Updated user entries from AddressForm
  const [streetAddress, setStreetAddress] = React.useState();
  const [city, setCity] = React.useState();
  const [state, setState] = React.useState();
  const [zipCode, setZipCode] = React.useState();
 
  // Set initial values
  React.useEffect(() => {
    const { 
      street_address: currentStreet,
      city: currentCity,
      state: currentState,
      zip_code: currentZipCode
    } = location; 
    if (streetAddress !== currentStreet) {
      setStreetAddress(currentStreet);
      setCity(currentCity);
      setState(currentState);
      setZipCode(currentZipCode);
    }
  }, [streetAddress, location]);

  const editAddressClick = (event) => {
    event && event.preventDefault();
    setEditAddress(true);
  }

  // e.g. update the provider
  const onAddressUpdate = (addressObj) => {
    onUpdate({
      street_address: addressObj.street_address,
      city: addressObj.city,
      state: addressObj.state,
      zip_code: addressObj.zip_code
    });  
    setEditAddress(false);
  }

  return (
    <div className={styles.addressForm}>
      { editAddress 
        ? <div className={styles.formWrap}> 
            <AddressFormInline
                location={location}
                setEditAddress={setEditAddress} 
                onUpdate={onAddressUpdate}
                onCancel={() => setEditAddress(false)}
              /> 
          </div>
        : <div className={styles.addressWithEditButton}> 
            <div className={styles.formattedAddress}>
              {`${streetAddress}, ${city}, ${state} ${zipCode}`}    
            </div>
            <div className={styles.fauxLabel}>
              Address
            </div>   
            <BasicButton 
              text="Edit" 
              withStyle={{paddingTop:'.375rem'}}
              handleClick={(event) => editAddressClick(event,true)} />
        </div>
      }
      <DeliveryBlacklistMessage 
          street_address={streetAddress}
          city={city}
          state={state}
          zip_code={zipCode}
        />
    </div>
  );
}

AddressUpdaterInline.propTypes = {
  location: PropTypes.object.isRequired,
  editAddress: PropTypes.bool,
  setEditAddress: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default AddressUpdaterInline;