import React, { useState } from "react";

import { auth } from "../../firebase";
import { trackEvent } from '../analytics/tracking';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import TextField from '@material-ui/core/TextField';
import Button from "../styleguide/Button";
import SendResetEmailForm from '../resetPassword/SendResetEmailForm';
import SimpleErrorMessage from "../styleguide/SimpleErrorMessage";
import PropTypes from 'prop-types';

/* material UI styles */
import './MaterialUI.module.css';
import styles from '../checkout/CheckOut.module.css';

/**
 * Inline Sign In, parent component will handle update/continue after successful Sign In 
 */
const SignInOrSignUp = ({
  onSignIn,
  signUpClick
}) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [showPasswordReset, setShowPasswordReset] = React.useState(false);
 
  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).then(function() {
      if (onSignIn) {
        onSignIn(); /* optional tracking, view updates */
      }
      trackEvent('inline_signin_success');
    }).catch(error => {
      trackEvent('inline_signin_failure');
      setError('Invalid email or password');
    });
 
  };
    
  const onChange = event => {
    const {id, value} = event.currentTarget;
    setError(false);
    if(id === 'loginEmail') {
      setEmail(value);
    } else if(id === 'loginPassword'){
      setPassword(value);
    }
  };

  return (
    <>    
      {/* toggle between sign in and forgot password */}
      { showPasswordReset
        ? <SendResetEmailForm handleCancel={() => setShowPasswordReset(false)} />
        : <>
            <PrimaryHeader isCentered text="Sign In" />
              
            <TextField id="loginEmail" value={email} label="Email" error={false} 
              type="email" variant="filled"  onChange={onChange} />
            <TextField id="loginPassword" value={password} label="Password" error={false}
              type="password" variant="filled" onChange={onChange} />  
            
            <div className={styles.buttonWrap}>
              <Button
                isCentered
                text="Sign In"
                handleClick={(event) => {
                  signInWithEmailAndPasswordHandler(event, email, password);
                }} />
            </div>

            { error && 
              <SimpleErrorMessage isCentered text={error} />
            }  
          
            <div className={styles.passwordResetLink}>
              <span onClick={() => setShowPasswordReset(true)}>
                Forgot your password?
              </span>
            </div>

            <div className={styles.signUpLink} onClick={signUpClick}>
              Sign Up with Zip Run
            </div>
          </>
      }  
    </>
  );  
};

SignInOrSignUp.propTypes = {
  onSignIn: PropTypes.func,
  signUpClick: PropTypes.func.isRequired
}

export default SignInOrSignUp;