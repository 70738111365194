import * as React from 'react';

import { auth } from '../../firebase';
import { navigateTop } from '../routing/router-utils';
import { OrderContext } from './OrderProvider';
import Helmet from 'react-helmet';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import Spinner from '../common/Spinner';
import OrderDetails from './OrderDetails';

import styles from './YourOrders.module.css';

/**
 * We're currently showing most recent 10 ( API returns 100 )
 */ 
const YourOrders = () => {
  
  const { recentOrders } = React.useContext(OrderContext); 
  
  // If we don't have a user session after 3s, redirect to home
  let redirectAnonymous = React.useRef();
  React.useEffect(() => {
    window.clearTimeout(redirectAnonymous.current);       
    redirectAnonymous.current = window.setTimeout(() => {
      if(auth.currentUser.isAnonymous) {
        navigateTop('/');   
      }
    }, 3000);
  }, []);
  
  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <Helmet>
        <title>Zip Run - Your Orders</title>
      </Helmet>
      <div className={styles.orderWrap}>
        <PrimaryHeader text="Your Orders">
          <span className={styles.headerNote}>(10 most recent)</span>
        </PrimaryHeader>
        { recentOrders 
          ? recentOrders.length > 0 
            ? recentOrders.map((order) => {
                return <OrderDetails key={order.id} {...order} />
              })
            : <div className={styles.noOrdersMsg}>
                <p>Hey, looks like you haven't placed any orders...</p>
                <p>Maybe it's time to 
                  {' '}<span className={styles.shopLink} onClick={() => { 
                    navigateTop('/shopByProduct')}}>
                    Shop Cannabis Products!
                  </span>
                </p>
              </div>  
          : <Spinner />
        }
      </div>
    </BodyWrapper>
  )
}

export default YourOrders;
