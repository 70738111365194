import * as React from 'react';

import { auth } from '../../firebase';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { PickupSlotsContext } from '../timeSlots/PickupSlotsProvider';
import { useSelector } from 'react-redux';
import useBankAccountId from '../payment/useBankAccountId';
import useForceAccountLinking from '../payment/useForceAccountLinking';
import { navigateTop } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import { calcPickupServiceFeeCents, calcTaxCents, submitDispensaryOrder, OrderType, validateCostCalculation } from '../checkout/order-utils';
import { validateAndPlaceOrder } from '../payment/payment-utils';
import TaxesTooltip from '../checkout/TaxesTooltip';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import LinkAccountsButton from '../payment/LinkAccountsButton';
import ErrorMessage from '../styleguide/ErrorMessage';
import SuccessMessage from '../styleguide/SuccessMessage';
import ProgressBar from '../common/ProgressBar';
import aeropayButton from '../../assets/purchaseWithAeroPay.png';
import Button from '../styleguide/Button';

import PropTypes from 'prop-types';

import styles from '../checkout/OrderButton.module.css';

/**
 * This is the PlaceOrder button for pickup orders
 * 
 * The logged-in user state is verfied/handled by the parent component
 * 
 * TODO: Enforce Pickup lead time restrictions
 * 
 */
const OrderPickupButton = ({
  dispensaryId,
  dispensaryName,
  minPickupOrder=0,
  isPayInStore 
}) => {
 
  const [orderProcessing, setOrderProcessing] = React.useState();
  const [orderResponse, setOrderResponse] = React.useState();
  // not sure we need this, latency is minimal;
  //const [accountFetchComplete, setAccountFetchComplete] = React.useState();
  
  const { selectedPickupSlot } = React.useContext(PickupSlotsContext);
  const { addedItems, itemTotal, emptyCart } = React.useContext(CartItemsContext);

  // User completed link account flow
  const newLinkedAccount = React.useRef();
  const controllerRef = React.useRef(new AbortController());
  // Retrieve linked banking account if available
  const [ bankAccountId, bankAccountHint, setBankAccountId ] = useBankAccountId(controllerRef.current); 
  // Force certin test users through checkout linking, regardless of existing linkages
  const [ testAccountLinking, setTestAccountLinking] = useForceAccountLinking(auth.currentUser?.displayName);

  /**
   * handleNewLinkedAccount
   * 
   * This is the post account linking callback
   * 
   * @param {object} response - the preferred bank account selected by user 
   */
  const handleNewLinkedAccount = (response) => {
    // We want to show the account last4 so we'll fetch full details via setBankAccountId  
    if (response.bankAccountId) {
      // Undo the forced linking flow for test users
      setTestAccountLinking(false);
      // We want to show the account last4 so we'll fetch full details via setBankAccountId 
      setBankAccountId(controllerRef.current);
      // Display linking success message
      newLinkedAccount.current = true;
      //setAccountFetchComplete(false);
      trackEvent('aeropay_checkout', 'account_linked');
    }
  }

  React.useEffect(() => {
    // controller for initial useBankAccountId hook
    const bankAccountController = controllerRef.current; 
    return () => bankAccountController?.abort();
  },[]);

  // order total cost components 
  const bulkDiscountCents = useSelector(state => state.bulk_discount_cents);
  
  const salesTaxPercent = useSelector(state => state.sales_tax_percent);
  const cannabisTaxPercent = useSelector(state => state.cannabis_tax_percent);

  const serviceFeePct = useSelector(state => state.pickup_service_fee_percent);
  const serviceFeePctPayInStore = useSelector(state => state.pickup_in_store_payment_fee_percent);
  const pickupFeeCents = useSelector(state => state.pickup_fee_cents);
 
  // total net of any bulk discounts
  const totalAfterDiscounts = itemTotal - bulkDiscountCents;

  // Round fees and taxes and calc total price
  const feeCents = calcPickupServiceFeeCents(totalAfterDiscounts, isPayInStore, serviceFeePctPayInStore, serviceFeePct, pickupFeeCents);
  const taxCents = calcTaxCents(totalAfterDiscounts, salesTaxPercent, cannabisTaxPercent);
  // used later
  const feesAndTaxCents = feeCents + taxCents;
  const totalPrice = (totalAfterDiscounts + feesAndTaxCents)/100; 

  // Handle errors from orders that are rejected by the validity endpoint (dispensary closed etc.)
  const handleResponseErrors = (responseMessage) => {
    // We'll get an empty string if there's no errors
    if (responseMessage) {
      // Cancel progress bar 
      setOrderProcessing(false); 
    }
    setOrderResponse(responseMessage);
  }; 

  /**
   * When the user   clicks the "Place Order" button, we validate it and submit
   * 
   * @param {event} event - mouseclick
   */
  const placeOrderClick = (event) => {
  
    event.preventDefault();
    // This will take ~8 seconds or so
    setOrderResponse('');
    // show progress bar 
    setOrderProcessing(true);

    validateAndPlaceOrder(
      dispensaryId, 
      true, /* isPickup */
      addedItems, 
      Math.ceil(totalPrice * 100),
      {}, 
      selectedPickupSlot,
      placeOrder, 
      handleResponseErrors, 
      new AbortController()  /* do not cancel */
    ); 
  }

  const getPaymentDetails = React.useCallback(() => {
    return isPayInStore 
    ? {
        processor: "on-site",
      }  
    : {
        processor: "aeropay",
        aeropay_payment_data: {
          bank_account_id: bankAccountId
        }
      };
  }, [bankAccountId, isPayInStore]);
  
  /**
   * This will be called if validatiion succeeds ( total cost + item availability ) 
   */
  const placeOrder = () => {
    // bank account retrieved via ZR api
    const payment_details = getPaymentDetails();
   
    submitDispensaryOrder(
      dispensaryId,
      true, /* isPickup */
      addedItems,
      {}, /* deliveryWindow */
      selectedPickupSlot,
      payment_details,
      placeOrderCallback, 
      new AbortController()  /* do not cancel */
    );
  }

  /**
   * When the order is submitted we notify the user and navigate to  /orderConfirmation 
   * 
   * @param {objec} API response for order submission 
   */
  const placeOrderCallback = (response) => {
    
    if (response.error) {
      // hide progress bar
      setOrderProcessing(false);
      setOrderResponse(<ErrorMessage text={response.error}/>);
    } else {  
      const { customer_id, dispensary_id, items, costs:backEndCosts, type, id, created_at, status } = response;
      
      // Empty the cart once the order is placed
      emptyCart();
      setOrderResponse('');
      trackEvent('order_requested', 'pickup', parseInt(totalPrice * 100, 10));

      // Post-order validation, each cost component (rounded penny amounts) 
      const pickupBaseFeeCents = isPayInStore ? 0 : pickupFeeCents 
      const frontEndCosts  = { 
        total: Math.ceil(totalPrice * 100), 
        subtotal: itemTotal - bulkDiscountCents,
        tax: taxCents,
        service_fee: feeCents,
        pickup_fee: pickupBaseFeeCents,
        discount: 0, /* TODO */
        bulk_discount:  bulkDiscountCents
      };
      // Compare API computed price components with Web App calcs
      const costCalcDiffs = validateCostCalculation(frontEndCosts, backEndCosts, OrderType.PICKUP);
      if ( costCalcDiffs.length ) {
        trackEvent('order_pricing_error', costCalcDiffs.join(','), id);  
      }

      // Hide the progress bar!
      setOrderProcessing(false);

      // Show order confirmation page, pass data for dev-only validation 
      navigateTop(`/menu/${dispensaryId}/orderConfirmation`, {
        state: {
          customer_id, 
          dispensary_id, 
          items,
          delivery_schedule: {},
          payment_details: getPaymentDetails(),
          pickup_time_slot: selectedPickupSlot,
          costs: backEndCosts,
          costCalcDiffs, 
          type, 
          id, 
          created_at, 
          status 
        }
      }); 
    }
  }; 

  const itemTotalDisplay = parseFloat(totalAfterDiscounts/100).toFixed(2);
  const minOrderDisplay = parseFloat(minPickupOrder/100).toFixed(2);
  const minOrderMet = totalAfterDiscounts >= minPickupOrder; 

  return (
    <div className={styles.toCheckoutForm}>
      <form>
        <div className={styles.checkoutDetail}>
          <span className={styles.label}>
            Subtotal:
          </span>  
          <span className={styles.amount}>
            ${itemTotalDisplay}
          </span>
        </div>
  
        <div className={styles.checkoutDetail}>
          <span className={styles.label}>
            Fees &amp; Estimated Taxes:
            <TaxesTooltip 
              dispensaryName={dispensaryName}
              itemTotal={totalAfterDiscounts}
              salesTaxPercent={salesTaxPercent}
              cannabisTaxPercent={cannabisTaxPercent}
              serviceFeeCents={feeCents}
              />
          </span>
          <span className={styles.amount}>
            ${(feesAndTaxCents/100).toFixed(2)}
          </span>    
        </div> 
        
        <div className={styles.checkoutTotal}>
          <span className={styles.label}>
            Total:
            { isPayInStore && 
              <sup>*</sup>
            }
          </span>
          <span className={styles.amount}>
            ${totalPrice.toFixed(2)}
          </span>
        </div>
        
        { 
          orderProcessing
          ? <ProgressBar ticks={10} /> 
          : minOrderMet
            ? isPayInStore
              ? <>
                  <Button text="Place Order" isCentered handleClick={placeOrderClick} />
                  <div className={styles.addlFeeMsg}>
                    <sup>*</sup>An additional dispensary fee for non-cash (debit&nbsp;card) transactions may apply.
                  </div> 
                </>      
              : bankAccountId && !testAccountLinking
                ? <div className={styles.apButtonWrap}> 
                    {/* Ready to order */}
                    <img className={styles.apButton} alt="Proceed with AeroPay" src={aeropayButton} onClick={placeOrderClick} />
                    { newLinkedAccount.current 
                      ?  <SuccessMessage text={`Account ...${bankAccountHint} linked! click Proceed with AeroPay to complete your order.`} />  
                      :  <div className={styles.apMessage}>Using your linked account ending in ...{bankAccountHint}</div> 
                    }
                  </div>
                // bank account not linked
                : <LinkAccountsButton onSuccess={handleNewLinkedAccount} />
            // min order not met
            : <DisabledButtonWithReason 
                text="Place Order" 
                reason={`Minimum pickup order: $${minOrderDisplay}`} /> 
        }
        
        {/* spinner + order post errors  - item not available etc. */}
        { orderResponse && 
          <div style={{textAlign:'center'}}>{orderResponse}</div>
        }
      </form>
    </div>
  );
}

OrderPickupButton.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  minPickupOrder: PropTypes.number.isRequired,
  isPayInStore: PropTypes.bool.isRequired
};

export default OrderPickupButton;
