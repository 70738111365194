import * as React from 'react';
import { auth } from '../../firebase';
import { queueForSessionReady } from '../providers/provider-utils';
import { fetchProducts } from '../dispensary/dispensary-utils';
import { trackEvent } from '../analytics/tracking';

import Accessories from '../../assets/accessories_stock80.jpg';
import Concentrates from '../../assets/concentrates_stock80.jpg';
import Edibles from '../../assets/edibles_stock80.jpg';
import Flower from '../../assets/flower_stock80.jpg';
import PreRolls from '../../assets/preroll_stock80.jpg';
import Tinctures from '../../assets/tinctures_stock80.jpg';
import Topicals from '../../assets/topicals_stock80.jpg';
import Vaporizers from '../../assets/vaporizers_stock80.jpg';

// Placeholder images (black and white) for products without images   
export const DefaultImages = {
  'Accessories': Accessories,
  'Concentrate': Concentrates,
  'Concentrates': Concentrates,
  'Edibles': Edibles,
  'Buds': Flower,
  'Flower': Flower,
  'Pre Rolls': PreRolls,
  'Pre-Rolls': PreRolls,
  'PreRolls' : PreRolls,
  'Tincture' : Tinctures,
  'Tinctures' : Tinctures,
  'Topicals': Topicals,
  'Vaporizers' : Vaporizers
};

// If we don't have a real product image, use the fallback
// The fallback is needed for CDN failure so we return it as well
export const handleMissingProductImage = (image_url, product_category) => {
  let imgUrl = image_url, 
      fallbackUrl = DefaultImages[product_category],
      isFallback = false;
  if (!image_url) {
    imgUrl = fallbackUrl;
    isFallback = true;   
  }
  return { imgUrl, fallbackUrl, isFallback };  
}

// Determine if product Image is a Category fallback image
export const isProductFallbackImage = (src) => {
  return src && 
         (src.indexOf('/static/media/') === 0 ||
          (src.indexOf('/static/media/') > 0 && src.indexOf('ziprun') > 0) ||
          src.indexOf('data:image') === 0);
};

/**
 * BULK / TIERED PRICING
 */

// We only display 1/8, 1/4, 1/2, 1oz - Used in product modal
export const bulkFractionWhitelist = [3.5, 7, 14, 28];

// We only care about pricing up to legal limit: 28grams 
const bulkWeightLabels = {
  '3.5': '1/8 oz',
  '7': '1/4 oz',
  '10.5': '3/8 oz',
  '14' : '1/2 oz',
  '17.5' : '5/8 oz',
  '21' : '3/4 oz',
  '24.5' : '7/8 oz', 
  '28' : '1 oz'
};

// Used when incrementing/decrementing cart count
const preciseTieredPrice = (dollarPrice, grams) => {
  return Math.round(dollarPrice * grams * 10000)/100;
}

// For use in select / drop-down
const displayPrice = (dollarPrice, grams) => {
  return Math.round(dollarPrice * grams * 100)/100;
}  

const quantityZeroBulkItem = {
  count: 0,
  grams: 0,
  label: 'none',
  priceInCents: 0,
  priceInDollars: 0,
  displayPrice: 0 /* used in select / drop-down */
}

/*
  Sample pricingTierData
  gramsEach = 7;
  const tierData = [
    {price: 9.9857, startWeight: 3.5, endWeight: 7},
    {price: 9.2786, startWeight: 7, endWeight: 14},
    {price: 8.7107, startWeight: 14, endWeight: 28},
    {price: 7.9625, startWeight: 28, endWeight: 9999}
  ];
*/

/**
 * Build bulk/tiered pricing object for use in Redux
 * 
 * @param {*} gramsEach 
 * @param {*} pricingTierData 
 * @returns {object}
 */
export const getBulkPricingOptions = (gramsEach, pricingTierData) => {
  const bulkPricingOptions = [];
  
  if (pricingTierData) {
    // Let's be 1-indexed
    bulkPricingOptions.push(quantityZeroBulkItem);

    let currentGrams = gramsEach; 
    let pricingTier;
    while (currentGrams && currentGrams <= 28) {
      /* eslint-disable-next-line no-loop-func */
      const [tier] = pricingTierData.filter(tier => tier.startWeight === currentGrams);    
      // build tier pricing object if found for startWeight
      pricingTier = tier || pricingTier;
      if (pricingTier) { 
        const count = parseInt(currentGrams/gramsEach, 10);
        bulkPricingOptions.push({
          count,
          grams: currentGrams,
          label: bulkWeightLabels[currentGrams.toString()], // '1/8 oz'
          priceInCents: preciseTieredPrice(pricingTier.price, currentGrams),
          priceInDollars: displayPrice(pricingTier.price, currentGrams) /* used in select / drop-down */
        })  
      }
      // We build but suppress the display of 3/8 oz 3/4 oz etc. via bulkFractionWhitelist
      currentGrams = currentGrams + gramsEach;
    }
  };
  return bulkPricingOptions;
};

/**
 * Custom hook that builds bulk/tiered pricing object for use in components
 * 
 * @param {*} gramsEach 
 * @param {*} pricingTierData 
 * @returns {object}
 */
export const useBulkPricingOptions = (gramsEach, pricingTierDataString) => {
  
  const [bulkPricingOptions, setBulkPricingOptions] = React.useState([]);
  
  React.useEffect(() => {
    const pricingTierData = pricingTierDataString ? JSON.parse(pricingTierDataString) : [];
    if (pricingTierData.length) {
      // Let's be 1-indexed
      const pricingOptions = [quantityZeroBulkItem];
      
      let currentGrams = gramsEach; 
      let pricingTier;
      while (currentGrams && currentGrams < 29) {
        /* eslint-disable-next-line no-loop-func */
        const [tier] = pricingTierData.filter(tier => tier.startWeight === currentGrams);    
        // update tier pricing if found for startWeight
        pricingTier = tier || pricingTier;
        if (pricingTier) { 
          const count = parseInt(currentGrams/gramsEach, 10);
          pricingOptions.push({
            count,
            grams: currentGrams,
            label: bulkWeightLabels[currentGrams.toString()], // '1/8 oz'
            priceInCents: preciseTieredPrice(pricingTier.price, currentGrams),
            priceInDollars: displayPrice(pricingTier.price, currentGrams) /* used in select / drop-down */
          })  
        }
        // We build but suppress the display of 3/8 oz 3/4 oz etc. via bulkFractionWhitelist
        currentGrams = currentGrams + gramsEach;
      };
      setBulkPricingOptions(pricingOptions);
    } else {
      setBulkPricingOptions([]);
    }
  }, [gramsEach, pricingTierDataString]);
  return bulkPricingOptions;
};

// Name, desc, price are guaranteed if product.is_active
const isValidProduct = (product) => {
  /**
   * NOTE: We currently request only "active" products
   * so no need to check .is_active
   * We SHOULD make sure the price is not accidentally 
   * set to 0 though...
   * 
   * Min price: $1.00 
   */
  return product.display_info && 
         parseInt(product.display_info.cost_usa_cents, 10) > 99;
};

/**
 * Validate and map products into categories supported by the app
 * 
 * Retrieving products by category is currently the primary way we display products
 * We validate products prior to putting them into the category map 
 * 
 * @param {object} response - the fetch response
 */
export const groupByStandardCategories = (response) => {
  const mapByCatName = (response.data || []).reduce(function(map, item) {
    if (isValidProduct(item) && item.display_info.category?.display_name) {
      (map[item.display_info.category.display_name] = map[item.display_info.category.display_name] || []).push(item);
    }
    return map;
  }, {});

  // Sort Featured (.is_featured) first!
  Object.keys(mapByCatName).forEach(category => {
    mapByCatName[category].sort((a,b,) => {
      return a.is_featured === b.is_featured ? 0 : a.is_featured? -1 : 1;
    })
  });
  
  return mapByCatName;
}

export const loadDispensaryProducts = (dispensaryId, productsCallback, abortController) => {
  const loadProductsCallback = (products) => {
    productsCallback(products);
  };
  
  try {
    if (typeof auth === 'undefined' || !auth.currentUser) {
      queueForSessionReady(() => {
        fetchProducts(dispensaryId, loadProductsCallback, abortController);
      });
    } else {
      fetchProducts(dispensaryId, loadProductsCallback, abortController);
    }
  } catch(error) {
    trackEvent('error_fetching_products',`${dispensaryId}:${error}`);
  }
}

// SAMPLE SUB CATEGORY DATA FOR DEV
const SAMPLE_SUB_CATEGORIES = [
  {
    name: "sub1",
    display_name: "Sub-Category 1",
    parent_category_name: "edibles"
  },
  {
    name: "sub2",
    display_name: "Sub-Category 2",
    parent_category_name: "edibles"
  },
  {
    name: "sub3",
    display_name: "Sub-Category 3",
    parent_category_name: "edibles"
  }
];

// Currently there is no subCategory data in dev
export const addSampleSubCategories = (products, subCategory) => {
  if (subCategory && Array.isArray(products)) {
    products.forEach((p) => {
      if (p.display_info.category?.name === subCategory) {
        p.display_info.sub_category = SAMPLE_SUB_CATEGORIES[Math.floor(Math.random() * 3)];
      }
    });
  }  
}

const SAMPLE_BRANDS = ['SuperDuper', 'Big Kahuna', 'ForRealz'];

// Currently there is no subCategory data in dev
export const addSampleBrands = (products, subCategory) => {
  if (subCategory && Array.isArray(products)) {
    products.forEach((p) => {
      if (p.display_info.category?.name === subCategory) {
        p.display_info.brand = SAMPLE_BRANDS[Math.floor(Math.random() * 3)];
      }
    });
  }  
}