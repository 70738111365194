import * as React from 'react';

import discountIcon from '../../assets/discountRedIcon.png'
import PropTypes from 'prop-types';

import styles from './ProductCardForGrid.module.css';

const GRAMS_TO_DISPLAY_OUNCES = new Map([
  ['3.5', '1/8 oz'],
  ['7', '1/4 oz'],
  ['14', '1/2 oz'],
  ['28', '1 oz'] 
]);

const WEIGHT_ENABLED_CATEGORIES = [
  'flower',
  'vaporizers',
  'concentrates'
];

const PriceWithWeightButton = ({
  formattedPrice,
  grams, 
  categoryName,
  bulkPricing,
  handleClick
}) => {

  const [displayWeight, setDisplayWeight] = React.useState();

  const buttonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  }


  React.useEffect(() => {
    if (grams && typeof displayWeight === 'undefined') {
      if (GRAMS_TO_DISPLAY_OUNCES.has(grams.toString())) {
        setDisplayWeight(GRAMS_TO_DISPLAY_OUNCES.get(grams.toString()));
      } else {
        setDisplayWeight(`${grams}g`);
      }  
    }  
  }, [grams, displayWeight]); 
  
  return (
    <div className={styles.productPrice} onClick={buttonClick}>
      { displayWeight && WEIGHT_ENABLED_CATEGORIES.includes(categoryName)
        ? <>
            <div className={styles.productWeight}>{displayWeight}</div>
            <div className={styles.productWeightDash}>-</div>
          </>
        : null
      }
      { formattedPrice && 
        <div>${formattedPrice}</div>
      }
      { bulkPricing && 
        <img alt="discount" className={styles.discountIcon} height="16" src={discountIcon} />
      }
    </div>
  )
}

PriceWithWeightButton.propTypes = {
  formattedPrice: PropTypes.string.isRequired,
  grams: PropTypes.number, 
  categoryName: PropTypes.string.isRequired,
  bulkPricing: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired 
}

export default  PriceWithWeightButton;