import * as React from 'react';
import config from '../../config';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { validateSignUpEntry } from './validation-utils';

import PropTypes from 'prop-types';

export const RegistrationContext = React.createContext();

const RegistrationProvider = ({children}) => {
  // Address via typeahead or storage
  const [location, setLocation] = React.useState({})
  const [addressErr, setAddressErr] = React.useState();
  // Inline SignUp form
  const [name, setName] = React.useState();
  const [nameErr, setNameErr] = React.useState();
  const [email, setEmail] = React.useState();
  const [emailErr, setEmailErr] = React.useState();
  const [password, setPassword] = React.useState();
  const [passwordErr, setPasswordErr] = React.useState();
  const [apartmentNumber, setApartmentNumber] = React.useState();
  const [birthDate, setBirthDate] = React.useState();
  const [birthDateErr, setBirthDateErr] = React.useState();
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [acceptTermsErr, setAcceptTermsErr] = React.useState();
  const [acceptMarketing, setAcceptMarketing] = React.useState(true);
  // PhoneNumberInline component
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [phoneNumberErr, setPhoneNumberErr] = React.useState();
  // PhotoIdInline component
  const [primaryPhotoId, setPrimaryPhotoId] = React.useState();
  const [secondaryPhotoId, setSecondaryPhotoId] = React.useState();
  // Success Message - for handling post registration message 
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(); 

  /**
   * Validate a single form input.
   * 
   * @param {string} inputId - input id
   * @param {string} value - input value
   */
  const isValidEntry = (inputId, value, errorSetter) => {
    const isValid = validateSignUpEntry(inputId, value);
    if (errorSetter) {
      errorSetter(!isValid);
    }
    return isValid;
  }; 

  /**
   * Validate a BirthDate
   *  
   * @param {string} value - MM/DD/YYYY
   * @returns boolean
   */
  const isValidBirthDate = (value) => {
    /**
     * Validate the date by comparing the Month/Year after setting the values
     * (An invalid Feb 30 date will resolve to March 2)
     */
    const dateItems = typeof value === "undefined" ? [] : value.split('/');
    let isValid = false;
    if (dateItems.length === 3 && dateItems[2].length === 4) {
      const bDay = new Date(`${dateItems[2]}/${dateItems[0]}/${dateItems[1]}`);
      const birthYear = bDay.getFullYear();
      const currentYear = new Date().getFullYear();
      isValid = birthYear === parseInt(dateItems[2], 10) &&
                birthYear > 1900 && birthYear < currentYear && 
                bDay.getMonth() === parseInt(dateItems[0], 10) - 1;
                // The bDay.getMonth() check ensures the user entered a valid day
    }
    setBirthDateErr(!isValid);
    return isValid;
  };

  const isValidAddress = (value) => {
    const isValid = !!(value.street_address && value.city && value.state && value.zip_code);
    setAddressErr(!isValid);
    return isValid;
  } 

  const isValidPhoneNumber = (value) => {
    // This is the less strict validation to reduce false negatives
    // See: https://gitlab.com/catamphetamine/react-phone-number-input#isvalidphonenumbervalue-string-boolean
    const isValid = typeof value === "string" && isPossiblePhoneNumber(value);
    setPhoneNumberErr(!isValid);
    return isValid;
  }

  /**
   * Validate all required form inputs
   */
  const validateAll = () => {
    const validName = isValidEntry('name', name, setNameErr);
    const validEmail = isValidEntry('email', email, setEmailErr);
    const validPassword = isValidEntry('password', password, setPasswordErr);
    const validAddress = isValidAddress(location);
    const validDOB = isValidBirthDate(birthDate);
    const validPhone = isValidPhoneNumber(phoneNumber);
    const validTerms = isValidEntry('acceptTerms', acceptTerms, setAcceptTermsErr);
    
    if (config.IS_DEV) {
      console.log(`Validating...`);
      console.log(`valid name: ${validName}`);
      console.log(`valid email: ${validEmail}`);
      console.log(`valid password: ${validPassword}`);
      console.log(`valid address: ${validAddress}`);
      console.log(`apartment is ${apartmentNumber}`);
      console.log(`valid phone is ${validPhone}`);
      console.log(`valid DOB: ${validDOB}`);
      console.log(`valid terms: ${validTerms}`);
      console.log(`accept marketing is ${acceptMarketing}`);
    }

    return  validName && validEmail && validPassword && validAddress &&
            validDOB && validPhone && validTerms; 
  };  

  return <RegistrationContext.Provider value={{
      location,
      setLocation,
      addressErr,
      name,
      setName,
      nameErr,
      setNameErr,
      email,
      setEmail,
      emailErr,
      setEmailErr,     /* also used in for onBlur email-exists check */
      password,
      setPassword,
      passwordErr,
      setPasswordErr,  /* also used for onBlur strong-password check */
      apartmentNumber,
      setApartmentNumber,
      birthDate,
      setBirthDate,
      birthDateErr,
      setBirthDateErr,
      acceptTerms,
      setAcceptTerms,
      acceptTermsErr,
      setAcceptTermsErr,
      acceptMarketing,
      setAcceptMarketing,
      phoneNumber,
      setPhoneNumber,
      phoneNumberErr,
      setPhoneNumberErr,
      primaryPhotoId,
      setPrimaryPhotoId,
      secondaryPhotoId,
      setSecondaryPhotoId,
      validateAll,
      showSuccessMessage, 
      setShowSuccessMessage
    }}>
    {children}
  </RegistrationContext.Provider>
}

RegistrationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default RegistrationProvider;