import * as React from 'react';

import CroppedProductImage  from './CroppedProductImage';
import ProductInfoTiles from '../products/ProductInfoTiles';
import AdjustQuantity from './AdjustQuantity';
import Button from '../styleguide/Button';
import StaffPick from './StaffPick';
import ProductWeightPill from './ProductWeightPill';
import ToolTip from '../common/ToolTip';
import BulkPricingTable from './BulkPricingTable';
import discountIcon from '../../assets/discountRedIcon.png'
import PropTypes from 'prop-types';

import styles from './ProductDetailsCard.module.css';

/**
 *  This component displays the detailed product view in the modal popup and the single product SEO page 
 */
const ProductDetailsCard= (
  {
    Id,
    name,
    brand,
    category,
    is_featured,
    imageInfo,
    description,
    cost_usa_cents,
    thc_lab_value,
    thc_lab_units,
    cbd_lab_value,
    cbd_lab_units,
    cannabis_type,
    productGrams,
    leaf_logix_pricing_tier,
    handleClick
  }) => {
 
  // These are all strings
  const showProductTiles = cbd_lab_value || thc_lab_value || cannabis_type;
 
  // Add to cart
  const addToCartClick = (event) => {
    event.stopPropagation();
    handleClick(itemCount); 
  }; 

  const [itemCount, setItemCount] = React.useState(1);
  const [displayTotal, setDisplayTotal] = React.useState();
  const [formattedPrice, setFormattedPrice] = React.useState();

  React.useEffect(() => {
    // handle button price
    setDisplayTotal(
      parseFloat(Math.round((itemCount * cost_usa_cents))/100).toFixed(2) 
    );
    setFormattedPrice(
      parseFloat(cost_usa_cents/100).toFixed(2) 
    );
  }, [itemCount, cost_usa_cents]);
  
  const formattedDesc = description
    ? description.replace(/\n/g, "<br />")
    : 'No Description';

  return (
    <div key={`${Id}-modal`} className={styles.card}>
      <div className={styles.productImageWrap}>
        <CroppedProductImage
          productName={name}
          src={imageInfo.productImage}
          category={imageInfo.productCategory}
          isWide />
      </div>
      <div className={styles.productBody}>
        <div className={styles.productHdr}>
          { brand &&
            <div className={styles.brand}>
              {brand}
            </div>
          }
          <div className={styles.productName}>{name}</div>
          { is_featured &&
            <div className={styles.staffPickWrap}> 
              <StaffPick />
            </div>
          }   
          
          { showProductTiles &&
            <div className={styles.productInfo}>
              <ProductInfoTiles
                productId={Id}
                thcVal={thc_lab_value}
                thcUnits={thc_lab_units}
                cbdVal={cbd_lab_value}
                cbdUnits={cbd_lab_units}
                cannabisType={cannabis_type} />
            </div>
          }
           
          <div className={styles.weightPillWrapper}> 
            { formattedPrice && 
              <ProductWeightPill 
                price={formattedPrice}
                grams={productGrams}
                category={category}
              />
            }
            { leaf_logix_pricing_tier?.pricingTierData &&
              <div className={styles.bulkTipBorder}>
                <div className={styles.bulkTipInner}>
                  <ToolTip
                    buttonIcon={discountIcon}
                    buttonText="Show Bulk Discounts"
                    buttonStyle={{color:'var(--zrPromoRed)'}} 
                    tipTitleIcon={discountIcon} 
                    tipTitle="Bulk Discount Pricing"
                    footerText="*Your discount will be calculated at checkout.">
                    <BulkPricingTable
                      grams={productGrams} 
                      pricingTierData={leaf_logix_pricing_tier.pricingTierData} /> 
                  </ToolTip>
                </div>
              </div>
            }
          </div>

          <div className={styles.controlsWrap}>  
            <div className={styles.adjustQuantityWrap}>
              <AdjustQuantity count={itemCount} setCount={setItemCount} />
            </div>
            <div className={styles.productButtonWrap}>
              <Button text={`Add to Cart - $${displayTotal}`} 
                            handleClick={addToCartClick} 
                            withStyle={{margin:'1rem 0'}} />
            </div>
            <div className={styles.taxesNote}>*Cannabis and Sales tax will be added at checkout.</div>
          </div>
        </div>
        
        <div className={styles.productDescWrap}>
          <div className={styles.productDescLabel}>
            Description
          </div>
          <div 
            className={styles.productDesc}
            dangerouslySetInnerHTML={{ __html:formattedDesc }}>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductDetailsCard.propTypes = {
  Id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  brand: PropTypes.string,
  category: PropTypes.object.isRequired,
  is_featured: PropTypes.bool.isRequired,
  description: PropTypes.string,
  imageInfo: PropTypes.object.isRequired,
  cost_usa_cents: PropTypes.number,
  thc_lab_value: PropTypes.string,
  thc_lab_units: PropTypes.string,
  cbd_lab_value: PropTypes.string,
  cbd_lab_units: PropTypes.string,
  cannabisType: PropTypes.string,
  productGrams: PropTypes.number,
  leaf_logix_pricing_tier: PropTypes.object, /* bulk products only */
  handleClick: PropTypes.func.isRequired
};

export default ProductDetailsCard;
