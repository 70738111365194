import * as React from "react";

import { UrlParamsContext } from "../providers/UrlParamsProvider";
import { RegistrationContext } from "./RegistrationProvider";
import { trackEvent } from '../analytics/tracking';
import { errMsg } from './registration-errors';
import { parsePhoneNumber } from 'react-phone-number-input';
import { sendVerificationCode } from './registration-utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ProcessRegistrationInline from "./ProcessRegistrationInline";
import DisplayModal from '../common/DisplayModal';
import EnterVerificationCode from './EnterVerificationCode';
// import LogRegFlowAction from '../intercom/LogRegFlowAction';
import Button from '../styleguide/Button';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './SignUpInline.module.css';

/**
 * Validate Phone Number (via SMS)
 * AND run validateAll to validate all registration values in the RegistrationProvider
 * 
 * Ask for and validate phone number (RFC3966)
 */
const VerifyPhoneAndComplete = () => {
  
  // For Intercom: name, email, acceptMarketing: okToEmail
  const { 
    phoneNumber,
    validateAll  
  } = React.useContext(RegistrationContext);
  
  const { dispensaryId } = React.useContext(UrlParamsContext);

  const [sendCodeError, setSendCodeError] = React.useState();
  const [verifyCodeError, setVerifyCodeError] = React.useState();
  
  // Verify Mode voice call option
  const [isVoiceCall, setIsVoiceCall] = React.useState(false);
  // Verify code modal
  const [showVerificationModal, setShowVerificationModal] = React.useState();
  
  // A reference to the number being verified
  const codeRecipientNumber = React.useRef();
  const countryCodeRef = React.useRef('US');

  // Results of full registration entry validation
  const [validationSuccess, setValidationSuccess] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState(false); 

  // Complete registration
  const [submitRegistration, setSubmitRegistration] = React.useState(false);
  const [registrationError, setRegistrationError] = React.useState();

  // Validate Registration data and send verification code if validation passes
  const handleVerify = (event) => {
    event.preventDefault();
    setVerifyCodeError(false);
    
    // VALIDATE ALL REGISTRATION DATA - and abort phone verification if validation fails
    if(!validateAll()) {
      setValidationErrors(true);
      return;
    } else {
      setValidationErrors(false);
      setValidationSuccess(true);
    }
    
    if (phoneNumber) {
      // Parse out country code, etc.n
      const phoneNumberInfo = parsePhoneNumber(phoneNumber);
        
      // (non-inline defensive code) The country code has a tendency to get unset on back navigation:
      countryCodeRef.current = phoneNumberInfo.country;
      
      // Handle verification response
      const sendCodeCallback = ((response) => { 
        // success
        if(response.recipient_tel_number) {
          setShowVerificationModal(true);
        // failure
        } else {
          trackEvent('error_sending_SMS_code', 'reg_flow');
          setSendCodeError(true); 
        }
      });
        
      codeRecipientNumber.current = phoneNumber;
      const verificationInfo = {
        delivery_method: 'sms-text', 
        tel_number: codeRecipientNumber.current
      };
      const controller = new AbortController();
      
      // Send the SMS message or voice and show modal for code
      sendVerificationCode(verificationInfo, isVoiceCall, sendCodeCallback, controller);  
    }
  }
  
  // The callback for the verification modal
  const onNumberVerfied = (response) => {
    if (response.success) {
      // Hide verify modal so that submission errors can be seen
      setShowVerificationModal(false);
      // Process registration
      setSubmitRegistration(true);
    } else {
      // something happened that was not success or error
      setVerifyCodeError(true);  
    }
  };
  
  // Handle voice call toggle 
  const onVoiceCallClick = (event) => {
    const { checked:value } = event.currentTarget;
    setIsVoiceCall(value);
  } 
  
  const ErrorMessage = ({errorType, isError}) => {
    return (
      <div style={{textAlign:'center'}}>
        <div className={`${styles.inputError} ${styles.buttonError}`}>
          {errMsg(errorType, isError)}
        </div>
      </div>
    )
  };
 
  const RegistrationError = ({errorText}) => {
    return (
      <div style={{textAlign:'center'}}>
        <div className={`${styles.inputError} ${styles.registrationError}`}>
          <ErrorIcon style={{fontSize:32}} />Registration Error: {errorText}
        </div>
      </div>
    )
  };
  
  return (
    <>
      { validationSuccess && submitRegistration
        // Submit Registration
        ? <ProcessRegistrationInline dispensaryId={dispensaryId} onFailure={(error) => {
            setRegistrationError(error); /* display error */
            setSubmitRegistration(false);  /* re-enable submit */
          }} />
        // Show Verify Button  
        : <div className={styles.verifyButton}>
            { registrationError &&
              <RegistrationError errorText={registrationError} />
            }
            <Button
              isCentered
              handleClick={handleVerify}
              text={ isVoiceCall
                ? "Verify Phone (Voice Call) to Complete"
                : "Verify Phone (via SMS) to Complete"
              } />
            <div className={styles.verifyMethod}>
              <FormControlLabel
                control={<Checkbox
                  color="default"
                  id="accept_terms"
                  checked={isVoiceCall}
                  onChange={onVoiceCallClick} />}
                label="Send my verification code via voice call"
              />
            </div>
            { sendCodeError &&
               <ErrorMessage errorType="send_code" isError={sendCodeError} /> 
            }
            { verifyCodeError &&
              <ErrorMessage errorType="verify_code" isError={verifyCodeError} />  
            }
            { validationErrors &&
              <ErrorMessage errorType="validation" isError={validationErrors} />
            } 
          </div>
      }

      {/* Only verify phone if registration data is valid */}
      { validationSuccess
        ? showVerificationModal
          ? <DisplayModal title="Verify Phone Number" closeFn={() => setShowVerificationModal(false)}>
              <EnterVerificationCode phoneNumber={codeRecipientNumber.current} 
                onVerified={onNumberVerfied}
                closeModalFn={() => setShowVerificationModal(false)} />
            </DisplayModal>
          : null
        : null
      }

      {/* Intercom logging removed for now
        <LogRegFlowAction
          name={name}
          email={email}
          okToEmail={okToEmail} 
          actionKey="Registration_Start"
          actionValue={true} />
      */} 
    </>
  );
}

export default VerifyPhoneAndComplete;