import * as React from 'react';

import { auth } from '../../firebase';
import { fetchFastLinkCredentials, postFastLinkCredentials } from './payment-utils';
import { trackEvent } from '../analytics/tracking';
import FastLinkWidget from './FastLinkWidget';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

/**
 * AeroPay/Yodlee-fastlink accounts are created on behalf of customers using (in part)
 * the user's phone number 
 *
 * @param {func} onSuccess - what to do after successful bank account linking
 * @param {func} onFailure - what to do after failure to link bank accounts
 */
const LinkAccountsWithFastLink = ({ onSuccess, onFailure }) => {

  /**
   * For AeroPay staging/dev environments use:
   *  
   * staging bank: 'dag site'.
   * staging username: YodTest.site16441.2
   * staging user password: site16441.2 
   */
  
  const trackingModule = 'aeropay'; 
  
  const [fastLinkUser, setFastLinkUser] = React.useState();
  const [token, setToken] = React.useState();
  const [fastLinkURL, setFastLinkURL] = React.useState()
  const controllerRef = React.useRef(new AbortController());
  
  const handleWidgetEvents = (event) => {
    const linkAccountCallback = (isSuccess, responseJSON, bankAccountId) => {
      if (isSuccess) {
        onSuccess({
          message: `Bank account sucessfully linked`,
          bankAccountId  /* this is a proxy account number, unknown to user */ 
        });  
      } else {
        const responseErr = responseJSON.error ? `: ${responseJSON.error}` : '';
        onFailure({
          message: `Error linking account: ${responseErr}`
        });
      }
    };
   
    // Not sure what sites > 1 would mean, multiple banks?
    if ( event.sites && event.sites.length ) {
      // Build json to post to Zip Run api
      const firstBank = event.sites[0]
      // Returned by FastLink
      const accountInfo  = (({ accountId, providerAccountId }) => ({ accountId, providerAccountId }))(firstBank);
      accountInfo.accountId = parseInt(accountInfo.accountId, 10);
      // Returned earlier via ZipRun API
      accountInfo.externalBankAccountId = fastLinkUser;
      accountInfo.token = token;
      
      // Success - we have an accountId, so post back to Zip Run
      if (accountInfo.accountId) {
        postFastLinkCredentials(
          accountInfo, 
          (isSuccess, responseJSON) => { linkAccountCallback(isSuccess, responseJSON, accountInfo.accountId) }, 
          controllerRef.current);
      // Failure
      } else {  
        const { status } = event;
        /**
         *  Sample response
         *  event.reason: "User canceled the linking process confirming account deletion."
         *  event.status: "ACTION_ABANDONED
         */
        trackEvent(trackingModule, status);
        onFailure({
          message: "Bank Account not linked. An AeroPay-linked bank account is required for delivery orders"
        });
      } 
    } else {
      trackEvent(trackingModule, 'linking_error');
      onFailure({
        message: "Bank Account not linked. An AeroPay-linked bank account is required for delivery orders"
      });
    }
  };
 
  React.useEffect(() => {
    console.log('fastlink widget parent render');
    // Retrieve the username, token, url ( required in part to launch Fastlink widget );
    const credentialsCallback = (response) => { 
      if (response.token) {
        const { username, token, fastlinkURL:fastLinkURL } = response;
        setFastLinkUser(username);
        setToken(token);
        setFastLinkURL(fastLinkURL);
      }  
    };  

    if (auth.currentUser && !auth.currentUser.isAnonymous) {  
      fetchFastLinkCredentials(credentialsCallback, controllerRef.current);     
    }
  }, []);
  
  return (
    <div>
      { token && fastLinkURL 
        ? <FastLinkWidget 
            accessToken={token} 
            fastLinkURL={fastLinkURL} 
            handleEvents={handleWidgetEvents} />
        : <Spinner inSmallLayout />
      }
    </div>
  )  
}

LinkAccountsWithFastLink.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired
};

export default LinkAccountsWithFastLink;