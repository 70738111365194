import * as React from 'react';

import PropTypes from 'prop-types';
import { trackEvent } from '../analytics/tracking';
import { freezeBody, unfreezeBody } from '../modal/modal-utils';
import closeX from '../../assets/closeX.png';
import Helmet from 'react-helmet';

import styles from './DisplayModal.module.css';

const DisplayModal = ({title, scrollingInner, isShrinkToFit, children, showAboveNav, trackContext, trackId, trackText, closeFn}) => {

  React.useEffect(() => {
    // track if desired
    trackContext && trackEvent('modalContent', trackContext, trackId, [{'msg' : trackText}]);
    // fix/hack via css for location search in modal
    document.body.setAttribute('data-modal-open', true);
    // prevent body scroll under modal
    freezeBody(); 

    // unfreeze document scroll on unmount
    return () => {
      document.body.setAttribute('data-modal-open', false);
      unfreezeBody();
    };

  }, [trackContext, trackId, trackText]);

  // Some modals (SignIn) should display above all: nav/cart etc.
  const styleOverrides = showAboveNav ? { zIndex:10 } : {}; 
  if (isShrinkToFit) {
    styleOverrides.width = 'auto';
  }

  const scrollStyle = scrollingInner ? styles.scrollingInner : '';

  return (
    <>
      {/* optional page title for SEO/UX */}
      {title && 
        <Helmet>
          <title>Zip Run - {title}</title>
        </Helmet>
      }
      <div className={styles.modalBGElem} style={styleOverrides}></div>
      <div className={styles.modalContent} style={styleOverrides}>
        <img alt="" className={styles.closeIcon} src={closeX} height="24" onClick={closeFn} />
        <div className={`${styles.modalInner} ${scrollStyle}`}>
          {children}
        </div>
      </div>  
    </> 
  );
};

DisplayModal.propTypes = {
  title: PropTypes.string.isRequired,  // used to update page title
  showAboveNav: PropTypes.bool,
  isShrinkToFit: PropTypes.bool,  
  trackContext: PropTypes.string,
  trackId: PropTypes.string,
  trackText: PropTypes.string,
  closeFn: PropTypes.func.isRequired
};

export default DisplayModal;