import * as React from "react";

import { auth } from "../../firebase";
import TextField from '@material-ui/core/TextField';
import { errMsg } from './registration-errors';
import { isValidEmail } from './validation-utils';
import Button from '../styleguide/Button';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types'; 

import styles from './PasswordReset.module.css';

/* Used on the account page */
const PasswordReset = ({userEmail, handleCancel}) => {
  
  const [email, setEmail] = React.useState(userEmail);
  const [emailErr, setEmailErr] = React.useState(false);
  const [emailHasBeenSent, setEmailHasBeenSent] = React.useState(false);
  const [errorSendingEmail, setErrorSendingEmail] = React.useState(false);
  
  const sendResetEmail = event => {
    event.preventDefault();
 
    if(!isValidEmail(email)) {
      setEmailErr(true);
      return;  
    }

    auth.sendPasswordResetEmail(email)
        .then(() => {
          setErrorSendingEmail(false);
          setEmailHasBeenSent(true);
          
        })
        .catch(() => {
          setErrorSendingEmail(true);
          setEmailHasBeenSent(false);
        });
  };

  const handleEmailOnFocus = () => {
    setEmailHasBeenSent(false);
    setErrorSendingEmail(false);
    setEmailErr(false);      
  };

  const handleEmailOnBlur = () => {
    setEmailErr(!isValidEmail(email));      
  };

  /**
   * Update email as it's entered
   * @param {event} event 
   */
  const onChange = event => {
    const { name, value:email } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(email);
    } 
  };     
   
  return (
    <div className={styles.plainForm}>
      <div className={styles.resetHeader}>Password Reset</div>
      <form action="" className={styles.plainInputs}>
        <TextField id="userEmail" name="userEmail" value={email} label="Your Email" error={emailErr} helperText={errMsg('email',emailErr)} 
                   onBlur={handleEmailOnBlur}
                   onFocus={handleEmailOnFocus} 
                   variant={'filled'}  onChange={onChange} />
        
        { emailHasBeenSent && (
          <div className={styles.emailSuccess}>
            An email has been sent to you!
          </div>
        )}
        {errorSendingEmail && (
          <div className={styles.emailError}>
            <ErrorIcon /> Error sending password reset email
          </div>
        )}
        
        <Button isCentered text="Send me a password reset link" handleClick={sendResetEmail} />

        { handleCancel &&
          <div className={styles.cancel} onClick={handleCancel}>&larr; Cancel</div>
        }
      </form>
    </div>
  );
};

PasswordReset.propTypes = {
  userEmail: PropTypes.string, 
  handleCancel: PropTypes.func
};

export default PasswordReset;