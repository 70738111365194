import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { sendMessage } from '../embedMenu/embed-utils';
import { useSelector } from 'react-redux';
import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { fetchProductDetail } from '../dispensary/dispensary-utils';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Spinner from '../common/Spinner';
import AddedToCartCheck from '../products/AddedToCartCheck';
import BackLink from '../common/BackLink';
import ProductDetailsCard from './ProductDetailsCard';
import PropTypes from 'prop-types';

/**
 * Product detail view
 */
const ProductDetailPage = ({itemId, location}) => {
  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { addItem } = React.useContext(CartItemsContext);
  // Might as well check redux
  const productsById = useSelector(state => state.productsById);
  const [product, setProduct] = React.useState();
  const [productCategory, setProductCategory] = React.useState();
  const [showAddedCheck, setShowAddedCheck] = React.useState();

  // Get the product based on the url
  const setProductItem = React.useCallback((dispensaryId, itemId, controller) => {
    const productCallback = (product) => {
      if (product.id) {
        setProduct(product);
        const productCategory = product.display_info.category?.display_name;
        if (productCategory) {
          setProductCategory(productCategory);
        }
        // Update host page
        sendMessage({
          productCategory,
          productId: itemId 
        });
      } else {
        trackEvent('product_page_fetch_error');
      } 
    };
    try {
      if (!product) {
        // check redux
        let cachedProduct;
        if (productsById && productsById[dispensaryId]) {
          const dispensaryProducts = productsById[dispensaryId];  
          cachedProduct = dispensaryProducts.get(itemId);
        }  
        if (cachedProduct) {
          console.log('using cached product');
          setProduct(cachedProduct);
          setProductCategory(cachedProduct.display_info.category_name);
        } else {
          console.log('fetching product data');
          fetchProductDetail(itemId, dispensaryId, productCallback, controller);
        }
      }
    } catch(error) {
      trackEvent('error_fetching_products',`${dispensaryId}:${error}`);
    }
  }, [productsById, product]);
  
  const abortController = React.useRef(new AbortController());

  // Load dispensary + product
  React.useEffect(() => {
    const controller = abortController.current;
    if (!product) {
      setProductItem(dispensaryId, itemId, controller);
    } 
    // Will not complete if controller aborted
    // return () => { controller && controller.abort() }; 
  }, [dispensaryId, itemId, product, setProductItem, abortController]); 

  const handleClick = (quantity) => {
    setShowAddedCheck(true);
    addItem(product.id, product, quantity);
  }

   // Set path for back link
   const [returnToPath, setReturnToPath] = React.useState();
   
   React.useEffect(() => {
     if (location?.state) {  
       const { returnToPath:backPath } = location?.state;
       if ( backPath !== returnToPath) {
         setReturnToPath(backPath);
       }
     }
   }, [location, returnToPath]);

  return ( 
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      { dispensaryId && product && productCategory
        ? <>
            <BackLink backToPath={returnToPath} backToText="Back" />
            <ProductDetailsCard
              Id={product.id}
              {...product.display_info}
              is_featured={!!product.is_featured}
              productGrams={product.grams}
              leaf_logix_pricing_tier={product.leaf_logix_pricing_tier} 
              imageInfo={{
                productImage: product.display_info.image_url,
                productCategory: product.display_info.category?.display_name
              }}
              isModal={false}
              handleClick={handleClick} 
            />  
          </>
        : <Spinner />
      }
      { showAddedCheck && 
        <AddedToCartCheck closeFn={() => { setShowAddedCheck(false)} } />
      }
    </BodyWrapper>
  )
}  

ProductDetailPage.propTypes = {
  location: PropTypes.object,  /* delayed, via router */
  itemId: PropTypes.string     /* delayed, via router */
};

export default ProductDetailPage;