import React from "react";

import { errMsg } from '../registration/registration-errors';
import { GuestCheckoutContext } from "./GuestCheckoutProvider";
import TextField from '@material-ui/core/TextField';
import PhoneNumberInline from "../registration/PhoneNumberInline";
import DetourButton from '../styleguide/DetourButton';
import ActionLink from "../styleguide/ActionLink";
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import styles from './CheckoutUserFlow.module.css';

const GuestForm= ({
  signInClick,
  signUpClick
}) => {
  
  const {
    name,
    setName,
    nameErr,
    setNameErr,
    birthDate,
    setBirthDate,
    birthDateErr,
    setBirthDateErr,
    phoneNumber,
    setPhoneNumber,
    phoneNumberErr,
    setPhoneNumberErr
  } = React.useContext(GuestCheckoutContext);

  /**
   * @param {*} event 
   * @param {func} input value setter
   * @param {func} input value Error setter
   */
  const onChange = (event, setter, errorSetter) => {
    const { value, checked } = event.currentTarget;
    const inputValue = event.currentTarget.type === "checkbox"
          ? checked
          : value;
    if (setter) {
      setter(inputValue);
    }
    if (errorSetter) {
      errorSetter(false);
    }
  };
  
  /* Material UI TextField Variant */
  const tF = 'filled'; 

  const dateInputRef = React.useRef();

  return (
    <>
      <div className={`${styles.inlineRegFlow} ${styles.inlineRegFlowGuest}`}>
        <div className={styles.hdrPair}>
          <span className={styles.pairLabel}>Guest Checkout</span>
          <span className={`${styles.hdrPairRgt} ${styles.signInText}`}>
            <span className={styles.signInPrompt}>Have an account?</span>
            <DetourButton text="Sign In" isSmall handleClick={signInClick} />
          </span>
        </div>
        <div>
          {/* Name */}
          <TextField id="name" value={name} label="Full Name" error={nameErr} helperText={errMsg('name',nameErr)} 
                     variant={tF} onChange={(event)=>onChange(event, setName, setNameErr)} />
     
          <div className={styles.inputPair}>
            {/* DOB - there is currently a findDOMNode error coming from react-input-mask */}
            <InputMask mask="99/99/9999" value={birthDate} onChange={(event)=>onChange(event, setBirthDate, setBirthDateErr)}>
              {(inputProps) => <TextField id="birthDate" inputRef={dateInputRef} { ...inputProps}
                        label="Date of Birth (MM/DD/YYYY)" variant={tF} error={birthDateErr} helperText={errMsg('birthDate', birthDateErr)} />}
            </InputMask> 
            
            <div className={styles.inlinePhoneInput}>
              <PhoneNumberInline 
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneNumberErr={phoneNumberErr}
                setPhoneNumberErr={setPhoneNumberErr}
              />
            </div>  
          </div>
        </div>
        <div className={styles.signUpLink}>
          Save your info by{' '} 
          <ActionLink text="Signing Up" handleClick={signUpClick} />
        </div>
      </div>
    </>
  );  
};

GuestForm.propTypes = {
  signInClick: PropTypes.func.isRequired,
  signUpClick: PropTypes.func.isRequired
}

export default GuestForm;