import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import { GuestCheckoutContext } from '../checkoutUserFlow/GuestCheckoutProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { PickupSlotsContext } from '../timeSlots/PickupSlotsProvider'; 
import { useSelector } from 'react-redux';
import { calcPickupServiceFeeCents, calcTaxCents, submitGuestPickupOrder, OrderType, validateCostCalculation } from '../checkout/order-utils';
import TaxesTooltip from '../checkout/TaxesTooltip';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import ErrorMessage from '../styleguide/ErrorMessage';
import ProgressBar from '../common/ProgressBar';
import Button from '../styleguide/Button';
import PropTypes from 'prop-types';

import styles from '../checkout/OrderButton.module.css';

/**
 * This is the PlaceOrder button for guest pickup orders
 * 
 * The logged-in/anonymous user state is handled by the parent component
 */
const OrderPickupAsGuest = ({
  dispensaryId,
  dispensaryName,
  minPickupOrder=0
}) => {

  /**
   * Handle Guest Checkout
   */ 
   const {
    name,
    birthDate,
    phoneNumber: tel_number,
    validationErrorMsg,
    isValidGuestInfo
  } = React.useContext(GuestCheckoutContext);

  const [guestInfoComplete, setGuestInfoComplete] = React.useState();
  
  React.useEffect(() => {
    if (!validationErrorMsg) {
      setGuestInfoComplete(true);
    } else {
      setGuestInfoComplete(false);
    }
  },[validationErrorMsg, setGuestInfoComplete]);  
   
  const [orderProcessing, setOrderProcessing] = React.useState();
  const [orderResponse, setOrderResponse] = React.useState();

  const { cartItems, itemTotal, emptyCart } = React.useContext(CartItemsContext);
  const { selectedPickupSlot } = React.useContext(PickupSlotsContext);

  const bulkDiscountCents = useSelector(state => state.bulk_discount_cents);
  const salesTaxPercent = useSelector(state => state.sales_tax_percent);
  const cannabisTaxPercent = useSelector(state => state.cannabis_tax_percent);
  const serviceFeePct = useSelector(state => state.pickup_service_fee_percent);
  const serviceFeePctPayInStore = useSelector(state => state.pickup_in_store_payment_fee_percent);
  const pickupFeeCents = useSelector(state => state.pickup_fee_cents);

  // total net of any bulk discounts
  const totalAfterDiscounts = itemTotal - bulkDiscountCents;

  // Round fees and taxes and calc total price
  const feeCents = calcPickupServiceFeeCents(totalAfterDiscounts, true, serviceFeePctPayInStore, serviceFeePct, pickupFeeCents);
  const taxCents = calcTaxCents(totalAfterDiscounts, salesTaxPercent, cannabisTaxPercent);
  // used later
  const feesAndTaxCents = feeCents + taxCents;
  const totalPrice = (totalAfterDiscounts + feesAndTaxCents)/100; 

  /**
   * Place the order
   */
  const placeOrder = (event) => {
    event.preventDefault();
    
    if (!isValidGuestInfo()) {
      // Errors will display
      return;
    }
    
    // Order processing may take ~10 seconds or so
    setOrderResponse('');
    // show progress bar 
    setOrderProcessing(true);

    // birthDate
    const dobInfo = birthDate.split("/");
    const dob = {
      month: dobInfo[0],
      day: dobInfo[1],
      year: dobInfo[2]
    };

    const guestInfo = { name, tel_number, dob };
    submitGuestPickupOrder(
      dispensaryId,
      cartItems,
      guestInfo,
      selectedPickupSlot,
      placeOrderCallback, 
      new AbortController()  /* do not cancel */
    );
  }

  /**
   * When the order is submitted we notify the user and navigate to  /orderConfirmation 
   * 
   * @param {object} response - API response for order submission 
   */
  const placeOrderCallback = (response) => {
    if (response.error) {
      // hide progress bar
      setOrderProcessing(false);
      setOrderResponse(<ErrorMessage text={response.error}/>);
    } else {  
      const { 
        id, 
        dispensary_id,
        type, 
        payment_details, 
        items, 
        costs:backEndCosts, 
        pickup_time_slot, 
        fulfillment_time_slot,
        status
      } = response;
      emptyCart();
      setOrderResponse('');
      trackEvent('order_requested', 'pickup', parseInt(totalPrice * 100, 10));

      const frontEndCosts  = { 
        total: Math.ceil(totalPrice * 100), 
        subtotal: itemTotal - bulkDiscountCents,
        tax: taxCents,
        service_fee: feeCents,
        pickup_fee: 0, /* pickupFeeCents is currently ignored for pay-in-store */
        discount: 0, /* TODO */
        bulk_discount:  bulkDiscountCents
      };
      // Compare API computed price components with Web App calcs
      const costCalcDiffs = validateCostCalculation(frontEndCosts, backEndCosts, OrderType.PICKUP);
      if ( costCalcDiffs.length ) {
        trackEvent('order_pricing_error', costCalcDiffs.join(','), id);  
      }

      // Hide the progress bar!
      setOrderProcessing(false);

      // Show order confirmation page, pass data for dev-only validation 
      navigateTop(`/menu/${dispensary_id}/orderConfirmation`, {
        replace: true, /* DISABLE return to place order page */
        state: {
          id,
          dispensary_id,
          type,
          payment_details, 
          items,
          pickup_time_slot,
          fulfillment_time_slot,
          costs: backEndCosts,
          costCalcDiffs,
          status
        }
      }); 
    }
  }; 

  const itemTotalDisplay = parseFloat(totalAfterDiscounts/100).toFixed(2);
  const minOrderDisplay = parseFloat(minPickupOrder/100).toFixed(2);
  const minOrderMet = totalAfterDiscounts >= minPickupOrder; 

  return (
    <div className={styles.toCheckoutForm}>
      <form>
        <div className={styles.checkoutDetail}>
          <span className={styles.label}>
            Subtotal:
          </span>  
          <span className={styles.amount}>
            ${itemTotalDisplay}
          </span>
        </div>
  
        <div className={styles.checkoutDetail}>
          <span className={styles.label}>
            Fees &amp; Estimated Taxes:
            <TaxesTooltip 
              dispensaryName={dispensaryName}
              itemTotal={totalAfterDiscounts}
              salesTaxPercent={salesTaxPercent}
              cannabisTaxPercent={cannabisTaxPercent}
              serviceFeeCents={feeCents}
              />
          </span>
          <span className={styles.amount}>
            ${(feesAndTaxCents/100).toFixed(2)}
          </span>    
        </div> 
        
        <div className={styles.checkoutTotal}>
          <span className={styles.label}>
            Total: <sup>*</sup>
          </span>
          <span className={styles.amount}>
            ${totalPrice.toFixed(2)}
          </span>
        </div>
        
        { orderProcessing
          ? <ProgressBar ticks={10} /> 
          : minOrderMet
            ? guestInfoComplete
              ? <>
                  <Button text="Place Order" isCentered handleClick={placeOrder} />
                  <div className={styles.addlFeeMsg}>
                    <sup>*</sup>An additional dispensary fee for non-cash (debit&nbsp;card) transactions may apply.
                  </div> 
                </>
              // Missing/invalid guest field data  
              : <DisabledButtonWithReason 
                  text="Place Order" 
                  reason={validationErrorMsg} />        
            // min order not met
            : <DisabledButtonWithReason 
                text="Place Order" 
                reason={`Minimum pickup order: $${minOrderDisplay}`} /> 
        }
        
        {/* spinner + order post errors  - item not available etc. */}
        { orderResponse && 
          <div style={{textAlign:'center'}}>{orderResponse}</div>
        }
      </form>
    </div>
  );
}

OrderPickupAsGuest.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  minPickupOrder: PropTypes.number.isRequired
};

export default OrderPickupAsGuest;