import React from 'react';

import { OrderTypeContext } from '../providers/OrderTypeProvider';

import PropTypes from 'prop-types';

import styles from './OrderTypeButtons.module.css';

// A Delivery/Pickup toggle for the header nav
const OrderTypeButtons = ({
  inNav=false,
  inBody=false,
  withStyle={},
}) => {

  // Toggle is disabled until delivery launches (pickup is default)
  // (but enabled via window.tests_enabled on qa2)
  const toggleClick = (enablePickup) => {
    if (window.tests_enabled) {
      setIsPickup(enablePickup);
    }
  }

  const { isPickup, setIsPickup } = React.useContext(OrderTypeContext);


  const disabledClass = window.tests_enabled ? '' : styles.disabled;

  const deliverySelectedClass = isPickup ? '' : styles.selectedToggle;
  const pickupSelectedClass = isPickup ? styles.selectedToggle : '';   

  const inNavClass = inNav ? styles.inNav : '';
  const inBodyClass = inBody ? styles.inBody : '';

  return (
    <div className={`${styles.orderTypeToggle} ${inNavClass} ${inBodyClass}`} style={withStyle}>
      <span className={`${styles.toggle} ${pickupSelectedClass}`} onClick={() => toggleClick(true)}>
        Pickup
      </span>
      <span className={`${styles.toggle} ${deliverySelectedClass} ${disabledClass}`} onClick={() => toggleClick(false)}>
        Delivery
      </span>
    </div> 
  )
}

OrderTypeButtons.propTypes = {
  inNav: PropTypes.bool,
  inBody: PropTypes.bool,
  withStyle: PropTypes.object,
};

export default OrderTypeButtons;