import React from 'react';
import { ThemeContext } from '../providers/ThemeProvider';
import PropTypes from 'prop-types';

import styles from './Stylesheet.module.css';

const PrimaryHeader = ({text, isCentered, withStyle={}, children}) => {
  
  const { theme } = React.useContext(ThemeContext);

  const centerStyle = isCentered ?  { textAlign: 'center'} : {};
  const headerStyle = Object.assign(centerStyle, withStyle); 

  const themeStyle = Object.assign(headerStyle, {
    fontFamily: theme.fontFamily,
    fontWeight: theme.headerWeight,
    color: theme.textColor
  });

  return (
    <div className={styles.primaryHeader} style={themeStyle}>
      {text}
      {children} 
    </div>
  );
};

PrimaryHeader.propTypes = {
  text: PropTypes.string,
  isCentered: PropTypes.bool,
  withStyle: PropTypes.object
}

export default PrimaryHeader;
