import React from 'react';

import { ProductCategoryContext } from '../providers/ProductCategoryProvider';
import { trackEvent } from '../analytics/tracking';
import SecondaryHeader from '../styleguide/SecondaryHeader';
import OptimizedImage from '../common/OptimizedImage';
import Spinner from '../common/Spinner'; 
import PropTypes from 'prop-types';

import styles from './ProductTypesGrid.module.css';

export const TypeIcon = ({itemKey, name, image, handleFilterClick}) => {
  const handleClick = () => {
    if (handleFilterClick) {
      trackEvent('shop_by_product_click', name);   
      handleFilterClick(name);
    }
  }  
 
  return ( itemKey
    ? <div key={itemKey} className={styles.productType} onClick={handleClick}>
        <div className={styles.name}>
          <div className={styles.nameSubHead}>SHOP</div>
          {name}
        </div>
        <div className={styles.icon}>
          <OptimizedImage imgAlt="" imgSrc={image} />
        </div>
      </div>
    : null
  );
}

/**
 * A product category filter based on the product types retrieved from the /categories endpoint
 * and stored in redux.
 * 
 * @param {boolean} isCondensed
 * @param {function} handleFilterClick ---provides the behavior for the individual product-type icon clicks
 */
const ProductTypes = ({isCondensed, handleFilterClick}) => {
   
  // loaded at startup
  const productCategories = React.useContext(ProductCategoryContext);
  // for unused product config page I believe
  const condensedStyle = isCondensed ? styles.condensed : '';

  return (
    <div className={`${styles.productTypesWrap} ${condensedStyle}`}>
      <SecondaryHeader text="Shop by Product" />
      { productCategories.length
        ? <div className={styles.productTypes}> 
            { productCategories.map(category => {
              return <TypeIcon itemKey={category.display_name} name={category.display_name} image={category.image_url} handleFilterClick={handleFilterClick} />   
            })}  
          </div>
        : <Spinner />
      }
    </div>
  );  
}

ProductTypes.propTypes = {
  isCondensed: PropTypes.bool, // used in tool views
  handleFilterClick: PropTypes.func.isRequired
};

export default ProductTypes;
