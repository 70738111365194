import * as React from 'react';

import { ProductCategoryContext } from '../providers/ProductCategoryProvider';
import OptimizedImage from '../common/OptimizedImage';
import PropTypes from 'prop-types';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

import styles from './ProductFilterCarouselTiles.module.css';

/**
 * Product category carousel
 * 
 * 
 * @param {*} currentCategory - initial category
 * @param {function} handleClick - handle category click 
 */
const ProductFilterCarousel = ({
  currentCategory, 
  handleClick
}) => {

  // all categories displayed
  const FULL_DISPLAY_WIDTH = 987;
  
  const { productCategories } = React.useContext(ProductCategoryContext);

  const filterRef = React.useRef();    
  const carouselRef = React.useRef();
  
  let animateTask = React.createRef();
  // A simple timeout-based animation
  const animateScroll = (scrollAmount) => {
    const carousel = carouselRef.current;
    let scrollProgress = 0;
    // We'll scroll 20px at a time    
    const roundedAmount = Math.floor(scrollAmount/20) * 20;    
    window.clearTimeout(animateTask.current);
    if (carousel) {
      const scrollMore = () => {
        const amount = scrollAmount < 0 ? -20 : 20;
        carousel.scrollBy (amount, 0);
        scrollProgress += 20;
	      if (scrollProgress <= Math.abs(roundedAmount)) {
          animateTask.current = window.setTimeout(scrollMore, 15);
        }
      }
      // initiate scroll
      scrollMore();
    }  
  } 

  // Handle arrow click/tap
  const scrollItems = (direction) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const currentLeft = carousel.scrollLeft;
      const scrollAmount = parseInt(window.innerWidth * .8, 10);
      if (direction < 0) {
        // scroll back some or to 0
        animateScroll(Math.min(scrollAmount, currentLeft) * -1);
      } else {
        // scroll foward some or to end  
        const more = currentLeft + scrollAmount;
        const max = carousel.scrollWidth - carousel.clientWidth;
        animateScroll(Math.min(more, max));
      }
    }      
  }

  // Monitor category scroll
  React.useEffect(() => {
    const wrapper = filterRef.current;
    const carousel = carouselRef.current;
    
    // Hide / show arrows 
    const toggleArrow = (show, styleClass) => {
      if (wrapper) {
        wrapper.classList.toggle(styleClass, show);
      } 
    }  
    
    // debounce
    let task;
    const handleCarouselScroll = () => { 
      window.clearTimeout(task);
      task = window.setTimeout(() => {
        if (carousel) {
          const max = carousel.scrollWidth - carousel.clientWidth;
          // show/hide right arrow
          const isNotMaxScroll = carousel.scrollLeft + 10 < max;
          toggleArrow(isNotMaxScroll, styles.withRightArrow);
          // show/hide left arrow
          const isScrolled = carousel.scrollLeft > 10;
          toggleArrow(isScrolled, styles.withLeftArrow);
        }
      }, 300);
    };
    
    // debounce
    let resizeTask;
    const handleResize = () => {
      window.clearTimeout(resizeTask);
      resizeTask = window.setTimeout(() => {
        if (wrapper && wrapper.clientWidth >= FULL_DISPLAY_WIDTH) {
          toggleArrow(false, styles.withLeftArrow);
          toggleArrow(false, styles.withRightArrow);  
        }
      }, 300);  
    }; 

    // Add scroll/resize listeners
    if (carousel) {
      carousel.addEventListener('scroll', handleCarouselScroll);
      window.addEventListener('resize', handleResize);
    }
    // Initial right arrow display
    if (wrapper && wrapper.clientWidth < FULL_DISPLAY_WIDTH) { 
      toggleArrow(true, styles.withRightArrow);
    }
        
    return () => { 
      if (carousel) {
        carousel.removeEventListener('scroll', handleCarouselScroll);
        window.removeEventListener('resize', handleResize);
      }
    }   
  }, []);   

  return (
    <div ref={filterRef} id="category_filter" className={styles.carouselOuter}>
      <div className={styles.controlsWrap}>
          <span className={`${styles.scrollArrow} ${styles.scrollLft}`} onClick={() => scrollItems(-1)}><ChevronLeftRoundedIcon style={{fontSize:30}}/></span>
          <span className={`${styles.scrollArrow} ${styles.scrollRgt}`} onClick={() => scrollItems(1)}><ChevronRightRoundedIcon style={{fontSize:30}}/></span>
      </div>

      <div className={`${styles.carouselWrap} ${currentCategory}`}>
        {/* blur the edge of first/last items */}
        <div className={`${styles.itemMask} ${styles.firstItemMask}`}></div>
        <div className={`${styles.itemMask} ${styles.lastItemMask}`}></div>

        <ul ref={carouselRef} className={styles.carousel}>
          { productCategories?.map((category) => {
              const selectedStyle = currentCategory === category.display_name ? styles.selectedCategory : '';
              return (
                <li key={`link${category.name}`} 
                    onClick={()=> {
                      /* update category */
                      handleClick(category); 
                    }} 
                    className={`${styles.carouselItem} ${selectedStyle}`} 
                    data-val={category.name}>
                  <OptimizedImage imgAlt={category.display_name} imgSrc={category.image_url} />
                  <span className={styles.label}>    
                    {category.display_name}
                  </span>
                </li>
              )
          }) }
        </ul>  
      </div>
    </div>  
  )
}

ProductFilterCarousel.propTypes = {
  currentCategory: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default ProductFilterCarousel;