import * as React from "react";

import { validateSignUpEntry } from '../registration/validation-utils';
import { errMsg } from '../registration/registration-errors';
import TextField from '@material-ui/core/TextField';
import BasicButton from '../styleguide/BasicButton';
import PropTypes from 'prop-types';

import '../registration/MaterialUI.module.css';
import styles from '../address/Address.module.css';

/**
 * Provide a form to edit the location-search selected address ( minus apartment number ) 
 */
const AddressFormInline = ({
  location,
  setEditAddress,
  onUpdate, /* onUpdate will update location, which will refresh form */
}) => {

  // Updated user entries
  const [streetAddress, setStreetAddress] = React.useState();
  const [streetAddressErr, setStreetAddressErr] = React.useState();
  
  const [city, setCity] = React.useState();
  const [cityErr, setCityErr] = React.useState();

  const [state, setState] = React.useState();
  const [stateErr, setStateErr] = React.useState();

  const [zipCode, setZipCode] = React.useState();
  const [zipCodeErr, setZipCodeErr] = React.useState();

  // Initialize address values
  React.useEffect(() => {
    const { 
      street_address: currentStreet,
      city: currentCity,
      state: currentState,
      zip_code: currentZipCode
    } = location; 
    if (!streetAddress && currentStreet) {
      setStreetAddress(currentStreet);
      setCity(currentCity);
      setState(currentState);
      setZipCode(currentZipCode);
    }
  }, [location, streetAddress]);
  
  /**
   * Validate a single form input.
   * 
   * @param string inputId - the form input id
   * @param string inputValue - the input vaue
   */
  const isValidEntry = (inputId, inputValue) => {
    const isValid = validateSignUpEntry(inputId, inputValue);
    if (inputId === 'street_address') {
      setStreetAddressErr(!isValid);
    } else if (inputId === 'city') {
      setCityErr(!isValid);
    } else if (inputId === 'state') {
      setStateErr(!isValid);
    } else if (inputId === 'zip_code') {
      setZipCodeErr(!isValid); 
    }
    return isValid;
  }; 

  /**
   * Validate all required form inputs
   */
  const validate = () => {
    const validStreetAddress = isValidEntry('street_address', streetAddress);
    const validCity = isValidEntry('city', city);
    const validState = isValidEntry('state', state);
    const validZipCode  = isValidEntry('zip_code', zipCode);
    return validStreetAddress && validCity && validState && validZipCode;
  };  

  const onChange = (event) => {
    const { id, value } = event.currentTarget;
    if (id === 'street_address') {
      setStreetAddress(value);
      setStreetAddressErr(false);
    } else if (id === 'city') {
      setCity(value);
      setCityErr(false);
    } else if (id === 'state') {
      setState(value);
      setStateErr(false);
    } else if (id === 'zip_code') {
      setZipCode(value);
      setZipCodeErr(false); 
    }
  }
 
  return (
    <div>
      {/* Street Address */}
      <TextField id="street_address" 
                 value={streetAddress} error={streetAddressErr} 
                 helperText={errMsg('street_address',streetAddressErr)} 
                 label="Street Address (for Delivery)" 
                 variant='filled' onChange={onChange} /> 
      {/* City / State / ZipCode */}
      <div className={styles.addressInputTriple}>
        <TextField id="city" 
                   value={city} error={cityErr} 
                   helperText={errMsg('city',cityErr)} 
                   label={"City"} variant='filled' onChange={onChange} />
        <TextField id="state" 
                   value={state} error={stateErr}
                   helperText={errMsg('state',stateErr)}
                   inputProps={{ maxLength:2, pattern:"[a-zA-Z]+" }}
                   label={"State Code"} variant='filled' onChange={onChange} />
    
        <TextField id="zip_code" type="number"
                   value={zipCode} error={zipCodeErr} 
                   inputProps={{ maxLength:5, pattern:"[0-9]+" }}
                   helperText={errMsg('zip_code', zipCodeErr)} 
                   label={"Zip Code"} variant='filled' onChange={onChange} />
      </div>
      <div className={styles.formButtonWrap}>
        <BasicButton text="Cancel" 
          handleClick={(event) => {
            event.preventDefault();
            setEditAddress(false);
          }
        } /> 
        
        <BasicButton text="Update" 
          handleClick={(event) => { 
            event.preventDefault();
            const isValid = validate();
            if (isValid) {
              onUpdate({
                street_address: streetAddress,
                city,
                state,
                zip_code: zipCode
              });
              setEditAddress(false);
            }
          }
        } />
      </div>
    </div>
  );
}

AddressFormInline.propTypes = {
  location: PropTypes.object.isRequired,
  setEditAddress: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default AddressFormInline;