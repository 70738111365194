import * as React from 'react';

import { ModalType } from './ModalProvider';
import DisplayModal from '../common/DisplayModal';
import SignIn from '../registration/SignIn';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

const SignInModal = ({ navigateTo, displayModal }) => {
  return (
    <DisplayModal title="Sign In" closeFn={() => { displayModal(ModalType.NONE) }}>
      <ErrorBoundary>
        <SignIn 
          withHeader={false}
          navigateTo={navigateTo}
          displayModal={displayModal} 
          closeFn={() => displayModal(ModalType.NONE)} />
      </ErrorBoundary>
    </DisplayModal>
  );
};

SignInModal.propTypes = {
  navigateTo : PropTypes.string, 
  displayModal: PropTypes.func.isRequired 
}

export default SignInModal;