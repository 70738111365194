// Time (MS) at midnight for the given day offset: 0,1,2,etc.
const getDayStartMS = (dayOffset, timeStamp) => {
  const dayOffsetMS = dayOffset * 86400 * 1000; 
  return dayOffsetMS + new Date(new Date(timeStamp).toDateString()).getTime();
};

// Time (MS) from midnight to the provided hour+minute 
const getTimeSlotMS = (hour, minute) => {
  return ((hour * 60) + minute) * 60000;
};

export const getLatestOrderTimeMS = (dayOffset, slot, timeStamp) => {
  const dayStartMS = getDayStartMS(dayOffset, timeStamp);
  const { end_hour, end_minute } = slot.hours;
  const timeSlotMS = getTimeSlotMS(end_hour, end_minute);

  return (dayStartMS + timeSlotMS) - (slot.allows_orders_up_until_minutes_before_end * 60000);                             
};

export const getEarliestOrderTimeMS = (dayOffset, slot, timeStamp) => {
  const dayStartMS = getDayStartMS(dayOffset, timeStamp);
  const { start_hour, start_minute } = slot.hours;
  const timeSlotMS = getTimeSlotMS(start_hour, start_minute);
  return (dayStartMS + timeSlotMS) - (slot.max_minutes_can_order_in_advance * 60000);  
};

/**
 * The method allows altering the timeStamp for testing purposes 
 * 
 * @param {number} timeStamp 
 */
 const getCompareDate = (timeStamp) => {
  // adjust for testing
  const timeShiftMS = 0; // 6*60*60*1000; // 6 hours
  return new Date(timeStamp + timeShiftMS);
}; 

// Display: Dec 2, Dec 3, etc.
export const getOrderDate = (offset, timeStamp) => {
  const today = getCompareDate(timeStamp);
  today.setDate(today.getDate() + offset);
  const dateInfo = today.toDateString().split(' ');
  return `${dateInfo[1]} ${parseInt(dateInfo[2])}`;  
};

// Determine how many days out to show timeslots, based on max advance order time
export const getMaxDaysOut = (dailyTimeSlots, timeStamp) => {
  const enabledDailySlots = dailyTimeSlots.map((day=[], idx) => {
    return day.filter(slot => {
      const earliestMS = getEarliestOrderTimeMS(idx, slot, timeStamp);
      return timeStamp >= earliestMS; 
    })
  });
  // Find the index of the first empty array (We won't show options)
  const [firstDisabledDay] = enabledDailySlots.reduce((array, slots, idx) => { 
    if (slots.length === 0) {
      array.push(idx);
    }
    return array;
  }, []); 
  return firstDisabledDay;
};

// Find the first available same-day pickup slot idx or -1
export const getDefaultSlotForToday = (slotsForToday=[], timeStamp) => {
  const [firstEnabledIdx] = slotsForToday.reduce((array, slot, idx) => { 
    const earliestMS = getEarliestOrderTimeMS(0, slot, timeStamp);
    const latestMS = getLatestOrderTimeMS(0, slot, timeStamp);
    if ( timeStamp > earliestMS && timeStamp < latestMS ) {
      array.push(idx);
    } 
    return array;
  }, []);
  return typeof firstEnabledIdx === "number" ? firstEnabledIdx : -1;
};

// Find the first available pickup slot for a specific day or null
// This is used when dispensary doesn't accept orders outside of biz hours  
export const getEarliestOrderTime = (slotsForDay=[], dayOffset, timeStamp) => {
  const [firstEnabledTime] = slotsForDay.reduce((array, slot) => {
    const latestMS = getLatestOrderTimeMS(dayOffset, slot, timeStamp);
    // if slot is available
    if ( timeStamp < latestMS ) {
      // console.log(`calced latest ${ new Date(latestMS).toLocaleTimeString()}`);
      array.push(latestMS);
    }
    // Sort the timestamps lowest to highest
    return array.sort((a, b) => a - b);
  }, []);
  return typeof firstEnabledTime === "number" ? firstEnabledTime : null;
};
