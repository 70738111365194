import React, { useState } from "react";

import { auth } from "../../firebase";
import { trackEvent } from '../analytics/tracking';
import BackLink from '../common/BackLink';
import TextField from '@material-ui/core/TextField';
import Button from "../styleguide/Button";
import DetourButton from "../styleguide/DetourButton";
import SendResetEmailForm from '../resetPassword/SendResetEmailForm';
import SimpleErrorMessage from "../styleguide/SimpleErrorMessage";
import PropTypes from 'prop-types';

import styles from '../checkoutUserFlow/CheckoutUserFlow.module.css';

/**
 * Inline Sign In, parent component will handle update/continue after successful Sign In 
 */
const SignInInline = ({
  onSignIn,
  onCancel,
}) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [showPasswordReset, setShowPasswordReset] = React.useState(false);
 
  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).then(function() {
      if (onSignIn) {
        onSignIn(); /* optional tracking, view updates */
      }
      trackEvent('inline_signin_success');
    }).catch(error => {
      trackEvent('inline_signin_failure');
      setError('Invalid email or password');
    });
 
  };
    
  const onChange = event => {
    const {id, value} = event.currentTarget;
    setError(false);
    if(id === 'loginEmail') {
      setEmail(value);
    } else if(id === 'loginPassword'){
      setPassword(value);
    }
  };

  return (
    <>
      {/* toggle between sign in and forgot password */}
      { showPasswordReset
        ? <>
            <div className={styles.hdrPair}>
               <div className={styles.hdrPairLft}>
                 RESET ACCOUNT PASSWORD
               </div>
             </div>  
            <div className={`${styles.inlineRegFlow} ${styles.inlineRegFlowGuest}`}>
              <div className={styles.narrowForm}>
                <SendResetEmailForm isInline handleCancel={() => setShowPasswordReset(false)} />
              </div>
            </div>
          </>
        : <>
            <div className={styles.hdrPair}>
              <div className={styles.hdrPairLft}>
                REGISTERED USER CHECKOUT
              </div>
              <div className={`${styles.hdrPairRgt} ${styles.passwordResetLink}`}>
                <span className={styles.detourPrompt}>Forgot your password?</span>
                <DetourButton isSmall text="Reset" handleClick={() => setShowPasswordReset(true)} />
              </div>
            </div>
            
            <div className={`${styles.inlineRegFlow} ${styles.inlineRegFlowGuest}`}>
              <div className={styles.narrowForm}>
                <TextField id="loginEmail" value={email} label="Email" error={false} 
                  type="email" variant="filled"  onChange={onChange} />
                <TextField id="loginPassword" value={password} label="Password" error={false}
                  type="password" variant="filled" onChange={onChange} />  
                
                <div className={styles.buttonWrap}>
                  <Button
                    isCentered
                    text="Sign In"
                    handleClick={(event) => {
                      signInWithEmailAndPasswordHandler(event, email, password);
                    }} />
                </div>
                <div className={styles.formCancelLink}>
                  <BackLink backToText="Return to Guest Checkout" onClick={onCancel} />
                </div>
              </div>  
            </div>
            { error && 
              <SimpleErrorMessage isCentered text={error} />
            }  
        </>
      }  
    </>
  );  
};

SignInInline.propTypes = {
  onSignIn: PropTypes.func,
  onCancel: PropTypes.func.isRequired
}

export default SignInInline;