/**
 * Note: Prod/Dev Authentication is configured in Firebase.js
 *
 * PROD- https://ziprun-prod.uc.r.appspot.com/api/v1
 * QA1- https://ziprun-qa1.uc.r.appspot.com/api/v1
 * QA2- https://ziprun-qa2.uk.r.appspot.com/api/v1 
 */

// staging = QA1/QA2 sites
const isStagingBuild = typeof process.env.REACT_APP_STAGING_BUILD !== 'undefined' &&
                              process.env.REACT_APP_STAGING_BUILD === 'true';

// API: QA2 is the default api domain for web dev. QA1 is generally used for back end development
const api_domain_qa1 = 'https://ziprun-qa1.uc.r.appspot.com';
const api_domain_qa2 = 'https://ziprun-qa2.uk.r.appspot.com';
// MAPS: QA2 is the default api domain for web dev. QA1 is generally used for back end development
const google_maps_key_qa1 = 'AIzaSyDOxPRDg4rKJnjJK2IuBeLW90PfJ6CKgpU';
const google_maps_key_qa2 = 'AIzaSyAZlGK5Q01ILCn4E8ypjGbgMT9yN87WaG0';
// QA Dispensary App Hosts
const dispensary_host_qa1 = 'ziprun-qa1-dispensary.web.app';
const dispensary_host_qa2 = 'ziprun-qa2-dispensary.web.app';

let api_domain_qa = api_domain_qa2;
let google_maps_key_qa = google_maps_key_qa2;
let dispensary_host = dispensary_host_qa2;

if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.indexOf('-qa1') > -1) {
  api_domain_qa = api_domain_qa1;
  google_maps_key_qa = google_maps_key_qa1;
  dispensary_host = dispensary_host_qa1;   
} 

// TODO: remove AeroPay accounts?
const development = {
  REACT_APP_API_DOMAIN: api_domain_qa,
  GOOGLE_MAPS_KEY: google_maps_key_qa,
  AEROPAY_ENV: 'staging',
  AEROPAY_ENABLED: false,
  AEROPAY_MERCHANT_ID: 1089,
  AEROPAY_LOCATION_ID: 1060,
  IS_DEV: true,
  ENABLE_ID_UPLOAD: false,
  DISPENSARY_HOST: dispensary_host,
  PAUSED_DISPENSARIES: [],  /* disable ordering for id */
  BULK_DISCOUNT_DISPENSARIES: ['47f0d3e1-ffd7-460f-aee2-d24e23c93238']  /* show bulk discount banner */
}

const production = {
  REACT_APP_API_DOMAIN: 'https://ziprun-prod.uc.r.appspot.com',
  GOOGLE_MAPS_KEY: 'AIzaSyAFzrir5dvNfpY9EOFKUBIcxXpJVly-nVI', /* no localhost support */
  AEROPAY_ENV: 'production',
  AEROPAY_ENABLED: false,
  AEROPAY_MERCHANT_ID: 1089,   //  TODO: Get prod value
  AEROPAY_LOCATION_ID: 1060,   //  TDOD: Get prod value 
  IS_DEV: false,
  ENABLE_ID_UPLOAD: false,
  DISPENSARY_HOST: 'ziprun-prod-dispensary.web.app',
  PAUSED_DISPENSARIES: [],   /* disable ordering for id */
  BULK_DISCOUNT_DISPENSARIES: ['8b700d49-f5eb-4d2f-ae04-3cfe8ff2a017']  /* show bulk discount banner */
}

const config = process.env.NODE_ENV === 'development' || isStagingBuild ? development : production;

export default config;