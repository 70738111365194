import * as React from 'react';

import useFastLinkCredentials  from './useFastLinkCredentials';
import { postFastLinkCredentials } from './payment-utils';
import { trackEvent } from '../analytics/tracking';
import FastLinkWidget from './FastLinkWidget';
import ErrorMessage from '../styleguide/ErrorMessage';
import PropTypes from 'prop-types';
// import configureAeroPayButton from '../../assets/aeropay_link_account.png';
import aeropayConnectBank from '../../assets/aeropay_connect_bank.png';

import styles from './FastLink.module.css';

/**
 * Configure and load FastLink widget (AeroPay) so users can link a bank accout at checkout
 *
 * This component also handles all widget events and  
 * success/error messages are rendered here 
 *
 * @param {func} onSuccess - Proceed with order after successful bank account linking
 */
const LinkAccountsButton = ({ onSuccess }) => {
  /**
   * For AeroPay staging/dev environments use:
   *  
   * staging bank: 'dag site'.
   * staging username: YodTest.site16441.2
   * staging user password: site16441.2 
   */
  const trackingModule = 'aeropay_checkout'; 

  const [showWidget, setShowWidget] = React.useState();

  // API fetch and response messages
  const controllerRef = React.useRef(new AbortController());

  // params required by widget
  const [fastLinkUser, token, fastLinkURL, setFastLinkCredentials] = useFastLinkCredentials(controllerRef.current);
  const [responseMsg, setResponseMsg] = React.useState();
  
  // Handle widget abort via "X" prior to bank signin 
  const handleWidgetAbort = (reason) => {
    setResponseMsg(<ErrorMessage text="Bank Account not linked. An AeroPay-linked bank account is required for delivery orders" />);
    setShowWidget(false);
    reason = reason || 'link_widget_abort'; 
    trackEvent(trackingModule, reason);
  } 

  // Handle abort/errors post widget signin" 
  const handleAccountLinkFailure = (error) => {
    setResponseMsg(<ErrorMessage text={error} />);
    setShowWidget(false);
    trackEvent(trackingModule, 'link_widget_failure');
  }

  // Respond to widget events 
  const handleWidgetEvents = (event) => {
    // Final step (here): handle ZR API reponse when posting acct info
    const linkAccountCallback = (isSuccess, responseJSON, bankAccountId) => {
      // For some reason a proxy account number (not the real ...9060 account number) is returned
      if (isSuccess) {
        onSuccess({
          message: `Bank account sucessfully linked`,
          bankAccountId
        });  
      } else {
        const responseErr = responseJSON.error ? `: ${responseJSON.error}` : '';
        handleAccountLinkFailure(`Error linking account: ${responseErr}`);
      }
    };
      
    // If sites (banks) are returned, the user has logged into their bank.
    // If they chose to exit without linking, no bank accounts will be returned in sites[]
    if ( event.sites && event.sites.length ) {
      const { accountId } = event.sites[0]; 
      // Sucessful account selection
      if (accountId) {
        // Grab the details we need 
        const accountInfo  = (({ accountId, providerAccountId }) => ({ accountId, providerAccountId }))(event.sites[0]);
        // Returned earlier via ZipRun API (useFastLinkCredentials)
        accountInfo.externalBankAccountId = fastLinkUser;
        accountInfo.token = token;
      
        postFastLinkCredentials(
          accountInfo, 
          (isSuccess, responseJSON) => { linkAccountCallback(isSuccess, responseJSON, accountInfo.accountId) }, 
          controllerRef.current);
      // Failure
      } else {  
        /**
         *  Sample response
         *  status: "ACTION_ABANDONED" 
         *  reason: "User canceled the linking process confirming account deletion."
         */
        const { reason } = event.sites[0]; // "ACTION_ABANDONED"
        handleWidgetAbort(reason);
      } 
    } else {
      handleWidgetAbort();
    }
  };
 
  // Button click to load widget
  const loadWidgetClick = () => {
    setFastLinkCredentials(controllerRef.current);
    setShowWidget(true);  
  };
  
  React.useEffect(() =>{ 
    const controller = controllerRef.current;
    return () => controller?.abort();
  }, [])

  return (
    <div>
      { showWidget && token && fastLinkURL  // set via callback
        ? <FastLinkWidget 
            accessToken={token} 
            fastLinkURL={fastLinkURL} 
            handleEvents={handleWidgetEvents} />
        : <div>
            <img className={styles.apButton} 
                 alt="Link bank accounts" 
                 onClick={loadWidgetClick}
                 src={aeropayConnectBank} />
            {/*<div className={styles.accountMessage}><sup>*</sup>Link your bank account with AeroPay to place your&nbsp;order.</div>*/} 
            { responseMsg && 
              <div className={styles.widgetMessage}>
                {responseMsg}
              </div>
            }
          </div>
      }
    </div>
  )  
}

LinkAccountsButton.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default LinkAccountsButton;
