import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { CommerceLoggingContext, SHOPPING_MILESTONES } from '../analytics/CommerceLoggingProvider';
import PropTypes from 'prop-types';

export const CartItemsContext = React.createContext();

/**
 * Cart Items Provider
 *
 * TODO: Add restore Cart Items fetch ( useSavedCartItems )
 *  
 * restoreCartCallback = (response) => {
 *   if (response.dispensary_id && response.items?.length) {
 *     const { dispensary_id, items, order_type } = response;
 *     restoreCart(dispensary_id, restoreItemDetails(items), order_type);
 *   }
 * }
 */
const CartItemsProvider = ({children}) => {
  
  // Commerce Logging
  const { logCommerceAction } = React.useContext(CommerceLoggingContext); 

  // Carted Items
  const [cartItems, setCartItems] = React.useState([]);
  const [itemTotal, setItemTotal] = React.useState(0);
  const [itemTotalDisplay, setItemTotalDisplay] = React.useState('0'); 
  
  const calcItemTotal = (items) => {
    let itemTotalCents = 0, itemTotalFormatted = '0';    
     
    itemTotalCents = items.map(item => item.display_info.cost_usa_cents * item.quantity)
                          .reduce((a, b) => a + b, 0);

    itemTotalFormatted = parseFloat(itemTotalCents/100).toFixed(2);                      

    return { itemTotalCents, itemTotalFormatted };
  }

  const updateCartDetails = (cart) => {
    const { itemTotalCents, itemTotalFormatted} = calcItemTotal(cart);
    setItemTotal(itemTotalCents);
    setItemTotalDisplay(itemTotalFormatted);
  }

  // For use after placing order
  const emptyCart = () => {
    setCartItems([]);
    updateCartDetails([]);
  }

  // Add Item or increase quantity of existing carted item
  const addItem = (id, item, quantity) => {
    const previouslyCarted = cartItems.find(item => item.id === id); 
    const newCart = [...cartItems];
    if ( previouslyCarted ) {
      previouslyCarted.quantity += quantity;
    } else {
      const itemCopy = Object.assign({}, item);
      itemCopy.quantity = quantity || 1;
      newCart.push(itemCopy); 
      setCartItems(newCart);
    }
    updateCartDetails(newCart);
    trackEvent('cart_addItem', id);
    logCommerceAction(SHOPPING_MILESTONES.ITEM_CARTED);
  };

  // Add Item or increase quantity of existing carted item
  const removeItem = (id) => {
    const previouslyCarted = cartItems.find(item => item.id === id); 
    if ( previouslyCarted ) {
       const newCart = cartItems.filter(item => item.id !== id);
       setCartItems(newCart);
       updateCartDetails(newCart);
    }
    trackEvent('cart_removeItem', id);
  }; 

  // Increase quantity of an existing carted item
  const increaseQuantity = (id, quantity) => {
    const previouslyCarted = cartItems.find(item => item.id === id); 
    if ( previouslyCarted ) {
      previouslyCarted.quantity += quantity;
    }
    updateCartDetails(cartItems);
    trackEvent('cart_increaseQuantity', id); 
  };

  // Increase quantity of an existing carted item
  const decreaseQuantity = (id, quantity) => {
    const previouslyCarted = cartItems.find(item => item.id === id); 
    if ( previouslyCarted ) {
      if ( previouslyCarted.quantity <= quantity) {
        removeItem(id);
      } else {
        previouslyCarted.quantity -= quantity; 
        updateCartDetails(cartItems);
      }
    }
    trackEvent('cart_decreaseQuantity', id); 
  };

  return <CartItemsContext.Provider value={{
      cartItems,
      itemTotal,
      itemTotalDisplay,
      addItem,
      removeItem,
      increaseQuantity,
      decreaseQuantity,
      emptyCart 
    }}>
    {children}
  </CartItemsContext.Provider>
}

CartItemsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default CartItemsProvider;