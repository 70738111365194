import * as React from 'react';

import useHostDomain from '../messaging/useHostDomain';
import { navigateTop } from '../routing/router-utils';
import { sendMessage, navigationMessageHandler } from './embed-utils';
import { FilteredProductsContext } from '../products/FilteredProductsProvider';
import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import Spinner from '../common/Spinner';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import BulkDiscountMsg from '../dispensary/BulkDiscountMsg';
import ProductFilterCarousel from '../dispensary/ProductFilterCarousel';
import FilterWithinCategory from '../productFilters/FilterWithinCategory';
import WeightFilter from '../productFilters/WeightFilter';
import BrandFilter from '../productFilters/BrandFilter';
import TypeFilter from '../productFilters/TypeFilter';
import SubCategoryFilterModal from '../productFilters/SubCategoryFilterModal';
import DisplayModal from '../common/DisplayModal';
import AddedToCartCheck from '../products/AddedToCartCheck';
import ProductListing from '../dispensary/ProductListing';
import FilterIcon from '../../assets/filterIcon2.png';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';

import styles from '../dispensary/Dispensary.module.css';

const EmbedMenu = () => {
  
  const { filteredProducts, categoryName, setCategory } = React.useContext(FilteredProductsContext);
  const hostDomain = useHostDomain();

  // checkmark on add-to-cart
  const [showAddedCheck, setShowAddedCheck] = React.useState();
  const [showFilterModal, setShowFilterModal] = React.useState();

  // Dispensary info from Providers
  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { addItem } = React.useContext(CartItemsContext);

  // Listen to category from host page url
  React.useEffect(() => {
    const handleHostMessages = navigationMessageHandler('zrCategory', (data) => {
      const { zrCategory, zrProductId } = data;
      if (zrCategory) {
        setCategory(zrCategory);
        if (zrProductId) {
          navigateTop(`/menu/${dispensaryId}/product/${zrProductId}`);
        } else {
          navigateTop(`/menu/${dispensaryId}/products`);
        }
      }
    });
    window.addEventListener("message", handleHostMessages, false);
    return () =>  window.removeEventListener("message", handleHostMessages);
  }, [dispensaryId, setCategory, categoryName]);

  // Update category 
  const updateProductCategory = React.useCallback((category) => {
    setCategory(category.display_name);
    sendMessage({ productCategory:category.display_name}, hostDomain);
  }, [setCategory, hostDomain]);

  const handleAddToCart = (id, item, quantity) => {
    setShowAddedCheck(true);
    addItem(id, item, quantity);
  }

  return ( 
    <BodyWrapper LayoutType={LayoutTypes.FullBleed}>
      { dispensaryId && categoryName 
        ? <>
            <ProductFilterCarousel
              currentCategory={categoryName}
              handleClick={updateProductCategory} />
            
            <div className={styles.productGridWithFilters}>
            { categoryName === 'Flower' &&
              <BulkDiscountMsg dispensaryId={dispensaryId} />
            }  
            <div className={styles.headerWithFilters}>
              <PrimaryHeader text={categoryName} />
              <div className={styles.hdrFilterWrap} onClick={() => setShowFilterModal(true)}>
                <img className={styles.filterIcon} alt="filter" src={FilterIcon} />
                Filter
              </div>
            </div>
              <div className={styles.filterCol}>
                {/* will collapse if emtpy */}
                <FilterWithinCategory />
                <WeightFilter />
                <BrandFilter />
                <TypeFilter />
              </div>
              <div className={styles.productGrid}>
              { filteredProducts?.length > 0
                ? <ProductListing
                    dispensaryId={dispensaryId}
                    filteredProducts={filteredProducts}
                    handleClick={handleAddToCart} />
                : <Spinner />
              }
              </div>
            </div>
            { showFilterModal &&
              <DisplayModal title={`Filter: ${categoryName}`} closeFn={() => setShowFilterModal(false)}>
                <SubCategoryFilterModal handleClose={() => setShowFilterModal(false)} />
              </DisplayModal>
            }
          </>
         : <Spinner />
      }
      
      { showAddedCheck && 
        <AddedToCartCheck closeFn={() => { setShowAddedCheck(false) }} />
      }
    </BodyWrapper>
  )
}

export default EmbedMenu;