import * as React from 'react';

/**
 * Any user sharing the same phone number will share AeroPay bank account links
 * automatically via their phone number.
 * 
 * To force the link-at-checkout flow, add "unlinked" to the user name: "Joe Smith unlinked" 
 * 
 * @param {string*} displayName - auth.currentUser.displayName 
 */
const useForceAccountLinking = (displayName) => {
  const isTestUser = displayName && displayName.toLowerCase().indexOf('unlinked') !== -1;
  const [forceLinking, setForceLinking] = React.useState(isTestUser);
  return [ forceLinking, setForceLinking ]; 
}

export default useForceAccountLinking;