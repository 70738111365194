import React from 'react';

import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

/* Not enough CSS to warrant a module */
const useStyles = makeStyles({
  button: {
    cursor: 'pointer',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#333',
    width: 'auto',
    marginTop: 0,
    minWidth: 'auto',
    padding: '0.5rem 1rem',
    backgroundColor: '#ccc',
    border: '1px solid #999',
    borderRadius: '4px',
    boxShadow: '0px 0px 10px #fff',
    font: 'var(--zrHeaderFont)',
    fontWeight: 800,
    fontSize: '.875rem',
    whiteSpace: 'nowrap'
  }
});

/** 
 * ID/License upload button
 **/
const UploadButton = ({text, withStyle={}, handleClick}) => {
  
  const classes= useStyles();
  
  return (
    <button 
      className={classes.button}
      style={withStyle}
      onClick={handleClick}
    >
      {text} 
    </button>
  );
};

UploadButton.propTypes = {
  text: PropTypes.string.isRequired,
  withStyle: PropTypes.object,
  handleClick: PropTypes.func
}

export default UploadButton;