import * as React from 'react';

import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import ProductSection from './ProductSection';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

const PreviewProducts = ({dispensaryId}) => {
  
  const { productsByCategory } = React.useContext(ProductPreviewContext);
  const [ productsLoaded, setProductsLoaded] = React.useState();

  React.useEffect(() => {
   if (productsByCategory && Object.keys(productsByCategory).length > 0) {
     setProductsLoaded(true);
   }
  }, [productsByCategory, setProductsLoaded]);
  
  return ( productsLoaded
    ? <>
      { Object.keys(productsByCategory).map(categoryName => {
          const catProducts = productsByCategory[categoryName];    
          const max = Math.min(catProducts.length, 8);
          const previewProducts = catProducts.slice(0,max);
          
          return <ProductSection
              key={categoryName}
              dispensaryId={dispensaryId}
              productCategoryName={categoryName}
              handleClick={() => {}} /* no navigatioon after carting */
              categoryCount={catProducts.length}
              searchResultProducts={previewProducts}
            />
        })}    
     </>
    : <Spinner />  
  )
}

PreviewProducts.propTypes = {
  dispensaryId: PropTypes.string.isRequired
};

export default PreviewProducts;