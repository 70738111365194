import React from 'react';

import { CartItemsContext } from './CartItemsProvider';
import { UserContext } from '../providers/UserProvider';
import { CommerceLoggingContext, SHOPPING_MILESTONES } from '../analytics/CommerceLoggingProvider';
import { formatPhoneNumber } from 'react-phone-number-input'
import useIntercomLogging from '../intercom/useIntercomLogging';
import CurrentTimeProvider from '../timeSlots/CurrentTimeProvider';
import DeliverySlotsProvider from '../timeSlots/DeliverySlotsProvider';
import DisplayDeliverySlots from '../timeSlots/DisplayDeliverySlots';
import PlaceOrderItems from './PlaceOrderItems';
import DisplayBulkDiscount from './DisplayBulkDiscount';
import Header, { HeaderSize } from '../styleguide/Header';
import OrderDeliveryButton from './OrderDeliveryButton';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

import styles from './PlaceOrder.module.css';

/**
 * Place Order - Delivery
 * TODO: Need to add the delivery zip code check
 * TODO: Disable Aeropay 
 */
const PlaceOrder = ({
  dispensaryId /* from controller via url */, 
  dispensaryName,
  minDeliveryOrder,
  tel_number='+15555555555', /* TODO! */
}) => {

  const { itemTotal } = React.useContext(CartItemsContext);
  const { user, location:delivery_address } = React.useContext(UserContext);

  // Commerce Logging
  const { logCommerceAction } = React.useContext(CommerceLoggingContext);

  const [noDiscountFound, setNoDiscountFound] = React.useState();

  const OrderSection = ({title, children}) => {
    return (
      <div className={styles.section}>
        { title && (
          <div className={styles.sectionTitle}>{title}</div>
        )}
        <div className={styles.sectionInner}>
          {children}
        </div>
      </div>
    );
  }
  
  const { street_address, apartment_number, city, state, zip_code } = delivery_address || {};
  const displayApartmentNumber = apartment_number ? `${' '}${apartment_number}`: '';

  // Ensure bulk discount recalc
  React.useEffect(() => {
    setNoDiscountFound(false);
  }, [itemTotal]);

  // Track user starting checkout
  useIntercomLogging(user, 'Checkout_Delivery', true);

  React.useEffect(() => {
    // User has attempted to view checkout
    logCommerceAction(SHOPPING_MILESTONES.CHECKOUT_VIEWED); 
  }, [logCommerceAction]);

  return (
    <CurrentTimeProvider>
      <DeliverySlotsProvider dispensaryId={dispensaryId}>
        <OrderSection>
          <Header size={HeaderSize.Large} isCentered text='Checkout' />
        </OrderSection>
    
        <OrderSection title="ORDER TYPE">
          Delivery
        </OrderSection>
        
        <OrderSection title="DELIVERY TIME">
          <ErrorBoundary>
            <DisplayDeliverySlots dispensaryId={dispensaryId} dispensaryName={dispensaryName} />
          </ErrorBoundary>
        </OrderSection>
        
        <OrderSection title="ADDRESS">
          <span>
            { street_address &&
              `${street_address}${displayApartmentNumber}, ${city}, ${state}, ${zip_code}`
            }
          </span>
        </OrderSection>
        
        <OrderSection title="PHONE NUMBER">
           <span>{formatPhoneNumber(tel_number)}</span>
        </OrderSection>
        
        <OrderSection title="SUMMARY">
          <PlaceOrderItems />
        </OrderSection>
        
        { !noDiscountFound &&
          <OrderSection title="BULK DISCOUNTS">
            <DisplayBulkDiscount handleNoDiscount={setNoDiscountFound} />
          </OrderSection>
        }
        
        <OrderSection>
          {/* TODO - do another zip code check here */}
          <OrderDeliveryButton
            dispensaryId={dispensaryId}
            dispensaryName={dispensaryName}
            minDeliveryOrder={minDeliveryOrder} />
        </OrderSection>
      </DeliverySlotsProvider>
    </CurrentTimeProvider>
  )
}

PlaceOrder.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  minDeliveryOrder: PropTypes.number.isRequired
};

export default PlaceOrder;