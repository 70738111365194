import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  buttonText: {
    margin:'1px 0 0 0.25rem',
    fontSize:'.675rem',
    fontWeight:700,
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  wrapper: {
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #ccc',
    fontSize: '0.8125rem',
    borderRadius: '.375rem',
    backgroundColor: '#f7f7f7',
    maxWidth: 308,
    boxShadow: '0 0 4px #000c',
    padding: '.25rem .75rem',
    boxSizing:  'border-box' 
  },
  titleIcon: {
    verticalAlign: '-4px',
    marginRight: '.25rem'
  },
  title: {
    fontWeight: '700',
    fontSize: '1rem',
    margin: '.5rem 0 .75rem'
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: '.5rem',
    right: '.5rem',
    cursor:'pointer'
  },  
  details: {
    margin: '.5rem 0'
  },
  footerText: {
    fontSize:'.75rem', 
    lineHeight:'120%',
    paddingTop: '.75rem',
    borderTop: '1px solid #ccc', 
    margin:'.5rem 0'
  }
});

const ToolTip = ({
  buttonIcon,
  buttonText,
  buttonStyle={},
  tipTitleIcon,
  tipTitle,
  footerText,
  children
}) => {
  
  const classes= useStyles();
  const [open, setOpen] = React.useState(false);
  
  const handleOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(true);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };
  
  const muiButtonIconStyle = {cursor:'pointer', fontSize: 20, color:'rgba(0, 0, 0, 0.87)'};
  const anchorRef = React.useRef();
  
  return (
    <span>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          arrow: {
            enabled: false,
            element: anchorRef.current,
          },
        }}>
        <div className={classes.wrapper}>
          <div className={classes.title}>
            { tipTitleIcon &&
              <img className={classes.titleIcon} alt="" src={tipTitleIcon} height="20" />
            }
            {tipTitle}
          </div>
          <div className={classes.closeIcon} onClick={handleClose}>
            <CloseRoundedIcon style={{fontSize:24}} />
          </div>
          <div className={classes.details}>
            {children}
          </div> 
          { footerText &&
            <div className={classes.footerText}>
              {footerText} 
            </div>
          }
        </div>
      </Popper>

      {/* This is the icon/button that triggers the tool tip */}
      <Button ref={anchorRef} style={buttonStyle} onClick={(event) => handleOpen(event)}>
        { buttonIcon
          ? <img alt="" src={buttonIcon} height="18" />
          : <InfoIcon style={muiButtonIconStyle} />
        }
        { buttonText &&
          <span className={classes.buttonText}>{buttonText}</span>
        }
      </Button>
    </span>
  );
}

ToolTip.propTypes = {
  buttonIcon: PropTypes.string, /* an image src */
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.object, /* for the button text */
  tipTitleIcon: PropTypes.string,
  tipTitle: PropTypes.string.isRequired,
  footerText: PropTypes.string,
  childen: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
};

export default ToolTip
