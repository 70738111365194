import React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

const Button = ({text, disabled, isCentered, withStyle={}, handleClick}) => {

  const { theme } = React.useContext(ThemeContext);

  const disabledClass = disabled ? styles.disabledButton : '';
  const buttonClasses = `${styles.button} ${disabledClass}`;

  const centerStyle = isCentered ? { display:'block', marginLeft:'auto', marginRight: 'auto' } : {};
  const propsStyle = Object.assign(centerStyle, withStyle);

  const themeStyle = Object.assign(withStyle, {
    fontFamily: theme.fontFamily,
    color: theme.buttonTextColor,
    backgroundColor: theme.buttonColor
  });

  const buttonStyle = Object.assign(propsStyle, themeStyle);

  /* ignore click function if disabled */
  const buttonClick = disabled ? event => event.preventDefault() : handleClick;

  return (
    <button
      className={buttonClasses}
      style={buttonStyle}
      onClick={buttonClick}
    >
      {text} 
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isCentered: PropTypes.bool,
  withStyle: PropTypes.object, 
  handleClick: PropTypes.func
}

export default Button;
