import * as React from 'react';

import PropTypes from 'prop-types';

export const OrderTypeContext = React.createContext();

const OrderTypeProvider = ({children}) => {
  
  const [isPickup, setIsPickup] = React.useState(true);
  
  return <OrderTypeContext.Provider value={{
      isPickup,
      setIsPickup,
      currentService: isPickup ? 'Pickup' : 'Delivery',
      trackingOrderType: isPickup ? 'pickup' : 'delivery'   /* session_analytics endpoint */ 
    }}>
    {children}
  </OrderTypeContext.Provider>
}

OrderTypeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default OrderTypeProvider;