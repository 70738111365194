import React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

const ButtonWithPrice = ({
  text, 
  price,
  disabled,
  isFullWidth,
  handleClick}) => {

  const buttonClasses = disabled ? `${styles.button} ${styles.disabledButton}` : styles.button;
  /* ignore click function if disabled */
  const buttonClick = disabled ? event => event.preventDefault() : handleClick;
  const { theme } = React.useContext(ThemeContext); 

  const widthStyle = isFullWidth ? { width:'100%', padding:'.75rem 1rem'} : {};

  const themeStyle = {
    color: theme.buttonTextColor,
    backgroundColor: theme.buttonColor
  };

  const buttonStyle = Object.assign(widthStyle, themeStyle);

  return (
    <button 
      className={`${buttonClasses} ${styles.priceButton}`}
      style={buttonStyle}
      onClick={buttonClick}
    >
      <span className={styles.buttonText}>{text}</span>
      <span className={styles.buttonPrice}>${price}</span>
    </button>
  );
};

ButtonWithPrice.propTypes = {
  text: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,  
  handleClick: PropTypes.func
}

export default ButtonWithPrice;