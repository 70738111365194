import React from 'react';

import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { UserContext } from '../providers/UserProvider';
import { ThemeContext } from '../providers/ThemeProvider';
import { promoTextEndpoint } from '../../constants/api-endpoints';
import { dispensaryEndpointRequest } from './dispensary-utils';
import PromoTextRenderer from './PromoTextRenderer';
import ErrorBoundary from '../common/ErrorBoundary';

import styles from './PromoText.module.css';

/**
 * Render promo text as provided by the Dispensary App (settings)
 */
const PromoText = () => {

  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { user } = React.useContext(UserContext); 
  const [promoTextLft, setPromoTextLft] = React.useState();
  const [promoTextRgt, setPromoTextRgt] = React.useState();

  const { theme } = React.useContext(ThemeContext);

  React.useEffect(() => {
    const callback = (data) => {
      if (data.text) {
        const textNodes = JSON.parse(data.text);
        setPromoTextLft(textNodes.left);
        setPromoTextRgt(textNodes.right);
      }
    }

    if (dispensaryId && user && !promoTextLft) {
      dispensaryEndpointRequest(promoTextEndpoint, dispensaryId, callback, new AbortController()); 
    } 
  }, [dispensaryId, user, promoTextLft, theme]); 

  const themeStyle = {
    borderColor: theme.promoBorderColor,
    backgroundColor: theme.promoBackgroundColor,
    borderRadius: theme.promoBorderRadius 
  };

  return (
    <ErrorBoundary>
      { promoTextLft 
        ? <div className={styles.promo} style={themeStyle}>
            <div className={styles.innerGrid}>
              <div className={styles.gridCol}>  
                <PromoTextRenderer contentArray={promoTextLft} column="left" theme={theme} />
              </div>
              <div className={styles.gridCol}> 
                <PromoTextRenderer contentArray={promoTextRgt} column="right" theme={theme} />
              </div>
            </div>
          </div>
        : <></>
      }
    </ErrorBoundary>
  );
} 

export default PromoText;