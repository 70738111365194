import * as React from 'react';

import styles from './Legal.module.css';

/**
 * Zip Run Privacy Policy as of 4/7/21
 */
const ZipRunPrivacyPolicy = () => {

  return (
    <div className={styles.legalWrap}>  
      <h1 className={styles.legalHdr}>Zip Run Privacy Policy</h1>
      <div className={styles.subHead}>Effective Date: April 7, 2021</div>
      <p>
        Thank you for using Zip Run, the leading-edge mobile e-commerce platform and an on-demand delivery service for cannabis products. This Privacy Policy applies to your use of Zip Run, and any websites, mobile applications, services, programs, or features (the “Zip Run Platform”) owned or operated by Zip Run, Inc. (collectively, “Zip Run,” “we,” “our,” or “us”). Zip Run values your privacy, and taking proper care of your information is a top priority for us. Our goal is to help you understand what information we collect about you (also known as “User Data”) and describe how we use that information to provide you and all of our users with safe and secure access to legal cannabis, industrial hemp-derived CBD, and other products and accessories.
      </p>
      <p>
        This Privacy Policy is part of, and is governed by, the terms and conditions set forth in our Terms of Service including any agreements incorporated by reference within the Terms of Service (the “Zip Run Terms”). Any terms not defined in this Privacy Policy will have the meaning given to them in our Terms of Service. If you do not feel comfortable with any part of this Privacy Policy or our Terms of Service, you should stop using or accessing the Zip Run Platform and terminate your Account.
      </p>
      <h3 className={styles.sectionHdr}>INFORMATION WE COLLECT</h3>
      <p>
        Please note, not all accounts or features are available in all locations, so some of the examples below may not apply to you.
      </p>
      <div className={styles.subHead}>Information You Provide To Us</div>
      <p>
        We collect information about you when you provide it to us directly, when we request it from you, or when you give us permission to get it from a third party source. Below are some examples of the types of information we collect.
      </p>
      <div className={styles.subHead}>Registration and Identity Verification</div>
      <p>
        When you sign up for any Zip Run account, you give us your name, email address, phone number, date of birth, and, if necessary, a copy of your government-issued ID (i.e. your driver’s license, state ID card, or passport). In order to receive a delivery or shipment, you must provide a valid delivery address — (i.e. your home address). 
      </p>
      <div className={styles.subHead}>Demographic Information</div>
      <p>
        We also collect demographic information from you when you register, when you take our surveys, and throughout your use of the Zip Run Platform. Demographic information may include your age, gender, location, how you heard about Zip Run, spending habits, consumption preferences, and other preferences or habits.
      </p>
      <div className={styles.subHead}>Communications</div>
      <p>
        If you contact us directly, we may collect information from you that helps us provide a satisfying experience and answers any questions you have while using Zip Run. For example, when you communicate with a member of our customer service team, we may ask you for your name, email address, delivery address, phone number, or proof of charge in order to identify your account, confirm your identity, or assist with your issue. If you send us an email, we will collect the contents of that email and any other information you choose to provide with your email. If you communicate with us via live chat, that correspondence will be archived. If you communicate with us via social media, we collect that communication and associate that information with your user account to the extent the information can be linked. Customer service communications over the phone may be recorded for quality control purposes and may be summarized within our customer support systems.
      </p>
      <div className={styles.subHead}>Payment Information</div>
      <p>
        Payment information may be collected in connection with a transaction facilitated by Zip Run. Payment information flows through AeroPay (https://www.aeropay.com/privacy) who processes the payments for Zip Run and the Retailers. The collection of your personal financial information by AeroPay shall be subject to AeroPay’s relevant terms of service and privacy policy. In addition, we may retain a limited amount of information about your transaction for identification and convenience. 
      </p>
      <div className={styles.subHead}>Voluntary User Surveys</div>
      <p>
        From time to time, we may invite you to fill out voluntary user surveys or to participate in focus groups to provide feedback on various topics, which may include, without limitation: your experiences with Zip Run and/or our partners, your experience with the Zip Run Platform, available products, new services, your preferences related to cannabis, and your sentiments on public policy or other cannabis industry topics. These surveys are often conducted by Zip Run or third-party market research firms, who collect such data on our behalf, to analyze industry preferences and trends. Zip Run does not share your survey information on an individualized basis, and does not share this data for direct marketing to its users.
      </p>
      <div className={styles.subHead}>Information We Collect When You Use Zip Run</div>
      <p>
        Like most online services, Zip Run automatically collects technical information about you or your device used to access the Zip Run Platform. We collect information from your browser, computer, mobile device or other device used to access the Zip Run Platform as necessary to administer and operate the Zip Run Platform and use data analytics to improve the Zip Run Platform and the User experience. Below we provide examples of the types of data we may collect about you.
      </p>
      <div className={styles.subHead}>Location Information</div>
      <p>
        We collect your location information when you provide us with a delivery address or we may use geolocation-based services in order to verify your location. For instance, knowing your location may be necessary to allow you full access to our mobile applications or certain aspects of the Zip Run Platform. Furthermore, for compliance reasons, some of the Zip Run Platform may be restricted depending on where you are located. Collecting your location information is also necessary to connect you to a Retailer that services your delivery area, to send you relevant content based on your location, and to optimize or improve our products and services.
      </p>
      <div className={styles.subHead}>Usage Information</div>
      <p>
        We log how you interact with the Zip Run Platform by automatically recording data sent by your application or browser. This includes, but is not limited to, the products and content you view, your actions on the Zip Run Platform, how you access our Zip Run Platform, and the details of any orders you place.
      </p>
      <div className={styles.subHead}>Device Information</div>
      <p>
        We collect information about the device you are using, such as the device’s IP address, web browser, operating system, phone carrier, manufacturer, unique identifiers (such as advertising ID, VIN, MAC address, and IDFA), and other related software or hardware information related to your device.
      </p>
      <div className={styles.subHead}>Cookies and Technical Information</div>
      <p>
        We also collect information through the use of “cookies,” tracking pixels, and similar technologies. Cookies are small text files created by websites and stored on your device. They provide a way for the Zip Run Platform to recognize you and keep track of your settings and preferences. We also use cookies and similar technologies to provide you with a secure experience and to understand, among other things, how you navigate the Zip Run Platform, learn what content is popular, recognize repeat users, and save your account information. The Zip Run Platform is not configured to read or respond to “do not track” settings or signals in your browser settings.
      </p>
      <p>
        There are also cookies or other tracking technologies set by third party services which we have engaged. We use Google Analytics for Firebase, which employs cookies that are stored to your computer in order to facilitate an analysis of your use of the Zip Run Platform. Google’s use of cookies and other tracking technologies are described further in its privacy policy (https://policies.google.com/technologies/partner-sites). We also use Yoast SEO (https://yoast.com/privacy-policy/), a plugin which helps us get more visitors from search engines and social media, and the Zip Run Platform may include other third-party social media features. Each of Yoast and third-party social media providers may set cookies to enable them to function properly. You should review the privacy policies of Yoast and such third-party social media providers to determine how they handle the information they collect from you.
      </p>
      <div className={styles.subHead}>Call and Text Information</div>
      <p>
        We work with third-party partners to facilitate phone calls and text messages, including communications between you and your driver. We collect information about such communications, including the date and time of the call or SMS message, the parties’ phone numbers, and the content of any SMS messages. This helps us provide and optimize our services and the Zip Run Platform.
      </p>
      <div className={styles.subHead}>User Feedback</div>
      <p>
        We want to make sure you have a consistently positive experience with the Zip Run Platform, and we are always looking to improve our products and services. Accordingly, we collect and retain information about any ratings, reviews, and feedback you provide about our service, the Zip Run Platform, the products on our site, Retailers, and Delivery Service Providers.
      </p>
      <h3 className={styles.sectionHdr}>Information Collected from Third Parties</h3>
      <div className={styles.subHead}>Retailers and Delivery Service Providers</div>
      <p>
        When you place an order through the Zip Run Platform, we may collect information about you from the Retailers or Delivery Service Providers based on your interactions with them. For instance, when your delivery driver reaches the delivery address you provided, they will check your government-issued ID and ask for your signature before completing the transaction. In certain jurisdictions, when required by local law or regulation, your delivery driver will also scan the barcode on the back of your government-issued ID to confirm that the information displayed on the ID is accurate. You may also communicate with your driver via text or phone call in order to complete the delivery. You should review the privacy policies of the Retailer or Delivery Service Provider to determine how they handle the information they collect from you.
      </p>
      <div className={styles.subHead}>Payment Processor</div>
      <p>
        When you place an order through the Zip Run Platform, we may collect information about you from AeroPay. You should review AeroPay privacy policy to determine how it handles the information it collects from you.
      </p>
      <div className={styles.subHead}>Advertisers</div>
      <p>
        We may receive information about you from our advertisers, such as your interests and preferences to help us provide you with tailored advertisements.
      </p>
      <div className={styles.subHead}>Vendors</div>
      <p>
        We may collect information about you directly from our vendor services or tools if your use of their services led to or related to your use of Zip Run. We may also collect information from you by using such vendor services or tools as necessary to comply with the law, process payment, and other legitimate business purposes. The information we collect from vendors will be treated in the same manner as the information we collect from you directly.
      </p>
      <h3 className={styles.sectionHdr}>HOW WE USE THE INFORMATION WE COLLECT</h3>
      <p>
        In addition to the uses described above, we also use the information we collect about you to:
      </p>
      <ul>
        <li>Provide the Zip Run Platform to you, including, but not limited to, connecting you with a Retailer and a Delivery Service Provider (or our drivers) to facilitate your legal access to cannabis and cannabis-related products, and facilitation of your transactions and payments</li>
        <li>Support our Retailers with information necessary for them to maintain their records in accordance with their normal business practices and to comply with applicable law</li>
        <li>Identify you as a User in our system</li>
        <li>Comply with applicable local, state and federal laws and regulations</li>
        <li>Analyze and assess current and potential user markets</li>
        <li>Improve, optimize and create new products and services</li>
        <li>Evaluate retailer, brand, and other third-party partnerships</li>
        <li>Personalize your experience with Zip Run</li>
        <li>Provide customer support and assist with your access or use of the Zip Run Platform</li>
        <li>Communicate with you, either directly or through one of our partners, including for marketing and promotional purposes</li>
        <li>Facilitate your communication with the Delivery Service Provider (or our driver) fulfilling your order</li>
        <li>Engage in marketing activities with you and assess marketing and promotional initiatives</li>
        <li>Detect, prevent, or mitigate fraud or any other suspicious activities</li>
        <li>Conduct research and development, such as by conducting voluntary user surveys or obtaining other feedback from you</li>
        <li>Create aggregated, de-identified and/or anonymized data</li>
        <li>Support public health and safety efforts</li>
        <li>Support ongoing efforts in cannabis legalization and social equity and justice</li>
        <li>Conduct such other activities as otherwise described in this Privacy Policy, the Zip Run Terms or in any other notices we have or will provide to you</li>
      </ul>
      
      <h3 className={styles.sectionHdr}>HOW WE SHARE INFORMATION</h3>
      <p>
        We may share your information as described below:
      </p>
      <div className={styles.subHead}>To Facilitate Delivery</div>
      <p>
        In order to facilitate deliveries, we share information with our business partners, including Retailers and Delivery Service Providers. When you send an order to the Retailer through Zip Run, the Retailer receives information required to complete your order, such as your first name, delivery address, order details, birthdate, and a picture of your face taken from your government-issued ID. Zip Run connects you with a Delivery Service Provider (or our driver) who fulfills your order, and the driver receives this information to complete the delivery. When the driver arrives with your order, they will check your ID in person to verify your identity and to authorize the transaction in accordance with applicable law.
      </p>
      <div className={styles.subHead}>Anonymized, De-Identified and/or Aggregated Data</div>
      <p>
        We share aggregated, de-identified and/or anonymized information with third parties. Aggregated and anonymized means that user information is combined together with all personally identifiable information removed so that any individual user cannot be identified. De-identified information means we have removed any identifiers that make a user identifiable, such as their name or address. We may also share anonymized, aggregated and/or de-identified data with third parties for educational, marketing, consulting or independent research purposes. This data provides valuable information including how users interact with Zip Run, our partners, available products, and also helps us drive conversation on consumer habits, preferences, and behavior within the cannabis industry. When we share anonymized, de-identified and/or aggregated data, we require the third party not to re-identify individuals and to have technical and business safeguards that prevent re-identification and inadvertent release of such information.
      </p>
      <div className={styles.subHead}>Vendor Tools and Services</div>
      <p>
        We may share your information with service providers, business partners, consultants, tools, and services to facilitate Zip Run operations such as verification, compliance, marketing, communications, statistical analysis, hosting, database management, and a number of other functions. We share your information with these parties to perform their respective specific tasks on our behalf.
      </p>
      <div className={styles.subHead}>Government Authority, Law Enforcement, &amp; Safety</div>
      <p>
        We only disclose information about you to a government authority, law enforcement official, public health or safety official, or one of our partners if we believe we are required to by law, or in order to respond to or comply with regulations or legal processes, including, but not limited to, responding to subpoenas or warrants. We may also share your information if we think doing so is necessary to protect the rights, property, or safety of Zip Run, our partners and their employees, our users, the public, or others.
      </p>
      <div className={styles.subHead}>At Your Direction</div>
      <p>
        There may be instances where you direct us to share personal information on your behalf, such as for medical records, for legal or administrative proceedings, or otherwise.
      </p>
      <div className={styles.subHead}>Acquisition or Merger of Zip Run</div> 
      <p>
        In the event that all or a portion of the assets, business, or stock of Zip Run are acquired by or merged with another party, your information may be among those assets transferred. An acquirer of Zip Run or its assets may continue to use your information as described in this Privacy Policy. We will notify you with any choices you may have regarding your information as a result of such acquisition or merger.
      </p>
      <div className={styles.subHead}>Onward Transfer</div>
      <p>
        If we share your information as described in this Privacy Policy, we will seek assurances from the recipients of your information that they will safeguard your information in a manner consistent with this policy. Such assurances, where appropriate, may include contractual relationships with confidentiality clauses and non-disclosure agreements that provide the same level of commitment to safeguarding your information as provided in this Privacy Policy.
      </p>
      <div className={styles.subHead}>YOUR CHOICES</div>
      <p>
        We provide you with the choices below regarding your personal information:
      </p>
      <div className={styles.subHead}>Email Subscriptions</div>
      <p>
        You can always unsubscribe from Zip Run promotional emails by clicking the link provided at the bottom of the email. We will still send you transactional emails and receipts generated from your use of Zip Run as these are not promotional communications.
      </p>
      <div className={styles.subHead}>Text Messages</div>
      <p>
        You can opt out of receiving promotional text messages by texting the word STOP from the mobile device receiving the messages to the phone number you received the message from. Promotional texts are SMS messages sent to you containing offers, promo codes, or deal reminders. Standard messaging rates from your cell phone carrier will apply.
      </p>
      <div className={styles.subHead}>Push Notifications</div>
      <p>
        If you are using the Zip Run mobile application(s), you can opt out of receiving promotional push notifications through your device settings. Please note that opting out of receiving transactional push notifications, such as receiving a notification that your order has arrived, may impact your use of Zip Run.
      </p>
      <div className={styles.subHead}>Location Information</div>
      <p>
        You can prevent your device from sharing geolocation information at any time through your device settings. Please note that the use of geolocation information may be necessary to access some of our services.
      </p>
      <div className={styles.subHead}>Editing Account Information</div>
      <p>
        You can review and edit certain account information at any time by logging into your account and navigating to your “Account Settings.”
      </p>
      <div className={styles.subHead}>Deleting Your Account</div>
      <p>
        We will store your information as long as your account is open. If you wish to terminate, delete, suspend, or otherwise disable your Account or use of the Zip Run Platform, please go to your Account Settings and click on “Delete My Account.” When we delete user accounts, we must maintain certain information as long as necessary to comply with legal obligations, to prevent fraudulent activity, to enforce agreements, or as otherwise deemed necessary at our discretion to support business efforts. Upon your request for deletion, the information that can be deleted will be removed from your Account in a reasonable amount of time or as otherwise required by applicable law.
      </p>
      <h3 className={styles.sectionHdr}>REMINDERS</h3>
      <div className={styles.subHead}>Changes to the Policy</div>
      <p>
        We may update this Privacy Policy periodically. If we make any material changes, we will update you on the Zip Run Platform, by email, or by other communication. We encourage you to stay up to date about our privacy practices by reviewing this Privacy Policy periodically. Continued use of Zip Run following notices of changes to the Privacy Policy indicates your acknowledgment of such changes and agreement to be bound by the terms and conditions of such changes.
      </p>
      <p>
        In addition, we may provide you with “just-in-time” disclosures or additional information about the data collection, use and sharing practices of specific parts of the Zip Run Platform. These notices may provide more information about our privacy practices, or provide you with additional choices about how we process your personal information.
      </p>
      <div className={styles.subHead}>Age Restrictions</div>
      <p>
        Zip Run is intended for access and use by individuals who are of legal age for the services offered based on the applicable regulations of their state or other government entities (“Qualifying Age”). We do not knowingly collect personal information from or about anyone who is not of Qualifying Age. If you are not of Qualifying Age, you should not attempt to access or use Zip Run, or send any information to us. If we discover that we have collected personal information from an individual who is not of Qualifying Age, we will take steps to remove that information.
      </p>
      <div className={styles.subHead}>Account Activity</div>
      <p>
        You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to emails or physical mail between you and Zip Run at all times. When you direct Zip Run to share your information with third parties, please note that your privacy and security may also be affected by changes in such third party services. We are not responsible for the functionality, privacy, or security measures of any such other organization or third party.
      </p>
      <div className={styles.subHead}>CONTACT US</div>
      <p>
        If you have questions or comments about this Privacy Policy, your information, our use and sharing practices, or your choices, please contact us at <a href="mailto:support@ziprun.com">support@ziprun.com</a>. 
      </p>
    </div>
  ) 
}

export default ZipRunPrivacyPolicy;