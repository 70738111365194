import * as React from 'react';

import useBulkPricing from './useBulkPricing';
import { makeStyles } from '@material-ui/core/styles';
import ErrorBoundary from '../common/ErrorBoundary';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  header: {
    textAlign: 'right',
    fontWeight: 600,
    paddingRight: '2px'
  },
  weightHeader: {
    textAlign: 'left',
    fontWeight: 600,
    paddingLeft: '2px'
  },
  weight: {
    fontWeight: 600,
    width: '4rem',
    paddingLeft: '2px'
  },
  price: {
    textAlign: 'right'
  },
  discount: {
    textAlign: 'right',
    paddingRight: '2px',
    fontWeight: 600,
    color: 'var(--zrPromoRed)'
  },
  currentProduct: {
    backgroundColor: '#ffffffcc',
    outline: '1px solid #ccc'
  }
});

/**
 * Content for Bulk Pricing Tooltip
 */
const BulkPricingToolTip = ({grams, pricingTierData}) => { 

  const classes= useStyles();
  const  [ pricingMap, discountMap, productWeights, displayWeights ] = useBulkPricing(pricingTierData);

  const priceFormat = (cents) => {
    return cents === 0
      ? '-'
      : `$${parseFloat(cents/100).toFixed(2)}`;
  };

  return ( 
    <ErrorBoundary>
      { pricingMap
        ? <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.weightHeader}>Weight</th>
                <th className={classes.header}>Price</th>
                <th className={classes.header}>Discount*</th>
              </tr>
            </thead>
            <tbody>
              { productWeights.map(tierGrams => 
                 pricingMap.get(tierGrams)
                 ? <tr key={tierGrams} className={grams === tierGrams ? classes.currentProduct : ''}>
                     <td className={classes.weight}>{displayWeights.get(tierGrams)}</td>
                     <td className={classes.price}>{priceFormat(pricingMap.get(tierGrams))}</td>
                     <td className={classes.discount}>{priceFormat(discountMap.get(tierGrams))}</td>
                   </tr>
                 : null
              )}
            </tbody>       
          </table>
        : null
      }  
    </ErrorBoundary>
  )
}

BulkPricingToolTip.propTypes = {
  grams: PropTypes.number.isRequired,  
  pricingTierData: PropTypes.array.isRequired
}

export default BulkPricingToolTip;
