import React from "react";

import UrlParamsProvider from './components/providers/UrlParamsProvider';
import UserProvider from "./components/providers/UserProvider";
import Application from "./components/Application";

function App() {

  const firebase_SDK_url = "https://www.gstatic.com/firebasejs/7.15.1/firebase-app.js";
  const scr = document.createElement('script');
  scr.src = firebase_SDK_url;
  document.body.appendChild(scr);

  /**
   *  The UserProvider will maintain a session login for the Application 
   */
  return (
    <UrlParamsProvider>
      <UserProvider>
        <Application />
      </UserProvider>
    </UrlParamsProvider>
  );
}
export default App;
