import * as React from "react";

import { Router } from '@reach/router';
import { UrlParamsContext } from "./providers/UrlParamsProvider";
import ThemeProvider from "./providers/ThemeProvider";
import NavigationMessageProvider from "./providers/NavigationMessageProvider";
import CommerceLoggingProvider from "./analytics/CommerceLoggingProvider";
import OrderTypeProvider from "./providers/OrderTypeProvider";
import RegistrationProvider from "./registration/RegistrationProvider";
import GuestCheckoutProvider from "./checkoutUserFlow/GuestCheckoutProvider";
import OrderProvider from "./orders/OrderProvider";
import DispensaryProvider from "./dispensary/DispensaryProvider";
import ProductCategoryProvider from "./providers/ProductCategoryProvider";
import FilteredProductsProvider from "./products/FilteredProductsProvider";
import ModalProvider from "./modal/ModalProvider";
import CartItemsProvider from "./checkout/CartItemsProvider";
import EmbedPageLayout from "./embedMenu/EmbedPageLayout";
import MenuHome from "./menuHome/MenuHome";
import EmbedMenu from './embedMenu/EmbedMenu';
import PlaceOrderController from "./viewControllers/PlaceOrderController";
import EmbeddedOrderConfirmation from './embedMenu/EmbeddedOrderConfirmation';
import SignIn from "./registration/SignIn";
import SignUpPage from "./registration/SignUpPage";
import YourAccount from "./account/YourAccount";
import YourOrders from "./orders/YourOrders";
import ProductDetailPage from "./products/ProductDetailPage";
import ProductPreviewProvider from "./products/ProductPreviewProvider";
import PageNotFound from './common/PageNotFound';
import Spinner from "./common/Spinner";

const Application = () => {
  const { dispensaryId } = React.useContext(UrlParamsContext); 
  
  return (
    dispensaryId
    ? <ThemeProvider>
        <OrderTypeProvider>
        <CommerceLoggingProvider>
        <NavigationMessageProvider>
        <RegistrationProvider>
          <GuestCheckoutProvider>
            <OrderProvider>
              <ProductCategoryProvider>
                <DispensaryProvider>
                  <ProductPreviewProvider>
                    <FilteredProductsProvider>
                      <ModalProvider>
                        <CartItemsProvider>
                          <EmbedPageLayout>
                            <Router>
                              <MenuHome path="/menu/:dispId" />
                              <EmbedMenu path="/menu/:dispId/products" />
                              <PlaceOrderController path="/menu/:dispensaryId/checkOut" />
                              <EmbeddedOrderConfirmation path="/menu/:dispensaryId/orderConfirmation" />
                              <SignIn path="/menu/:dispensaryId/signIn" />
                              <SignUpPage path="/menu/:dispensaryId/signUp" />
                              <YourAccount path="/menu/:dispensaryId/account" />
                              <YourOrders path="/menu/:dispensaryId/orders" />
                              <ProductDetailPage path="/menu/:dispensaryId/product/:itemId" />
                              <PageNotFound default path="/" />
                            </Router>
                          </EmbedPageLayout>
                        </CartItemsProvider>
                      </ModalProvider>
                    </FilteredProductsProvider>
                  </ProductPreviewProvider>
                </DispensaryProvider>
              </ProductCategoryProvider>
            </OrderProvider>
          </GuestCheckoutProvider>
        </RegistrationProvider>
        </NavigationMessageProvider>
        </CommerceLoggingProvider>
        </OrderTypeProvider>
      </ThemeProvider>
    : <Spinner />
  );
}

export default Application;