export const userAddressKey = 'userAddress';
export const userZipCodeKey = 'userZipCode';
// NOTE: This key is also used on the Marketing Site, so don't modify
export const userIs21Key = 'zrIs21';

export const isStorageSupported = (isSimpleCheck) => {
  var storage = window.localStorage;
  if (isSimpleCheck) {
    return storage && !!storage.getItem;
  }
  try {
    storage.setItem('test', 'test');
    storage.removeItem('test');    
    return true;
  } catch (e) {
    return false;
  }
};

// Check for a stored value
export const hasStoredValue = (key) => {
  if (isStorageSupported(true)) {
    const storedVal = window.localStorage.getItem(key); 
    return !!storedVal;  
  }
  return false;
};

// Store a value for use in storage
export const storeValue = (key, value) => {
  try {
    if (isStorageSupported()) {
      window.localStorage.setItem(key, value); 
    }
  } catch(e) {
    console.log(`storage set error ${e}`);
  }
};

// Get a stored value
export const getStoredValue = (key) => {
  try {
    if (isStorageSupported(true)) {
      return window.localStorage.getItem(key); 
    }
  } catch(e) {
    console.log(`storage get error ${e}`);
  } 
  return null;
};

// Get a url hashed value ( zip code etc.)
export const getUrlHashedValue = (key) => {
  // app.ziprun.com#zip.12345+showlogin.true
  const hashParams = window.location.hash.substring(1);
  if (hashParams.indexOf(`${key}.`) > -1) {
    const keyRegex = new RegExp(`${key}.(\\w+)`); 
    return hashParams.match(keyRegex)[1];
  } 
  return null;
}; 

// Get a url hashed value ( zip code etc.) else return stored value
export const getUrlHashedOrStoredValue = (hashKey, storageKey) => {
  // any hashed value should be stored (overwrite existing)
  const hashedVal = getUrlHashedValue(hashKey);
  if (hashedVal) {
    storeValue(storageKey, hashedVal);
    return hashedVal;
  } else {
    return getStoredValue(storageKey);
  }
};

export const setSessionVal = (key, val) => {
  try {
    window.sessionStorage.setItem(key, val);
  } catch (e) {
    // too bad.
  }
};

export const getSessionVal = (key) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (e) {
    return null;
  }
};