import React from 'react';

import { fetchProductDetail } from '../dispensary/dispensary-utils';
import { makeStyles } from '@material-ui/core';
import { ThemeContext } from '../providers/ThemeProvider';
import BasicButton from '../styleguide/BasicButton';
import QuantityIcon from './QuantityIcon';
import CartProductImage from '../products/CartProductImage';
import ErrorIcon from '@material-ui/icons/Error';
import { ZIP_RUN_GREEN } from '../../constants/colors';
import PropTypes from 'prop-types';

import styles from './CartItem.module.css';

// Implement dispensary theme
const useStyles = makeStyles({
  control: {
    backgroundColor: (props) => `${props.primaryColorDark}bb`
  },
  '&hover': {
    backgroundColor: (props) => props.primaryColorDark
  } 
});

const CartItem = ({
  Id,
  dispensaryId,
  name,
  productFlowerGrams,
  totalFlowerGrams,
  image_url,
  cost_usa_cents,
  leaf_logix_pricing_tier,
  quantity,
  category,
  handleRemove,
  handleIncrease,
  handleDecrease
}) => {

  const { theme } = React.useContext(ThemeContext);
  const classes = useStyles(theme);

  const itemPrice = quantity * cost_usa_cents / 100; 
  const totalPrice = parseFloat(itemPrice).toFixed(2);

  // only do the availability check once
  const availabilityRef = React.useRef();
  const [notAvailable, setNotAvailable] = React.useState();
  
  const notAvailableIconStyle = {
    fontSize: '22px', 
    verticalAlign: '-5px', 
    color: ZIP_RUN_GREEN 
  }

  React.useEffect(() => {
    const controller = new AbortController();
    // Make sure product is still available
    const callback = (response) => {
      // only check once, metric_id is a string so check is zero-safe
      availabilityRef.current = true;
      if (response.id) {
        setNotAvailable(false);
      } else {
        setNotAvailable(true);
      }  
    };
    if (!availabilityRef.current) {
      fetchProductDetail(Id, dispensaryId, callback, controller);
    } 
    return () => controller.abort();  
  }, [Id, dispensaryId]);

  return (
    <li className={styles.cartItem} key={Id}>
      <div className={styles.itemDetails}>
        <span className={styles.itemCount}>x{quantity}</span>
        <span className={styles.itemTitle}>
          {name} 
        </span>
        <span className={styles.itemPrice}>${totalPrice}</span>
      </div>
      
      <div className={styles.itemRemoveWrap}>
        <span className={styles.itemRemove} 
              style={{color: theme.primaryColorDark}}
              onClick={handleRemove}>
          Remove
        </span>
      </div>
      
      <div className={styles.imageWrap}>
        <CartProductImage imgUrl={image_url} imgAlt={name} imgClass={styles.itemImg} imgCategory={category} />
        <QuantityIcon cartCount={quantity} productFlowerGrams={productFlowerGrams} totalFlowerGrams={totalFlowerGrams} />
        <span className={`${styles.itemControl} ${styles.increase} ${classes.control}`} onClick={handleIncrease}>+</span>
        <span className={`${styles.itemControl} ${styles.decrease} ${classes.control}`} onClick={handleDecrease}>-</span>
      </div>
      { notAvailable && 
         <>
           <div className={styles.notAvailableMask}></div>
           <div className={styles.notAvailableWrap}>
             <div className={styles.notAvailableMsg}>
               <ErrorIcon style={notAvailableIconStyle} /> Sorry, this item has just sold&nbsp;out.
             </div>
             <BasicButton text="Remove from bag" handleClick={handleRemove} />  
           </div>
         </>        
      }
    </li>
  )
}

CartItem.propTypes = {
  Id: PropTypes.string.isRequired,
  dispensaryId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  productFlowerGrams: PropTypes.number.isRequired,
  totalFlowerGrams: PropTypes.number.isRequired,
  image_url: PropTypes.string, /* we handle null */
  cost_usa_cents: PropTypes.number.isRequired,
  leaf_logix_pricing_tier: PropTypes.object, /* optional */
  quantity: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleIncrease: PropTypes.func.isRequired,
  handleDecrease: PropTypes.func.isRequired
};

export default CartItem;