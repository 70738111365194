import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import useHostDomain from '../messaging/useHostDomain';
import { sendMessage, navigationMessageHandler } from '../embedMenu/embed-utils';
import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { ProductCategoryContext } from '../providers/ProductCategoryProvider';
import { FilteredProductsContext } from '../products/FilteredProductsProvider';
import PreviewProducts from './PreviewProducts';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from '../styleguide/Header';
import SimpleDesktopCarousel from '../common/SimpleDesktopCarousel';
import { TypeIcon } from '../products/ProductTypes';
import PromoText from '../dispensary/PromoText';
import Spinner from '../common/Spinner';

import styles from '../embedMenu/EmbeddedMenu.module.css';

const MenuHome = () => {
  
  // From url: /menu/{dispId}
  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { productCategories } = React.useContext(ProductCategoryContext);
  // Category click
  const { setCategory } = React.useContext(FilteredProductsContext);
  const categoryProps = React.useRef();
  const [itemProps, setItemProps] = React.useState();
  const hostDomain = useHostDomain();

  // Listen to category from host page url hash and navigate if needed
  React.useEffect(() => {
    const handleHostMessages = navigationMessageHandler('zrCategory', (data) => {
      const { zrCategory, zrProductId } = data;
      if (zrCategory) {
        setCategory(zrCategory);
        if (zrProductId) {
          navigateTop(
            `/menu/${dispensaryId}/product/${zrProductId}`,
            { state: { returnToPath:`/menu/${dispensaryId}/products` }}
          );
        } else {
          navigateTop(`/menu/${dispensaryId}/products`);
        }
      }
    });
    window.addEventListener("message", handleHostMessages);
    return () =>  window.removeEventListener("message", handleHostMessages);
  }, [dispensaryId, setCategory]); 

  const filterClick = React.useCallback((categoryName) => {
    setCategory(categoryName);
    sendMessage({productCategory:categoryName}, hostDomain);
    navigateTop(`/menu/${dispensaryId}/products`);
  }, [dispensaryId, setCategory, hostDomain]); 

  // Build props for the category carousel
  React.useEffect(() => {
    if (dispensaryId && productCategories?.length > 0 && !categoryProps.current) {
      const propArray = productCategories.reduce((array, category) => {
        array.push({
          itemKey: `tile_${category.display_name}`,
          name: category.display_name, 
          image: category.image_url,
          handleFilterClick: categoryName => filterClick(categoryName)
        });
        return array;
      } , []);
      categoryProps.current = propArray;
      setItemProps(propArray);
    } else {
      setItemProps(categoryProps.current);
    }
  }, [dispensaryId, productCategories, setCategory, hostDomain, filterClick]) 

  return (
    <BodyWrapper pageLayout={LayoutTypes.Default}>
      <PromoText />
      <div className={styles.headerWrap}>
        <Header size={HeaderSize.Medium} text="Categories" />
      </div>
      { dispensaryId && itemProps?.length
        ? <>
            <SimpleDesktopCarousel 
              CarouselComponent={TypeIcon}
              carouselItemProps={itemProps}
              maxItemCount={8}
              withStyle={{ padding:'0.5rem', margin: '0 -0.5rem' }}
              trackingLabel="menu_home_category_click"
            />
            <PreviewProducts dispensaryId={dispensaryId} />
          </>
        : <Spinner />
      }
    </BodyWrapper> 
  )
}

export default MenuHome;