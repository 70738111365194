import * as React from "react";

import { auth } from "../../firebase";
import { devLog } from "../util/util";
import { useSelector, useDispatch } from "react-redux";
import { storeValue, getStoredValue, userAddressKey } from '../storage/storage-utils';
import { fetchUserInfo } from '../registration/registration-utils';
import { fetchMetaData } from '../dispensary/dispensary-utils';
import { setFeeStructure } from '../actions/cartActions';
import { trackEvent } from "../analytics/tracking";
import { executeSessionRestoreQueue, executeSessionReadyQueue } from './provider-utils';
import { logWithIntercom } from '../intercom/intercom-utils';
import PropTypes from 'prop-types';

export const UserContext = React.createContext({ user: null });

/**
 *  There should always be a real signed-in user or an "anonymous" user
 *  as a token is required with (most) API requests.      
 * 
 *  Note: API calls can be wrapped in queueForSessionReady
 *  so they are not called prior to session creation (alternately use the UserContext)
 */
const UserProvider = ({children}) => {
  
  const [user, setUser] = React.useState();
  // Location from database
  const [location, setLocation] = React.useState();
  // Location entered via an address prompt
  const [searchLocation, setLocalSearchLocation] = React.useState(); /* e.g. from address prompt */

  const sales_tax_percent = useSelector(state => state.sales_tax_percent);

  const abortController = new AbortController();

  const dispatch = useDispatch();

  /**
   * We set the fee structure (in redux) on session start/update
   */ 
  const feeStructureCallback = React.useCallback((response) => {
    if (response.order_fees) {
      const { cannabis_tax_percent, sales_tax_percent, delivery_fee_cents, delivery_service_fee_percent, pickup_fee_cents, pickup_service_fee_percent, in_store_payment_fee_percent } = response.order_fees;
      dispatch(setFeeStructure(cannabis_tax_percent, sales_tax_percent, delivery_fee_cents, delivery_service_fee_percent, pickup_fee_cents, pickup_service_fee_percent, in_store_payment_fee_percent));
    } else {
      trackEvent('error_meta_data', 'order_fees data not returned by API');
    }
  }, [dispatch]);

  /**
   * We set the delivery address for registered users on session start/update
   */ 
  const deliveryAddressCallback = (response) => {
    devLog('setting user location');
    if (response.location) {
      setLocation(response.location);
    } 
  };
  
  // For canceling anonymous user creation 
  const timerRef = React.useRef();

  React.useEffect(() => {
    try {
      // Iframed Incognito will fail on 'local' setting!
      // Possible solution to upgrade to "local":
      // Store something in localStorage and if found use local
      // otherwise use session.  Goal is to persist on non-incognito tabs  
      
      auth.setPersistence('session'); /* local or session */

      auth.onAuthStateChanged(async newUser => {
        
        // Clear all queued requests
        window.clearTimeout(timerRef.current);
        
        // If newUser is null, create an anonymous user so we can make API requests
        if ( newUser === null ) {
          timerRef.current = window.setTimeout(() => {
            devLog('null user: creating anonymous user');
            createAnonymousUser(() => setUser(auth.currentUser));
          } , 500);
        } else {
          devLog('non-null user: running callbacks');
          if ( newUser.displayName !== user?.displayName) {
            setUser(newUser);
          }

          // TODO: Remove the Queues as we have Providers for most things

          // execute any API requests that are waiting for the newUser to be set/created
          executeSessionReadyQueue(newUser);
          
          // these are requests for real-user data like "Your Account"
          if (!newUser.isAnonymous) {
            executeSessionRestoreQueue(newUser);     
            // Add registered user to Intercom dashboard
            if (newUser.displayName) {
              const userCreationTS = new Date(newUser.metadata?.creationTime).getTime()/1000;
              const actionObj = {
                Registration_Complete: true
              };
              logWithIntercom(newUser.displayName, newUser.email, userCreationTS, actionObj);
            }
          }
        }

        // Debounce these API requests 
        timerRef.current = window.setTimeout(() => { 
          // Fetch pricing metadata (redux)
          if (sales_tax_percent === 0) {
            fetchMetaData(feeStructureCallback, abortController);
          }
          
          // Fetch the Logged-In user's delivery address/tel number
          if (newUser && !newUser.isAnonymous && !location?.street_address) {
            fetchUserInfo(deliveryAddressCallback, abortController);
          }
    
          // Check storage for location
          if(!searchLocation) {
            devLog('checking storage for search location');
            const storedLocation = getStoredValue(userAddressKey);
            if (storedLocation) {
              devLog('storing search location');
              setLocalSearchLocation(storedLocation);
            }
          }
        }, 300);
      });
    } catch (error) {
      trackEvent('error_auth_state', error); 
    }
  }, [user, abortController, feeStructureCallback, location, sales_tax_percent, searchLocation]);

  const createAnonymousUser = (onCompleteFn) => {
    auth.signInAnonymously()
      .then(data => {
        onCompleteFn();
      })
      // Anonymous auth is enabled in the Firebase Console
      .catch(function(error) {
        trackEvent('error_create_anon_user', error.code);
        console.error(error);
      });
  };
  
  /**
   * Set searchLocation based on a user's address input
   * 
   * The registration-based location is preferred over the searchLocation
   * 
   * @param {object} location 
   */
  const setSearchLocation = (location) => {
    try {
      if (!searchLocation ||
           JSON.stringify(location) !== JSON.stringify(searchLocation)) {
        setLocalSearchLocation(searchLocation);
        storeValue(userAddressKey, JSON.stringify(searchLocation));
      }
    } catch(e) {
      trackEvent('error_setting_search_location');
    }
  }

  return (
    <UserContext.Provider value={{
      user,
      location,
      searchLocation,
      setSearchLocation
    }}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
};

export default UserProvider;