import { auth, storage } from '../../firebase';
import { trackEvent } from '../analytics/tracking';
import { notificationsReadEndpoint } from '../../constants/api-endpoints';

export const formatDateString = (dateString) => {
  const dateItems = new Date(dateString).toDateString().split(' ').slice(1,4);
  return `${dateItems[0]} ${parseInt(dateItems[1],10)}, ${dateItems[2]}`;
};

/**
 * This method returns an array of notification Ids that have been seen by the user.
 * 
 * @param {*} onSuccess 
 * @param {*} controller 
 */
export const getReadNotifications = (onSuccess, controller) => {
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(`${notificationsReadEndpoint}`, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          onSuccess(data);
        });  
      } else {
        trackEvent(`notifications_read_response_error_${response.status}`);
      }
    })
    .catch(error => {
      trackEvent('notifications_read_error'); 
    });
  });
};

/**
 * This method updates the notification Ids that have been seen by the user.
 * 
 * Currently the notifications to-be-seen are determined by in-app business logic
 * and simply marked as read in the database. 
 * 
 * @param {Array} notificationIds -- An array of ALL notifications the user has been assigned to see.
 * @param {*} onSuccess 
 * @param {*} controller 
 */
export const setReadNotifications = (notificationIds, onSuccess, controller) => {
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(`${notificationsReadEndpoint}`, { 
      method: 'PUT',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(notificationIds),
      signal: controller.signal
    })
    .then(response => {
      if (response.status === 204) {
        onSuccess();
      } else {  
        trackEvent(`mark_notifications_response_error_${response.status}`);
      }
    }).catch(error => {
      trackEvent('mark_notifications_error');
    });
  });
};

/**
 * Below methods demonstrate saving/retrieving files from firebase storage
 * Not currently in use
 */

const storagePath = 'files/customer_notifications/';

/**
 * Store a file indicating that a notification has been seen
 * 
 * @param notificationID the ID of the notification
 * @param encodedEmail the users base64 encoded email to use as a directory
 * @param onUploadComplete the onCompletion callback
 * @param onProgressChange the onProgress callback 
 */
 export const handleNotificationSeen = (notificationID, encodedEmail, onUploadComplete, onProgressChange) => {
  
  const storageRef = storage.ref();
  
  // Create the file metadata
  const metadata = {
    contentType: 'text/plain'
  };  
  // full filepath with encodedEmail/filename
  const filePath = `${storagePath}${encodedEmail}/${notificationID}.txt`;  
  // Create the text file
  var file = new window.Blob([notificationID], {type: 'text/plain'});  
  // Upload file and metadata to the object 'images/mountains.jpg'
  const uploadTask = storageRef.child(filePath).put(file, metadata);
  
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',  // or storage.TaskEvent.STATE_CHANGED
    function(snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgressChange(progress);
    }, function(error) {
      trackEvent('error_notification_file_upload', error.code, window.atob(encodedEmail),[]);
    }, function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        onUploadComplete({
          path: filePath,
          url: downloadURL
        });
      });
    }
  );
}       

export const fetchViewedNotifications = async (userEmail, onSuccess, onFailure) => {
  const storageRef = storage.ref();  
  const encodedEmail = window.btoa(userEmail);

  // Create a reference to the user's notifications folder
  var listRef = storageRef.child(`files/customer_notifications/${encodedEmail}`);
  
  // Accumulate the filenames (async) 
  const resolveFileRefs = async (items) => {
    const promisedItems = items.map(async item => {
      return await item.getDownloadURL().then(function(url) {
        // notification ID files would be "credit1.txt", "credit2.txt", etc.
        const filenameRegex = new RegExp("\\%2F(\\w+)\\.txt\\?alt");
        const matches = url.match(filenameRegex);
        if (matches && matches.length > 1) {
          return matches[1];
        } else {
          return null;
        }
      })
    });
    const results = await Promise.all(promisedItems);
    const filenames = results.filter(name => name !== null);
    onSuccess(filenames);
  }; 

  // Find all the prefixes and items.
  listRef.listAll()
    .then((res) => {
      resolveFileRefs(res.items);
    }).catch((error) => {
      // TODO: log Error
      onFailure();
    });
};