import * as React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import PropTypes from 'prop-types';
import { ZIP_RUN_GREEN } from '../../constants/colors';
import styles from './Spinner.module.css';

const Spinner = ({ inSmallLayout=false, color, size=80, style={} }) => {
  
  const { theme } = React.useContext(ThemeContext) || {};
  const spinnerColor = theme?.primaryColorDark || ZIP_RUN_GREEN;

  const circles = [...Array(4)].map((_, index) => {
    return (
      <div
        key={index}
        style={{
          borderColor: `${spinnerColor} transparent transparent transparent`,
          width: size * 0.8,
          height: size * 0.8,
          margin: size * 0.1,
          borderWidth: size * 0.1,
        }}
      ></div>
    )
  })

  const wrapperStyle = inSmallLayout ? styles.spinnerWrapTight : styles.spinnerWrap; 

  return (
    <div className={wrapperStyle}>
      <div className={styles.ldsRing} style={{ width: size, height: size, ...style }}>
        {circles}
      </div>
    </div>
  );
}

Spinner.propTypes = {
  /** adjust margin for small layout */
  inSmallLayout: PropTypes.bool,
  /** hex color */
  color: PropTypes.string,
  /** size in pixel */
  size: PropTypes.number,
  /** style object */
  style: PropTypes.object,
}


export default Spinner;