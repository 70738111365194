import * as React from 'react'; 
import config from '../../config';
import { trackEvent } from '../analytics/tracking';

// The global map instance
let map, geocoder;
export const destroyMap = () => {
  map = null;
};

/**
 * Create a full-function google map centered on the location
 * 
 * @param {string} elemId - the target div element id
 * @param {number} zoom - map zoom level 
 * @param {string} locationAddress - address, e.g. 123 Main Street, Boston... 
 */
export const initSingleLocationMap = (elemId, zoom, locationAddress) => {
  // We're centering on map pin so no center param needed
  // const center =  new window.google.maps.LatLng(40.7767644, -73.9761399);
  const options = { zoom, mapTypeControl: false, streetViewControl: false, scaleControl: true /*, center */ };
  // We need to retrieve the address lat/long
  geocoder = new window.google.maps.Geocoder();
  codeAddress(geocoder, map);
   
  function codeAddress(geocoder, map) {
    geocoder.geocode({'address':locationAddress}, function(results, status) {
      if (status === 'OK') {
        const elem = document.getElementById(elemId);
        if (elem) {
          map = new window.google.maps.Map(elem, options);
          map.setCenter(results[0].geometry.location);
          // old pin url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          /* eslint-disable-next-line no-unused-vars */
          const marker = new window.google.maps.Marker({
            map: map,
            position: results[0].geometry.location,
            icon: {
              url: '/pinBlack.png',
              scaledSize: new window.google.maps.Size(29, 42)
            }
          });
        }
      } else {
        // return error image?
        trackEvent('location_map_load_failure', status);
      }
    });
  }
};

/** 
 * The parseGooglePlace method was pulled from the parse-google-place package 
 * which is not a proper React package
 */
const parseGooglePlace = (place) => {
  place = place || {};

  // map place by type
  const byType = (place.address_components || []).reduce(function (acc, data) {
    data.types.forEach(function (type) {
      acc[type] = data;
    })
    return acc;
  }, {});

  const result = {
    streetNumber: placeGet('street_number'),
    streetName: placeGet('route'),
    city: placeGet('locality') ||
      placeGet('sublocality') ||
      placeGet('sublocality_level_1') ||
      placeGet('neighborhood') ||
      placeGet('administrative_area_level_3') ||
      placeGet('administrative_area_level_2'),
    county: placeGet('administrative_area_level_2'),
    stateShort: placeGet('administrative_area_level_1', true),
    stateLong: placeGet('administrative_area_level_1'),
    countryShort: placeGet('country', true),
    countryLong: placeGet('country'),
    zipCode: placeGet('postal_code')
  };

  // remove null/undefined
  result.address = [
    result.streetNumber,
    result.streetName
  ].filter(Boolean).join(' ');

  return result;

  // hoisted function - prefer short_name
  function placeGet(key, short) {
    if (!(key in byType)) return '';
    return short ? byType[key].short_name : byType[key].long_name;
  };
};

// Create a street address string from a Zip Run location object
export const getFormattedAddress = (location) => {
  if (location && location.street_address) {
    const { street_address, apartment_number, city, state, zip_code } = location;
    const apartment = apartment_number ? ` ${apartment_number}`:'';
    return `${street_address}${apartment}, ${city}, ${state}, ${zip_code}`;
  }
  return '';
};

/**
 * Load specific api scripts, e.g. Google Places typeahead library
 * 
 * @param {string} url 
 * @param {function} callback 
 */
export const loadScript = (url, callback) => {
  // create script tag
  const script = document.createElement("script"); 
  script.type = "text/javascript";
  // when script state is ready and loaded or complete, run the callback
  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  // load and append to head
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

// The global autoComplete instance
let autoComplete;
/**
 * Destroy the autoComplete on component unmount
 */
export const destroyAutoComplete = () => {
  autoComplete = null;
};

/**
 * Configure the Google Places autocomplete for US address lookup
 * 
 * Make sure instance is destroyed on component unmount
 * 
 * @param {ref} inputRef - Reference to meterial UI TextField 
 * @param {function} onInputChange - on input chage
 * @param {function} onSelection - on autocomplete address selection
 */
export const configureAutoComplete = (inputRef, onInputChange, onSelection) => {
  
  // Assign autoComplete with Google maps place one time
  // If this happens more than once per the getPlace() call will return undefined
  autoComplete = new window.google.maps.places.Autocomplete(
    inputRef.current,
    { types: [("geocode")], componentRestrictions: { country: "us" } }
  );
  // specify what properties we will get from API: address
  autoComplete.setFields(["address_components", "formatted_address"]); 
  // add a listener to handle when the place is selected
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(onInputChange, onSelection)
  );
};

/**
 * Handle user selection of the autocomplete suggestion
 * 
 * and parse out the various address components
 * 
 * @param {function} onInputChange - on input chage
 * @param {function} onSelection - on autocomplete address selection
 */
async function handlePlaceSelect(onInputChange, onSelection) {
  // Get place from google api
  const addressObject = autoComplete.getPlace(); 
  // If we want the input to show only street address, etc.
  
  if (onSelection) {
    const usableLoc = parseGooglePlace(addressObject);
    onInputChange(`${usableLoc.address}, ${usableLoc.city}, ${usableLoc.stateShort} ${usableLoc.zipCode}`);
    onSelection(usableLoc);
    trackEvent('autocomplete_address_select', encodeURIComponent(usableLoc?.city));
  }
};

const STATIC_MAPS_BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap';
/**
 * Get a static map url (e.g. to show dispensary location)
 * 
 * @param {*} location  - the dispensary address JSON 
 * @param {*} zoom - map zoom level
 * @param {*} sizeArray - e.g. [300, 250]
 */
export const getStaticMapUrl = (location, zoom, sizeArray) => {
  // TODO remove: Handle funky demo dispensary address with no number
  if (location.street_address && 
      location.street_address.indexOf('Brighton') === 0) {
    location.street_address = '1 Brighton Ave';
  } 
    
  const size = sizeArray.join('x');  //300x250 etc.
  
  const pinOrigin = config.IS_DEV
    ? 'https://ziprun-qa2-menu.web.app/'
    : window.location.origin

  const mapParams = new URLSearchParams({
    size,
    zoom,
    maptype: 'roadmap',
    format: 'png',
    visual_refresh: true,
    style: 'feature:poi|visibility:off',
    markers:  `icon:${pinOrigin}/pinBlackSmall.png|${Object.values(location).join(',')}`,
    key: config.GOOGLE_MAPS_KEY
  });
  return `${STATIC_MAPS_BASE_URL}?${mapParams.toString()}`; 
};

/**
 * Calculate the distance from the user's address to the dispensary address
 * 
 * @param {*} userAddress 
 * @param {*} dispensaryAddress 
 * @param {*} callback - handle service response
 */ 
export const useDistanceMatrix = (userAddress, dispensaryAddress, callback) => {
  React.useEffect(() => {
    let distanceService;
    if (userAddress && dispensaryAddress) {
      distanceService = new window.google.maps.DistanceMatrixService();
      distanceService.getDistanceMatrix({
        origins: [userAddress],
        destinations: [dispensaryAddress],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        durationInTraffic: false,
        avoidHighways: false,
        avoidTolls: false
      },
      (response, status) => {
        if (status !== window.google.maps.DistanceMatrixStatus.OK) {
          trackEvent('distance_matrix_error', status);
          console.log('Error:', status);
        } else {
          try {
            callback(response.rows[0]
                             .elements[0]
                             .distance
                             .text.replace(' ','\u00A0'));
          } catch { 
            trackEvent('distance_matrix_error', 'response_parsing_error');
            console.log('Distance Matrix: error parsing response');
          } 
        }
      });
    }
    return () => distanceService = null;
  }, [userAddress, dispensaryAddress, callback]);
};

/**
 * Calculate the distance from the user's address to the dispensary addresses
 * 
 * @param {object} userAddress 
 * @param {array} dispensaries 
 * @param {function} callback - handle service response
 */ 
export const getDistanceToDispensaries = (userAddress, dispensaries, callback) => {
  const destinations = Object.values(dispensaries.reduce((map, dispensary) => {
    map[dispensary.id] = getFormattedAddress(dispensary.location);
    return map;
  }, {}));

  // This is slightly deferred and may not be immediately available
  if (window.google && window.google.maps) { 
    const distanceService = new window.google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix({
      origins: [userAddress],
      destinations,
      travelMode: window.google.maps.TravelMode.DRIVING,
      unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      durationInTraffic: false,
      avoidHighways: false,
      avoidTolls: false
    },
    (response, status) => {
      if (status !== window.google.maps.DistanceMatrixStatus.OK) {
        trackEvent('dispensaries_distance_error', status);
        console.log('Error:', status);
      } else {
        callback(response);
      }
    });
  }
};