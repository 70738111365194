import firebase from "firebase/app";

/**
 * NOTE: There was a storage breakage in firebase 8.6.0
 * https://firebase.google.com/support/release-notes/js#8.6.1
 * 
 * To fix we upgraded to:
 * 
 * "firebase": "^8.6.1",
 * "firebase-tools": "^9.11.0",
 * 
 */

import "firebase/auth";
import "firebase/storage";
import 'firebase/analytics';

/**
 * PROD
 **/ 
var firebaseConfigProd = {
  apiKey: "AIzaSyDoGzr37czsa5OqMtkhz7A9avgaMzO9bdE",
  authDomain: "ziprun-prod.firebaseapp.com",
  databaseURL: "https://ziprun-prod.firebaseio.com",
  projectId: "ziprun-prod",
  storageBucket: "ziprun-prod.appspot.com",
  messagingSenderId: "483091914269",
  appId: "1:483091914269:web:aa875c358fcd3344cbf0f4",
  measurementId: "G-C2Z7CZ4PDP"
};

/** 
 * QA1
 **/
const firebaseConfigQA1 = {
  apiKey: "AIzaSyA1RWr8entMmdizW433pWG5V0gN5UMHLcA",
  authDomain: "ziprun-qa1.firebaseapp.com",
  databaseURL: "https://ziprun-qa1.firebaseio.com",
  projectId: "ziprun-qa1",
  storageBucket: "ziprun-qa1.appspot.com",
  messagingSenderId: "669319868205",
  appId: "1:669319868205:web:ee5116548e0342ea0e2da4",
  measurementId: "G-DN6WX5C49L"
};

/**
 * QA2
 **/
const firebaseConfigQA2 = {
  apiKey: "AIzaSyDBbJ7OR6MYapqfe_TQTk9RFFUIW492zdE",
  authDomain: "ziprun-qa2.firebaseapp.com",
  databaseURL: "https://ziprun-qa2.firebaseio.com",
  projectId: "ziprun-qa2",
  storageBucket: "ziprun-qa2.appspot.com",
  messagingSenderId: "953555140464",
  appId: "1:953555140464:web:37c507f4863c135f4999b5",
  measurementId: "G-1BSK4J7SS1"
};

/**
 * Configure QA for developmnent/staging, else Production
 * 
 * Staging is just a build param added to package.json in the "scripts" section
 * 
 *   Staging build: npm run-script build:staging
 *   Production build: npm run-script build 
 *
 *   Staging currently runs on the demo site
 */

const isStagingBuild = typeof process.env.REACT_APP_STAGING_BUILD !== "undefined" &&
                              process.env.REACT_APP_STAGING_BUILD === "true";

// QA2 is the default auth for web dev. QA1 is generally used for back end development
let firebaseConfig = firebaseConfigQA2;
if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.indexOf('-qa1') > -1) {
  firebaseConfig = firebaseConfigQA1;   
} else if (process.env.NODE_ENV === "production" && !isStagingBuild) {
  firebaseConfig = firebaseConfigProd;  
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
// export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
