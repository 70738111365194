import * as React from "react";

import PhoneInput,  { isPossiblePhoneNumber } from 'react-phone-number-input';
import PropTypes from 'prop-types';
import { parsePhoneNumber } from 'react-phone-number-input'
import { errMsg } from '../registration/registration-errors';
import { sendVerificationCode } from '../registration/registration-utils';
import EnterVerificationCode from '../registration/EnterVerificationCode';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BasicButton from '../styleguide/BasicButton';
import DisplayModal from '../common/DisplayModal';
import ErrorMessage from '../styleguide/ErrorMessage';

import styles from './YourAccount.module.css';

/**
 * Validate and update phone number (RFC3966)
 * 
 * The phone input is tricky because it accepts a country code (US) and a number (617-555-12324)
 * and onChange it returns the full number +16175551234
 */
const PhoneNumberForm = ({
  tel_number,     /* existing phone number minus country code parsed from API */
  handleSubmit,   /* handle submit after verification */
  msgHandler,     /* callback for handleSubmit */
  sectionRef,     /* element to fade animate */
  updateMsg       /* final success/failure message delivered after update */
}) => {
 
  const [updatedTelNumber, setUpdatedTelNumber] = React.useState();
  
  // Verify Mode voice call option
  const [isVoiceCall, setIsVoiceCall] = React.useState(false);

  // bad number input, missing, unchanged
  const [telNumberError , setTelNumberError] = React.useState();
  // incorrect sms code input
  const [sendCodeError, setSendCodeError] = React.useState();
  // API failure sending code
  const [verifyCodeError, setVerifyCodeError] = React.useState();
  // SMS Verification modal
  const [showVerificationModal , setShowVerificationModal] = React.useState();
  
  const countryCodeRef = React.useRef('US');
  
  const onVoiceCallClick = (event) => {
    const { checked:value } = event.currentTarget;
    setIsVoiceCall(value);
  } 

  // The callback for the verification modal
  const onNumberVerfied = (response) => {
    // save phone number to redux and continue  
    if (response.success) {
      setShowVerificationModal(false);
      handleSubmit({ tel_number:updatedTelNumber }, sectionRef, msgHandler);
    } else {
      // something happened that was not success or error
      setVerifyCodeError(true);  
    }
  };

  const handleVerify = (event) => {
    event.preventDefault();
    // clear errors
    setSendCodeError(false);
    setVerifyCodeError(false);

    // Handle unchanghed
    if (updatedTelNumber === tel_number || !updatedTelNumber) {
      setTelNumberError('You have not modified your phone number');
      return;
    } else {
      // This is the less strict validation to reduce false negatives
      // See: https://gitlab.com/catamphetamine/react-phone-number-input#isvalidphonenumbervalue-string-boolean
      const isValid = isPossiblePhoneNumber(updatedTelNumber); 
      setTelNumberError(!isValid);
      if (!isValid) {
        return;
      } else {
        // if a code send to user was successful
        const sendCodeCallback = ((response) => { 
          // success
          if(response.recipient_tel_number) {
            setShowVerificationModal(true);
          // failure
          } else {
            setSendCodeError(true); 
          }
        });
        
        //codeRecipientNumber.current = telNumber;
        const verificationInfo = {
          delivery_method: 'sms-text', 
          tel_number: updatedTelNumber
        };
        const controller = new AbortController();
        // Send the SMS message and show modal for code
        sendVerificationCode(verificationInfo, isVoiceCall, sendCodeCallback, controller);  
      }
    }
  } 

  const abortModal = () => {
    setShowVerificationModal(false);
    setTelNumberError('Your phone number has not been updated.');
  };

  // Add error styling if needed
  const inputWrapperStyles = telNumberError
  ? `${styles.phoneInputWrapper} ${styles.phoneInputError}`
  : styles.phoneInputWrapper; 

  React.useEffect(() => {
    const telNumberFromProps = tel_number  ? parsePhoneNumber(tel_number) : {};
    const { number, country:defaultCountryCode='US' } = telNumberFromProps; 
    countryCodeRef.current = defaultCountryCode; 
    if (number) {
      setUpdatedTelNumber(number);
    }
  }, [tel_number])
  
  return (
    <>
    <form>
        <div className={inputWrapperStyles}>
          <PhoneInput
            id="tel_number"
            initialValueFormat="national"
            defaultCountry={countryCodeRef.current}
            placeholder="Enter phone number"
            value={updatedTelNumber}
            onChange={ value => setUpdatedTelNumber(value) } />
          
          <div id="tel_number_err_msg" className={styles.phoneNumberErrorMsg }>
            { telNumberError 
              ? typeof telNumberError === 'boolean'
                ? errMsg('tel_number', telNumberError)
                : <ErrorMessage text={telNumberError} />
              : null
            }
            {errMsg('send_code', sendCodeError)}
            {errMsg('verify_code', verifyCodeError)}
          </div>
        </div>
        <div className={styles.updateBtnWrap}>
          <BasicButton text="Update Mobile Number" onGreen handleClick={handleVerify} />
          {/* success / failure */}
          { updateMsg &&   
            <span className={styles.updateMsg}>{updateMsg}</span> 
          }
        </div>
        <div className={styles.verifyMethod}>
          <FormControlLabel 
            control={<Checkbox 
              color="default" 
              id="accept_terms" 
              checked={isVoiceCall} 
              onChange={onVoiceCallClick} />}
            label="Send my verification code via voice call"
          />
        </div>
      </form>
      { showVerificationModal && 
        <DisplayModal title="Verify Phone Number" closeFn={abortModal}>
          <EnterVerificationCode phoneNumber={updatedTelNumber} 
            onVerified={onNumberVerfied}
            closeModalFn={() => setShowVerificationModal(false)} />
        </DisplayModal>
      }  
    </>
  );
};

PhoneNumberForm.propTypes = {
  tel_number: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  msgHandler: PropTypes.func.isRequired,
  sectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  updateMsg: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired
};

export default PhoneNumberForm;