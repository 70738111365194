import * as React from 'react';

import Button from '../styleguide/Button';
import AccountTipImage from '../../assets/YourAccountTip.png';
import CheckMark from '../../assets/AddedCheck.png';
import PropTypes from 'prop-types';

import styles from './SignUpInline.module.css';

const RegistrationSuccessMsg = ({onContinue}) => {
  return (
    <div className={styles.successWrap}>
      <div className={styles.successInner}>
        <div className={styles.successMsg}>
          <span className={styles.exclaim}>
            <img alt="" height="24" style={{verticalAlign:'-2px'}} src={CheckMark} />
            {'  '}<span>Success!</span>
          </span>
          {'  '}You're registered. 
          {'  '}<span role="img" aria-label="celebrate">🎉</span>
        </div>
        <Button isCentered text="Continue" handleClick={() => {
          if (onContinue) {
            /* close the modal, hide message, etc. */
            onContinue(); 
          }
        }}/>
      </div>
      <div className={styles.accountTip}>
        TIP: Manage your account using the <i>Your Account</i> menu option
        <img height="48" alt="Your Account Menu Option" src={AccountTipImage} />
      </div>
    </div>
  );
};

RegistrationSuccessMsg.propTypes = {
  onContinue: PropTypes.func.isRequired
};

export default RegistrationSuccessMsg;