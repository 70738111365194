import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { FilteredProductsContext, subCategoryAll, SHOW_ALL } from '../products/FilteredProductsProvider';

import SubCategoryFilter from './SubCategoryFilter';
import WeightFilter from './WeightFilter';
import BrandFilter from './BrandFilter';
import TypeFilter from './TypeFilter';
import Button from '../styleguide/Button';
import BasicButton from '../styleguide/BasicButton';

import FilterIcon from '../../assets/filterIcon2White.png';

import styles from './SubCategoryFilterModal.module.css';

/**
 * This component will 
 */ 
const SubCategoryFilterModal = ({
  handleClose
}) => {
  
  const { categoryName, filteredProducts, setFilters } = React.useContext(FilteredProductsContext);

  const closeFilters = () => {
    handleClose();
    trackEvent('filter_modal_apply_filters');
  };

  return (
      <div className={styles.filterWrap}>
        <div className={styles.hdr}>
          <img className={styles.hdrIcon} alt="" height="24" src={FilterIcon} />
          Filter: {categoryName}
        </div> 
        <div className={styles.scrollingContent}>
          <SubCategoryFilter startOpen inModal />
          <WeightFilter startOpen inModal />
          <BrandFilter startOpen inModal />
          <TypeFilter startOpen inModal />
          <div className={styles.resetWrap}>
            <BasicButton text="Reset Filters" handleClick={
              () => { 
                setFilters({
                  type: SHOW_ALL,
                  brand: SHOW_ALL,
                  subCategory: subCategoryAll  
                });
                trackEvent('filter_modal_reset_filters');
              } 
            } />
          </div>
        </div>
        <div className={styles.buttonWrap}>
          <Button text={`View ${filteredProducts.length} Product(s)`} handleClick={closeFilters} />
        </div>
      </div>
  );
}

export default SubCategoryFilterModal;