import * as React from 'react';

import styles from './Legal.module.css';

/**
 * Zip Run Terms of Service as of 4/6/21
 */
const ZipRunTermsOfService = () => {

  return (
    <div className={styles.legalWrap}>  
      <h1 className={styles.legalHdr}>Zip Run Terms of Service</h1>
      <div className={styles.subHead}>Effective Date: April 6, 2021</div>
      <h3 className={styles.sectionHdr}>INTRODUCTION</h3>
      <p>
        Zip Run is the first-ever social equity company to build a state-of-the-art e-commerce platform for cannabis.
      </p>
      <p>
        Welcome to Zip Run, a state-of-the-art e-commerce platform for cannabis. This Terms of Service Agreement (“Agreement”) describes the terms and conditions that govern your use of Zip Run and its associated services, and constitutes a legal, binding agreement between you, Zip Run, Inc. (“Zip Run”).
      </p>
      <p>
        THE DISPUTE RESOLUTION SECTION OF THIS AGREEMENT CONTAINS A MUTUAL ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT REQUIRES YOU AND ZIP RUN TO RESOLVE DISPUTES WITH EACH OTHER ON AN INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. PLEASE REVIEW THE DISPUTE RESOLUTION SECTION OF THIS AGREEMENT CAREFULLY. BY ENTERING INTO THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.
      </p>
      <div className={styles.subHead}>Acceptance Of The Zip Run Terms</div>
      <p>
        By using, accessing, or downloading the Zip Run Platform (defined below under the section entitled “The Zip Run Platform”), you hereby expressly acknowledge and agree to be bound by all of the terms and conditions of this Agreement. In addition to this Agreement, please review the Zip Run Privacy Policy (“Privacy Policy” and collectively with this Agreement, the “Zip Run Terms”), which is incorporated by reference herein. If you do not agree to the Zip Run Terms, you may not use the Zip Run Platform. If you violate the provisions of the Zip Run Terms, Zip Run may, in its sole discretion, suspend, discontinue, or change your account or any aspect of your access to or use of the Zip Run Platform in whole or in part. By continuing to use the Zip Run Platform, you agree to the Zip Run Terms and any future modifications and updates to the Zip Run Terms as published from time to time.
      </p>
      <div className={styles.subHead}>Modifications and Updates to the Zip Run Terms</div>
      <p>
        Zip Run may, in its sole discretion, modify or update the Zip Run Terms from time to time. Please review this page periodically to ensure you are up-to-date with any changes. Any changes to the Zip Run Terms are effective upon the publishing of said modification or update. Your continued use of the Zip Run Platform after any such changes shall constitute your acceptance of such changes.
      </p>
      <div className={styles.subHead}>Terminology</div>
      <p>
        For the purposes of this Agreement, the terms “cannabis” and “marijuana” are interchangeable, will be deemed under this Agreement to have the same legal meaning and significance, and do not encompass industrial hemp and products derived therefrom. “Industrial hemp” is defined by the Agriculture Improvement Act of 2018 as any part of the Cannabis sativa L. plant that contains less than 0.3 percent delta-9 tetrahydrocannabinol on a dry weight basis.
      </p>
      <h3 className={styles.sectionHdr}>THE ZIP RUN PLATFORM</h3>
      <p>
        Your use of the “Zip Run Platform” consists of the Application and the Service. You may access services Zip Run provides through the Zip Run marketplace located at ziprun.com, and any other websites, applications, programs, or tools provided, owned, or operated by Zip Run (collectively, the “Application”). Your use of the Application may include ordering adult-use cannabis and cannabis-related products from Zip Run or a retailer dispensary that has partnered with Zip Run (“Retailer”) and receiving delivery services from the Retailer, a third-party delivery service provider (“Delivery Service Provider”) or from Zip Run directly in some circumstances, or any other services provided or facilitated by Zip Run (collectively, the “Service”). Together, the Service and the Application are known as the Zip Run Platform.
      </p>
      <h3 className={styles.sectionHdr}>DISCLAIMERS &amp; ACKNOWLEDGMENTS</h3>
      <div className={styles.subHead}>Zip Run Is A Technology Platform</div>
      <p>
        You expressly agree and acknowledge that Zip Run (i) is a technology services provider that connects adult-use cannabis consumers (“Consumer” or “User”) with Retailers; (ii) does provide delivery services to Consumers in some circumstances; (iii) does not cultivate, manufacture, package, label, or test medical or adult-use cannabis, and (iv) does facilitate the sale of cannabis. It is the sole responsibility of the Zip Run to offer its services to you in a legal and compliant manner, which are facilitated through its use of the Zip Run Platform. Zip Run has no responsibility or liability for any delivery services provided to you by Retailers or a Delivery Service Provider.
      </p>
      <div className={styles.subHead}>Acknowledgment of Federal Law</div>
      <p>
        Zip Run operates under applicable state and local laws. You expressly acknowledge and understand that marijuana (cannabis) is listed on Schedule I of the United States Controlled Substances Act. Under the federal laws of the United States of America, manufacturing, distributing, dispensing or possession of marijuana is illegal, and individuals are subject to arrest and/or prosecution for doing so. Pursuant to the Agricultural Improvement Act of 2018, industrial hemp is not listed on Schedule 1 of the Controlled Substances Act. You further acknowledge that medical use is not recognized as a valid defense under federal laws regarding marijuana. You also acknowledge and understand that the interstate transportation of marijuana is a federal offense.
      </p>
      <div className={styles.subHead}>Acknowledgment of State Law</div>
      <p>
        You expressly acknowledge, agree, and understand that the use, possession, cultivation, manufacturing, transportation, and distribution of cannabis is illegal in your state of residence and in the state you are located unless all participants are acting completely within the scope of the state’s applicable laws.
      </p>
      <div className={styles.subHead}>Acknowledgment of the Laws of Your Location</div>
      <p>
        While the Application or parts of the Zip Run Platform may be accessed from various locations, the Service is currently available only to Users and Retailers located in certain states, cities, counties, municipalities, and jurisdictions, determined at the sole discretion of Zip Run (“Service Area”). In all scenarios, you must abide by and follow the applicable laws of the state, city, county, municipality, or jurisdiction in which you are located in order to use the Zip Run Platform. You expressly acknowledge, agree, understand, and assume full responsibility for cooperating with the laws of your place of location while using the Zip Run Platform. Zip Run reserves the right to determine or change its Service Area in its sole discretion at any time.
      </p>
      <div className={styles.subHead}>Health Information</div>
      <p>
        You expressly acknowledge, agree, and understand that any statements relating to cannabis goods, accessories or any other products available on the Zip Run Platform (“Products”) have not been evaluated by the FDA. Neither the Products nor the ingredients in any of the Products have been approved or endorsed by the FDA or any regulatory agency. The Products available on the Zip Run Platform are not intended to diagnose, treat, cure, mitigate, or prevent any medical condition, illness, or disease in humans or animals. The information provided is designed for educational purposes only and is not intended to be a substitute for informed medical advice or care. If you are pregnant, nursing, taking medication, or have a medical condition, we suggest consulting with a physician before using any Products.
      </p>
      <h3 className={styles.sectionHdr}>USING THE ZIP RUN PLATFORM</h3>
      <div className={styles.subHead}>Your User Account</div>
      <p>
        In order to use most aspects of the Zip Run Platform, Users must register for and maintain an active personal Zip Run account (“Account”). Account registration requires you to submit to Zip Run certain information related to you that Zip Run collects from you, through your use of the Zip Run Platform, and from third parties as detailed in the Privacy Policy (“User Data”). To register an Account, you may be required to submit User Data, including personally identifiable information (“PII”) such as your name, phone number, address, email address, date of birth, or government-issued ID (i.e. driver’s license, state ID card, or passport). You grant Zip Run permission to collect and maintain your User Data, and to disclose the User Data you provide to third parties for registration and identity verification purposes and to facilitate your use of the Zip Run Platform as detailed in the Privacy Policy or any “just-in-time” disclosures describing our data collection, use, and sharing practices of specific parts of our Zip Run Platform. A “just-in-time” notice may provide more information about our privacy practices, or provide you with additional choices about how we process your PII and/or User Data.
      </p>
      <p>
        You agree to maintain accurate, complete, and up-to-date information in your Account and that we and others may rely on your information as accurate, current and complete. Your failure to maintain accurate, complete, and up-to-date Account information, including having an invalid or expired identification on file, may result in your inability to access or use the Service.
      </p>
      <p>
        You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times. Unless otherwise permitted by Zip Run in writing, you may only possess one Account.
      </p>
      <p>
        Your participation in using the Zip Run Platform is for your sole, personal use. You agree that you are the sole authorized user of your Account, and you may not assign or otherwise transfer your Account to any other person or entity. Any attempt by another individual to use your Account may result in your inability to use the Service, and/or suspension from the Zip Run Platform. You are responsible for the use of your Account and Zip Run expressly disclaims any liability arising from the unauthorized use of your Account. If you suspect unauthorized access of your Account or any other breach of security, you agree to notify us immediately.
      </p>
      <p>
        Zip Run reserves the right, in its sole discretion, to set the requirements for, or to suspend, disable, deny access to, or disallow the registration of your Account for any reason. If you believe your inability to access your Account or the Zip Run Platform has been made in error, please contact us as described below under the heading “Notices”. You acknowledge and agree that Zip Run shall not be liable to you or any third party for any termination of your access to the Zip Run Platform. The Zip Run Terms shall survive and endure beyond any termination, deletion, or suspension of your Account, or if you cease using the Zip Run Platform.
      </p>
      <p>
        If you wish to terminate, delete, suspend, or otherwise disable your Account or use of the Zip Run Platform, please contact us as described below under the heading “Notices”. By registering for an Account, you acknowledge and agree that Zip Run must maintain certain User Data, including PII, for business, legal and/or operational purposes, including to prevent account abuse, or as otherwise permitted under applicable law. Any remaining PII not necessary for this task will be deleted from your Account in a reasonable amount of time following the request. For further information on our privacy practices and how we handle your User Data, please review the Privacy Policy.
      </p>
      <div className={styles.subHead}>Age Restrictions</div>
      <p>
        You must be at least 21 years old to use the Zip Run Platform and order, receive, or facilitate delivery of adult-use cannabis. By using the Zip Run Platform you expressly represent and warrant that you are at least 21 years old, are legally entitled to enter into this Agreement, and have the right, authority and capacity to enter into and abide by the terms and conditions of this Agreement. 
      </p>
      <div className={styles.subHead}>Zip Run Communications</div>
      <p>
        By entering into this Agreement or using the Zip Run Platform, you expressly consent and agree to accept and receive communications from Zip Run and/or Retailers (including Delivery Service Providers) that you transact with, including via email, text message, calls, and push notifications to the cellular telephone number you provided to Zip Run.
      </p>
      <p>
        By consenting to being contacted, you understand and agree that you may receive communications sent by or on behalf of Zip Run, and/or Retailers (including Delivery Service Providers), including but not limited to: operational or transactional communications concerning your Account or use of the Zip Run Platform; updates concerning new or existing features of the Zip Run Platform; communications concerning Promotions (defined below) run by Zip Run, Retailers or third parties; and news or industry developments concerning Zip Run, Retailers, or third parties. Standard text messaging charges applied by your cell phone carrier will apply to text messages you receive.
      </p>
      <p>
        IF YOU WISH TO OPT OUT OF PROMOTIONAL EMAILS, UNSUBSCRIBE FROM ZIP RUN’S PROMOTIONAL EMAIL LIST BY FOLLOWING THE UNSUBSCRIBE OPTIONS IN THE PROMOTIONAL EMAIL ITSELF, INCLUDING CLICKING THE UNSUBSCRIBE LINK PROVIDED AT THE BOTTOM OF THE EMAIL. IF YOU WISH TO OPT OUT OF PUSH NOTIFICATIONS YOU MAY DO SO THROUGH YOUR DEVICE SETTINGS. IF YOU WISH TO OPT OUT OF PROMOTIONAL CALLS OR TEXTS, YOU MAY TEXT “STOP” FROM THE MOBILE DEVICE RECEIVING THE MESSAGES TO THE PHONE NUMBER YOU RECEIVED THE MESSAGE FROM.
      </p>
      <p>
        YOU ACKNOWLEDGE THAT YOU ARE NOT REQUIRED TO CONSENT TO RECEIVE PROMOTIONAL TEXTS OR CALLS AS A CONDITION OF USING THE ZIP RUN PLATFORM. IF YOU WISH TO OPT OUT OF ALL TEXTS OR CALLS FROM ZIP RUN (INCLUDING OPERATIONAL OR TRANSACTIONAL TEXTS OR CALLS), PLEASE CONTACT US AS PROVIDED BELOW UNDER THE HEADINGS “NOTICES”. HOWEVER, YOU ACKNOWLEDGE THAT OPTING OUT OF RECEIVING ALL TEXTS MAY IMPACT YOUR USE OF THE ZIP RUN PLATFORM.
      </p>
      <div className={styles.subHead}>Electronic Records &amp; Signatures</div>
      <p>
        You agree that your electronic signature on the Zip Run Platform is the legal equivalent of your manual or paper signature. You consent to receive any communications, records, receipts, notices, agreements, documents, and disclosures provided by Zip Run regarding your Account and the Zip Run Platform electronically (collectively, “Electronic Communications”). We may provide these Electronic Communications to you via the Zip Run Platform, email, text message, push notification, chat, customer support, or other electronic communications.
      </p>
      <p>
        In order to access and retain Electronic Communications, you will need at least the following computer hardware and software:
      </p>
      <ul>
        <li>A device with an Internet connection</li>
        <li>A current web browser with cookies enabled</li>
        <li>A valid email address</li>
        <li>Storage space to save past Electronic Communications on your device or an installed printer to print Electronic Communications</li>
      </ul>
      <p>
        In order for us to send you Electronic Communications, you must ensure your contact information and email address is true, accurate and complete at all times. You acknowledge and agree Zip Run will be deemed to have provided Electronic Communications to you even if your contact information on file is incorrect, out of date, blocked by your service provider, or you are otherwise unable to receive Electronic Communications. You can update your contact information at any time by logging into your Account and updating your profile settings, or by contacting us as provided below under the heading “Notices”.
      </p>
      <p>
        You can withdraw your consent to receive Electronic Communications at any time by sending us an email with the subject line “Withdrawing E-Sign Consent” to support@ziprun.com. However, if you withdraw your consent to receive Electronic Communications, Zip Run reserves the right to immediately close your Account as we may be unable to properly facilitate your legal use of the Zip Run Platform.
      </p>
      <div className={styles.subHead}>Charges, Billing, &amp; Refunds</div>
      <p>
        You understand and acknowledge that you may incur fees or charges from Zip Run, or other third parties in connection with the goods and services purchased or facilitated through the Zip Run Platform (“Charges”). All Charges will be identified in your cart prior to or during checkout, and in your receipt. Such Charges may include some combination of the following:
      </p>
      <p>
        Retail Price. The “Retail Price” of goods and accessories are displayed with that item on the Zip Run Platform. The displayed Retail Price may include discounts, promotions, and price reductions at the sole discretion of Zip Run. At checkout, the combined Retail Price of goods and accessories is the subtotal of an order as shown in your cart.
      </p>
      <p>
        State Taxes. Retailers charge applicable “State Taxes,” such as cannabis excise taxes, on sales of goods and accessories based on the state of your location, pursuant to state laws and regulations.
      </p>
      <p>
        Local Taxes. Retailers charge applicable “Local Taxes” on sales of cannabis goods and accessories. Local Taxes may be based on the city, county, municipality, or other jurisdictional location of your delivery address, the Retailer fulfilling your order, or both, pursuant to local laws and regulations.
      </p>
      <p>
        Sales Tax. Retailers charge applicable “Sales Tax” on all sales of goods and accessories. Sales tax may be based on the state, city, county, municipality, or other jurisdictional location of your delivery address, the Retailer fulfilling your order, or both, pursuant to applicable laws and regulations.
      </p>
      <p>
        Other Taxes. You may be charged additional taxes based on applicable laws and regulations. Such taxes may vary depending on the laws and regulations in place at the time of your purchase.
      </p>
      <p>
        Delivery Fee. You may be charged a “Delivery Fee” for orders utilizing the delivery services provided by a Retailer, a Delivery Service Provider or from Zip Run directly.
      </p>
      <p>
        Cancellation Fee. After placing an order, you may cancel it through the Application, but you may be charged a “Cancellation Fee” in certain circumstances. You may also be charged if the delivery driver completing your order is unable to reach you to complete delivery.
      </p>
      <p>
        Service Fee. You may be charged a “Service Fee” for each order you place using the Zip Run Platform and the Payment Vendor’s services.
      </p>
      <p>
        Tips. You may elect to “Tip” your delivery driver through the Zip Run Platform where available.
      </p>
      <p>
        Other Fees. Additional fees and charges may apply to your orders as determined by Zip Run, the Retailers, and/or third parties with whom you transact. The description of any such fees will be available at checkout. Your bank may also charge additional fees.
      </p>
      <p>
        Zip Run may change any Charges at its sole discretion and reserves the right to determine final prevailing pricing. Please note that pricing information published on the Zip Run Platform may not accurately reflect pricing. Zip Run may, at its sole discretion, make promotional offers with differing features and/or Charges to any individual or User. Such offers, unless made to you, have no bearing whatsoever on your use of the Zip Run Platform.
      </p>
      <p>
        You may receive Promotions (defined below) or Referral Codes (defined below) that you can apply toward payment of certain Charges. Promotions or Referral Codes are only valid for use on the Zip Run Platform, and are not transferable or redeemable for cash except as required by law. Additional restrictions on Promotions or Referral Codes may apply as communicated to you in a relevant promotion.
      </p>
      <p>
        All Charges are due immediately and are non-refundable. This no-refund policy shall apply at all times regardless of your decision to terminate usage of the Zip Run Platform, Zip Run’s decision to terminate your usage, any disruption to the Zip Run Platform, or any other reason whatsoever. If you are dissatisfied with any of the Products you receive, please contact us as provided below under the heading “Notices”.
      </p>
      <p>
        Zip Run has partnered with AeroPay (www.aeropay.com), a third-party payment processor (“Payment Vendor”), which may bill your purchase of Products purchased through the Zip Run Platform. Payment Vendor services may require you to visit, enter information into, or create an account with the Payment Vendor’s application, website, or platform, separate and distinct from the Zip Run Platform. By entering your payment, banking, or other billing information, you authorize Zip Run to send that information to or through the applicable Payment Vendor to charge your form of payment. The processing of payments, authorizations, holds, credits, and refunds will be subject to the terms and conditions and privacy policy of the Payment Vendor. 
      </p>
      <div className={styles.subHead}>Third-Party Providers</div>
      <p>
        During your use of the Zip Run Platform, you may purchase goods and/or services from, correspond with, participate in the promotions of, or otherwise utilize third parties facilitating their business through the Zip Run Platform, including without limitation, (i) Retailers; (ii) Delivery Service Providers; (iii) advertisers, business partners, or affiliates; (iv) Payment Vendor; and (v) the respective employees of each of the preceding (“Third-Party Providers”). You agree and acknowledge that Zip Run is not a party to any transaction you enter into with any Third-Party Provider, unless otherwise indicated in this Agreement. Any such activity, and any terms, conditions, warranties, or representations associated with such activity is solely between you and the applicable Third-Party Provider. Zip Run shall have no liability, obligation or responsibility for any such purchase, correspondence, promotion, or transaction between you and any Third-Party Provider. In no event shall Zip Run be responsible for any content, products, services, or other materials on or available from Third-Party Providers.
      </p>
      <p>
        Zip Run does not endorse any third-party advertisements, links, promotions, or websites linked through the Zip Run Platform, and in no event shall Zip Run be responsible for any content, products, services or other materials on or available from such sites or Third-Party Providers. Certain Third-Party Providers may require you to agree to additional or different terms and conditions prior to your use of or access to their goods and/or services. Zip Run disclaims any and all responsibility or liability arising from such agreements between you and the Third-Party Providers.
      </p>
      <p>
        Zip Run may make available materials by Third-Party Providers, such as their opinions, advice, statements, offers, other information or content concerning Zip Run or other materials made available through the Zip Run Platform (“Third-Party Materials”). Such Third-Party Materials are not provided by Zip Run and Zip Run is not responsible for the content of Third-Party Materials. The authors of the Third-Party Materials are solely responsible for such content. Zip Run disclaims responsibility for any loss or damage resulting from your reliance on information or other content posted by Third-Party Providers, whether on the Zip Run Platform or otherwise. We reserve the right, without any obligation, to monitor, remove or suspend any Third-Party Materials posted on the Zip Run Platform and remove any such material that, in our sole opinion, violates or is alleged to violate: (i) the law; (ii) this Agreement; (iii) the rights, or threatens to harm the safety or rights of Users or others.
      </p>
      <p>
        The Zip Run Platform may contain third-party advertising and marketing. By agreeing to this Agreement, you agree to receive such advertising and marketing. Zip Run disclaims responsibility for any loss or damage resulting from or related to such advertising and/or marketing.
      </p>
      <div className={styles.subHead}>Product Descriptions</div>
      <p>
        Zip Run attempts to be as accurate as possible in Product descriptions or images for Products available through the Zip Run Platform. However, Zip Run is not a retailer and does not itself package, test, or label the Products available on the Platform. Zip Run does not warrant that Product descriptions, images, or other Content (defined below) available on the Zip Run Platform is accurate, complete, reliable, current, or error-free.
      </p>
      <p>
        You acknowledge and agree that amounts in Product descriptions on the Zip Run Platform are averages or estimates, and that amounts may vary for individual items, packages, or orders that you receive. Amounts on the Zip Run Platform include without limitation the level (by weight, mass, volume, or percentage) of THC, CBD, other cannabinoids, and terpenes in a Product, the total weight, mass, or volume of a Product, the size, number of individual items, or servings, in a Product, and any other unit of measurement related to a Product.
      </p>
      <div className={styles.subHead}>Promotions</div>
      <p>
        Zip Run may, in its sole discretion, make available certain promotions or offers, which may at times be redeemed by entering promotional codes, that may confer discounts, price reductions, features, benefits, related to the Service, subject to any additional terms that Zip Run establishes (“Promotions”). Promotions may be offered by Zip Run, Retailers, or other Third-Party Providers on the Zip Run Platform. Each Promotion is a limited time offer and is valid only for the time specified therein. Unless otherwise specified on the Promotion itself, you may only use one Promotion per Account, per day.
      </p>
      <p>
        Each Promotion (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold, resold or transferred in any manner, made available to the general public (whether posted to a public form or otherwise) or combined with any other offers unless otherwise permitted by Zip Run; (iii) may only apply to qualifying items and may require a minimum dollar order purchase; (iv) may be modified, disabled, or withdrawn by Zip Run at any time for any reason or no reason without liability to Zip Run; (v) is not valid for cash or credit, has no monetary value, and cannot be applied after an order is submitted; (vi) may expire prior to your use; and (vii) is void where prohibited by law. If you violate any of the Promotion terms, the Promotion will be invalid.
      </p>
      <p>
        Zip Run, at its sole discretion, reserves the right to issue Promotions with different features, discounts, offers, or deals to any Users or groups of Users determined solely by Zip Run. These Promotions, unless made to you, shall have no bearing whatsoever on your use of Zip Run, or any Promotions offered to you.
      </p>
      <p>
        Unless otherwise indicated by Zip Run, Promotions do not apply to taxes, fees, or any Charges other than Retail Price. Taxes and fees may apply to the full value of Products subsequently reduced in price by a Promotion.
      </p>
      <div className={styles.subHead}>Referral Codes</div>
      <p>
        Zip Run may, in its sole discretion, create referral links or codes (“Referral Codes”) that may be redeemed for discounts on future Services, or other features or benefits related to the Service, subject to any additional terms that Zip Run establishes. You agree that Referral Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold, resold, or transferred in any manner, made available to the general public (whether posted to a public form or otherwise) or combined with any other referral codes, promotional codes or other promotions, discounts, coupons, or any other Zip Run offers, unless in each case expressly permitted by Zip Run; (iii) may be disabled or withdrawn by Zip Run at any time for any reason or no reason without liability to Zip Run; (iv) may only be used pursuant to the specific terms set forth in the program rules; (v) are not valid for cash or credit; and (vi) may expire prior to your use. Zip Run reserves the right to withhold or deduct credits or other features or benefits obtained through the use of the referral system or Referral Codes by you or any other User in Zip Run’s sole discretion, including, without limitation, in the event that Zip Run determines or believes that the use of the referral system or use or redemption of the Referral Code was in error, fraudulent, illegal, or otherwise in violation of the program rules. The program rules are incorporated herein by reference. Please refer to them for the full terms and conditions applicable to your use of Referral Codes.
      </p>
      <div className={styles.subHead}>Mobile Data Fees &amp; Usage</div>
      <p>
        Zip Run is not responsible for any fees or usage charges related to your access of the Zip Run Platform. Your mobile network’s data and messaging rates and fees may apply if you access or use the Zip Run Platform from a device. You are solely responsible for acquiring and updating compatible hardware or devices necessary to access and use the Zip Run Platform and any updates thereto. Zip Run does not guarantee that the Zip Run Platform, or any portion thereof, will function on any particular network, hardware, or devices.
      </p>
      <h3 className={styles.sectionHdr}>PRIVACY &amp; CONFIDENTIALITY</h3>
      <div className={styles.subHead}>Confidential Information</div>
      <p>
        You agree not to use, disclose, or permit disclosure of, for any purpose other than as stated in this Agreement: (i) any technical, financial, strategic, and other proprietary and confidential information or data relating to Zip Run, Retailer or any Third-Party Provider’s business, operations, and properties; (ii) any User Data, PII, or any other information or data related to any other User; (iii) any non-public information of Zip Run, any Retailer, or other Third-Party Provider, or any other User that such party designates as being proprietary or confidential, or of which you should reasonably know that it should be treated as confidential (collectively, “Confidential Information”).
      </p>
      <p>
        You agree that all Confidential Information shall remain the exclusive property of the disclosing party. You agree not to disclose or permit disclosure of any Confidential Information to third parties for any purpose except as permitted under the Zip Run Terms, to take all reasonable measures to protect the secrecy of and avoid disclosure or use of Confidential Information, to prevent Confidential Information from falling into the public domain, and to return or destroy all Confidential Information of the disclosing party upon termination of this Agreement or at the request of the disclosing party subject to applicable law. In the event Confidential Information is required to be disclosed pursuant to the order or requirement of a court administrative agency, or other governmental body, you agree to provide prompt notice of such court order or requirement to Zip Run to enable Zip Run to seek a protective order or otherwise prevent or restrict such disclosure.
      </p>
      <p>
        Notwithstanding the foregoing, Confidential Information shall not include any information to the extent it: (i) is or becomes part of the public domain at a time it is intentionally disclosed by Zip Run or has entered the public domain through no act or omission on the part of the receiving party; (ii) was possessed by the receiving party prior to the date of this Agreement without an obligation of confidentiality; (iii) is disclosed to the receiving party by a third party having no obligation of confidentiality with respect thereto; or (iv) is required to be disclosed pursuant to law, court order, subpoena or governmental authority, provided the receiving party notifies the disclosing party thereof and provides the disclosing party a reasonable opportunity to contest or limit such required disclosure.
      </p>
      <div className={styles.subHead}>User Privacy</div>
      <p>
        Zip Run may collect User Data as detailed in the Privacy Policy. By entering into this Agreement, you affirmatively agree to Zip Run’s collection, use, and sharing of your information and data as described in the Privacy Policy and any subsequent and future updates thereto. You understand that Zip Run may retain your User Data for business, legal, regulatory, safety and other purposes after this Agreement is terminated, and agree to Zip Run’s use of your User Data.
      </p>
      <div className={styles.subHead}>Restricted Activities</div>
      <p>
        With respect to your use of the Zip Run Platform, you agree you will not:
      </p>
      <ul>
        <li>Impersonate any person or entity</li>
        <li>Stalk, threaten, or otherwise harass any person, or carry any weapons</li>
        <li>Violate any law, statute, rule, permit, ordinance, or regulation</li>
        <li>Post, email, upload, deploy, send, or otherwise make available (“Transmit”) any text, graphics, images, music, software, audio, video, code, files, programs, information or other materials (“Content”) that is unlawful, harmful, threatening, abusive, harassing, hateful, violent, pornographic, defamatory, libelous, invasive of another’s privacy, harmful to minors in any way, or otherwise offensive</li>
        <li>Transmit any Content that advertises any illegal service or the sale of any items which are prohibited by the laws of your state of location, or any other jurisdiction of your location</li>
        <li>Access the Zip Run Platform using unauthorized means, unauthorized applications, or an unauthorized device; or Transmit any Content that attempts to gain unauthorized access to Zip Run’s computer systems, networks, servers, software, hardware, telecommunications equipment, or any other internal or external Zip Run infrastructure (the “Zip Run Network”)</li>
        <li>Interfere with, disrupt, diminish the quality or performance of, or impair the functionality of the Zip Run Platform or the Zip Run Network, or Transmit any Content designed to do the same</li>
        <li>Surreptitiously intercept or expropriate any system, data, personal information, or any portion of the Zip Run Network or the Zip Run Platform</li>
        <li>Use the Zip Run Platform in any way that infringes Zip Run’s or a third party’s rights, including intellectual property rights, copyright, patent, trademark, trade secret, or other proprietary rights or rights of publicity or privacy</li>
        <li>Modify, adapt, translate, reverse engineer, decipher, decompile, or otherwise disassemble any portion of the Zip Run Platform or the Zip Run Network</li>
        <li>Rent, lease, lend, sell, redistribute, license or sublicense the Zip Run Platform or access to any portion of the Zip Run Platform</li>
        <li>Use any robot, spider, site search/retrieval application or other manual or automatic device or process to retrieve, index, crawl, scrape, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Zip Run Platform or its contents</li>
        <li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted through the Zip Run Platform</li>
        <li>“Frame” or “mirror” any part of the Zip Run Platform without Zip Run’s prior written authorization or use meta tags or code or other devices containing any reference to us in order to direct any person to any other website for any purpose</li>
        <li>Use the Zip Run Platform to design or develop a competitive or substantially similar product or service</li>
        <li>Use the Zip Run Platform to copy or extract any features, functionality, or Content</li>
        <li>Link directly or indirectly to any other websites</li>
        <li>Transfer or sell your Account, password, username, or identification to any other party</li>
        <li>Discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation</li>
      </ul>

      <h3 className={styles.sectionHdr}>LICENSING AND INTELLECTUAL PROPERTY</h3>
      <div className={styles.subHead}>Limited Right Granted By Zip Run</div>
      <p>
        Subject to your compliance with the terms and conditions of the Zip Run Terms, Zip Run hereby grants you a limited, non-exclusive, non-transferable, non-sublicensable, revocable right to access and use the Zip Run Platform only as permitted by its functionality. This right permits you to access the Application on a mobile device or computer that you own or control and to use the Application solely for your own personal, non-commercial use. 
      </p>
      <p>
        You will not download, copy, adapt, modify, prepare derivative works based upon, reverse engineer, decompile, disassemble, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Zip Run Platform, Content, or Confidential Information, except as expressly permitted in the Zip Run Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Zip Run, except for the rights expressly granted in this Agreement.
      </p>
      <p>
        Furthermore, with respect to any form of the Application accessed through or downloaded from the Apple App Store, Android Market, Google Play Store, Amazon App Store, BlackBerry App World, Samsung Apps Store, Nokia OVI store, Windows marketplace for Mobile, or any online marketplace, you will use that version of the Application as permitted by any “Usage Rules” set forth in the respective third-party terms of service or use applicable to such version of the Application. Zip Run disclaims any and all responsibility or liability arising from such agreements between you and such third parties. Zip Run reserves all rights in and to the Zip Run Platform not expressly granted to you under this Agreement.
      </p>
      <div className={styles.subHead}>License Granted By User</div>
      <p>
        The Zip Run Platform may allow you to post or submit feedback, comments, questions, and other content or information that a User submits, posts, uploads, or otherwise makes available on or through the Zip Run Platform (“User Content”). You are solely responsible for, and the sole owner of the User Content that you upload, publish, display, link to, or otherwise make available on the Zip Run Platform, and agree Zip Run is acting only as an interactive computer service for your publication of such User Content.
      </p>
      <p>
        By making available any User Content, you hereby grant Zip Run a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free right and license, with the right to sublicense through multiple tiers, to use, view, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, distribute, broadcast and otherwise exploit such User Content in any media or format, or to prepare derivative works or incorporate into other works such User Content in any media or format.
      </p>
      <p>
        Nothing in this Agreement will be deemed to restrict any rights that you may have to use and exploit any User Content. You acknowledge and agree that you do not acquire any rights to any research or commercial products that may be developed by Zip Run or its collaborating partners. You specifically understand that you will not receive compensation for any research or commercial products that include or result from your User Content.
      </p>
      <p>
        You acknowledge and agree that you are solely responsible for all User Content that you make available through or to the Zip Run Platform. Accordingly, you represent and warrant that: (i) you either are the sole and exclusive owner of all User Content that you make available or you have all rights, licenses, consents and releases that are necessary to grant Zip Run the above license to such User Content; and (ii) neither the User Content itself, you making it available, or Zip Run’s use of the User Content will infringe, misappropriate or violate any third party rights, including, without limitation, any patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
      </p>     
     <p>
        Zip Run reserves the right, but is not obligated, to review, monitor, reject or remove any User Content, at Zip Run’s sole discretion at any time and for any reason, without notice to you.
      </p>
      <div className={styles.subHead}>Intellectual Property Ownership</div>
      <p>
        Zip Run owns all intellectual property rights in and related to the Zip Run Platform absolutely and in their entirety. You acknowledge that any suggestions, ideas, enhancement requests, feedback, recommendations, or other information provided by you relating to the Zip Run Platform are non-confidential and shall become the sole property of Zip Run. This Agreement is not a sale and does not convey to you any rights of ownership in or related to the Zip Run Platform, or any intellectual property rights owned by Zip Run. Any trademarks, logos, service marks, company or product names displayed through the Zip Run Platform are trademarks of Zip Run or third parties, and no right or license is granted to use them.
      </p>
      <p>
        Other than as specifically permitted by Zip Run, you are not permitted to use or reference in any manner Zip Run or its Retail partners’ company names, logos, product and service names, trademarks, service marks, trade dress, copyrights or other indicia of ownership, alone and in combination with other letters, punctuation, words, symbols and/or designs (the “Zip Run Marks and Names”) for any commercial purposes.
      </p>
      <p>
        You agree that you will not: (i) try to register or otherwise use and/or claim ownership in any of the Zip Run Marks and Names, alone or in combination with other letters, punctuation, words, symbols and/or designs, or in any confusingly similar mark, name or title, for any goods and services; (ii) take any other action that would jeopardize or impair Zip Run’s rights as owner of the Zip Run Marks and Names or the legality and/or enforceability of the Zip Run Marks and Names, including, challenging or opposing Zip Run’s ownership in the Zip Run Marks and Names; or (iii) use the Zip Run Marks or Names on or in connection with any product, service, or activity that is in violation of any law, statute, government regulation or standard. Violation of this license may result in immediate termination of your license, at the sole discretion of Zip Run.
      </p>
      <h3 className={styles.sectionHdr}>DISPUTE RESOLUTION</h3>
      <div className={styles.subHead}>Agreement to Arbitrate</div>
      <p>
        YOU AND ZIP RUN MUTUALLY AGREE TO WAIVE OUR RESPECTIVE RIGHTS TO RESOLUTION OF DISPUTES IN A COURT OF LAW BY A JUDGE OR JURY AND AGREE TO RESOLVE ANY CLAIM OR DISPUTE AGAINST EACH OTHER ON AN INDIVIDUAL BASIS IN ARBITRATION. This will prohibit you from bringing any class, collective, or consolidated action against Zip Run, and will also prohibit you from participating in or recovering relief under any current or future such actions brought against Zip Run by someone else. Arbitration is more informal than a lawsuit in court: there is no judge or jury in arbitration; discovery in arbitration may be more limited than discovery in litigation; and court review of an arbitration award is limited. This agreement to arbitrate (“Arbitration Agreement”) also applies to claims between you and Zip Run’s Third-Party Providers, and such Third-Party Providers will be considered intended third party beneficiaries of this Arbitration Agreement.
      </p>
      <p>
        You and Zip Run agree that any dispute, claim, or controversy, whether based on past, present or future events, arising out of or relating to: this Agreement and prior versions thereof (including the breach, termination, enforcement, interpretation or validity thereof); the Zip Run Platform; Content currently or previously available on or through the Zip Run Platform; any Products currently or previously available on the Zip Run Platform; your relationship with Zip Run; your User Data; the threatened or actual suspension, deactivation, or termination of your Account or this Agreement; payments made by you or any payments made or allegedly owed to you; any Promotions, referral benefits, or other offers; and any other federal and state statutory and common law claims (collectively, “Disputes”) will be settled by binding arbitration, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights. All disputes concerning the arbitrability of a Dispute (including disputes about the scope, applicability, enforceability, legality, revocability, or validity of the Arbitration Agreement) will be decided by the arbitrator, except as expressly provided herein.
      </p>
      <p>
        YOU UNDERSTAND, ACKNOWLEDGE, AND AGREE THAT YOU AND ZIP RUN ARE EACH WAIVING THE RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL FOR ANY AND ALL DISPUTES, EXCEPT AS EXPRESSLY OTHERWISE PROVIDED IN THIS ARBITRATION AGREEMENT.
      </p>
      <div className={styles.subHead}>Prohibition Of Class Actions And Non-individualized Relief</div>
      <p>
        You acknowledge and agree that you and Zip Run are each waiving the right to a trial by jury or to participate as a plaintiff or class in any purported class, collective, or consolidated proceeding. Further, unless both you and Zip Run otherwise agree in writing, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of any class, collective, consolidated or representative proceeding.
      </p>
      <div className={styles.subHead}>Arbitration Rules And Governing Law</div>
      <p>
        The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the Consumer Arbitration Rules (the “AAA Rules”) then in effect, except as modified by this Agreement. The AAA Rules are available at adr.org or by calling the AAA at 1-800-778-7879 or any subsequent applicable telephone number. The Federal Arbitration Act (“FAA”) will govern the interpretation and enforcement of this Arbitration Agreement. It is your and Zip Run’s intent that the FAA and AAA Rules will preempt all state laws to the fullest extent permitted by law. If the FAA and AAA Rules are found to not apply to any issue that arises under this Arbitration Agreement or the enforcement thereof, then that issue will be resolved under the laws of the State of Massachusetts. This Agreement governs to the extent it conflicts with the AAA Rules or FAA.
      </p>
      <div className={styles.subHead}>Arbitration Process</div>
      <p>
        A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. The AAA provides a form Demand for Arbitration and a separate form for Massachusetts residents at adr.org. The arbitrator will be either a retired judge or an attorney licensed to practice law in the state of Massachusetts and will be selected by the parties from the AAA’s roster of consumer dispute arbitrators. If the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the AAA Rules.
      </p>
      <div className={styles.subHead}>Arbitration Location And Procedure</div>
      <p>
        Unless you and Zip Run otherwise agree, the arbitration will be conducted in the county where you reside. If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of documents you and Zip Run submit to the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration.
      </p>
      <div className={styles.subHead}>Arbitrator’s Decision</div>
      <p>
        The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s award damages must be consistent with the terms of the “Limitation of Liability” section above as to the types and the amounts of damages for which a party may be held liable. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. The prevailing party in arbitration will be entitled to an award of attorneys’ fees and expenses, to the extent provided under applicable law.
      </p>
      <div className={styles.subHead}>Fees</div>
      <p>
        Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules.
      </p>
      <div className={styles.subHead}>Severability</div> 
      <p>
        If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any reason, (1) the unenforceable or unlawful provision will be severed from this Arbitration Agreement, (2) severance of the unenforceable or unlawful provision will have no impact on the remainder of the Arbitration Agreement or the parties’ ability to compel arbitration of any remaining claims on an individual basis, and (3) to the extent that any Disputes must therefore proceed on a class, collective, consolidated or representative basis, such Disputes must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims will be stayed pending the outcome of any individual claims in arbitration. In any case in which the Dispute proceeds on a class, collective, consolidated or representative basis and there is also a final judicial determination that this Arbitration Agreement is unenforceable as to a portion (but not all) of such Dispute, then such portion of the Dispute will be severed from any remaining claims and the remainder will be enforced in arbitration on an individual basis as to all other Disputes to the fullest extent possible. Except as otherwise provided herein, this “Arbitration Agreement” section will survive any termination of this Agreement.
      </p>
      <div className={styles.subHead}>Changes</div>
      <p>
        Notwithstanding the provisions of the modification-related provisions above, if Zip Run changes this Arbitration Agreement after the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement), you may reject any such change by sending us a notice to support@ziprun.com with “Legal” in the subject line within 30 days of the date such change became effective, as indicated above or in the date of Zip Run’s email to you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any Dispute between you and Zip Run in accordance with the provisions of this Arbitration Agreement as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement). If you do not opt out by the deadline, you are agreeing to the arbitration process as set forth in the new Agreement.
      </p>
      <h3 className={styles.sectionHdr}>MISCELLANEOUS</h3>
      <div className={styles.subHead}>Indemnification</div>
      <p>
        By entering into this Agreement and using the Zip Run Platform, you agree to defend, indemnify and hold Zip Run and its officers, directors, employees, attorneys and agents harmless from and against any and all third-party claims, costs, damages, losses, liabilities and expenses (including attorneys’ fees and costs) arising out of or in connection with: (i) your violation or breach of any term of this Agreement, the Zip Run Terms, or any applicable law or regulation, whether or not referenced herein; (ii) your violation of any rights of any third party, including other Users, Retailers, or other Third-Party Providers; and (iii) your use or misuse of the Zip Run Platform or Products available therein.
      </p>
      <div className={styles.subHead}>Disclaimer Of Warranties</div>
      <p>
        ZIP RUN MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE ZIP RUN PLATFORM. ZIP RUN DOES NOT REPRESENT OR WARRANT THAT: (A) THE USE OF THE ZIP RUN PLATFORM WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, APPLICATION, SYSTEM OR DATA, (B) THE ZIP RUN PLATFORM WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE ZIP RUN PLATFORM WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS IN THE ZIP RUN PLATFORM WILL BE CORRECTED, OR (F) THE ZIP RUN NETWORK OR OTHER ASPECTS OF THE ZIP RUN PLATFORM ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE ZIP RUN PLATFORM IS PROVIDED TO YOU STRICTLY ON AN “AS IS” BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY ZIP RUN. ZIP RUN MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF ANY SERVICES, PRODUCTS OR GOODS OBTAINED BY THIRD PARTIES THROUGH THE USE OF THE ZIP RUN PLATFORM. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE ZIP RUN PLATFORM, AND ANY THIRD-PARTY SERVICES OR PRODUCTS REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED BY LAW.
      </p>
      <p>
        BY USING THE ZIP RUN PLATFORM, YOU ACKNOWLEDGE AND AGREE THAT YOU MAY BE INTRODUCED TO A THIRD PARTY THAT MAY POSE HARM OR RISK TO YOU OR OTHER THIRD PARTIES. YOU ARE ADVISED TO TAKE REASONABLE PRECAUTIONS WITH RESPECT TO INTERACTIONS WITH THIRD PARTIES ENCOUNTERED IN CONNECTION WITH THE USE OF THE ZIP RUN PLATFORM.
      </p>
      <div className={styles.subHead}>Delays</div>
      <p>
        The Zip Run Platform may be subject to limitations, delays, and other problems inherent in the use of the internet and electronic communications. Zip Run does not guarantee the availability or uptime of the Zip Run Platform. You acknowledge and agree that the Zip Run Platform may be unavailable at any time and for any reason (e.g., due to scheduled maintenance or network failure). Further, the delivery services provided by Retailers, Delivery Service Providers or Zip Run may be subject to limitations, delays, and other problems inherent in physical delivery, and neither Zip Run nor Third-Party Providers are responsible for any delays, delivery failures, or other damages, liabilities, or losses resulting from such problems.
      </p>
      <div className={styles.subHead}>Limitation Of Liability</div>
      <p>
        IN NO EVENT SHALL ZIP RUN, INCLUDING ITS SUCCESSORS AND ASSIGNS, AND EACH OF OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR SHAREHOLDERS (COLLECTIVELY “ZIP RUN” FOR PURPOSES OF THIS SECTION), BE LIABLE TO YOU OR ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING, WITHOUT LIMITATION, PERSONAL INJURY, LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE). ZIP RUN SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE OR INJURY WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO LOSS, DAMAGE, OR INJURY ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, THE ZIP RUN PLATFORM, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE ZIP RUN PLATFORM, ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY RETAILER, PAYMENT VENDOR, OR OTHER THIRD-PARTY PROVIDER WHOSE ADVERTISING APPEARS ON THE ZIP RUN PLATFORM OR IS REFERRED THROUGH THE ZIP RUN PLATFORM, EVEN IF ZIP RUN HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>
      <p>
        ZIP RUN MAY INTRODUCE YOU TO RETAILERS, DELIVERY SERVICE PROVIDERS OR OTHER THIRD-PARTY PROVIDERS FOR THE PURPOSES OF PROVIDING DELIVERY SERVICES. ZIP RUN WILL NOT ASSESS THE SUITABILITY, LEGALITY, OR ABILITY OF ANY RETAILER, DELIVERY SERVICE PROVIDER OR OTHER THIRD-PARTY PROVIDER AND YOU EXPRESSLY WAIVE AND RELEASE ZIP RUN FROM ANY AND ALL ANY LIABILITY, CLAIMS OR DAMAGES ARISING FROM OR IN ANY WAY RELATED TO SUCH THIRD PARTIES. YOU ACKNOWLEDGE THAT RETAILERS OR DELIVERY SERVICE PROVIDERS PROVIDING DELIVERY SERVICES REQUESTED THROUGH THE ZIP RUN PLATFORM MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. ZIP RUN WILL NOT BE A PARTY TO DISPUTES, NEGOTIATIONS OF DISPUTES BETWEEN YOU AND ANY THIRD PARTIES. IN ADDITION, ZIP RUN CANNOT AND WILL NOT PLAY ANY ROLE IN MANAGING PAYMENTS BETWEEN YOU AND PAYMENT VENDOR OR OTHER THIRD-PARTY PROVIDERS. RESPONSIBILITY FOR THE DECISIONS YOU MAKE REGARDING SERVICES OFFERED VIA THE ZIP RUN PLATFORM (WITH ALL ITS IMPLICATIONS) RESTS SOLELY WITH YOU.
      </p>
      <p>
        CERTAIN JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES AND DAMAGES. ACCORDINGLY, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
      </p>
      <div className={styles.subHead}>Notice</div>
      <p>
        Zip Run may give notice by means of a general notice on the Zip Run Platform, email, telephone, text message or by written communication sent by first class mail or pre-paid post to your address on record in your Account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email). You may give notice to Zip Run (such notice shall be deemed given when received by Zip Run) at any time by sending an email to: support@ziprun.com. Please specify the reason for the email in the subject line so it can be forwarded to the proper department.
      </p>
      <div className={styles.subHead}>Assignment</div>
      <p>
        This Agreement may not be assigned by you without the prior written approval of Zip Run but may be assigned without your consent by Zip Run to: (i) a parent or subsidiary; (ii) an acquirer of assets; (iii) a successor by merger; or (iv) any third party that assumes Zip Run’s rights and obligations under this Agreement. Any purported assignment in violation of this section shall be void.
      </p>
      <div className={styles.subHead}>Export Control</div>
      <p>
        You agree to comply fully with all U.S. and foreign export laws and regulations to ensure that neither the Zip Run Platform nor any technical data related thereto nor any direct product thereof is exported or re-exported directly or indirectly in violation of, or used for any purposes prohibited by, such laws and regulations. By using the Zip Run Platform, you represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
      </p>
      <div className={styles.subHead}>Term For Cause Of Action</div>
      <p>
        You and Zip Run agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Zip Run Platform or the Zip Run Terms must be filed within one (1) year after such claim or cause of action arose. Otherwise such a claim or cause of action is permanently barred.
      </p>
      <h3 className={styles.sectionHdr}>GENERAL</h3>
      <p>
        No joint venture, partnership, employment, or agency relationship exists between you, Zip Run, or any third party as a result of this Agreement or use of the Zip Run Platform. If any provision of the Agreement is held to be invalid or unenforceable, you and Zip Run agree that such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law. The failure of Zip Run to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Zip Run in writing.
      </p>
      <p>
        This Agreement (including the Zip Run Terms) constitutes the entire agreement between you and Zip Run and governs your use of the Zip Run Platform, superseding any prior agreements between you and Zip Run. This Agreement and the relationship between you and Zip Run shall be governed by the laws of the State of Massachusetts without regard to its conflict of law provisions. In the event that either you or Zip Run commence a court action, any such action shall be brought exclusively in the state or federal courts located in Suffolk County, Massachusetts, and you and Zip Run both submit to personal jurisdiction there. The section titles or headings in this Agreement are for convenience only and have no legal or contractual effect. A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
      </p>
    </div>
  ) 
}

export default ZipRunTermsOfService;