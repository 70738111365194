import * as React from 'react';

import { ModalType } from './ModalProvider';
import DisplayModal from '../common/DisplayModal';
import SecondaryHeader from '../styleguide/SecondaryHeader';
import GoogleMap from '../googlemaps/GoogleMap';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

const DispensaryMapModal = ({ dispensaryName, formattedAddress, displayModal }) => {
  return (
    <DisplayModal title="Dispensary Location" closeFn={() => { displayModal(ModalType.NONE) }}>
      <ErrorBoundary>
        <SecondaryHeader isCentered text={dispensaryName} />
        <div style={{textAlign:'center'}}>
          {formattedAddress}
        </div>
        <GoogleMap address={formattedAddress} />
      </ErrorBoundary>
    </DisplayModal>
  );
};

DispensaryMapModal.propTypes = {
  dispensaryName: PropTypes.string.isRequired, 
  formattedAddress: PropTypes.string.isRequired,
  displayModal: PropTypes.func.isRequired 
}

export default DispensaryMapModal;