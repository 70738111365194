import * as React from 'react';

import { getFormattedAddress } from '../googlemaps/google-maps-utils';
import { formatPhoneNumber } from 'react-phone-number-input';
import { getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils'; 
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import { OrderType } from '../checkout/order-utils';
import PropTypes from 'prop-types';

import styles from './OrderConfirmation.module.css';

const OrderDispensaryInfo = ({
  dispensary={},
  order={}
}) => {  
  
  const { name, location, tel_number } = dispensary;
  const { delivery_schedule={}, pickup_time_slot } = order;
  
  // TODO: Fix after API update
  const schedule = delivery_schedule.window ? delivery_schedule : pickup_time_slot;
  const timeslot = schedule?.window || schedule?.hours || '';

  // For pickup only
  let readyBy = '';
  if (order.type === OrderType.PICKUP && timeslot.start_hour) {
    readyBy = getFormattedTimeLabel(timeslot.start_hour, timeslot.start_minute);
  }

  let intlNumber = tel_number; 
  if (intlNumber && !intlNumber.startsWith('+')) {
    intlNumber = `+${intlNumber}`;   
  }  
  
  const formattedPhoneNumber = intlNumber ? formatPhoneNumber(intlNumber) : '';
  const formattedAddress = getFormattedAddress(location);

  return ( dispensary.name 
    ? <> 
        <div>{name}
        { readyBy
          ? <> &bull; Ready by {readyBy}</>
          : null
        }
        </div>
        <div>{formattedAddress}</div>
        <div>
          <a className={styles.telLink} href={`tel:${intlNumber}`}>
            <span className={styles.mobileCall}>
              <PhoneRoundedIcon fontSize='inherit' /> 
            </span>
            {formattedPhoneNumber}
          </a>
        </div>
      </>
    : null
  )    
}

OrderDispensaryInfo.propTypes = {
  dispensary: PropTypes.object,
  order: PropTypes.object    /* for pickup */
}

export default OrderDispensaryInfo;