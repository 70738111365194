import * as React from 'react';

import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { FilteredProductsContext } from '../products/FilteredProductsProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { navigateTop } from '../routing/router-utils';
import useMenuNavigation from '../routing/useMenuNavigation';
import { trackEvent } from '../analytics/tracking';
import Header, { HeaderSize } from '../styleguide/Header';
import AddedToCartCheck from '../products/AddedToCartCheck';
import ProductPreviewCard from '../products/ProductPreviewCard';
import SimpleCarousel from '../common/SimpleCarousel';
import PropTypes from 'prop-types';

import styles from './ProductSection.module.css';

/**
 * This component will display a grid of category-specific products for a dispensary 
 * 
 * @param {string} productCategoryName - The category name
 * @param {number} categoryCount - The total number of category products
 * @param {object} searchResultProducts - The category products (subset for display)
 */
const ProductSection = ({
  productCategoryName,
  categoryCount=0,
  searchResultProducts=[]
}) => {

  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { setCategory } = React.useContext(FilteredProductsContext);
  const { addItem } = React.useContext(CartItemsContext);

  const menuNavigateTop = useMenuNavigation();

  const [showAddedCheck, setShowAddedCheck] = React.useState();

  const handleAddToCart = React.useCallback((productId, productObj, quantity) => {
    trackEvent('product_preview_add_to_cart', productCategoryName, productId);
    setShowAddedCheck(true);
    addItem(productId, productObj, quantity);
  }, [addItem, productCategoryName]);
 
  const dispensaryClick = React.useCallback(() => {
    setCategory(productCategoryName);
    menuNavigateTop(`/menu/${dispensaryId}/products`);
  }, [dispensaryId, setCategory, menuNavigateTop]);

  const [itemProps, setItemProps] = React.useState();
 
  React.useEffect(() => {
    const getPropArray = (products) => {
      const propArray = [];
      products.forEach(product => {
        propArray.push({
          itemKey: product.id,
          Id: product.id,
          Name : product.display_info.name,
          image_url: product.display_info.image_url,
          is_featured: !!product.is_featured,
          cost_usa_cents: product.display_info.cost_usa_cents,
          brand: product.display_info.brand,
          category: product.display_info.category,
          cannabis_type: product.display_info.cannabis_type,
          thc_lab_value: product.thc_lab_value,
          thc_lab_units: product.thc_lab_units,
          cbd_lab_value: product.cbd_lab_value,
          cbd_lab_units: product.cbd_lab_units,
          dispensaryId: dispensaryId,
          handleClick: () => {
            navigateTop(
              `/menu/${dispensaryId}/product/${product.id}`,
              { state: { returnToPath: `/menu/${dispensaryId}` }}
            );
          },
          handleCartClick: (quantity) => { handleAddToCart(product.id, product, quantity) }
        });
      });
      return propArray;
    }
    if(!itemProps) {
      setItemProps(getPropArray(searchResultProducts));
    }
  }, [dispensaryId, handleAddToCart, itemProps, searchResultProducts]);

  return ( dispensaryId && searchResultProducts.length
    ? <>
        <div className={styles.sectionHeader}>
          <Header size={HeaderSize.Medium}>
            <span className={styles.dispensaryName}>{`Popular ${productCategoryName}`}</span>
            <span className={styles.seeAllLink} onClick={dispensaryClick}>
              View All <span className={styles.viewAllDetails}>{productCategoryName} ({categoryCount})</span> 
            </span>
          </Header>  
        </div>
        
        { itemProps?.length &&
          <SimpleCarousel
            CarouselComponent={ProductPreviewCard}
            carouselItemProps={itemProps}
            maxItemCount={8}
            trackingLabel="home" />
        }
        { showAddedCheck && 
          <AddedToCartCheck
            key={`chk${dispensaryId}`} 
            closeFn={() => { 
              setShowAddedCheck(false);
            }}  
            closeMs={2000}
          />
        }
      </>
    : null
  );
}

ProductSection.propTypes = {
  productCategoryName: PropTypes.string.isRequired,
  categoryCount: PropTypes.number.isRequired,
  searchResultProducts: PropTypes.array.isRequired
};

export default ProductSection;
