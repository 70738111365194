import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { fetchProductCategories } from '../dispensary/dispensary-utils';
import { trackEvent } from '../analytics/tracking';

export const ProductCategoryContext = React.createContext();

export const DEFAULT_PRODUCT_CATEGORY = { 
  name:'vaporizers', 
  display_name:'Vaporizers'
};

const ProductCategoryProvider = ({children}) => {
  
  const { user } = React.useContext(UserContext);
  const [productCategories, setProductCategories] = React.useState();
  const [defaultCategory, setDefaultCategory] = React.useState(DEFAULT_PRODUCT_CATEGORY);

  // e.g. get category based on path: /Flower
  const getCategoryFromDisplayName = (displayName) => {
    return productCategories?.length
      ? productCategories.find((category) => category.display_name === displayName)
      : null
  };

  const callback = React.useCallback((response) => {
    if (response.data.length) {
      const categories = response.data;
      /* SORT CATEGORIES based on .sort_order */
      try {
        // handle missing .sort_order
        categories.forEach((category) => {
          if (typeof category.sort_order !== 'number') {
            category.sort_order = 99;
          }  
        });
        categories.sort((catA, catB) => catA.sort_order - catB.sort_order);
        setDefaultCategory(categories[0]);
      } catch(e) {
        trackEvent('error_fetching_product_categories');  
      }
      setProductCategories(categories);
    }
  }, []);
 
  const abortControllerRef = React.useRef(new AbortController());
  
  // Fetch Dispensaries
  React.useEffect(() => {
    const controller = abortControllerRef.current;
    // Fetch the product categories for Shop by Product
    if (user && !productCategories) {
      fetchProductCategories(callback, controller);
    }
    // return () => controller.abort();
  }, [user, productCategories, callback, abortControllerRef]);

  return <ProductCategoryContext.Provider value={{
      productCategories,
      defaultCategory,
      getCategoryFromDisplayName
    }}>
    {children}
  </ProductCategoryContext.Provider>
}

export default ProductCategoryProvider;