import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import ErrorIcon from '@material-ui/icons/Error'; 

import styles from './YourOrders.module.css';

const ActiveOrdersWarning = () => {
  return (
    <div className={styles.activeOrdersWarning}>
      <ErrorIcon /> Note: You currently have active orders and won't be able to 
      update your account until <span className={styles.yourOrdersLink} onClick={() => navigateTop('/your/orders')}>Your Orders</span> 
      {' '} are complete (Picked up/Delivered). 
    </div>
  )
}

export default ActiveOrdersWarning;