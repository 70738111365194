
// Queue functions to run after a non-anonymous user session is restored (page reload, inactivity etc.)
// e.g. for Your Account page where real user dataq is required.
const sessionRestoreQueue = [];

export const queueForSessionRestore = (onSessionRestore) => {
  sessionRestoreQueue.push(onSessionRestore);
}

export const executeSessionRestoreQueue = (user) => {
  if (user && !user.isAnonymous) {
    while (sessionRestoreQueue.length) {
      console.log('running session restore function');
      const queuedFn = sessionRestoreQueue.shift();
      queuedFn();
    }
  } 
}

// Queue functions for new Session ( e.g. anonymous user creation )
const sessionReadyQueue = [];

export const queueForSessionReady = (onSessionReady) => {
  sessionReadyQueue.push(onSessionReady);
}

export const executeSessionReadyQueue = (user) => {
  if (user) {
    while (sessionReadyQueue.length) {
      console.log('running session ready function');
      const queuedFn = sessionReadyQueue.shift();
      queuedFn();
    }           
  } 
}
