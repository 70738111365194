import React from 'react';
import PropTypes from 'prop-types';

import styles from './Stylesheet.module.css';

const BasicButton = ({text, isCentered=false, withStyle={}, handleClick}) => {
  
  const buttonClasses = `${styles.button} ${styles.basicButton}`;
  const centerStyle = isCentered ? { display:'block', marginLeft:'auto', marginRight: 'auto' } : {};
 
  const buttonStyle = Object.assign(centerStyle, withStyle); 

  return (
    <button 
      className={buttonClasses}
      style={buttonStyle}
      onClick={handleClick}
    >
      {text} 
    </button>
  );
};

BasicButton.propTypes = {
  text: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  handleClick: PropTypes.func
}

export default BasicButton;