import React from 'react';

import emptyBag from '../../assets/emptyCartBag.png';
import styles from './EmptyCart.module.css';

const EmptyCart = ({isInMenu, dispensaryLogo}) => {

  const emptyBagStyle = isInMenu ? styles.inMenu : styles.inLayout;

  return (
    <div className={`${styles.emptyBag} ${emptyBagStyle}`}>
      <img alt="" src={emptyBag} className={styles.bagImage} />
      { dispensaryLogo && 
        <img alt="" src={dispensaryLogo} className={styles.bagLogo} />
      }
      <div>Your bag is empty</div>
      <div>Add Items to get started</div>
    </div>
  )
}

export default EmptyCart;