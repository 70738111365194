import React from 'react';

import { CurrentTimeContext } from '../timeSlots/CurrentTimeProvider';
import { PickupSlotsContext, STORE_NOT_OPEN_YET } from './PickupSlotsProvider';
import { daysOfWeek, getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getOrderDate } from './timeslot-utils';
import SelectDayCarousel from './SelectDayCarousel';
import SelectTimeSlot from './SelectTimeSlot';
import StoreNotOpenBanner from '../dispensary/StoreNotOpenBanner';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

const DisplayPickupSlots = ({
  dispensaryId,
  dispensaryName
}) => {

  // Time to use when deterimining what to display
  const currentTimeMS = React.useContext(CurrentTimeContext);
  const dayOfWeek = new Date(currentTimeMS).getDay();

  // Valid timeSlots to display
  const {timeSlotsByDay, defaultSlot, setSelectedPickupSlot} = React.useContext(PickupSlotsContext);
  
  // The indexes used to build the timeslot JSON and pass to redux 
  const [dayIdx, setDayIdx] = React.useState(0);
  const [slotIdx, setSlotIdx] = React.useState();
  // The current day (tab) we're viewing
  const [displayDayIdx, setDisplayDayIdx] = React.useState(0);

  // Set the default/updated selection in redux.
  const updateProvider = React.useCallback((slotIdx, dayIdx) => {
    const timeSlots = timeSlotsByDay[dayIdx];
    if (timeSlots) {
      const { hours } = timeSlots[slotIdx];
      // set date for calcing date plus future offset    
      const orderDate = new Date();
      orderDate.setDate(orderDate.getDate() + dayIdx);
      const date = {
        month: orderDate.getMonth() + 1,
        day: orderDate.getDate(),
        year: orderDate.getYear() + 1900
      };
      setSelectedPickupSlot({ date, hours });
    }
  }, [timeSlotsByDay, setSelectedPickupSlot]);

  const handleSlotSelect = (slotIdx) => {
    // update UI
    setDayIdx(displayDayIdx);
    setSlotIdx(slotIdx);
    updateProvider(slotIdx, displayDayIdx);
  };
 
  const getDayLabel = (offset) => {
    if (offset === 0) {
      return 'TODAY';
    }
    const weekdayNum = (dayOfWeek + offset) % 6;
    const label = daysOfWeek[weekdayNum];
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  React.useEffect(() => {
    // initialize using the provider values
    if (timeSlotsByDay.length && typeof defaultSlot === 'number') {
      // No available same-day slots
      if (defaultSlot === -1) {
        setSlotIdx(0);
        setDayIdx(1);
        setDisplayDayIdx(1);
        updateProvider(0, 1);
      } else { 
        setSlotIdx(defaultSlot);
        setDayIdx(0);
        setDisplayDayIdx(0);
        updateProvider(defaultSlot, 0);  
      }
    }
    // NEA: Handle store not open yet, ordering allowed only during biz hours
    if (defaultSlot === STORE_NOT_OPEN_YET) {
      setSlotIdx(STORE_NOT_OPEN_YET);
    }

    // dayIdx
  }, [defaultSlot, updateProvider, timeSlotsByDay]);

  return (
    <div className={styles.timeslotWrap}>
      { typeof slotIdx === 'number' && (
         /* Same-day ordering only --past store closing */
         (timeSlotsByDay.length === 1 && dayIdx === 1) || 
         /* Same-day ordering only --store not open yet */
         (timeSlotsByDay.length === 0 && slotIdx === STORE_NOT_OPEN_YET))
        ? <StoreNotOpenBanner dispensaryName={dispensaryName} />
        : typeof slotIdx === 'number' && timeSlotsByDay.length > 0
          ? <>
              <div className={styles.selectedSlotLabel}>
                <span>{`${getDayLabel(dayIdx)}, ${getOrderDate(dayIdx, currentTimeMS)}, ${getDeliveryWindowLabel(timeSlotsByDay[dayIdx][slotIdx].hours) }`}</span>
              </div>
  
              <SelectDayCarousel
                displayDayIdx={displayDayIdx}
                displayDayLabelIdx={dayOfWeek}
                timeSlotsByDay={timeSlotsByDay}
                currentTimeMS={currentTimeMS}
                handleDayClick={setDisplayDayIdx} />
  
              <SelectTimeSlot
                dayIdx={dayIdx}
                slotIdx={slotIdx}
                displayDayIdx={displayDayIdx}
                timeSlots={timeSlotsByDay[displayDayIdx]}
                currentTimeMS={currentTimeMS}
                handleSelect={handleSlotSelect} />
            </>
          : <Spinner />
      }
    </div>
  )
}

DisplayPickupSlots.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired
};

export default DisplayPickupSlots;