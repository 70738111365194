import * as React from "react";

import DisplayModal from '../common/DisplayModal';
import PasswordReset from '../registration/PasswordReset';
import BasicButton from '../styleguide/BasicButton';
import PropTypes from 'prop-types';

/**
 * A small form that includes a button to display the PasswordReset Modal 
 */
const ChangePasswordForm = ({userEmail}) => {
  
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>    
      <div>
        <BasicButton text="Change Password" handleClick={() => setShowModal(true) } />
      </div>
      
      { showModal && 
        <DisplayModal title="Change Password" closeFn={() => setShowModal(false)}>
          <PasswordReset userEmail={userEmail} />
        </DisplayModal>  
      }
    </>
  );
}

ChangePasswordForm.propTypes = {
  userEmail: PropTypes.string,
};

export default ChangePasswordForm;