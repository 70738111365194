import React from 'react';

import config from '../../config';
import { CommerceLoggingContext, SHOPPING_MILESTONES } from '../analytics/CommerceLoggingProvider';
import PickupSlotsProvider from '../timeSlots/PickupSlotsProvider';
import { UserContext } from '../providers/UserProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { useDistanceMatrix, getFormattedAddress } from '../googlemaps/google-maps-utils';
import { formatPhoneNumber } from 'react-phone-number-input';
import useIntercomLogging from '../intercom/useIntercomLogging';
import PlaceOrderItems from '../checkout/PlaceOrderItems';
import CurrentTimeProvider from '../timeSlots/CurrentTimeProvider';
import DisplayPickupSlots from '../timeSlots/DisplayPickupSlots';
import OrderPickupButton from './OrderPickupButton';
import RegisteredUserInfo from '../checkoutUserFlow/RegisteredUserInfo';
import GuestForm from '../checkoutUserFlow/GuestForm';
import SignInInline from '../registration/SignInInline';
import OrderPickupAsGuest  from './OrderPickupAsGuest';
import DisplayBulkDiscount from '../checkout/DisplayBulkDiscount';
import Header, { HeaderSize } from '../styleguide/Header';
import ActionLink from '../styleguide/ActionLink';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import styles from '../checkout/PlaceOrder.module.css';

const PlaceOrderPickup = ({
  currentUser,
  dispensaryId,
  dispensaryName,
  dispensaryTelNumber,
  dispensaryLocation,
  minPickupOrder,
  handleSignUpClick
}) => {
  
  const { user, location:delivery_address } = React.useContext(UserContext);
  const { itemTotal } = React.useContext(CartItemsContext);
  const { logCommerceAction } = React.useContext(CommerceLoggingContext);
 
  const [showSignIn, setShowSignIn] = React.useState();

  // Disable AeroPay - handling guest checkout would complicate flow
  const aeroPayEnabled = config.AEROPAY_ENABLED;

  const [distance, setDistance] = React.useState();
  const [pickupAddress, setPickupAddress] = React.useState();
  const [noDiscountFound, setNoDiscountFound] = React.useState();
  const [dispensaryPhone, setDispensaryPhone] = React.useState();

  const userAddress = getFormattedAddress(delivery_address);
  const dispensaryAddress = getFormattedAddress(dispensaryLocation);

  // Launching with pay-in-store
  const [payInStore, setPayInStore] = React.useState(!aeroPayEnabled); 

  // Google Maps API
  useDistanceMatrix(userAddress, dispensaryAddress, setDistance);

  const OrderSection = ({title, children}) => {
    return (
      <ErrorBoundary>
        <div className={styles.section}>
          { title && (
            <div className={styles.sectionTitle}>{title}</div>
          )}
          <div className={styles.sectionInner}>
            {children}
          </div>
        </div>
      </ErrorBoundary>
    );
  }
  
  React.useEffect(() => {
    const dispensaryAddress = getFormattedAddress(dispensaryLocation);
    setPickupAddress(dispensaryAddress);
  }, [dispensaryLocation]);  

  React.useEffect(() =>{
    if(dispensaryTelNumber) {
      setDispensaryPhone(
        dispensaryTelNumber.indexOf('+') !== -1 ? dispensaryTelNumber : `+${dispensaryTelNumber}`
      );   
    }
  }, [dispensaryTelNumber]);

  // Ensure bulk discount recalc
  React.useEffect(() => {
    setNoDiscountFound(false);  
  }, [itemTotal]);

  React.useEffect(() => {
    // User has attempted to view checkout
    logCommerceAction(SHOPPING_MILESTONES.CHECKOUT_VIEWED); 
  }, [logCommerceAction]);

  // Track user starting checkout
  useIntercomLogging(user, 'Checkout_Pickup', true);

  const iconStyles = {
    verticalAlign: '-6px'
  };

  return (
    <CurrentTimeProvider>
      <PickupSlotsProvider dispensaryId={dispensaryId}>
        <OrderSection>
          <Header size={HeaderSize.Large} isCentered text='Checkout' />
        </OrderSection>
  
        <OrderSection title="CUSTOMER">
          { currentUser && !currentUser.isAnonymous
            ? <RegisteredUserInfo registeredUser={currentUser} />
            : showSignIn
              ? <SignInInline onCancel={() => setShowSignIn(false)} /> 
              : <GuestForm 
                  signInClick={() => setShowSignIn(true)}
                  signUpClick={handleSignUpClick} />
          } 
        </OrderSection>
  
        <OrderSection title="ORDER TYPE">
          <div>In-store Pickup</div>
        </OrderSection>

        <OrderSection title="PAYMENT">
          <>
            { aeroPayEnabled &&
              <div className={styles.radioWrapper} onClick={() => setPayInStore(false)}>
                <input id="payNow" name="paymentType" type="radio" value="payNow" defaultChecked={!payInStore} />
                <label htmlFor="payNow">Pay using your AeroPay-linked bank account</label>
              </div>
            }
            <div className={styles.radioWrapper} onClick={() => setPayInStore(true)}>
              <input id="payInStore" name="paymentType" type="radio" value="payInStore" defaultChecked={payInStore}/>
              <label htmlFor="payInStore">Pay in-store</label>
            </div>
          
            <div className={styles.paymentMethods}>
              <span className={styles.paymentLabel}>Accepted Payment Methods:</span>
              <span className={styles.paymentValues}>
                <span className={styles.paymentValue}>
                  Cash&nbsp;<LocalAtmIcon style={iconStyles} />
                </span>  
                <span className={styles.paymentValue}>
                  Debit&nbsp;Card&nbsp;<CreditCardIcon style={iconStyles} />
                </span>
              </span>
            </div>
          </>
        </OrderSection>
        
        <OrderSection title="PICKUP TIME">
          <ErrorBoundary>
            <DisplayPickupSlots dispensaryId={dispensaryId} dispensaryName={dispensaryName} />
          </ErrorBoundary>
        </OrderSection>

        <OrderSection title="ADDRESS">
          <span>
            { pickupAddress &&
              `${pickupAddress}`
            }
          </span>
          { distance &&
            <b>{` (${distance}`})</b>
          }
        </OrderSection>
      
        <OrderSection title="PHONE NUMBER">
          <ActionLink text={formatPhoneNumber(dispensaryPhone)} href={`tel:${dispensaryPhone}`} />
        </OrderSection>
        
        <OrderSection title="SUMMARY">
          <PlaceOrderItems />
        </OrderSection>
        { !noDiscountFound &&
          <OrderSection title="BULK DISCOUNTS">
            <DisplayBulkDiscount handleNoDiscount={setNoDiscountFound} />
          </OrderSection>
        }
        <OrderSection>
          { currentUser && !currentUser.isAnonymous
            ? <OrderPickupButton
                dispensaryId={dispensaryId}
                dispensaryName={dispensaryName}
                minPickupOrder={minPickupOrder}
                isPayInStore={payInStore} />
            : <OrderPickupAsGuest  
                dispensaryId={dispensaryId}
                dispensaryName={dispensaryName}
                minPickupOrder={minPickupOrder}
              />
          }    
        </OrderSection>
      </PickupSlotsProvider>
    </CurrentTimeProvider>
  )
}

PlaceOrderPickup.propTypes = {
  currentUser: PropTypes.object,
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  dispensaryTelNumber: PropTypes.string.isRequired,
  dispensaryLocation: PropTypes.object.isRequired,
  minPickupOrder: PropTypes.number.isRequired,
  handleSignUpClick: PropTypes.func.isRequired
};

export default PlaceOrderPickup;