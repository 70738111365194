import React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import PropTypes from 'prop-types';

import styles from './Header.module.css';

// Various header sizes - TODO use in place of PrimaryHeader, MediumHeader etc.
export const HeaderSize = {
  Large: "large",
  Medium: "medium", 
  Small: "small"
};

const Header = ({text, isCentered, size=HeaderSize.Large, onClick, children}) => {

  const centerStyle = isCentered ?  {textAlign: 'center'} : {};
  const clickStyle = onClick ? styles.clickable : '';

  const { theme } = React.useContext(ThemeContext);

  const themeStyle = Object.assign(centerStyle, {
    fontFamily: theme.fontFamily,
    fontWeight: theme.headerWeight,
    color: theme.textColor
  });

  // Unclickable headers
  const noClick = ()=>{};

  return (
    <div className={`${styles.header} ${styles[size]} ${clickStyle}`} onClick={onClick || noClick} style={themeStyle}>
      {text}
      {children} 
    </div>
  );
};

Header.propTypes = {
  text: PropTypes.string,
  isCentered: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Header;