import * as React from 'react';

import { devLog } from '../util/util';
import { navigateTop } from '../routing/router-utils';
import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { UserContext } from '../providers/UserProvider';
import { messageHandler } from '../embedMenu/embed-utils';
import PropTypes from 'prop-types';

export const NavigationMessageContext = React.createContext();

const NavigationMessageProvider = ({children}) => {
  
  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { user } = React.useContext(UserContext);

  // Placeholder, not implemented
  const sendNavigationMessage = (page) => {
    devLog(`sendNavigationMessage not implemented: ${page}`);
  }; 
 
  // The supported page navigation (hashLink) message values: e.g. somedispensary.com#MenuAccount
  const navigationPaths = {
    MenuHome: `/menu/${dispensaryId}`,
    MenuAccount: `/menu/${dispensaryId}/account`,
    MenuSignIn: `/menu/${dispensaryId}/signIn`
  };

  // Listen to deep link navigation messages from the host page
  React.useEffect(() => {
    const handleNavigationMessages = messageHandler('hashLink', (path) => {
      // Support only known deep link url hashes
      if (navigationPaths[path]) {
        // Account link is gated/special
        if (path === 'MenuAccount') {
          if (user && !user.isAnonymous) {
            navigateTop(navigationPaths[path]);
          } else {
            const stateInfo = { state: {
              returnToPath: navigationPaths['MenuHome'],
              continuePath: navigateTop(navigationPaths[path])
            }};
            navigateTop(`/menu/${dispensaryId}/signIn`, stateInfo);
          }
        } else { 
          navigateTop(navigationPaths[path]);
        }
      }  
    });
    window.addEventListener("message", handleNavigationMessages, false);
    devLog('NavigationMessageProvider render');

    return () =>  { 
      window.removeEventListener("message", handleNavigationMessages);
    };
  }, [user, dispensaryId, navigationPaths]);
  
  return <NavigationMessageContext.Provider value={{
      sendNavigationMessage
    }}>
    {children}
  </NavigationMessageContext.Provider>
}

NavigationMessageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default NavigationMessageProvider;