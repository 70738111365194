import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { fetchDispensary } from '../dispensary/dispensary-utils';
import { trackEvent } from '../analytics/tracking';
import PropTypes from 'prop-types';

// In case we have no valid id
const DISPENSARY_NOT_AVAILABLE = '-1';

export const DispensaryContext = React.createContext();

const DispensaryProvider = ({children}) => {
  
  const { user } = React.useContext(UserContext);
  const { dispensaryId, setDispensaryId } = React.useContext(UrlParamsContext);
  const [dispensary, setLocalDispensary] = React.useState();
  const [dispensaryDomain, setDispensaryDomain] = React.useState();
  const [loading, setLoading] = React.useState();
  
  // Handle missing/disabled dispensaries
  const [dispensaryNotFound, setDispensaryNotFound] = React.useState(false);

  const dispensaryCallback = React.useCallback((response) => {   
    if (response.id) {
      console.log('Dispensary Provider: SETTING');
      setLocalDispensary(response);
      if (response.website_domain) {
        setDispensaryDomain(`https://${response.website_domain}`);  
      }
      setDispensaryNotFound(false);
    } else if (response.status === 404) {
      console.log('Dispensary Not Found');
      setDispensaryNotFound(true);
      setDispensaryId(DISPENSARY_NOT_AVAILABLE);
      trackEvent(`dispensary_not_found_${dispensaryId}`);
    } else {
      console.log('Dispensary Provider Error: non-400');
      setDispensaryNotFound(true);
      setDispensaryId(DISPENSARY_NOT_AVAILABLE); 
      trackEvent(`dispensary_provider_error_${dispensaryId}`);
    }
    setLoading(false);  
  }, [dispensaryId, setDispensaryId, setDispensaryDomain]);

  const controllerRef = React.useRef(new AbortController());

  // Fetch Dispensary  
  React.useEffect(() => {
    const controller = controllerRef.current || new AbortController(); 
    if (user && dispensaryId && !dispensary?.id) {
      setLoading(true);
      fetchDispensary(dispensaryId, dispensaryCallback, controller);
    }
    // cancel fetch on unmount ?
    // return () => controller.abort();
  }, [user, dispensaryId, dispensary, dispensaryCallback]);

  return <DispensaryContext.Provider value={{
     dispensary,
     dispensaryId,
     dispensaryDomain,
     dispensaryNotFound,
     loading
    }}>
    {children}
  </DispensaryContext.Provider>
}

DispensaryProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default DispensaryProvider;