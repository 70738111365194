import * as React from 'react';

import config from '../../config';
import PropTypes from 'prop-types';

export const UrlParamsContext = React.createContext();

const UrlParamsProvider = ({children}) => {
  
  const [dispensaryId, setDispensaryId] = React.useState();
  const [previewMode, setPreviewMode] = React.useState(false);
  const [previewHost, setPreviewHost] = React.useState(false);
  
  // Parse dispensary from url  
  React.useEffect(() => {
    if (!dispensaryId) {
      // Set the dispensary Id
      const Id = document.body.getAttribute('data-dispensary-id') ||
                 window.location.pathname.split('/')[2];
      document.body.setAttribute('data-dispensary-id', Id);
      setDispensaryId(Id);
      
      // Check for Menu Preview in dispensary app
      const hasPreviewParam = new URLSearchParams(window.location.search).get('preview') === '1';
      const isPreview = document.body.getAttribute('data-preview-mode') === 'true' || hasPreviewParam;      
      if (isPreview) {
        document.body.setAttribute('data-preview-mode', true);
        setPreviewMode(true);
      }
      setPreviewHost(`https://${config.DISPENSARY_HOST}`);
    }
  }, [dispensaryId, setDispensaryId]);
  
  return <UrlParamsContext.Provider value={{
      dispensaryId,
      previewMode, /* embedded in dispensary app */
      previewHost, /* dispensary app host */
      setDispensaryId
    }}>
    {children}
  </UrlParamsContext.Provider>
}

UrlParamsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default UrlParamsProvider;
