import * as React from 'react';

import { auth } from '../../firebase';
import { CartItemsContext } from './CartItemsProvider';
import { DeliverySlotsContext } from '../timeSlots/DeliverySlotsProvider';
import { useSelector } from 'react-redux';
import useBankAccountId from '../payment/useBankAccountId';
import useForceAccountLinking from '../payment/useForceAccountLinking';
import { validateAndPlaceOrder } from '../payment/payment-utils';
import { navigateTop } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import { calcServiceFeeCents, calcTaxCents, STANDARD_DELIVERY_FEE, OrderType, submitDispensaryOrder, validateCostCalculation } from './order-utils';
import ErrorMessage from '../styleguide/ErrorMessage';
import SuccessMessage from '../styleguide/SuccessMessage';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import LinkAccountsButton from '../payment/LinkAccountsButton';
import TaxesTooltip from './TaxesTooltip';
import ProgressBar from '../common/ProgressBar';
import aeropayButton from '../../assets/purchaseWithAeroPay.png';
import PropTypes from 'prop-types';

import styles from './OrderButton.module.css';

/**
 * Calculate the total price at checkout for delivery
 * 
 * This includes:
 *   20% Sales tax
 *   ~10% ZipRun Fee
 * 
 * We are gating the "place order" button based on:
 *  1) User must be logged in
 *  2) Minimum product order 
 *  3) Delivery Available for user's zip code 
 *  4) AeroPay linked bank account retrieved
 *  5) Product availability and total price pre-validated
 *  6) All cost components post-order validated 
 */
const OrderDeliveryButton = ({
  dispensaryId,
  dispensaryName, 
  minDeliveryOrder=0
}) => {
  
  const { cartItems, itemTotal, emptyCart } = React.useContext(CartItemsContext);
  const { selecteDeliverySlot } = React.useContext(DeliverySlotsContext);

  const [orderProcessing, setOrderProcessing] = React.useState();
  const [orderResponse, setOrderResponse] = React.useState();
  
  // User completed link account flow
  const newLinkedAccount = React.useRef();
  const controllerRef = React.useRef(new AbortController());
  const [ bankAccountId, bankAccountHint, setBankAccountId ] = useBankAccountId(controllerRef.current); 
  
  const [ testAccountLinking, setTestAccountLinking] = useForceAccountLinking(auth.currentUser?.displayName);

  /**
   * handleNewLinkedAccount
   * 
   * This is the post account linking callback
   * 
   * @param {object} response - the preferred bank account selected by user 
   */
  const handleNewLinkedAccount = (response) => {
    // We want to show the account last4 so we'll fetch full details via setBankAccountId  
    if (response.bankAccountId) {
      // Undo the forced linking flow for test users
      setTestAccountLinking(false);
      // We want to show the account last4 so we'll fetch full details via setBankAccountId 
      setBankAccountId(controllerRef.current);
      // Display linking success message
      newLinkedAccount.current = true;
      //setAccountFetchComplete(false);
      trackEvent('aeropay_checkout', 'account_linked');
    }
  }
  // order total cost components 
  const bulkDiscountCents = useSelector(state => state.bulk_discount_cents);

  const salesTaxPercent = useSelector(state => state.sales_tax_percent);
  const cannabisTaxPercent = useSelector(state => state.cannabis_tax_percent);

  const serviceFeePct = useSelector(state => state.delivery_service_fee_percent);
  const deliveryFeeCents = useSelector(state => state.delivery_fee_cents);

  // total net of any bulk discounts
  const totalAfterDiscounts = itemTotal - bulkDiscountCents;

  // Calc and round fees/taxes
  const feeCents = calcServiceFeeCents(totalAfterDiscounts, serviceFeePct);
  const taxCents = calcTaxCents(totalAfterDiscounts, salesTaxPercent, cannabisTaxPercent);
  
  // Fees and taxes are individually rounded and displayed as a single amount
  const feesAndTaxCents = feeCents + taxCents;
  const totalPrice = (totalAfterDiscounts + feesAndTaxCents + deliveryFeeCents)/100; 
 
  const abortControllerRef = React.useRef(new AbortController());

  // Handle errors from orders that are rejected by the validity endpoint (dispensary closed etc.)
  const handleResponseErrors = (responseMessage) => {
    // We'll get an empty string if there's no errors
    if (responseMessage) {
      // Cancel progress bar 
      setOrderProcessing(false); 
    }
    setOrderResponse(responseMessage);
  }; 

  const placeOrderCallback = (response) => {
    if (response.error) {
      // hide progress bar
      setOrderProcessing(false);
      setOrderResponse(<ErrorMessage text={response.error}/>);
    } else {  
      const { 
        id, 
        dispensary_id,
        type, 
        payment_details,
        items, 
        costs: backEndCosts, 
        fulfillment_time_slot,
        status } = response;
      // Empty the cart once the order is placed
      emptyCart();
      // Track order
      trackEvent('order_requested', 'delivery', parseInt(totalPrice * 100, 10));
      
      // Validate costs using rounded penny amounts 
      const frontEndCosts  = { 
        total: Math.ceil(totalPrice * 100), 
        subtotal: itemTotal - bulkDiscountCents,
        tax: taxCents,
        service_fee: feeCents,
        delivery_fee: deliveryFeeCents,
        discount: 0, /* TODO */
        bulk_discount:  bulkDiscountCents
      };
      
      // Compare API computed price components with Web App calcs
      const costCalcDiffs = validateCostCalculation(frontEndCosts, backEndCosts, OrderType.DELIVERY);
      if ( costCalcDiffs.length ) {
        trackEvent('order_pricing_error', costCalcDiffs.join(','), id);  
      }

      // Hide the progress bar!
      setOrderProcessing(false); 

      // Order details will be cleared from redux so pass via router for validation on confirmation page
      navigateTop(`/menu/${dispensaryId}/orderConfirmation`, {
        state: {
          id,
          dispensary_id,
          type,
          payment_details, 
          items,
          fulfillment_time_slot,
          costs: backEndCosts,
          costCalcDiffs,
          status
        }
      }); 
    }
  };
  
  // Validate and place order if valid
  const placeOrderClick = (event) => {
    event.preventDefault();
    // This will take ~8 seconds or so
    setOrderResponse('');
    // show progress bar 
    setOrderProcessing(true);
    
    validateAndPlaceOrder(
      dispensaryId, 
      false, /* isPickup */
      cartItems, 
      Math.ceil(totalPrice * 100), 
      selecteDeliverySlot,
      {}, /* pickup time slot */
      placeOrder, 
      handleResponseErrors, 
      abortControllerRef.current || new AbortController()  /* do not cancel */
    ); 
  }
  
  // Place order
  const placeOrder = () => {
    const payment_details = {
      processor: "aeropay",
      aeropay_payment_data: {
        bank_account_id: bankAccountId
      }
    };
    submitDispensaryOrder(
      dispensaryId,
      false,  /* !isPickup */
      cartItems,
      selecteDeliverySlot,
      {}, /* pickup time slot */
      payment_details,
      placeOrderCallback,
      abortControllerRef.current || new AbortController()  /* do not cancel */
    );
  }

  React.useEffect(() => {
    // controller for initial useBankAccountId hook
    const bankAccountController = controllerRef.current; 
    return () => bankAccountController?.abort();
  }, []);  
 
  // For display only
  const minOrderDisplay = parseFloat(minDeliveryOrder/100).toFixed(2);
  const itemTotalDisplay = parseFloat(totalAfterDiscounts/100).toFixed(2);
  const minOrderMet = totalAfterDiscounts >= minDeliveryOrder; 

  return (
    <div className={styles.toCheckoutForm}>
      <form>
        <div className={styles.checkoutDetail}>
          <span className={styles.label}>
            Subtotal:
          </span> 
          <span className={styles.amount}>
            ${itemTotalDisplay}
            {!minOrderMet &&
              <sup className={styles.minOrderSup}>*</sup>  
            }
          </span>
        </div>
       
        <div className={styles.checkoutDetail}>
          <span className={styles.label}>
            Delivery Fee:
          </span>
          <span className={styles.amount}>
            { deliveryFeeCents === 0 
              ? <>
                  <span className={styles.noCharge}>{STANDARD_DELIVERY_FEE}</span>
                  <span className={styles.priceComment}>Free</span>
                </>
              : <span>${(deliveryFeeCents/100).toFixed(2)}</span>
            }
          </span>
        </div>

        <div className={styles.checkoutDetail}>
          <span className={styles.label}>
            Fees and Estimated Taxes:
            <TaxesTooltip
              dispensaryName={dispensaryName}
              itemTotal={totalAfterDiscounts}
              salesTaxPercent={salesTaxPercent}
              cannabisTaxPercent={cannabisTaxPercent}
              serviceFeeCents={feeCents} /> 
          </span>
          <span className={styles.amount}>
            ${(feesAndTaxCents/100).toFixed(2)}
          </span>
        </div>
        
        <div className={styles.checkoutTotal}>
          <span className={styles.label}>  
            Total: 
          </span>
          <span className={styles.amount}>
            ${totalPrice.toFixed(2)}
          </span>
        </div>
        
        <div className={styles.buttonWrap}>
          { orderProcessing
            ? <ProgressBar ticks={10} />
            : minOrderMet
              ? bankAccountId && !testAccountLinking
                ? <div className={styles.apButtonWrap}>
                    {/* Ready to order */} 
                    <img className={styles.apButton} alt="Proceed with AeroPay" src={aeropayButton} onClick={placeOrderClick} />
                    { newLinkedAccount.current 
                      ?  <SuccessMessage text={`Account ...${bankAccountHint} linked, click Proceed with AeroPay to complete your order.`} />  
                      :  <div className={styles.apMessage}>Using your linked account ending in ...{bankAccountHint}</div> 
                    }
                  </div>
                // bank account not linked
                : <LinkAccountsButton onSuccess={handleNewLinkedAccount} />
              : <DisabledButtonWithReason text="Place Order" reason={`Minimum product order: $${minOrderDisplay}`} /> 
          }
        </div>
        
        {/* spinner + order post errors  - item not available etc. */}
        { orderResponse && 
          <div style={{textAlign:'center'}}>{orderResponse}</div>  
        }
        
        { deliveryFeeCents === 0 && 
          <div className={styles.promotion}>
            You saved {STANDARD_DELIVERY_FEE} with our New User Promotion
          </div>
        }
      </form>
    </div>
  );
}

OrderDeliveryButton.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  minDeliveryOrder: PropTypes.number.isRequired
};

export default OrderDeliveryButton;