import React from 'react';

import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { navigateTop } from '../routing/router-utils';
import CheckOut from '../checkout/CheckOut';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

/**
 * This controller just provides the DispensaryContext data to the Checkout component
 * 
 * @param {string} dispensaryId - id from the url ( although context has the dispensary already )
 */

const PlaceOrderController = ({dispensaryId /* from url */}) => {
  
  const { dispensary, dispensaryNotFound } = React.useContext(DispensaryContext);

  // Handle bad url/dispensary id
  React.useEffect(() => {
    if(dispensaryId && dispensaryNotFound) {
      navigateTop(`/`);
    }
  }, [dispensaryId, dispensaryNotFound]);

  return (
    <>
      { dispensary?.id
        ? <CheckOut 
            dispensaryId={dispensary.id}
            dispensaryName={dispensary.name}
            dispensaryTelNumber={dispensary.tel_number} 
            dispensaryLocation={dispensary.location}
            minPickupOrder={dispensary.minimum_pickup_value_usa_cents}
            minDeliveryOrder={dispensary.minimum_delivery_value_usa_cents}
          />
        : <Spinner />
      }
    </>
  );
}

PlaceOrderController.propTypes = {
  dispensaryId: PropTypes.string /* initially undefined */
};

export default PlaceOrderController;