import * as React from 'react';

import styles from './StaffPick.module.css';

/**
 * Staff Pick (featured pill)
 */
const StaffPick = () => { 
  return ( 
    <div className={styles.staffPickWrap}> 
      <span className={styles.staffPick}>
        Staff&nbsp;Pick&nbsp;<span role="img" aria-label="gold star">⭐</span>
      </span>
    </div>
  )
}

export default StaffPick;