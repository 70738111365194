import React from 'react';

import { CartItemsContext } from './CartItemsProvider';
import PlaceOrderItem from './PlaceOrderItem';
import ErrorBoundary from '../common/ErrorBoundary'; 

/**
 * Display the cart items on the Place Order screen
 */
const PlaceOrderItems = () => {

  const { cartItems, removeItem } = React.useContext(CartItemsContext);

  return ( cartItems.length
    ? <ErrorBoundary> 
        <ul>
          { cartItems.map(item => {
            return <PlaceOrderItem 
              key={item.id} 
              Id={item.id} 
              quantity={item.quantity}
              { ...item.display_info} 
              removeFn={() => removeItem(item.id)} 
               />
          })}
        </ul>
      </ErrorBoundary>
    : <span>There are currently no items in your cart.</span>  
  )
}

export default PlaceOrderItems;
