import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import PropTypes from 'prop-types';

import styles from './AdjustQuantity.module.css';

/**
 * Adjust item quantity prior to adding to cart
 *
 * @param {number} count - initial/current item count from parent
 * @param {string} addlMessage - parent count setter
 */
const AdjustQuantity = ({count=1, setCount}) => { 
  
  const [displayQuantity, setDisplayQuantity] = React.useState(1);

  React.useEffect(() => {
    if (count !== displayQuantity) {
     setDisplayQuantity(count);
    } 
  }, [count, displayQuantity]);

  const increment = () => {
    const newQuantity = displayQuantity + 1;
    setDisplayQuantity(newQuantity)
    setCount(newQuantity);
    trackEvent('adjust_quantity_up', 'product_modal', newQuantity);  
  }

  const decrement = () => {
    if (displayQuantity > 1) {
      const newQuantity = displayQuantity - 1;
      setDisplayQuantity(newQuantity); 
      setCount(newQuantity);
      trackEvent('adjust_quantity_down', 'product_modal', newQuantity);  
    }
  }
  
  return ( 
    <div className={styles.adjustQuantity}>
      <div className={styles.control} onClick={decrement}>
        <RemoveCircleOutlineRoundedIcon />
      </div>
      <div className={styles.countWrap}>
        <div className={styles.count}>
          {displayQuantity}
        </div>
      </div> 
      <div className={styles.control} onClick={increment}>
        <AddCircleOutlineRoundedIcon />
      </div>
    </div>
  )
}

AdjustQuantity.propTypes = {
  count: PropTypes.number,  
  setCount: PropTypes.func.isRequired
}

export default AdjustQuantity;