import * as React from "react";

import ImageUploading from "react-images-uploading";
import ProgressCircle from './ProgressCircle';
import BasicButton from '../styleguide/BasicButton'; 
import photoIDFront from '../../assets/photoIDSmall.png';
import photoIDBack from '../../assets/photoIDBack.png';
import { uploadIDImages } from './registration-utils';
import PropTypes from 'prop-types';

import styles from './SelectIDPhotos.module.css';

/**
 *  Upload/Update front and back images of ID to Firebase 
 */
const SelectIDPhotos = ({
  email, 
  hasExistingPhotos ,
  sectionRef, 
  handleSubmit, 
  msgHandler, 
  updateMsg}) => {
  
  const [userImages, setUserImages] = React.useState([photoIDFront, photoIDBack]);
  const [uploadPct, setUploadPct] = React.useState(-1);

  // Temporarily store images in a directory that's a base64 encoding of their email 
  const encodedEmail = window.btoa(email.trim());
  const requiredImageCount = 2;
  let firstImageInfo;
  
  const onSelectImage = imageList => {
    // image data to submit
    setUserImages(imageList);
  };
  
  // runs for each image upload
  const onUploadComplete = (imageInfo) => {
    if (!firstImageInfo) {
      firstImageInfo = imageInfo;
    } else {
      handleSubmit({ 
        photo_ids: { 
          primary: firstImageInfo, 
          secondary: imageInfo }
        },
        sectionRef, 
        msgHandler);
    }
  };        
  
  const onUploadProgressChange = (pctComplete) => {
    setUploadPct(parseInt(pctComplete, 10));
  }
  
  const instructionDetails = [
    { placeholderSrc: photoIDFront, label: 'Front', buttonText: 'Select ID Image (Front)'}, 
    { placeholderSrc: photoIDBack, label: 'Back', buttonText: 'Select ID Image (Back)'}
  ]; 
  
  return (
    <ImageUploading 
      onChange={onSelectImage}
      acceptType={['jpg', 'jpeg', 'gif', 'png']} 
      maxNumber={requiredImageCount}
      multiple>
      {({ imageList, onImageUpload, onImageRemoveAll }) => (
        <>
          <div className={styles.imageGrid}>
            {/* display the user-uploaded images */}
            { imageList.map((image, idx) => (
              <div key={image.key} className={styles.imageItem}>
                <div className={styles.placeholderLabel}>{instructionDetails[idx].label}</div>
                <div className={styles.imageWrapper}>
                  <img src={image.dataURL} alt="" />
                  <span className={styles.alternateButton} onClick={image.onUpdate}>Select a different image</span>
                </div>
              </div>
            ))}

            {/* add image placeholders for any missing images */}
            {/* allow click on placeholder to upload for currently needed image only */}
            { imageList.length < requiredImageCount && 
              instructionDetails.slice(imageList.length, requiredImageCount).map((instruction, idx) => (
                <div key={instruction.label} className={`${styles.placeholderItem} ${styles.imageItem}`}>
                   <div className={styles.placeholderLabel}>{instructionDetails[imageList.length + idx].label}</div>
                  <img src={instruction.placeholderSrc} alt="" 
                       onClick={ idx === 0 ? onImageUpload : ()=>{}} 
                  />    
                </div>
              ))
            }
          </div>
          
          {/* 
            TODO: This would be better implemented as uploadPct1 and uploadPct2 so we're not mixing 
            the data for both images into a single measurement: 0, 0, 79, 49, 100, 100. 
          */} 
          <div className={styles.progressWrap}>
            { updateMsg
              ? <div className={styles.updateMsg}>{updateMsg}</div> 
              : uploadPct > 0 
                ? <ProgressCircle onGreen percentageInt={parseInt(uploadPct, 10)} />     
                : <div className={styles.photoIDInstructions}>
                    { hasExistingPhotos
                      ? <>
                          <div>Your Photo ID is on file <span>(required for delivery orders).</span></div>
                          <div>If you need to update your ID, <span>you can do it&nbsp;here.</span></div> 
                        </>
                      : <>
                          <div className={styles.statusText}>Your Photo ID is not on file <span>(required for delivery orders).</span></div> 
                          <div>Before placing a delivery order, <span>upload your ID&nbsp;here.</span></div> 
                        </>
                    }
                    <div>(But not while an order is in progress)</div>  
                  </div>
            }
          </div>

          <div className={styles.imageUploadButtons}>
            { imageList.length < requiredImageCount
              ? <BasicButton 
                  text={instructionDetails[imageList.length]['buttonText']} 
                  handleClick={onImageUpload} />
              : <BasicButton
                  text="Upload Your New ID Images"
                  handleClick={event => {
                    event.preventDefault();
                    uploadIDImages(userImages, encodedEmail, onUploadComplete, onUploadProgressChange);
                  }} />
            }
          </div>
        </>       
      )}
    </ImageUploading>
  );
};  

SelectIDPhotos.propTypes = {
  email: PropTypes.string.isRequired,
  hasExistingPhotos: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  msgHandler: PropTypes.func.isRequired,
  updateMsg: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired
};

export default SelectIDPhotos;