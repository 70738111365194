import { auth } from '../../firebase';
import { trackEvent } from '../analytics/tracking';
import { newOrderEndpoint, guestOrderEndpoint, retreiveOrdersEndpoint, validateOrderEndpoint, customerCartEndpoint } from '../../constants/api-endpoints';
import { queueForSessionRestore } from '../providers/provider-utils';

export const OrderType = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup'
};

export const PaymentLabel = {
  ONSITE: 'pay-in-store',
  PREPAID: 'pre-paid'
};

export const getPaymentLabel = (payment_details) => {
  return payment_details?.processor === 'on-site'
    ? PaymentLabel.ONSITE
    : PaymentLabel.PREPAID;
};

/**
 * Validate and order prior to displaying the AeroPay payment popup
 *
 * (Prevent charging for invalid orders ) 
 *  
 * @param {string} dispensary_id 
 * @param {boolean} isPickup 
 * @param {array} items 
 * @param {object} deliverySchedule 
 * @param {object} paymentDetails 
 * @param {function} validateOrderCallback 
 * @param {AbortController} controller 
 */
export const validateDispensaryOrder = async (dispensary_id, isPickup, items, deliverySchedule, pickupTimeSlot, validateOrderCallback, controller) => {
  // Authentication has been checked prior to displaying /placeOrder
  if (!auth.currentUser) {
    return;
  }
  
  const dispensaryOrder = {
    dispensary_id,
    customer_id: auth.currentUser.uid,
    type: isPickup ? 'pickup' : 'delivery',
    items: getItemDetails(items)
  };

  // TODO: Remove deprecated fields
  if (isPickup) {
    dispensaryOrder.pickup_time_slot = pickupTimeSlot;
    dispensaryOrder.fulfillment_time_slot = pickupTimeSlot;
  } else {  
    dispensaryOrder.delivery_schedule = deliverySchedule;
    dispensaryOrder.fulfillment_time_slot = deliverySchedule;
  }

  const validateOrderURL = validateOrderEndpoint.replace('[dispId]', dispensary_id);

  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(validateOrderURL, { 
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dispensaryOrder),
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        validateOrderCallback(body);
      })
      .catch(error => { 
        validateOrderCallback({ error: error.message });
      });
  });
};  

/**
 * Place an order
 * 
 * Delivery orders are validated prior to the payment flow and will not be submitted
 * TODO: Implement this for pickup. 
 * 
 * @param {string} dispensary_id 
 * @param {boolean} isPickup 
 * @param {array} items 
 * @param {object} deliverySchedule 
 * @param {object} payment_details 
 * @param {function} placeOrderCallback - continue to /orderConfirmation view
 * @param {AbortController} controller 
 */
export const submitDispensaryOrder = async (dispensary_id, isPickup, items, deliverySchedule, pickupTimeSlot, payment_details, placeOrderCallback, controller) => {
  // Authentication has been checked prior to displaying /placeOrder
  if (!auth.currentUser) {
    return;
  }
  
  const dispensaryOrder = {
    dispensary_id,
    customer_id: auth.currentUser.uid,
    type: isPickup ? 'pickup' : 'delivery',
    items: getItemDetails(items),
    payment_details
  };

  /* TODO - remove deprecated fields: pickup/delivery */  
  if (isPickup) {
    dispensaryOrder.fulfillment_time_slot = pickupTimeSlot;
    dispensaryOrder.pickup_time_slot = pickupTimeSlot;
  } else { 
    dispensaryOrder.fulfillment_time_slot = deliverySchedule; 
    dispensaryOrder.delivery_schedule = deliverySchedule;
  }

  const dispensaryOrderURL = `${newOrderEndpoint}${dispensary_id}`;

  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(dispensaryOrderURL, { 
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dispensaryOrder),
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        placeOrderCallback(body);
      })
      .catch(error => { 
        placeOrderCallback({ error: error.message });
      });
  });
};

export const submitGuestPickupOrder = async (dispensary_id, items, guestInfo, timeSlot, placeOrderCallback, controller) => {
  // Authentication has been checked prior to displaying /placeOrder
  if (!auth.currentUser) {
    return;
  }

  const { name, tel_number, dob } = guestInfo;
  
  const guestOrder = {
    type: 'pickup',
    payment_details: { processor: "on-site" }, 
    name,
    tel_number,
    dob,
    dispensary_id,
    items: getItemDetails(items),
    fulfillment_time_slot: timeSlot,
    referrer_dispensary_id: dispensary_id    
  };

  const dispensaryOrderURL = `${guestOrderEndpoint}${dispensary_id}`;

  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(dispensaryOrderURL, { 
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(guestOrder),
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        placeOrderCallback(body);
      })
      .catch(error => { 
        placeOrderCallback({ error: error.message });
      });
  });
};

/**
 * Retrieve orders for a user 
 * 
 * @param dispensaryId - the dispensary receiving the order
 * @param getOrdersCallback - the function that handles the response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const getCustomerOrders = async (getOrdersCallback, controller) => {
  // We need a user to proceed
  if (!auth.currentUser) {
    return;
  } 
  
  const dispensaryOrderURL = `${retreiveOrdersEndpoint}${auth.currentUser.uid}`;
  const getOrders = () => {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(dispensaryOrderURL, {
        method: 'GET',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(body => {
          getOrdersCallback(body);
        })
        .catch(error => {
          getOrdersCallback({ error: error.message });
        });
    });
  };
  // We need a real user for this, allow time foor restore
  if (auth.currentUser.isAnonymous) {
    queueForSessionRestore(() => {
      getOrders();
    });
  } else {
    getOrders();    
  }
};

/**
 * TOTAL PRICE CALCULATIONS
 * Computed based on API-provided tax/fee/delivery amounts stored in redux   
 */

// $30 
const MAX_SERVICE_FEE_CENTS = 3000; 

// TODO: API will provide is_free_delivery / is_free_pickup
export const STANDARD_DELIVERY_FEE = 3.99; 

/**
 * IMO Taxes and Fees cannot be billed in penny fractions so round as needed
 * NOTE: number.toFixed(2) rounds .255 down and .155 up... 
 * TODO: revisit?
 */
// const roundPennies = (number) => {
   // Currently rounding everything UP
   // return Math.ceil(number);
   // Normal rounding
   // return Math.round(number*Math.pow(10,2))/Math.pow(10,2);
//}  

/** 
 * Max Service Fee Max is $30
 * 
 * Cents because: 30 + 7.23 = 37.230000000000004
 * 
 * @param {number} itemTotalCents 
 * @param {number} serviceFeePct - e.g. 3.25 pickup or 10 delivery
 */
export const calcServiceFeeCents = (itemTotalCents, serviceFeePct) => {
  const computedFee =  Math.ceil((itemTotalCents * serviceFeePct)/100);
  return Math.min(computedFee, MAX_SERVICE_FEE_CENTS);
}

/** 
 * Max Service Fee Max is $30
 * 
 * Cents because: 30 + 7.23 = 37.230000000000004
 * 
 * @param {number} itemTotalCents
 * @param {boolean} isPayInStore
 * @param {number} inStoreFeePct --- For in-store payment, e.g. 1 or 2%
 * @param {number} prePayFeePct --- For prepaid orders (higher fee) which covers transaction cost
 * @param {number} prePayBaseFeeCents --- We start at 25 cents when calcing the prepaid fee
 */
export const calcPickupServiceFeeCents = (itemTotalCents, isPayInStore, inStoreFeePct, prePayFeePct, prePayBaseFeeCents) => {
  if (isPayInStore) {
    const inStoreFee =  Math.ceil((itemTotalCents * inStoreFeePct)/100);  
    return Math.min(inStoreFee, MAX_SERVICE_FEE_CENTS);
  /* Prepaid via AeroPay etc. */
  } else {
    const prePayFee = prePayBaseFeeCents + Math.ceil((itemTotalCents * prePayFeePct)/100);
    return Math.min(prePayFee, MAX_SERVICE_FEE_CENTS) ;
  }
}

/**
 * Taxes are currently 23%
 *
 * Cents because: 30 + 7.23 = 37.230000000000004
 * 
 * @param {number} itemTotalCents - total product cost
 * @param {number} salesTaxPct - e.g. 6.25
 * @param {number} cannabisTaxPct - e.g. 16.75
 * 
 */
export const calcTaxCents = (itemTotalCents, salesTaxPct, cannabisTaxPct) => {
  /**
   * Round each tax
   * 
   * return Math.ceil((itemTotalCents * salesTaxPct)/100) +
   *        Math.ceil((itemTotalCents * cannabisTaxPct)/100); 
   */

  // Round once as the backend does
  return Math.ceil((itemTotalCents * salesTaxPct)/100 +
                   (itemTotalCents * cannabisTaxPct)/100);
}

/** 
 * Compare order cost calculations front end versus back end
 * This is mostly to prevent off-by-one rounding issues, and ensure the 
 * displayed total matches the billed total.
 * 
 * Everything is rounded up: 10.1 -> 11.
 * 
 * Any differences with be logged as an error.
 * 
 * @param {object} frontEnd - the cost components used to display the total price
 * @param {object} backEnd - the cost components returned by the API 
 */
export const validateCostCalculation = (frontEnd, backEnd, orderType) => {
  console.log(`validating costs for order type: ${orderType}`);
  console.log(`front: ${JSON.stringify(frontEnd)}`);
  console.log(`back: ${JSON.stringify(backEnd)}`);
  
  const diffs = [];
  if (backEnd.total && frontEnd.total) {
    const itemsToValidate = Object.keys(backEnd);
    itemsToValidate.forEach((key) => {
      // Math.ceil(null) === 0 ftw
      if (Math.ceil(backEnd[key]) !== frontEnd[key]) {
        diffs.push(`Front end: ${key} off by ${Math.ceil(frontEnd[key] - Math.ceil(backEnd[key]))} cent(s)`);
      }
    });
  }
  return diffs;
};

// debounce cart save/clear requests
let cartUpdateTask;
const cartUpdateDelayMS = 1000;

/**
 * Retrieve a user's cart items from previous session
 * ( DeBounced ) 
 * 
 * @param cartInfo - cart item details: { order_type, dispensary_id, items }
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const saveCartItems = async (cartInfo, controller) => {
  // Authentication has been checked
  if (!auth.currentUser) {
    return;
  } 
  cartInfo.customer_id = auth.currentUser.uid
   
  window.clearTimeout(cartUpdateTask);
  cartUpdateTask = window.setTimeout(() => {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(customerCartEndpoint, { 
        method: 'POST',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cartInfo),
        signal: controller.signal
      }).then(response => {
        if (response.status === 200) {
          trackEvent('cart_saved', `item_count_${cartInfo.items.length}`);   
        } else {
          response.json().then(body => {
            trackEvent(`error_saving_cart_${response.status}`, body.error);
          });
        }
      })
      .catch(error => {
        trackEvent('error_saving_cart', `item_count_${cartInfo.items.length}`);
      });
    })
  }, cartUpdateDelayMS);
};

/**
 * Retrieve a user's cart items from previous session 
 * 
 * @param cartItemsCallback - the function that handles the response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const getCartItems = async (cartItemsCallback, controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(customerCartEndpoint, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        cartItemsCallback(body);
      })
      .catch(error => { 
        cartItemsCallback({ error: error.message });
      });
  });
};

/**
 * Clear a user's cart items - when they've removed everything 
 * 
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
 export const clearSavedCartItems = async (controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  window.clearTimeout(cartUpdateTask);
  cartUpdateTask = window.setTimeout(() => {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(customerCartEndpoint, { 
        method: 'DELETE',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        signal: controller.signal
      }).then(response => {
        if (response.status === 204) {
          // OK: console.log('cart cleared');
        } else {
          trackEvent(`error_clearing_cart_${response.status}`);
        }
      }).catch(error => {
        const snippet = error.message?.substring(0,20);
        trackEvent(`error_clearing_cart_${snippet}`);
      });
    });
  }, cartUpdateDelayMS);  
};

// Extract only what's needed by the API for processing
export const getItemDetails = (items) => {
  return items.map(itm => ({ product_id: itm.id, quantity: itm.quantity }));  
}

// Convert product_id => id so web app can use item returned by API
export const restoreItemDetails = (items) => {
  return items.map(itm => ({ ...itm, id: itm.product_id })); 
}
