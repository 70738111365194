import * as React from 'react';

import BodyWrapper from '../layout/BodyWrapper';
import { Warning } from '@material-ui/icons';
import { Link } from '@reach/router';
import { trackEvent } from '../analytics/tracking';
import XLargeHeader from '../styleguide/XLargeHeader';
import Helmet from 'react-helmet';

import styles from './PageNotFound.module.css';

const PageNotFound = () => {
  
  const unkownRoute = window.location.pathname;

  // Log the 404 error
  React.useEffect(() => {
    trackEvent('error404', unkownRoute);
  }, [unkownRoute]);

  return (
    <BodyWrapper>
      <Helmet>
        <title>Zip Run - Page Not Found</title>
      </Helmet>
      <div className={styles.wrap404}>
        <XLargeHeader>
          <Warning style={{ fontSize:'3rem', verticalAlign:'bottom', marginRight:'.5rem' }}/>
          <span>Wait... What?</span>
        </XLargeHeader>
        <div className={styles.notFoundMsg}>
          <p>Sorry, I'm not finding anything for the page: {unkownRoute}.</p>
          <p><Link to="/">Return to the Zip Run Home page</Link></p>
        </div>
      </div>
    </BodyWrapper> 
  );
};

export default PageNotFound;