import * as React from 'react';

import config from '../../config.js';
import { makeStyles } from '@material-ui/core/styles';

import discountIcon from '../../assets/discountRedIcon.png';

const useStyles = makeStyles(theme => ({
  msgWrap: {
    margin: '1rem 0 0',
    flexBasis: '100%',
    padding: '12px 16px',
    backgroundColor: '#e6e7e8',
    borderRadius: '6px',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      margin: '-8px 0 24px'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    } 
  },
  
  hdr: {
    marginBottom: '8px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    } 
  },

  icon: {
    height: '28px',
    verticalAlign: 'bottom',
    marginRight:'8px',
    [theme.breakpoints.up('md')]: {
      height: '24px',
      verticalAlign:'-6px',
      marginRight:'8px',
      marginLeft: '-4px' 
    } 
  },

  iconTxt: {
    fontFamily: 'Nunito Sans',
    lineHeight: '26px',
    display: 'inline-block',
    fontSize: '1.125rem',
    fontWeight: '800',
    color: '#c9342d'
  },

  bdy: {
    [theme.breakpoints.up('md')]: {
      lineHeight: '26px',
      marginLeft: '.5rem'
    }
  },

  exclaim: {
    fontStyle: 'italic',
    marginRight: '6px',
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px'
    } 
  },
 
  disclaim: {
    fontWeight: 400    
  }
}));

const BulkDiscountMsg = ({dispensaryId}) => {
  const classes= useStyles();
  return (
    (config.IS_DEV || config.BULK_DISCOUNT_DISPENSARIES.includes(dispensaryId)) 
      ? <div className={classes.msgWrap}>
          <div className={classes.hdr}>
            <img alt="discount" className={classes.icon} src={discountIcon} />
            <span className={classes.iconTxt}>BULK DISCOUNTS</span> 
          </div>
          <div className={classes.bdy}>
            <span className={classes.exclaim}>YES!</span> 
            There are bulk discounts on many flower products 
            {' '}<span className={classes.disclaim}>(discount calculated at checkout)</span>
          </div>
        </div>
      : null      
  )
}

export default BulkDiscountMsg;