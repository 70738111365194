import * as React from 'react';

import ProductInfoTiles from './ProductInfoTiles';
import StaffPick from './StaffPick';
import { SEOLinkWrapper } from '../routing/router-utils';
import PreviewProductImage  from './PreviewProductImage';
import InCartCheck from './InCartCheck';
import discountIcon from '../../assets/discountRedIcon.png'
import PropTypes from 'prop-types';

import styles from './ProductPreviewCard.module.css';

const ProductPreviewCard = ({
  itemKey,
  Id, 
  Name,
  brand,
  category, 
  image_url, 
  cost_usa_cents,
  is_featured,
  leaf_logix_pricing_tier,
  thc_lab_value,
  thc_lab_units,
  cbd_lab_value,
  cbd_lab_units,
  cannabis_type,
  dispensaryId, 
  orderQuantity, 
  showOrderQuantity,
  isFullWidth,
  handleCartClick=()=>{},
  handleClick
}) => {
  
  const formattedPrice = parseFloat(cost_usa_cents/100).toFixed(2) || 'N/A';

  // The deep link for Google
  const deepLink = `/menu/${dispensaryId}/product/${Id}`;
  // display in Your Orders etc.
  const widthStyle = isFullWidth ? styles.fullWidth : '';
  // These are all strings
  const showProductTiles = cbd_lab_value || thc_lab_value || cannabis_type;
  
  const displayOrderQuantity = showOrderQuantity && orderQuantity > 0;
     
  return (
    <div key={itemKey} className={`${styles.productCardWrap} ${widthStyle}`}>  
      <SEOLinkWrapper deepLink={deepLink} key={Id} styleClass={styles.productCard} handleClick={handleClick}>
        <div className={styles.productImageWrap}>
          <PreviewProductImage src={image_url} category={category.display_name} productName={Name} /> 
        </div>
        <div className={styles.productBody}>
          { is_featured &&
            <div className={styles.staffPickWrap}>
              <StaffPick />
            </div>
          }
          <div className={styles.productName}>
            {Name}
          </div>
          { brand && 
            <div className={styles.brand}>
              {brand} 
            </div>
          }
          { showProductTiles &&
            <div className={styles.productInfo}>
              <ProductInfoTiles
                productId={Id}  
                thcVal={thc_lab_value}
                thcUnits={thc_lab_units}
                cbdVal={cbd_lab_value}
                cbdUnits={cbd_lab_units}
                cannabisType={cannabis_type} />
            </div>
          }
          <div className={styles.productPrice}>
            ${formattedPrice}
            { leaf_logix_pricing_tier && 
              <img alt="discount" className={styles.discountIcon} height="16" src={discountIcon} />
            }
            {/* Quantity For previous order display only */}
            { displayOrderQuantity &&
              <span className={styles.orderQuantity}>(x{orderQuantity})</span>
            }
          </div>
        </div>
          
        {/**
          * ShowOrderQuantity also enables quickAdd
          * We suppress when showing order history 
          */}
        {!showOrderQuantity && 
          <div className={styles.addedWrapper}>
            <InCartCheck itemId={Id} handleCartClick={handleCartClick} />
          </div>
        }
  
      </SEOLinkWrapper>
    </div>     
  )
};

ProductPreviewCard.propTypes = {
  itemKey: PropTypes.string.isRequired,
  Id: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  brand: PropTypes.string,
  category: PropTypes.object.isRequired,
  image_url: PropTypes.string,
  cost_usa_cents: PropTypes.number,
  is_featured: PropTypes.bool,
  leaf_logix_pricing_tier: PropTypes.string,
  thc_lab_value: PropTypes.string,
  thc_lab_units: PropTypes.string,
  cbd_lab_value: PropTypes.string,
  cbd_lab_units: PropTypes.string,
  cannabisType: PropTypes.string,
  dispensaryId: PropTypes.string.isRequired,
  orderQuantity: PropTypes.number,
  showOrderQuantity: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  handleCartClick: PropTypes.func.isRequired, /* quick add icon */
  handleClick: PropTypes.func.isRequired
};

export default ProductPreviewCard;