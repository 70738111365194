import * as React from 'react';

import { OrderTypeContext } from '../providers/OrderTypeProvider';
import CheckOutButton from './CheckOutButton';
import QuantityWarning from './QuantityWarning';
import PropTypes from 'prop-types';

import styles from './Cart.module.css';

const CartHeader = ({
  dispensaryId,
  dispensaryName,
  flowerGrams=0,
  minOrderDelivery,
  closeMenuFn
}) => {

  const { currentService } = React.useContext(OrderTypeContext);

  return (
    <div className={styles.cartHeader}>
      
      <div className={styles.cartDetails}>
        <div className={styles.dispensaryDetail}>
          <div>SHOPPING AT:</div>
          <div className={styles.dispensaryName}>{dispensaryName}</div>  
        </div>
        <div className={styles.orderTypeDetail}>
          {currentService}
        </div>  
      </div>
      
      {/* Don't confuse the user with a checkout button on the /placeOrder page */}
      { window.location.pathname.indexOf('placeOrder') === -1 && 
        <CheckOutButton
          dispensaryId={dispensaryId}
          dispensaryName={dispensaryName}
          minOrderDelivery={minOrderDelivery} 
          closeMenuFn={closeMenuFn} />
      }
      <QuantityWarning gramsInCart={flowerGrams} />
    </div>
  )
}

CartHeader.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  flowerGrams: PropTypes.number.isRequired,
  minOrderDelivery: PropTypes.number.isRequired,
  closeMenuFn: PropTypes.func.isRequired,
}            

export default CartHeader;