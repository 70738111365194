import React from 'react';

import { CartItemsContext } from './CartItemsProvider';
import { useDispatch } from 'react-redux';
import { setBulkDiscount } from '../actions/cartActions';
import { trackEvent } from '../analytics/tracking';
import PropTypes from 'prop-types';

/**
 * Component that displays the total bulk/tier pricing discount for carted items (at checkout)
 * 
 * Assumes:
 *  Exact product weight (3.5g = 1/8) is included in teir price data as .startWeight
 *  Tier price for total grams is calculated by rounding up after quantity * grams * tier price * 100 
 */
const DisplayBulkDiscount = ({handleNoDiscount}) => {
  // Cart items
  const { cartItems } = React.useContext(CartItemsContext);
  
  const dispatch = useDispatch();
  // Total tier-pricing discount on cart items
  const [tierDiscount, setTierDiscount] = React.useState(0);
  // Product categories, for display only
  const [discountLabel,setDiscountLabel] = React.useState([]);

  // Create a map of startWeight to price for a single tier: 3.5 => 9.275 etc.
  const createTierPriceByWeightMap = (tierDataArray) => {
    return  tierDataArray.reduce((map, tier) => {
      map.set(tier.startWeight, tier.price);
      return map;
    }, new Map());
  };
  
  // Calc the discount for a single tier
  const calcTierDiscount = (tierProducts, tierPricingMap, totalPreDiscount) => {
    // Calc total weight for tier
    const tierGrams = tierProducts.reduce((total, item) => {
      total += item.quantity * item.grams;
      return total;
    }, 0);
    
    /**
     * EDGE CASE:
     * IF THE PRODUCT WEIGHT (GRAMS) IS NOT A KEY 
     * IN THE PRICING TIER MAP WE MUST ABORT THE TIER CALC.  
     * 
     * return 0 for tier
     */ 
    const productExceptions = tierProducts.reduce((arr, item) => {
      if (!tierPricingMap.get(item.grams)) {
        // Map doesn't have pricing for product weight
        arr.push(item);
      }
      return arr;
    }, []);
    if (productExceptions.length) {
      const product = productExceptions[0];
      trackEvent('tier_pricing_error', product.display_info?.name, product.id);
      // SKIP THE DISCOUNT CALC
      return 0;
    }
    
    // Sort keys highest to lowest
    const priceKeys =  Array.from(tierPricingMap.keys());
    const startWeights = priceKeys.sort((a, b) => { return b - a });

    let totalBulkPrice = 0;
    let remainingGrams = tierGrams;
    startWeights.forEach(startWeight => {
      if (startWeight <= remainingGrams) {
        const count = Math.floor(remainingGrams/startWeight);
        // Round for better price calcs: .95 etc.
        totalBulkPrice += Math.round(count * startWeight * tierPricingMap.get(startWeight) * 100);
        remainingGrams -= startWeight * count;  
      } 
    }); 
    // console.log(`total tier discount: ${totalPreDiscount - totalBulkPrice}`);
    if (totalBulkPrice > totalPreDiscount) {
      // TODO: Log Error: Bulk Price higher than standard 
      return 0;
    } else {
      return totalPreDiscount - totalBulkPrice;
    }
  }; 
  
  // Label to display next to discount total: 'Flower products' etc.
  const buildDiscountLabel = React.useCallback((tierPricedItems) => {
    const categorySet = tierPricedItems.reduce((idSet, item) => {
      if (item.display_info?.category?.display_name) {
        idSet.add(item.display_info.category.display_name); // e.g. Flower
      }
      return idSet;
    }, new Set());
    return `${Array.from(categorySet).join(',')} products`;   
  }, []); 

  React.useEffect(() => {
    /**
     * Important! Remove any previously calculated discount 
     */
    let discount = 0;
    
    const tierPricedItems = cartItems.filter(item => !!item.leaf_logix_pricing_tier);
    if (tierPricedItems.length) {
      // build the tierSet to identify unique tierIds
      const tierSet = tierPricedItems.reduce((idSet, item) => {
        idSet.add(item.leaf_logix_pricing_tier.pricingTier); // e.g. 2999
        return idSet;
      }, new Set());
      Array.from(tierSet).forEach(tierId => {
        // Get products for this tier
        const tierProducts = tierPricedItems.filter(item => item.leaf_logix_pricing_tier.pricingTier === tierId);
        // Create tier price by weight map
        const tierPricingMap = createTierPriceByWeightMap(tierProducts[0].leaf_logix_pricing_tier.pricingTierData);  
        // console.log(tierPricingMap);
        // Calc pre-discount tier total (cents)
        const tierTotalPreDiscount = tierProducts.reduce((total, item) => {
          total += item.quantity * item.display_info.cost_usa_cents;
          return total;
        }, 0); 
        // Accumulate tier discounts
        discount += calcTierDiscount(tierProducts, tierPricingMap, tierTotalPreDiscount);
      }); 
      setTierDiscount(discount);
      const label = buildDiscountLabel(tierPricedItems); 
      setDiscountLabel(label);
      // console.log('setting discount');
      dispatch(setBulkDiscount(discount, label));
    } else {
      // Remove any previously calculated discount
      dispatch(setBulkDiscount(0, ''));
    }
    if (handleNoDiscount) {
      handleNoDiscount(discount === 0);
    }
  },[cartItems, buildDiscountLabel, handleNoDiscount, dispatch]);
 
  return ( tierDiscount
    ? <div style={{display:'flex'}}>
        <div style={{flex:10}}>
         { discountLabel &&
           discountLabel
         }
        </div> 
        <div style={{flex:0, whiteSpace:'nowrap'}}>-${parseFloat(tierDiscount/100).toFixed(2)}</div>
      </div>
    : <>
        <div style={{flex:1,textAlign:'right'}}>N/A</div>  
      </>  
  )
}

DisplayBulkDiscount.propTypes = {
  handleNoDiscount: PropTypes.func
}

export default DisplayBulkDiscount;