import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { ZIP_RUN_GREEN } from '../../constants/colors';

/**
 * In dev, displays the differences between front-end and 
 * back-end price calcs ( The total, fees, taxes etc.) 
 * 
 * @param {array} costCalcDiffs - Array of line-item diffs 
 * @returns 
 */

const ValidateOrderResults = ({
  costCalcDiffs=[]
}) => {

  const isDev = window.location.host !== 'app.ziprun.com';
  
  // Skip modules for debug stuff
  // Line item styling, bold last item (total diffs)
  const itemStyle = {fontWeight:'400', marginBottom: '.25rem'};
  const lastItemStyle = Object.assign({fontWeight:'600'}, itemStyle);
 
  /**
   * Log an error for diffs 
   * Diffs should be less than $1 as orders are rejected
   * when total diff is > $1.00
   */  
  const errorLoggedRef = React.useRef();
  if (isDev && costCalcDiffs.length && !errorLoggedRef.current) {
    if (costCalcDiffs[costCalcDiffs.length - 1] > 1) {
      trackEvent('price_calc_error_major');
    } else {
      trackEvent('price_calc_error_minor');
    }
    errorLoggedRef.current = true;
  }

  return (
    <>
      { isDev 
        ? <div style={{width:'400px', maxWidth:'95vw', margin:'.5rem auto', fontWeight:'600',textAlign:'center'}}>
            { costCalcDiffs.length
              ? <>
                  <div style={{color:'darkRed'}}>DEBUG: Price calculation validation: Errors found!</div>
                  <ul style={{color:'darkRed'}}>
                    { costCalcDiffs.map((calcDiff, idx) => {
                        const diffStyle = idx === costCalcDiffs.length - 1 
                          ? lastItemStyle
                          : itemStyle; 
                        return <li key={btoa(calcDiff)} style={diffStyle}>{calcDiff}</li>;
                      })
                    }
                  </ul>
                </>
              : <div style={{color:ZIP_RUN_GREEN}}>
                  DEBUG: Price calculation validation: OK!
                </div>  
            }
          </div>   
        : null  
      } 
    </>
  )
}

export default ValidateOrderResults