import React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import config from '../../config.js';
import { navigateTop } from '../routing/router-utils'; 
import ButtonWithPrice from '../styleguide/ButtonWithPrice';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

import styles from './Cart.module.css';

/**
 * Calculate the total product price for checkout
 * 
 * Reconfirm delivery zip codes, minimum order and handle login if needed
 */
const CheckOutButton = ({ 
  dispensaryId,
  minOrderDelivery=0, 
  closeMenuFn }) => {

  const { dispensary } = React.useContext(DispensaryContext);
  const { itemTotal } = React.useContext(CartItemsContext);
  const { location:delivery_address } = React.useContext(UserContext);

  const anonymousZipCode = '02140'; /* TODO: FiXME */

  const isOrderingPaused = config.PAUSED_DISPENSARIES.includes(dispensaryId);  

  const [minOrderMet, setMinOrderMet] = React.useState();
  const [isDeliverableZipCode, setIsDeliverableZipCode] = React.useState();        
  const [zip_code, setZipCode] = React.useState();

  React.useEffect(() => {
    if (itemTotal) {
      // Minimum order
      setMinOrderMet(itemTotal >= minOrderDelivery); 
      // Delverable zip code
      const newZipCode = delivery_address?.zip_code || anonymousZipCode;
      if (zip_code !== newZipCode) {
        setZipCode(newZipCode);
        const dispensaryZipCodes = dispensary.delivery_zip_codes || [];
        setIsDeliverableZipCode(dispensaryZipCodes.indexOf(newZipCode) > -1); 
      }    
    }
  }, [dispensary, itemTotal, minOrderDelivery, anonymousZipCode, delivery_address, setZipCode, zip_code]);
  
  // Format fordisplay
  const minOrder = parseFloat(minOrderDelivery/100).toFixed(2);
  const itemTotalFloat = parseFloat(itemTotal/100).toFixed(2);
  
  const proceedToCheckout = (event) => {
    event.preventDefault();
    // close mobile cart menu
    if ( closeMenuFn ) {
      closeMenuFn(); 
    }
    if (isDeliverableZipCode) {
      navigateTop(`/menu/${dispensaryId}/checkOut/`);
    } 
  };
 
  return( dispensary?.id
    ? <div className={styles.toCheckoutForm}>
        <form>
          { isOrderingPaused
            ? <DisabledButtonWithReason text="Checkout" isFullWidth
                  reason={`Online ordering is currently paused for ${dispensary.name}`} /> 
            :  isDeliverableZipCode && minOrderMet
              ? <ButtonWithPrice isFullWidth text="Checkout" price={itemTotalFloat} handleClick={proceedToCheckout} />
              : <>
                  <ButtonWithPrice isFullWidth text="Checkout" price={itemTotalFloat} disabled handleClick={(e) => { e.preventDefault() }} />
                  <div className={styles.disabledMsg}>
                    { isDeliverableZipCode
                      ? <>
                          <sup className={styles.minOrderSup}>*</sup>
                          Minimum product order: ${minOrder}
                        </>
                      : <>
                          { zip_code
                             ? <>
                                 <sup className={styles.minOrderSup}>*</sup>
                                 Delivery not available to {zip_code}
                               </>
                             : <></>
                          }
                        </>
                    }
                  </div>
                </>
          }
        </form>
      </div>
    : <Spinner />  
  )
}

CheckOutButton.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  minOrderDelivery: PropTypes.number.isRequired,
  closeMenuFn: PropTypes.func
};

export default CheckOutButton;