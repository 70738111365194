import * as React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';

import PropTypes from 'prop-types';

/**
 * Displays Image Coming Soon, etc.
 */
const MissingImageLabel = ({withStyle={}}) => { 
 
  const { theme } = React.useContext(ThemeContext);

  // Specific to CartItem.jsx context
  const themeStyle = {
    fontFamily: 'Verdana, sans-serif',
    color: '#000',
    backgroundColor: `${theme.primaryBackgroundColor}bb`,
    textAlign: 'center'
  } 

  const labelStyle = Object.assign(themeStyle, withStyle);
  
  return ( 
    <div style={labelStyle}>
      Image Coming Soon
    </div>
  )
}

MissingImageLabel.propTypes = {
  withStyle: PropTypes.object
}

export default MissingImageLabel;