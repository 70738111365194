import * as React from 'react';

import useHostDomain from '../messaging/useHostDomain';
import { sendMessage } from './embed-utils';
import useResizeObserver from '../util/useResizeObserver';
import PageHeader from '../common/PageHeader';
import PoweredBy from '../common/PoweredBy';

import styles from './EmbeddedMenu.module.css';

/**
 *  This component keeps track of the page height and messages the host page
 *  as the content height changes so the host page iframe is always the correct height.  
 */
const EmbedPageLayout = ({children}) => {

  const hostDomain = useHostDomain();  

  const wrapperRef = React.useRef();
  const wrapperHeight = React.useRef(); 
  const timerRef = React.useRef();

  const updateIframeHeight = (element) => {
    if (element[0] && element[0].target) {
      const newHeight = Math.max(1000, element[0].target.offsetHeight);
      if (newHeight && newHeight !== wrapperHeight.current) {
        wrapperHeight.current = newHeight;
        window.clearTimeout(timerRef.current);
        timerRef.current = window.setTimeout(() => {
          sendMessage({ frameHeight:newHeight }, hostDomain); 
        }, 500);  
      }
    }
  };

  useResizeObserver(updateIframeHeight, wrapperRef);

  React.useEffect(() => {
    console.log('layout render');    
  }, []);

  return (
    <div id="pageWrapper" ref={wrapperRef}>
      <PageHeader />
      <div id="layout" className={styles.pageLayout}>
        {children}
      </div>
      <PoweredBy />
    </div> 
  );
};

export default EmbedPageLayout;