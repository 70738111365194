import * as React from "react";

import { formatDateString } from '../notifications/notification-utils';
import PropTypes from 'prop-types';

import styles from './YourAccount.module.css';

/**
 * The Your Account header containing static account info, Name etc.
 */
const YourAccountHeader = ({fullName, email, user}) => {
  return (
    <div className={styles.headerWrap}>
      { user &&   
        <>
          <div className={styles.headerItem}>
            <div className={styles.accountLbl}>Name</div>
            <div className={styles.accountVal}>{fullName}</div>
          </div>
          <div className={styles.headerItem}>
            <div className={styles.accountLbl}>Email</div>
            <div className={styles.accountVal}>{email}</div>
          </div>
          <div className={styles.headerItem}>
            <div className={styles.accountLbl}>Member since</div> 
            <div className={styles.accountVal}>{ 
              formatDateString(new Date(parseInt(user.metadata.a, 10)).toDateString())
            }</div>
          </div>
        </>
      }
    </div>
  );
}

YourAccountHeader.propTypes = {
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default YourAccountHeader;