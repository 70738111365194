import * as React from 'react';

import { optimizedImage } from '../products/CroppedProductImage';
import { trackEvent } from '../analytics/tracking';

import PropTypes from 'prop-types';

// Used in setting default widths 
export const ImageType = {
  DISPENSARY_LOGO: 'logo',
  DISPENSARY_HERO: 'hero',
  DEFAULT: 'default' /* 400 width */
};

// Related widths for CloudFlare resizing
const ImageWidths = {
  'logo': 300,
  'hero' : 1200,
  'default' :400  
}

/**
 * Resize/CDN an image but handle CDN failure with nonCDN fallback onError
 */
const OptimizedImage = ({
  imgSrc,
  imgAlt,
  imgClass='',
  imgType=ImageType.DEFAULT,
  width /* not implemented yet */
}) => {
  
  const [optimizedImgSrc, setOptimizedImgSrc] = React.useState();
  const [imgSrcNoCDN, setImgSrcNoCDN] = React.useState();

  // Sometimes CloudFlare returns 503 or even a 404 during outtages...  
  const handleError = (event) => {
    trackEvent('img_load_error', event.currentTarget.src);
    // Switch to non-CDN image:
    setOptimizedImgSrc(imgSrcNoCDN);
    // we only need to log once
    event.currentTarget.onError = null;
  };
  
  React.useEffect(() => {
    // Set a noCDN image in case CDN fails (onError)
    if (!optimizedImgSrc) {
      setOptimizedImgSrc(optimizedImage(imgSrc), ImageWidths[imgType]);
      setImgSrcNoCDN(imgSrc);
    }
  },[imgSrc, imgType, optimizedImgSrc]);
  
  return ( 
    optimizedImgSrc
    ? <img
        src={optimizedImgSrc}
        alt={imgAlt}
        className={imgClass}
        onError={(event) => handleError(event)}
        />
    : null 
  )
}

OptimizedImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgClass: PropTypes.string,
  width: PropTypes.number 
}

export default OptimizedImage;