import React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

// Handle button hover styling
const useStyles = makeStyles({
  button: {
    fontFamily: (props) => props.fontFamily,
    borderColor: (props) => props.primaryColorDark,
    backgroundColor: 'transparent',
    color: (props) => props.primaryColorDark,
    '&:hover': {
      backgroundColor: (props) => props.primaryColorDark,
      color: '#fff'
    }
  }   
});

const DetourButton = ({text, isCentered, isSmall, withStyle={}, handleClick}) => {

  const { theme } = React.useContext(ThemeContext);
  const classes = useStyles(theme);

  const smallStyle = isSmall ? { fontSize: '.875rem', padding: '.25rem .75rem'} : {}
  const centerStyle = isCentered ? { display:'block', marginLeft:'auto', marginRight: 'auto' } : {};
  const optionalStyle =  Object.assign(centerStyle, smallStyle);

  const propsStyle = Object.assign(optionalStyle, withStyle);
  
  return (
    <button
      className={`${styles.detourButton} ${classes.button}`}
      style={propsStyle}
      onClick={handleClick}
    >
      {text} 
    </button>
  );
};

DetourButton.propTypes = {
  text: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  isSmall: PropTypes.bool,
  withStyle: PropTypes.object, 
  handleClick: PropTypes.func
}

export default DetourButton;