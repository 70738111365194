import * as React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import { UrlParamsContext } from '../providers/UrlParamsProvider';
import useMenuNavigation from '../routing/useMenuNavigation';
import OrderTypeButtons from './OrderTypeButtons';
import Burger from 'react-css-burger';
import MenuItems from '../hamburgerMenu/MenuItems';
import SlideInCart from '../checkout/SlideInCart';
import ErrorBoundary from './ErrorBoundary';

import styles from './PageHeader.module.css';

const PageHeader = () => {

  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { themes, setTheme } = React.useContext(ThemeContext);
  
  // Hamburger menu state
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuNavigateTop = useMenuNavigation();

  // Theme testing
  const handleSelect = (e) => {
    setTheme(e.target.value);
  };

  return (
      <> 
        <ErrorBoundary>
          <div id="header" className={styles.pageHeader}>
            <div className={styles.burgerWrap}>
              <div id="burgerIcon">
                <Burger 
                  active={menuOpen}
                  burger='spring' 
                  onClick={() => setMenuOpen(!menuOpen)} 
                  hoverOpacity={0.8}
                  scale={.6}
                  style={{margin: '8px 2px', position:'relative', 'zIndex': 3, boxShadow:'none'}}
                />
              </div>
              <MenuItems open={menuOpen} setOpen={setMenuOpen} /> 
            </div>

            <div className={styles.navLinks}>
              <span className={styles.navLink} onClick={() => {
                  menuNavigateTop(`/menu/${dispensaryId}`);
                }}>
                Home
              </span>
            </div>       

            <OrderTypeButtons />

            <div className={styles.centerArea}>
              <div className={styles.themeToggle}>
                <label style={{fontSize:'.875rem', margin:'0 .25rem 0 1rem'}}>Theme:</label>
                <select onChange={handleSelect}>
                  { themes?.map((theme) => {
                      return <option key={theme} value={theme}>{theme}</option>
                    })
                  }
                </select>
              </div> 
            </div>

            <div className={styles.cartWrap}>
              <SlideInCart />
            </div>
          </div>
        </ErrorBoundary>
    </>
  );  
}

export default PageHeader;
