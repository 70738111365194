import * as React from "react";

import { navigateTop } from "../routing/router-utils";
import { Helmet } from "react-helmet";
import { RegistrationContext } from "./RegistrationProvider";
import SignUpInline from "./SignUpInline";
import RegistrationSuccessMsg from "./RegistrationSuccessMsg";
import BodyWrapper, { LayoutTypes } from "../layout/BodyWrapper";
import DisplayModal from "../common/DisplayModal";
import PropTypes from "prop-types";

/**
 * Single page sign up, replaces old reg flow
 */
const SignUpPage = ({location}) => {
  
    // The RegistrationProvider will be used to monitor registration success
    const { showSuccessMessage:registrationSuccess, setShowSuccessMessage } = React.useContext(RegistrationContext);
    
    const [returnToPath, setReturnToPath] = React.useState("/");
  
    // Within the app return user to referrer path
    React.useEffect(() => {
      if (location?.state?.returnToPath) {
        const path = location?.state?.returnToPath;     
         if ( path !== returnToPath) {
           console.log(`return: ${path}`);
           setReturnToPath(path);
         }
      }
    }, [location, returnToPath]);

    // Return to shopping 
    const handleCancel = () => {
      navigateTop(returnToPath);
    };
  
    // Return to shopping 
    const handleComplete = () => {
      setShowSuccessMessage(false);
      navigateTop(returnToPath);
    }
  
    return (
      <>
        <BodyWrapper pageLayout={LayoutTypes.Narrow} withStyle={{padding:'2rem 0 0'}}>
          <Helmet>
            <title>Zip Run - Sign Up</title>
          </Helmet>
          <SignUpInline onCancel={handleCancel} />
        </BodyWrapper>

        { registrationSuccess &&
          <DisplayModal title="Sign Up Successful" closeFn={handleComplete}>
            <RegistrationSuccessMsg onContinue={handleComplete} /> 
          </DisplayModal>
        }     
      </>
    );
  }
  
  SignUpPage.props = {
    returnToPath: PropTypes.string
  }

  export default SignUpPage;  