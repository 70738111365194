import { trackEvent } from '../analytics/tracking';
import { SET_FEE_STRUCTURE, SET_BULK_DISCOUNT } from '../actions/action-types/cart-actions'

// tracking context defaults ( can be overridden by dispatches)
const defaultActionContext = {
  [SET_FEE_STRUCTURE] : 'new_session'
};

// handle possibly missing data
//  const productFlowerGrams = (product) => {
//    return product.flower_equivalent_grams || 0; 
//  }

// Restore Cart-related functions
/**
const restoreAddedItems = (dispensaryId, items, state) => {
  const productsById = state.dispensaryProductsById.get(dispensaryId);
  if (productsById) {
    return items.map((item) => {
      const product = productsById.get(item.id);
      if (product) { 
        product.quantity = item.quantity;
      }
      return product;   
    });
  // Products not loaded
  } else {
    trackEvent('error_restore_cart');
    return [];
  }
}; 

const restoreFlowerGrams = (items) => {
  return items.reduce((total, item) => {
    return total + (item.quantity * (item.flower_equivalent_grams || 0)) 
  }, 0);
};
**/

const initState = {
  tel_number: '',
  user_name: '',
  cannabis_tax_percent: 0, /* new */
  sales_tax_percent: 0,    /* new */ 
  delivery_fee_cents: 0, 
  delivery_service_fee_percent: 0,
  pickup_fee_cents: 0,
  pickup_service_fee_percent: 0,
  pickup_in_store_payment_fee_percent: 0,
  bulk_discount_cents: 0,
  bulk_discount_label: '',
  flower_equivalent_grams: 0
};

const cartReducer = (state = initState, action) => {

  // first log action - ignore redux init
  if (action.type && action.type.indexOf('@@redux') === -1) {
    // action, type (context), id (product/dispensary)
    const context = defaultActionContext[action.type] || '';
    // default context is dispensary id 
    const { dispensaryId } = action;
    // default Id is product id (add to cart) or dispensary name (set address etc.)
    const Id = action.Id || dispensaryId;
    trackEvent( action.type, context, Id);
  }

  // Set meta data total cost calcs
  if (action.type === SET_FEE_STRUCTURE){
    return {
      ...state,
      cannabis_tax_percent: action.cannabis_tax_percent, /* new */
      sales_tax_percent: action.sales_tax_percent,       /* new */
      delivery_fee_cents: action.delivery_fee_cents,
      delivery_service_fee_percent: action.delivery_service_fee_percent,
      pickup_fee_cents: action.pickup_fee_cents,
      pickup_service_fee_percent: action.pickup_service_fee_percent,
      pickup_in_store_payment_fee_percent: action.in_store_payment_fee_percent
    }     
  }
  
  // Set any bulk discount for the current order
  if (action.type === SET_BULK_DISCOUNT){
    return {
      ...state,
      bulk_discount_cents: action.discount_cents,
      bulk_discount_label: action.discount_label
    }     
  }

  // default
  return state;
}

export default cartReducer;
