// Freeze document scroll on modal display from CSS Tricks
// timeout added for React 
export const freezeBody = () => {
  const scrollY = window.scrollY;
  window.setTimeout(() => {
    const bodyStyle = document.body.style;
    bodyStyle.position = 'fixed';
    bodyStyle.width = '100vw';
    bodyStyle.top = `-${scrollY}px`;
  }, 1);
};  

// Unfreeze document scroll on modal close from CSS Tricks
export const unfreezeBody = () => {
  window.setTimeout(() => {
    const bodyStyle = document.body.style;
    const scrollY = bodyStyle.top;
    bodyStyle.position = '';
    bodyStyle.width = '';
    bodyStyle.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }, 1);
};    