import * as React from 'react';

import { OrderType } from '../checkout/order-utils';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import styles from './YourOrders.module.css';

const OrderStatusHelp = ({orderType}) => {
  return (
    <>
      <PrimaryHeader isCentered text="Order Statuses" />
      <dl className={styles.statusHelp}>
        <dt>Requested</dt> 
        <dd>Order submitted (order can be canceled prior to acceptance).</dd>
        <dt>Accepted</dt>
        <dd>Your order is in process.</dd> 
        { orderType === OrderType.PICKUP
          ? <>
              <dt>Ready-for-pickup</dt>
              <dd>Your order is ready to be picked up.</dd> 
              <dt>Completed</dt>
              <dd>Your order was picked up.</dd>  
            </>
          : <>  
              <dt>Ready-for-pickup</dt>
              <dd>Awaiting driver pickup.</dd> 
              <dt>In-transit</dt>
              <dd>Out for delivery.</dd> 
              <dt>Completed</dt>
              <dd>Your order was delivered.</dd> 
            </>
        }
        <dt className={styles.cancelTerm}>Canceled</dt>
        <dd>Your ordered was canceled.</dd>
      </dl>
    </>
  )
}

export default OrderStatusHelp;