import React from 'react';
import PropTypes from 'prop-types';

import styles from './Stylesheet.module.css';

const XLargeHeader = ({text, children}) => {
  return (
    <div className={styles.xLargeHeader}>
      {text}
      {children} 
    </div>
  );
};

XLargeHeader.propTypes = {
  text: PropTypes.string
}

export default XLargeHeader;