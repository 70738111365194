import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import PropTypes from 'prop-types';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

const BackLink = ({backToPath, backToText="Back", onClick}) => {

  const backClick = (event) => {
    event && event.stopPropagation();
    if (typeof onClick === "function") {
      onClick();
    } else if (backToPath) {
      navigateTop(backToPath)
    }
  };

  return (
    <div style={{ display:'inline-flex',cursor:'pointer',marginTop:'2rem' }} onClick={backClick}>
      <ArrowBackRoundedIcon />
      <div style={{lineHeight:'24px', marginLeft:'.5rem', fontWeight:500}} >{backToText}</div>
    </div>  
  );
};

BackLink.propTypes = {
  backToPath: PropTypes.string,
  backToText: PropTypes.string,
  onClick: PropTypes.func
};

export default BackLink;