import * as React from "react";

import { UserContext } from "../providers/UserProvider";
import { OrderType } from '../checkout/order-utils';
import { getReadNotifications } from '../notifications/notification-utils';
import { OrderContext } from '../orders/OrderProvider';

export const NotificationsContext = React.createContext({});

const NotificationsProvider = ({children}) => {
  
  // IDs for the 2 possible delivery credit notifications
  const deliveryCreditIds = ['credit1', 'credit2'];

  const { user } = React.useContext(UserContext);

  // Order history to determine credits
  const { recentOrders } = React.useContext(OrderContext);
  
  // Free Delivery Credit Notifications
  const [creditNotifications, setCreditNotifications] = React.useState();

  // UnViewed Notifications  
  const [viewedIds, setViewedIds] = React.useState();

  // All we have currently are credit notifications
  const setAllViewed = (notificationIds) => {
    setViewedIds(notificationIds);
  };

  // Determine Credit Notifications  
  React.useEffect(() => {
    if (recentOrders?.length && !creditNotifications) {
      const completedPickupOrders = recentOrders.filter((order) => {
        return order.type === OrderType.PICKUP && order.status === 'completed';
      });
 
      // Max 2 credits
      const freeDeliveryCredits = Math.min(completedPickupOrders.length, deliveryCreditIds.length);   
      if (creditNotifications?.length !== freeDeliveryCredits) {
        console.log('EFFECT: setting applicable notifications');
        setCreditNotifications(deliveryCreditIds.slice(0,freeDeliveryCredits));
      }
    }
  },[recentOrders, deliveryCreditIds, creditNotifications])
  
  // Determine Read Notifications  
  React.useEffect(() => {
    const setViewedItems = (response) => {
      if (response.data && !viewedIds)  {
        setViewedIds(response.data);
      }
    }
    if (user && !user.isAnonymous && !Array.isArray(viewedIds)) {  
      console.log('EFFECT: fetching read notifications');
      getReadNotifications(setViewedItems, new AbortController());
    }
  }, [user, viewedIds]);  

  return (
    <NotificationsContext.Provider value={{
      notifications: creditNotifications,
      viewedIds,
      setAllViewed 
    }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;