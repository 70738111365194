import * as React from 'react';

import { devLog } from '../util/util';

/**
 * This hook calculates the bulk prices and discounts using the product's
 * leaf_logix_pricing_tier.pricingTierData
 */
const useBulkPricing = (pricingTierData=[]) => {

  // We're handling standard flower weights and ignoring the rest
  const displayWeights = new Map([
    [3.5, '1/8 oz'],
    [7, '1/4 oz'],
    [14, '1/2 oz'],
    [28, '1 oz']
  ]);
  const SUPPORTED_PRODUCT_WEIGHTS = Array.from(displayWeights.keys());
  
  // Product weights
  const [productWeights, setProductWeights] = React.useState([]); 
  // Calced bulk price by weight
  const [pricingMap, setPricingMap] = React.useState();
  // Calced discount by weight 
  const [discountMap,setDiscountMap] = React.useState();

  // Calc the discounted price
  const calculateBulkPrices = React.useCallback((tierData) => {
    const bulkPricingMap = new Map();
    if (tierData?.length) {
      tierData.forEach(tier => {
        const price = Math.round(tier.startWeight * tier.price * 100);
        bulkPricingMap.set(tier.startWeight, price); 
      });
    }        
    return bulkPricingMap;
  },[]);

  // Calc the discount from full price
  const calculateDiscount = React.useCallback((discountMap, productWeights) => {
    const bulkDiscountMap = new Map();
    if (discountMap?.size > 0) {
      // Sort weights lowest to highest
      const startWeights = productWeights.sort((a, b) => { return a - b });
      // Calc the base non-discount price
      const baseWeight = startWeights[0];
      const basePrice = discountMap.get(baseWeight);
      // Populate map
      startWeights.forEach(tier => {
        const price = discountMap.get(tier);
        const count = tier / baseWeight;
        const fullPrice = count * basePrice;
        bulkDiscountMap.set(tier, fullPrice - price); 
      });
    }       
    return bulkDiscountMap;
  },[]); 

  // Build the pricing/discount by weight maps
  React.useEffect(() => {
    if (pricingTierData && !pricingMap) {
      devLog('useBulkPricing - doing the calc..');
      // Handle only standard/supported flower weights: 1/8, 1/4, 1/2 or 1 oz.
      const validWeights = pricingTierData.reduce((arr, tier) => {
        if (SUPPORTED_PRODUCT_WEIGHTS.includes(tier.startWeight)) {
          arr.push(tier.startWeight);
        }
        return arr;
      }, []); 
      setProductWeights(validWeights);
      const bulkPrices =  calculateBulkPrices(pricingTierData, validWeights); 
      setPricingMap(bulkPrices);
      setDiscountMap(calculateDiscount(bulkPrices, validWeights));
    } 
  }, [pricingTierData, pricingMap, setPricingMap, 
      setDiscountMap, setProductWeights, calculateBulkPrices, 
      calculateDiscount, SUPPORTED_PRODUCT_WEIGHTS]);

  return [ pricingMap, discountMap, productWeights, displayWeights ]; 
}

export default useBulkPricing;
