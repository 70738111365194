import React from 'react';

import config from '../../config';
import { useDispatch } from 'react-redux';
import { fetchUserInfo, updateUserInfo } from '../registration/registration-utils';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import YourAccountHeader from './YourAccountHeader';
import { OrderContext } from '../orders/OrderProvider';
import ActiveOrdersWarning from '../orders/ActiveOrdersWarning';
import DeliveryCreditStatus from './DeliveryCreditStatus';
import DisplayNotifications from '../notifications/DisplayNotifications' 
import ChangePasswordForm from './ChangePasswordForm';
import AddressForm from './AddressForm';
// import VerifyEmailForm from './VerifyEmailForm';
import PhoneNumberForm from './PhoneNumberForm';
import SelectIDPhotos from '../registration/SelectIDPhotos';
import PaymentForm from './PaymentForm';
import SubscribeForm from './SubscribeForm';
import { UserContext } from '../providers/UserProvider';
import { trackEvent } from '../analytics/tracking';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import Spinner from '../common/Spinner';
import ErrorMessage from '../styleguide/ErrorMessage'; 
import SuccessMessage from '../styleguide/SuccessMessage'; 
import { Helmet } from "react-helmet";

import styles from './YourAccount.module.css';

const YourAccount = () => {

  const aeroPayEnabled = config.AEROPAY_ENABLED;

  const dispatch = useDispatch();

  // To cancel XHR requests on unmount
  const abortController = new AbortController(); 
  const updateAbortController = new AbortController();

  const noUpdateMsg = ('');
  
  const [fullName, setFullName] = React.useState();
  const [email, setEmail] = React.useState();

  // Address update
  const addressRef = React.useRef(); 
  const [address, setAddress] = React.useState({});
  const [updateMsg, setUpdateMsg] = React.useState(noUpdateMsg);
  
  // Mobile update
  const mobileRef = React.useRef();
  const [tel_number, setTel_Number] = React.useState();
  const [mobileUpdateMsg, setMobileUpdateMsg] = React.useState(noUpdateMsg);

  // Photo ID update
  const photoIDRef = React.useRef();
  const [photoIDUpdateMsg, setPhotoIDUpdateMsg] = React.useState(noUpdateMsg);
  const [hasExistingPhotos, setHasExistingPhotos] = React.useState(false);

  // Subscribe update
  const subscribeRef = React.useRef();
  const [emailEnabled, setEmailEnabled] = React.useState(false);
  const [textEnabled, setTextEnabled] = React.useState(false);
  const [subscribeMsg, setSubscribeMsg] = React.useState(noUpdateMsg);

  const { user } = React.useContext(UserContext);

  const { activeOrders, recentOrders } = React.useContext(OrderContext);
  const controllerRef = React.useRef(new AbortController());

  React.useEffect(() => {
    const abortController = controllerRef.current;
    // fetch the user info once
    if (user && !user.isAnonymous && !address.city){
      fetchUserInfo((info) => {
        if (info.error) {
          // TODO: general error message
        } else {
          setFullName(info.name);
          setEmail(info.email);
          setAddress(info.location);
          setTel_Number(info.tel_number);
          setEmailEnabled(info.marketing_communications.email_opted_in);
          setTextEnabled(info.marketing_communications.sms_text_opted_in);
          // ID messsage based on status
          setHasExistingPhotos(!!info.photo_ids?.primary?.url && !!info.photo_ids?.secondary?.url);
          // setPrimaryImageUrl(info.photo_ids.primary.url);
          // setSecondaryImageUrl(info.photo_ids.secondary.url);
        }
      }, abortController);
    }
    // return () => abortController.abort();
  }, [user, address.city, setAddress, setTel_Number, abortController]);

  // Send the update
  const handleSubmit = (payload, sectionRef, msgHandler) => {
    if (sectionRef.current) {
      sectionRef.current.classList.add(styles.faded);
    }
    msgHandler(noUpdateMsg);
    updateUserInfo(payload, (responseOk, response) => { updateCallback(responseOk, response, payload, sectionRef, msgHandler) }, updateAbortController);
  };
  
  // Handle the response: status === 204 or error in response 
  const updateCallback = (responseOk, response, payload, sectionRef, msgHandler) => {
    // status === 204 
    if (responseOk) {
      msgHandler(<SuccessMessage isInline text="Updated!" />);
      let updateType;
      if (payload.location) {
        setAddress(payload.location);
        updateType = 'location';
      }
      if (payload.tel_number) {
        setTel_Number(payload.tel_number);
        updateType = 'tel_number';
      }
      // Sync the nav and cart data
      // the state update is not immediate so we reference the payload values
      if (updateType) {
        updateReduxFromState(payload);
      }
      updateType = 'photoID';
      trackEvent('user_update_success', updateType, user.displayName);
    } else {
      const error = response.error || 'Unable to update your account';
      msgHandler(<ErrorMessage isInline text={error} />);
      trackEvent('user_update_error', error, user.displayName);
    }
    // unfade the section
    if (sectionRef.current) {
      sectionRef.current.classList.remove(styles.faded);
    }
  };
 
  const updateReduxFromState = (payload) => {
    const mergedData = Object.assign({
      location: address,
      tel_number
    }, payload);
    // dispatch(setDeliveryAddress( mergedData.location, mergedData.tel_number, user.displayName ));
  };


  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
        <Helmet>
          <title>Zip Run - Your Account</title>
        </Helmet>
        {/* if we have the city we have all the user data */}
        { address.city
          ? <div className={styles.yourAccountWrap}>
              <PrimaryHeader text="Your Account" />

              {/* Account updates are disabled when orders are active */}
              { activeOrders?.length > 0 &&
                <ActiveOrdersWarning />
              }

              {/* Show Free Delivery credits when applicable */}
              { recentOrders?.length > 0 &&
                <DeliveryCreditStatus recentOrders={recentOrders} />
              }  

              <div className={styles.formWrap}>
                <YourAccountHeader fullName={fullName} email={email} user={user} />
                
                <div className={`${styles.accountSection} ${styles.withForm}`}>
                  <div className={styles.accountLbl}>Notifications</div>
                  <div className={styles.sectionForm}>   
                    <DisplayNotifications 
                      dateJoined={user.metadata?.a}
                      recentOrders={recentOrders} />
                  </div>
                </div>
                
                <div className={`${styles.accountSection} ${styles.accountPassword}`}>
                  <div className={styles.accountLbl}>
                    Password
                  </div>
                  <ChangePasswordForm userEmail={email} />
                </div>
  
                <div ref={addressRef} className={`${styles.accountSection} ${styles.withForm}`}>
                  <div className={styles.accountLbl}>Address</div>  
                  <div className={styles.sectionForm}>  
                    <AddressForm 
                      street_address={address.street_address} 
                      city={address.city} 
                      state={address.state} 
                      zip_code={address.zip_code} 
                      apartment_number={address.apartment_number}
                      handleSubmit={handleSubmit}
                      sectionRef={addressRef}
                      msgHandler={setUpdateMsg}
                      updateMsg={updateMsg}
                    />
                  </div>
                </div>

                {/* Save Email Verification until we need it 
                <div className={`${styles.accountSection} ${styles.withForm}`}>
                  <div className={styles.accountLbl}>Email</div>  
                  <div className={styles.sectionForm}>  
                    <VerifyEmailForm email={email} />
                  </div>
                </div>
                */}

                { tel_number && 
                  <div ref={mobileRef} className={`${styles.accountSection} ${styles.withForm}`}>
                    <div className={styles.accountLbl}>Mobile</div>  
                    <div className={styles.sectionForm}>
                      <PhoneNumberForm 
                        tel_number={tel_number} 
                        handleSubmit={handleSubmit} 
                        sectionRef={mobileRef}
                        msgHandler={setMobileUpdateMsg}
                        updateMsg={mobileUpdateMsg} />
                    </div>  
                  </div>
                }
                
                { config.ENABLE_ID_UPLOAD === true &&
                  <div ref={photoIDRef} className={`${styles.accountSection} ${styles.withForm}`}>
                    <div className={styles.accountLbl}>Photo ID</div>
                    <div className={styles.sectionForm}>
                      <SelectIDPhotos
                        email={email}
                        hasExistingPhotos={hasExistingPhotos}
                        sectionRef={photoIDRef}
                        handleSubmit={handleSubmit}
                        msgHandler={setPhotoIDUpdateMsg}
                        updateMsg={photoIDUpdateMsg} />
                    </div>
                  </div>
                } 
                
                { aeroPayEnabled &&
                  <div className={`${styles.accountSection} ${styles.withForm}`}>
                    <div className={styles.accountLbl}>Payment</div>
                    <div className={styles.sectionForm}>  
                      <PaymentForm /> 
                    </div>
                  </div>
                }

                <div ref={subscribeRef} className={`${styles.accountSection} ${styles.withForm}`}>
                  <div className={styles.accountLbl}>Subscribe</div>
                  <div className={styles.sectionForm}>  
                    <SubscribeForm
                      emailEnabled={emailEnabled}
                      textEnabled={textEnabled}  
                      sectionRef={subscribeRef} 
                      handleSubmit={handleSubmit} 
                      msgHandler={setSubscribeMsg} 
                      updateMsg={subscribeMsg}
                    />
                  </div>
                </div>

              </div>
            </div>
          : user?.isAnonymous
            ? <div>Sign in please...</div>
            : <Spinner />
        }
    </BodyWrapper>
  )
}

export default YourAccount
