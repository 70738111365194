import * as React from 'react';

import GoogleFontLoader from 'react-google-font-loader';
import PropTypes from 'prop-types';

export const ThemeContext = React.createContext();

export const themeType = {
  DEFAULT: 'default',
  BERKSHIRE: 'berkshire',
  CAMPFIRE: 'campfire',
  REDI: 'redi'
};

const themeSettings = {
  'default' : {
    textColor: '#000',
    cartColor: 'var(--zrGreen)',
    buttonColor: '#054052', 
    buttonTextColor: 'white',
    fontFamily: 'Nunito Sans',
    headerWeight: 800,
    primaryBackgroundColor: '#2da700',
    primaryColorDark: '#2da700',
    promoBorderColor: '#d6dadd',
    promoBackgroundColor: '#f3f6f8',
    promoBorderRadius: '1.5rem',
    promoListStyle: 'none' 
  },
  'campfire': {
    textColor: '#1a2a4a',
    cartColor: '#fab039',
    buttonColor: '#fab039',
    buttonHoverColor: '#d94526',
    buttonTextColor: 'white',
    fontFamily: 'Corben',
    headerWeight: 600,
    primaryBackgroundColor: '#fab039',
    primaryColorDark: '#D18305',
    promoBorderColor: '#fae9da',
    promoBackgroundColor: '#fae9da',
    promoBorderRadius: '.75rem',
    promoListStyle: 'disc'
  },
  'berkshire' : {
    textColor: '#000',
    cartColor: '#059447',
    buttonColor: '#059447', 
    buttonTextColor: 'white',
    fontFamily: '"Roboto Condensed",sans-serif',
    headerWeight: 600,
    primaryBackgroundColor: '#6cc997',
    primaryColorDark: '#059447',
    promoBorderColor: '#ebf6ec',
    promoBackgroundColor: '#ebf6ec',
    promoBorderRadius: '.75rem',
    promoListStyle: 'disc'
  },
  'redi' : {
    textColor: '#7a7a7a',
    cartColor: '#e87722',
    buttonColor: '#7cb1c6', 
    buttonTextColor: '#000',
    fontFamily: 'Montserrat',
    headerWeight: 600,
    primaryBackgroundColor: '#7cb1c6',
    primaryColorDark: '#589DB6',
    promoBorderColor: '#6ec1e4',
    promoBackgroundColor: '#f1f8fb',
    promoBorderRadius: '1.25rem',
    promoListStyle: 'disc'
  }
}

const ThemeProvider = ({children}) => {
  
  const [theme, setCurrentTheme] = React.useState(themeSettings[themeType.DEFAULT]); 
  const [font, setFont] = React.useState(themeSettings['default'].fontFamily);

  // Set / Update theme
  const setTheme = (type) => {
    if (themeSettings[type]) {
       setCurrentTheme(themeSettings[type]);
       setFont(themeSettings[type].fontFamily)
    }  
  };

  return <ThemeContext.Provider value={{
      themes: Object.keys(themeSettings),
      theme,
      setTheme
    }}>
    <GoogleFontLoader
      fonts={[
        {
          font,
          weights: [400, 600, 700, 800, 900, '700i', '800i', '900i'],
        }
      ]}
      subsets={[]}
    /> 
    {children}
  </ThemeContext.Provider>
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default ThemeProvider;