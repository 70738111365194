import * as React from 'react';

import { OrderType } from '../checkout/order-utils';
import ZRBag from '../../assets/fullBag.png';  
import styles from './DeliveryCreditStatus.module.css';

const DeliveryCreditStatus = ({recentOrders=[]}) => {
  const MAX_DELIVERY_CREDITS = 2;
  const [creditCount, setCreditCount] = React.useState(0);

  React.useEffect(() => {
    if (recentOrders.length) {
      const completedPickupOrders = recentOrders.filter((order) => {
        return order.type === OrderType.PICKUP && order.status === 'completed';
      });
      setCreditCount(Math.min(completedPickupOrders.length, MAX_DELIVERY_CREDITS));
    }
  },[recentOrders, creditCount, setCreditCount])
  
  return (
    <>
      { creditCount > 0
        ? <div className={styles.creditBanner}>
            <img className={styles.creditImage} alt="" src={ZRBag} />
            <div className={styles.creditMessage}>
              <span className={styles.salutation}>Congratulations! </span>
              <span className={styles.details}>
                {' '}You've earned 
                {' '}<span className={styles.creditCount}>{creditCount}</span> Free&nbsp;Delivery&nbsp;credits.*
              </span>
              <div className={styles.disclaimer}>*Delivery launching soon!</div>
            </div>
          </div>
        : null 
      }
    </>
  )
}

export default DeliveryCreditStatus;