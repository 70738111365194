import * as React from 'react';

import { auth } from '../../firebase';
import { fetchFastLinkCredentials } from './payment-utils';

/**
 * AeroPay/Yodlee-fastlink accounts are created on behalf of customers using (in part)
 * the user's phone number.
 * 
 * The ZipRun API provides the web app with the necessary tokens to link accounts using the widget. 
 *
 * Note: Linked/Returning customers will not need these credentials so we don't request them
 * until setFastLinkCredentials is called.
 * 
 * @param {func} onSuccess - what to do after successful bank account linking
 */
const useFastLinkCredentials = () => {

  const controllerRef = React.useRef(new AbortController());

  const [fastLinkUser, setFastLinkUser] = React.useState();
  const [token, setToken] = React.useState();
  const [fastLinkURL, setFastLinkURL] = React.useState()

  // Retrieve the username, token, url ( required in part to launch Fastlink widget );
  const credentialsCallback = React.useCallback((response) => { 
    if (response.token) {
      const { username, token, fastlinkURL:fastLinkURL } = response;
      setFastLinkUser(username);
      setToken(token);
      setFastLinkURL(fastLinkURL);
    }  
  }, []);

  const setFastLinkCredentials = React.useCallback((controller) => {
    if (auth.currentUser && !auth.currentUser.isAnonymous) {  
      fetchFastLinkCredentials(credentialsCallback, controller);   
    }
  }, [credentialsCallback]);

  React.useEffect(() => {
    const controller = controllerRef.current;
    return () => controller?.abort();
  }, [fastLinkUser, token, fastLinkURL, setFastLinkCredentials]);
  
  return [fastLinkUser, token, fastLinkURL, setFastLinkCredentials];
}

export default useFastLinkCredentials;