import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { calcTaxCents } from './order-utils';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';

/* Not enough CSS to warrant a module */
const useStyles = makeStyles({
  wrapper: {
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #ccc',
    fontSize: '0.8125rem',
    borderRadius: '.375rem',
    backgroundColor: '#f7f7f7',
    maxWidth: 308,
    boxShadow: '0 0 4px #000c',
    padding: '.25rem .75rem',
    boxSizing:  'border-box' 
  },
  title: {
    fontWeight: '700',
    fontSize: '1rem',
    margin: '.5rem 0 .75rem'
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: '.5rem',
    right: '.5rem',
    cursor:'pointer'
  }, 
  detailsGrid: {
    display: 'flex',
    flexWrap: 'wrap', 
    maxWidth: '600px', 
    margin:'.5rem 0'
  },
  detailLabel: {
    flex: 1,
    flexBasis: '60%',
    paddingBottom: '2px'
  },
  detailValue: {
    flex: 0,
    paddingBottom: '2px'
  },
  totalLabel: {
    fontWeight: 700
  },
  totalValue: {
    fontWeight: 700
  },
  footerText: {
    fontSize:'.75rem', 
    lineHeight:'120%',
    paddingTop: '.75rem',
    borderTop: '1px solid #ccc', 
    margin:'.5rem 0'
  }
});

// TODO: Create a configured/re-useable Popper component
const TaxesTooltip = ({
  dispensaryName,
  itemTotal,
  serviceFeeCents=0,
  salesTaxPercent,
  cannabisTaxPercent}) => {
  
  const classes= useStyles();
  const [open, setOpen] = React.useState(false);
  
  const [cannabisTax, setCannabisTax] = React.useState(0);
  const [salesTax, setSalesTax] = React.useState(0);
  const [totalFeesAndTaxes, setTotalFeesAndTaxes] = React.useState(0);
  const [fees, setFees] = React.useState(0);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  React.useEffect(() => {
    const taxCents = calcTaxCents(itemTotal, salesTaxPercent, cannabisTaxPercent);
    const cannabisTaxCents = Math.ceil((itemTotal * cannabisTaxPercent)/100); 
    setCannabisTax(cannabisTaxCents/100);
    // For the total to add up to the penny, we derive the sales tax 
    // by subtracting the cannabis tax from the rounded-up total.
    // const salesTaxCents = Math.ceil((itemTotal * salesTaxPercent)/100);
    setSalesTax((taxCents - cannabisTaxCents)/100);
    setFees(serviceFeeCents/100);
    setTotalFeesAndTaxes((taxCents + serviceFeeCents)/100);
  }, [itemTotal, salesTaxPercent, cannabisTaxPercent, serviceFeeCents]); 

  const anchorRef = React.useRef();
  
  return (
    <span>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          arrow: {
            enabled: false,
            element: anchorRef.current,
          },
        }}>
        <div className={classes.wrapper}>
          <div className={classes.title}>
            Fees and Estimated Tax:
          </div>
          <div className={classes.closeIcon} onClick={handleClose}>
            <CloseRoundedIcon style={{fontSize:24}} />
          </div>
          <div className={classes.detailsGrid}>
            { serviceFeeCents > 0 &&
              <>
                <div className={classes.detailLabel}>Service Fee</div>
                <div className={classes.detailValue}>${fees.toFixed(2)}</div>
              </>
            }
            <div className={classes.detailLabel}>Cannabis Tax</div>
            <div className={classes.detailValue}>${cannabisTax.toFixed(2)}</div>
            <div className={classes.detailLabel}>Sales Tax</div>
            <div className={classes.detailValue}>${salesTax.toFixed(2)}</div>
            <div className={`${classes.totalLabel} ${classes.detailLabel}`}>Total Fees and Taxes</div>
            <div className={`${classes.totalValue} ${classes.detailValue}`}>${totalFeesAndTaxes.toFixed(2)}</div>
          </div>
          <div className={classes.footerText}>
            Taxes are set by {dispensaryName} to follow state compliance regulations and 
            may include both state and local taxes. Discounts for this dispensary 
            are applied before taxes.
          </div>
        </div>
      </Popper>
      <Button ref={anchorRef} onClick={handleOpen}>
          <InfoIcon style={{
            cursor:'pointer',
            marginTop:'-4px',
            fontSize: 20,
            color: 'inherit'
          }} />
      </Button>
    </span>
  );
}

TaxesTooltip.propTypes = {
  dispensaryName: PropTypes.string.isRequired,
  itemTotal: PropTypes.number.isRequired,
  serviceFeeCents: PropTypes.number.isRequired,
  salesTaxPercent: PropTypes.number.isRequired,
  cannabisTaxPercent: PropTypes.number.isRequired
};

export default TaxesTooltip