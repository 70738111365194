import * as React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import BagIcon from './BagIcon';
import PropTypes from 'prop-types';

import styles from './CartIcon.module.css';

/**
 * The Shopping Bag (cart) Icon for display in Footer Nav 
 * 
 * Doesn't always know whether the cart is in the layout so we can scrape the page
 * for the #LAYOUT_CART element.
 * 
 * @param {array} items - to display cart count 
 */

const CartIcon = ({items=[]}) => {
  
  const { theme } = React.useContext(ThemeContext); 
  const [themeStyle, setThemeStyle] = React.useState({});

  React.useEffect(() => {
    if (theme && items.length > 0) { 
      setThemeStyle({ backgroundColor: theme.cartColor });
    }
  }, [items, theme]);
  
  return (
    <div className={styles.cartIconWrapper} style={themeStyle}>
      <BagIcon fillColor='#fff' />
      <span className={styles.itemCount}>{items.length}</span>
    </div> 
  );
};

CartIcon.propTypes = {
  items: PropTypes.array
};

export default CartIcon;
