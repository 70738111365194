import * as React from "react";

import LicenseImageUploader from "./LicenseImageUploader";
import PhotoIdTooltip from './PhotoIdTooltip';
import photoIDFront from '../../assets/photoIDSmall.png';
import photoIDBack from '../../assets/photoIDBack.png';

import PropTypes from 'prop-types';

import styles from './PhotoIdInline.module.css';

/**
 *  Upload/Update front and back images of ID to Firebase 
 */
const PhotoIdInline = ({
  email, 
  setPrimaryPhotoId,
  setSecondaryPhotoId
}) => {

  return (
    <>
      <div className={styles.idHeader}>
        Photo ID Images <PhotoIdTooltip />
      </div>
      <div className={styles.imageGrid}>
        <LicenseImageUploader
          email={email}
          placeholderImage={photoIDFront}
          placeholderLabel="Front"
          setPhotoIdImage={setPrimaryPhotoId}
        />
        <LicenseImageUploader
          email={email}
          placeholderImage={photoIDBack}
          placeholderLabel="Back"
          setPhotoIdImage={setSecondaryPhotoId}
        />   
      </div>
    </>
  )
};  

PhotoIdInline.propTypes = {
  email: PropTypes.string.isRequired,
  setPrimaryPhotoId: PropTypes.func.isRequired,
  setSecondaryPhotoId: PropTypes.func.isRequired
};

export default PhotoIdInline;