import React from 'react';

import { auth } from "../../firebase";
import { trackEvent } from '../analytics/tracking';
import { RegistrationContext } from './RegistrationProvider';
import { newCustomerEndpoint } from '../../constants/api-endpoints';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

const ProcessRegistrationInline = ({dispensaryId, onFailure}) => {

  const {
    name,
    email,
    password,
    location,   // { street_address, city, state, zip_code }
    phoneNumber: tel_number,
    apartmentNumber: apartment_number,
    birthDate,  // { day, month, year }
    primaryPhotoId,
    secondaryPhotoId,
    acceptMarketing: marketing_accepted,
    setShowSuccessMessage
  } = React.useContext(RegistrationContext);

  // Registration Success
  const [registrationComplete, setRegistrationComplete] = React.useState();
  // Registration submitted success or failure
  const [submitted, setSubmitted] = React.useState();
  
  const signInNewUser = (email, password) => {
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      // setError(`Error signing in with password and email! ${error}`);
      console.error("Error signing in with password and email", error);
    });
  }; 

  // Collect all the info required for registration
  const buildCustomerInfo = React.useCallback(() => {
    const dobInfo = birthDate.split("/");
    const dob = {
      month: dobInfo[0],
      day: dobInfo[1],
      year: dobInfo[2]
    };
    
    const { 
      street_address,
      city,
      state,
      zip_code
    } = location; 

    const info = {
      name,
      email,
      password,
      location: {
        street_address,
        city,
        state,
        zip_code,
        apartment_number
      },  
      dob, 
      tel_number,
      marketing_email_opted_in: marketing_accepted,
      marketing_sms_text_opted_in: marketing_accepted,
      referrer_dispensary_id: dispensaryId
    };

    // Add ID images if available
    if (primaryPhotoId && secondaryPhotoId) {
      info.photo_ids = {
        primary: primaryPhotoId,
        secondary: secondaryPhotoId
      }  
    }  

    // Reg will fail if apartment_number is empty string
    if (info.location['apartment_number'] === undefined ||
        /\S+/.test(info.location['apartment_number']) === false) {   
      delete info.location['apartment_number'];
    }
    return info;
  }, [name, email, password, location, apartment_number, birthDate, tel_number, primaryPhotoId, secondaryPhotoId, marketing_accepted, dispensaryId]);
  
  const handleError = React.useCallback((error) => {
    if (onFailure) {
      onFailure(error); /* will re-enable submit */
    }
    setSubmitted(false);
  }, [onFailure]); 

  const controllerRef = React.useRef(new AbortController());

  React.useEffect(() => {
    const controller = controllerRef.current;
    try {
      if (!submitted) {
        fetch( newCustomerEndpoint, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(buildCustomerInfo()),
          signal: controller.signal
        }).then(response => response.json())
          .then(data => {
            setSubmitted(true);
            // for logging
            const zipCode = location.zip_code || 'N/A';
            // check for errors
            if (data["error"]) {
              trackEvent('registration_failure', 'registration', `zip_${zipCode}_err_${data['error']}`);
              handleError(data["error"]);
            } else {
              trackEvent('registration_success', 'registration', `zip_${zipCode}`);
              setRegistrationComplete(true);
            }
          })
          .catch(error => {
            handleError(error)
          });
        } 
    } catch (e) {
      handleError(`registration failed ${e.message}`);
    }
    //return () => controller.abort();
  }, [buildCustomerInfo, location.zip_code, submitted, handleError]);

  // Sign the user in! ( esp for bank account linking ).
  React.useEffect(() => {
    if (email && registrationComplete) {
      signInNewUser(email, password);
      setShowSuccessMessage(true); /* notify parent element */
      // Email verify not needed at the moment
      // auth.currentUser.sendEmailVerification();
    }
  }, [email, password, registrationComplete, setShowSuccessMessage]);

  return (
    <Spinner />
  )
}

ProcessRegistrationInline.propTypes = {
  onFailure: PropTypes.func.isRequired
}

export default ProcessRegistrationInline;