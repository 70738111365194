
export function sendMessage(msgObj, hostDomain) {
  // For dev message self
  const isDev = window.top === window.self;
  console.log(`sending message: ${JSON.stringify(msgObj)}`);
  if (isDev) {
    window.postMessage(msgObj, window.location.origin);
    console.log(`sending message to domain: ${window.location.origin}`);
  } else {
    const msgDomain = hostDomain || 'https://www.ziprun.com';
    window.top.postMessage(msgObj, msgDomain);
    console.log(`sending message to domain: ${msgDomain}`);
  
    if (hostDomain === 'https://www.ziprun.com') {
      console.log(`HOST NOT SET! ${hostDomain}`);
    }
  
  }
}

export function messageHandler(key, onMessage) {
  // For dev message self
  const isDev = window.top === window.self;
  const handleMessage = (event) => {
    if (!isDev && event.origin !== "https://www.ziprun.com") {
      return;
    }
    const value = event.data[key];
    if (typeof value !== "undefined") {
      console.log(`message received: ${key}:${value}`);
      onMessage(value);
    }
  };
  return handleMessage;
}

/**
 * Handle messages specific to deep links from the host page:
 * {host page url}#zrCategory:Edibles~zrProductId:12345
 * 
 * @param {*} key - the hashLink value receieved
 * @param {function} onMessage - run on message received 
 */
export function navigationMessageHandler(key, onMessage) {
  // For dev message self
  const isDev = window.top === window.self;
  const handleMessage = (event) => {
    if (!isDev && event.origin !== "https://www.ziprun.com") {
      return;
    }
    const hash = event.data['hashLink'];
    if (hash) {
      console.log(`message received: hashLink:${hash}`);
      const values = hash.split('~');
      // We have a match for the handler ('Cart')
      if (key === hash) {
         onMessage();
      // 'Cart' is not a navigation message, the rest are
      } else if (key !== 'Cart') {
        const zrCategory = values[0].split(':')[1];
        const zrProductId = values[1]?.split(':')[1];  
        if ( zrCategory ) {
          const data = {
            zrCategory,
            zrProductId
          };
          // Set category/product
          onMessage(data);
        }
      }
    }
  };
  return handleMessage;
}