import * as React from 'react';

import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { devLog } from '../util/util';

// Determine which host to message (dev/localhost is handled in sendMessage)
const useHostDomain = () => {
  
  // Host for Preview Page in Dispensary App (ziprun-prod-menu, etc.)
  const { previewMode, previewHost } = React.useContext(UrlParamsContext);
  // Host for Menu Embed on Partner Store (www.somedispensary.com)
  const { dispensaryDomain } = React.useContext(DispensaryContext);
  // The host to message
  const [hostDomain, setHostDomain] = React.useState();
 
  React.useEffect(() => {
    if (previewMode) {
      devLog(`message host is ${previewHost}`);   
      setHostDomain(previewHost);
    } else {
      devLog(`message host is ${dispensaryDomain}`);
      setHostDomain(dispensaryDomain);
    }      
  },[previewMode, previewHost, dispensaryDomain]);

  return hostDomain;
}

export default useHostDomain;