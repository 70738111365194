import React from 'react'

import { trackEvent } from '../analytics/tracking';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import PropTypes from 'prop-types'

import styles from './SimpleDesktopCarousel.module.css';

/**
 * A manual carousel of component items with arrow controls.
 * Can be scrolled with controls or via touch
 */
const SimpleDesktopCarousel = ({
  CarouselComponent,  // For each carousel item
  carouselItemProps,
  maxItemCount=4,
  trackingLabel="simple",
  withStyle={}
}) => {
  
  const controlsRef = React.useRef();
  const carouselRef = React.useRef();

  // Enforce max count 
  const itemProps = React.useRef(carouselItemProps.slice(0, maxItemCount));

  let animateTask = React.createRef();
  
  // How fast to scroll
  const scrollPixels = 33;
  // Item spacing: .carouselItem margin
  const itemSpacing = 16;

  // A simple timeout-based animation
  const animateScroll = (scrollAmount) => {
    // How much we've scrolled to target anount
    let scrollProgress = 0;
    window.clearTimeout(animateTask.current);
    
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollMore = () => {
        // We have to adjust the last animation pixel count to match the target scroll amount
        let adjustedScrollPixels = scrollAmount < 0 ? -1 * scrollPixels : scrollPixels;
        // If we're about to overshoot the scroll target
        if ((scrollProgress + scrollPixels) >  Math.abs(scrollAmount)) {
          if (scrollAmount > 0) {
            adjustedScrollPixels = scrollAmount - scrollProgress;
          } else { 
            adjustedScrollPixels = scrollAmount + scrollProgress;
          }
        }
        carousel.scrollBy(adjustedScrollPixels, 0);
        scrollProgress += scrollPixels;
	      if (scrollProgress < Math.abs(scrollAmount)) {
          animateTask.current = window.setTimeout(scrollMore, 15);
        }
      }
      // initiate scroll
      scrollMore();
    }  
  } 

  // Handle arrow click/tap
  const scrollItems = (direction) => {
    const itemWidth = controlsRef.current.clientWidth + itemSpacing;
    const carousel = carouselRef.current;
    if (carousel) {
      const currentLeft = carousel.scrollLeft;
      // I'm using the controls elem width to determine contentWith
      const scrollAmount = parseInt(itemWidth, 10);
      if (direction < 0) {
        // scroll back some or to 0, adjust for any offset from manual scrolling
        const backPixels = currentLeft % itemWidth === 0 ? scrollAmount : currentLeft % itemWidth;
        animateScroll(Math.min(backPixels, currentLeft) * -1);
        trackEvent(`carousel_${trackingLabel}_lft_click`);
      } else {
        // scroll foward some or to end, adjust for any offset from manual scrolling
        const forwardPixels = itemWidth - (currentLeft % itemWidth);
        const max = carousel.scrollWidth - carousel.clientWidth;
        animateScroll(Math.min(forwardPixels, max));
        trackEvent(`carousel_${trackingLabel}_rgt_click`);
      }
    }      
  }

  // Monitor category scroll
  React.useEffect(() => {
    const wrapper = controlsRef.current;
    const carousel = carouselRef.current;
    
    // Hide / show arrows 
    const toggleArrow = (show, styleClass) => {
      if (wrapper) {
        wrapper.classList.toggle(styleClass, show);
      } 
    }  
    
    // debounce
    let scrollTask;
    // Show appropriate control arrows aftr scroll
    const handleCarouselScroll = () => { 
      window.clearTimeout(scrollTask);
      scrollTask = window.setTimeout(() => {
        if (carousel) {
          const max = carousel.scrollWidth - carousel.clientWidth;
          // show/hide right arrow
          const isNotMaxScroll = carousel.scrollLeft + 10 < max;
          toggleArrow(isNotMaxScroll, styles.withRightArrow);
          // show/hide left arrow
          const isScrolled = carousel.scrollLeft > 10;
          toggleArrow(isScrolled, styles.withLeftArrow);
        }
      }, 300);
    };

    let resizeTask;
    // Return to 0 on resize 
    const handleResize = () => {
      window.clearTimeout(resizeTask);
      resizeTask = window.setTimeout(() => {
        const carousel = carouselRef.current;
        if (carousel) {
          carousel.scrollTo(0,0);
        }
      }, 300);
    };
    
    // Add scroll/resize listeners
    if (carousel) {
      carousel.addEventListener('scroll', handleCarouselScroll);
      window.addEventListener('resize', handleResize);
    }

    // Initial right arrow display
    toggleArrow(true, styles.withRightArrow);
        
    return () => { 
      if (carousel) {
        carousel.removeEventListener('scroll', handleCarouselScroll);
        window.removeEventListener('resize', handleResize);
      }
    }   
  }, []);   

  return (
    <div className={styles.carouselWrap}>
      <div ref={controlsRef} className={styles.controls}>
        <span className={`${styles.scrollArrow} ${styles.scrollLft}`} onClick={() => scrollItems(-1)}><ChevronLeftRoundedIcon /></span>
        <span className={`${styles.scrollArrow} ${styles.scrollRgt}`} onClick={() => scrollItems(1)}><ChevronRightRoundedIcon /></span>  
      </div>
      <div ref={carouselRef} className={styles.carousel} style={withStyle}>
        { itemProps.current?.length 
          ? itemProps.current.map(props => (
              <div key={`carousel_item_${props.itemKey}`} className={styles.carouselItem}>
                <CarouselComponent key={`carousel_child_${props.itemKey}`} {...props} />
              </div>
            ))
          : null
        }
      </div>
    </div>
  );
}

SimpleDesktopCarousel.propTypes = {
  CarouselComponent: PropTypes.func.isRequired, // functional component
  carouselItemProps: PropTypes.array.isRequired,
  maxItemCount: PropTypes.number,
  trackingLabel: PropTypes.string,
  withStyle: PropTypes.object
}

export default SimpleDesktopCarousel;