import * as React from 'react';

import useInterval from '../util/useInterval';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './ProgressBar.module.css';

const ProgressBar = ({ticks}) => {
  
  const [value, setValue] = React.useState(0);
  
  useInterval(() => {
    if ( value < ticks ) {
      console.log('running');
      setValue(value + 1);
    }
  }, 1500);
   
  return (  
    <div className={styles.wrapper}>
      <div className={styles.label}>
        OK, Let's get your order processed... {value * 10}%
      </div>
      <LinearProgress variant="determinate" value={value * 10} />
    </div>
  )
}

export default ProgressBar