import * as React from "react";

import { UrlParamsContext } from "../providers/UrlParamsProvider";
import { ProductPreviewContext } from "./ProductPreviewProvider";
import { ProductCategoryContext, DEFAULT_PRODUCT_CATEGORY } from "../providers/ProductCategoryProvider";
import { trackEvent } from '../analytics/tracking';

export const subCategoryAll = {
  name: 'all',
  display_name: 'All',
  parent_category_name: "none"
};

// show all brands/types
export const SHOW_ALL = 'all';

export const FilteredProductsContext = React.createContext({});
/**
 * This provider is used for the dispensary page filtered products view
 * 
 * NOTE: This provider works from category.display_name
 * rather than category.name.  This is related to optionally
 * pulling the category from the url (/Edibles).
 * 
 * @param {string} dispensaryId - The id of the current dispensary 
 * @param {string} initialCategory - The initial category name supplied by the url: /Edibles
 */
const FilteredProductsProvider = ({children}) => {

  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { defaultCategory } = React.useContext(ProductCategoryContext);
  const { productsByCategory } = React.useContext(ProductPreviewContext);

  // State for currently filtered products
  const [currentCategory, setCurrentCategory] = React.useState(DEFAULT_PRODUCT_CATEGORY.display_name);
  // subCategories are objects!
  const [currentSubCategories, setCurrentSubCategories] = React.useState([]);
  const [currentSubCategoryWeights, setCurrentSubCategoryWeights] = React.useState([]);
  const [currentSubCategoryBrands, setCurrentSubCategoryBrands] = React.useState([]);
  const [currentSubCategoryTypes, setCurrentSubCategoryTypes] = React.useState([]);

  // for filtering within categories
  const [currentSubCategory, setCurrentSubCategory] = React.useState(subCategoryAll);
  const [currentGrams, setCurrentGrams] = React.useState(SHOW_ALL);
  const [currentBrand, setCurrentBrand] = React.useState(SHOW_ALL);
  const [currentType, setCurrentType] = React.useState(SHOW_ALL);
  // Are any filters set
  const [isFiltered, setIsFiltered] = React.useState();
  const [loading, setLoading] = React.useState();

  /**
   * All dispensary products keyed by category name
   * (preserve during re-renders)
   */
  const productsByCategoryMap = React.useRef();
  // Dispensary poducts by category with filters applied 
  const [filteredProducts, setFilteredProducts] = React.useState([]);

  const updateSubCategories = React.useCallback((categoryName, productMap) => {
    const categoryProducts = productMap[categoryName] || [];
    // Map of subCategory name to full object ( display_name etc.)
    const subCategoryMap = categoryProducts.reduce((nameToObjMap, product) => {
      const name = product.display_info.sub_category?.name; 
      if (name) {
        nameToObjMap.set(name, product.display_info.sub_category);
      }
      return nameToObjMap;
    }, new Map());
    // Sort alphabetically
    const subCatArray =  Array.from(subCategoryMap.values());
    if (subCatArray.length) {
      subCatArray.sort((a, b) => a.display_name.localeCompare(b.display_name));
    } 
    setCurrentSubCategories(subCatArray);
  }, []);

  // Build the unique  brands/types/etc. for a subCategory 
  const buildFilterItems = (categoryName, productDetail, productMap) => {
    const categoryProducts = productMap[categoryName] || [];
    // build the brand set
    const detailSet = categoryProducts.reduce((unique, product) => {
      const productNode = productDetail === 'grams'
        ? product
        : product.display_info;
      if (productNode[productDetail]) {
        unique.add(productNode[productDetail]);
      }
      return unique;
    }, new Set());
    const itemArray = Array.from(detailSet);
    if (typeof itemArray[0] === 'number') {
      return itemArray.sort((a,b) => a - b);
    } else {
      return itemArray.sort(); 
    }
  };

  // Build the set of weights for the current subCategory
  const updateSubCategoryWeights = React.useCallback((categoryName, productMap) => {
    setCurrentSubCategoryWeights(buildFilterItems(categoryName, 'grams', productMap));
  }, []); 

  // Build the set of brands for the current subCategory
  const updateSubCategoryBrands = React.useCallback((categoryName, productMap) => {
    setCurrentSubCategoryBrands(buildFilterItems(categoryName, 'brand', productMap));
  }, []);

  // Build the set of cannabis types for the current subCategory
  const updateSubCategoryCannabisTypes = React.useCallback((categoryName, productMap) => {
    setCurrentSubCategoryTypes(buildFilterItems(categoryName, 'cannabis_type', productMap));
  }, []);

  // INITIAL PRODUCT FILTER STATE
  const setInitialState = React.useCallback((byCategory) => {
    productsByCategoryMap.current = byCategory;
    const initialCategory = currentCategory || defaultCategory.display_name; 
    setFilteredProducts(byCategory[initialCategory] || []);
    setCurrentCategory(initialCategory);
    updateSubCategories(initialCategory, byCategory);
    updateSubCategoryWeights(initialCategory, byCategory);
    updateSubCategoryBrands(initialCategory, byCategory);
    updateSubCategoryCannabisTypes(initialCategory, byCategory);
    setLoading(false);
  }, [currentCategory, defaultCategory, updateSubCategories, updateSubCategoryWeights, updateSubCategoryBrands, updateSubCategoryCannabisTypes]);

  // Handle initial product data fetch
  React.useEffect(() => {
    // Load all products for the dispensary
    if (productsByCategory && !productsByCategoryMap.current) {
      setLoading(true);
      try {
        setInitialState(productsByCategory);
      } catch(error) {
        setLoading(false);
        trackEvent('error_fetching_products',`${dispensaryId}:${error}`);
      }
    }
    return () => {
      setLoading(false);
    }  
  }, [dispensaryId, defaultCategory, currentCategory, productsByCategory, setInitialState]);

  const setCategory = (categoryName) => {
    if (categoryName !== currentCategory && productsByCategoryMap.current) {
      setFilteredProducts(productsByCategoryMap.current[categoryName] || []);
      setCurrentCategory(categoryName);
      setCurrentSubCategory(subCategoryAll);
      setCurrentGrams(SHOW_ALL);
      setCurrentBrand(SHOW_ALL);
      setCurrentType(SHOW_ALL);
      updateSubCategories(categoryName, productsByCategoryMap.current);
      updateSubCategoryWeights(categoryName, productsByCategoryMap.current);
      updateSubCategoryBrands(categoryName, productsByCategoryMap.current);
      updateSubCategoryCannabisTypes(categoryName, productsByCategoryMap.current);
    }
  };

  const setFilters = (filters) => {
    // Accept filter values using current for missing vals
    const { 
      subCategory=currentSubCategory, 
      grams=currentGrams, 
      brand=currentBrand,
      type=currentType
    } = filters;
    const categoryProducts = productsByCategoryMap.current[currentCategory] || [];
    
    const matchesSubCategory = (product, subCategoryName) => {
      return subCategoryName === subCategoryAll.name || 
             product.display_info.sub_category?.name === subCategoryName;
    };
    
    const matchesWeight = (product, weight) => {
      return weight === SHOW_ALL || 
             product.grams === grams;
    }

    const matchesBrand = (product, brand) => {
      return brand === SHOW_ALL || 
             product.display_info.brand === brand;
    };

    const matchesType = (product, type) => {
      return type === SHOW_ALL || 
             product.display_info.cannabis_type === type;
    };

    setFilteredProducts(categoryProducts.filter(product => {
      return matchesSubCategory(product, subCategory.name) && 
             matchesWeight(product, grams) && 
             matchesBrand(product, brand) &&
             matchesType(product, type);
    }));
    // update context
    if (subCategory.name !== currentSubCategory.name) {
      setCurrentSubCategory(subCategory);
    }
    if (grams !== currentGrams) {
      setCurrentGrams(grams);
    }
    if (brand !== currentBrand) {
      setCurrentBrand(brand);
    }
    if (type !== currentType) {
      setCurrentType(type);
    }
    // Update filtered/unfiltered state
    if (subCategory.name !== subCategoryAll.name || 
        brand !== SHOW_ALL || 
        type !== SHOW_ALL) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);   
    }
  };
 
  return (
    <FilteredProductsContext.Provider value={{
      filteredProducts,
      categoryName: currentCategory,
      subCategory: currentSubCategory,
      subCategories: currentSubCategories,
      grams: currentGrams,
      subCategoryWeights: currentSubCategoryWeights,
      brand: currentBrand,
      subCategoryBrands: currentSubCategoryBrands,
      type: currentType,
      subCategoryTypes: currentSubCategoryTypes,
      setCategory,
      setFilters,
      isFiltered,
      loading
    }}>
      {children}
    </FilteredProductsContext.Provider>
  );
};

export default FilteredProductsProvider;