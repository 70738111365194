import React from 'react';

import { UserContext } from '../providers/UserProvider';
import { OrderTypeContext } from '../providers/OrderTypeProvider';
import { RegistrationContext } from '../registration/RegistrationProvider';
import useIntercomLogging from '../intercom/useIntercomLogging';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import DisplayModal from '../common/DisplayModal';
import SignInOrSignUp from '../registration/SignInOrSignUp';
import SignUpInline from '../registration/SignUpInline';
import RegistrationSuccessMsg from '../registration/RegistrationSuccessMsg';
import PlaceOrderPickup from '../pickup/PlaceOrderPickup';
import PlaceOrder from './PlaceOrder';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

import styles from './CheckOut.module.css';

// Props from DispensaryContext via PlaceOrderController (pickup/delivery)
const CheckOut = ({
  dispensaryId,
  dispensaryName,
  dispensaryTelNumber,
  dispensaryLocation,
  minDeliveryOrder,
  minPickupOrder
}) => {
  
  // The RegistrationProvider will be used to monitor registration success
  const { showSuccessMessage:registrationSuccess, setShowSuccessMessage } = React.useContext(RegistrationContext);

  const { user } = React.useContext(UserContext);
  const { isPickup } = React.useContext(OrderTypeContext);
  const [registeredUser, setRegisteredUser] = React.useState();
  const [showSignUp, setShowSignUp] = React.useState();

  React.useEffect(() => {
    if(!registeredUser && user && !user.isAnonymous) {
      setRegisteredUser(true);
    } else if (registeredUser && user && user.isAnonymous) {
      // handle log out
      setRegisteredUser(false);
    }
  }, [user, registeredUser, isPickup]);  

  // Track user starting checkout
  useIntercomLogging(user, 'Checkout_Pickup', true);

  return (
    <BodyWrapper pageLayout={LayoutTypes.FullBleed}>
      <Helmet>
        <title>{`Zip Run - Order ${isPickup ? 'Pickup' : 'Delivery'}`}</title>
      </Helmet>
      <div className={styles.placeOrderGrid}>
        <div className={`${styles.placeOrderWrapper} ${styles.placeOrderCol}`}>
          {/* Pickup allows guest orders, Delivery does not */}
          { isPickup
            ? showSignUp
              ? <div className={styles.signIn}>
                  <SignUpInline
                    cancelLinkText="Guest Checkout"
                    onCancel={() => setShowSignUp(false)} />
                </div>
              : <PlaceOrderPickup
                  currentUser={user}                   
                  dispensaryId={dispensaryId}
                  dispensaryName={dispensaryName}
                  dispensaryTelNumber={dispensaryTelNumber} 
                  dispensaryLocation={dispensaryLocation}
                  minPickupOrder={minPickupOrder}
                  handleSignUpClick={() => setShowSignUp(true)} />
            : registeredUser
              ? <PlaceOrder 
                  dispensaryId={dispensaryId}
                  dispensaryName={dispensaryName}
                  minDeliveryOrder={minDeliveryOrder} />
              : showSignUp
                  ? <SignUpInline
                      cancelLinkText="Sign In"
                      onCancel={() => setShowSignUp(false)} />
                  : <SignInOrSignUp signUpClick={() => setShowSignUp(true)} />
          }

          {/* Display success message if user completes inline reg flow */}
          { registrationSuccess &&
              <DisplayModal title="Sign Up Successful" 
                            closeFn={() => {setShowSuccessMessage(false)}}>
                <RegistrationSuccessMsg 
                   onContinue={()=>{setShowSuccessMessage(false)}} /> 
              </DisplayModal>
          }      
        </div>
      </div>    
    </BodyWrapper>
  )
}

CheckOut.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  dispensaryTelNumber: PropTypes.string.isRequired, 
  dispensaryLocation: PropTypes.object.isRequired,
  minDeliveryOrder: PropTypes.number.isRequired,
  minPickupOrder: PropTypes.number.isRequired,
  dispensaryLogoPath: PropTypes.string /* initially undefined */ 
};

export default CheckOut;