import * as React from 'react';

import PropTypes from 'prop-types';

import styles from './ProductInfoTiles.module.css';

// Mood color theme
export const MoodColors = {
  sleep: '#4796ec66',     // blue
  relax: '#54a40a66',     // green 
  happy: '#ffb50088',     // yellow
  low_dose: '#8239bf66',  // purple
  default: '#eaeff2'      // gray
};

// Type for mood
export const TypeForMood = {
  sleep: 'Indica',     
  relax: 'Hybrid',      
  happy: 'Sativa',     
  low_dose: 'CBD',
  default: 'None' 
}

export const getMoodFromType = (cannabisType) => {
  const type = cannabisType.toLowerCase();
  if (type.indexOf('indica') > -1) {
    return 'default';
  } else if (type.indexOf('hybrid') > -1) { 
    return 'default';
  } else if (type.indexOf('sativa') > -1) {  
    return 'default';
  } else if (type.indexOf('cbd') > -1) {
    return 'default';
  }  
  return 'default';
}

export const getMoodColorFromType = (cannabisType) => {
  const type = cannabisType ? cannabisType.toLowerCase() : ''; 
  if (type.indexOf('indica') > -1) {
    return MoodColors['default'];
  } else if (type.indexOf('hybrid') > -1) { 
    return MoodColors['default'];
  } else if (type.indexOf('sativa') > -1) {  
    return MoodColors['default'];
  } else if (type.indexOf('cbd') > -1) {
    return MoodColors['default'];
  }  
  return MoodColors['default'];
}

const unitSymbolMap = new Map();
unitSymbolMap.set('percentage','%');
unitSymbolMap.set('milligrams', 'mg');
unitSymbolMap.set('milligrams_per_gram', 'mg/g');

const ProductInfoTiles = ({
  productId,
  thcVal,
  thcUnits,
  cbdVal,
  cbdUnits,
  cannabisType
}) => {
 
  // Exclude zero values
  const validThcValue = parseInt(thcVal,10) > 0;
  const validCbdValue = parseInt(cbdVal,10) > 0;

  const thcLabel = validThcValue ? `${thcVal}${unitSymbolMap.get(thcUnits) || ''}` : null;
  const cbdLabel = validCbdValue ? `${cbdVal}${unitSymbolMap.get(cbdUnits) || ''}` : null;
  
  const productInfo = { 
    'THC' : { 
              value: thcLabel,
              style: { backgroundColor: '#edf0f2',
                       color: '#5d5e61' }
            },
    'CBD' : { 
              value: cbdLabel,
              style: { backgroundColor: '#edf0f2',
                       color: '#5d5e61' }
            },
    'TYPE': {
              value: cannabisType,
              style: { backgroundColor: getMoodColorFromType(cannabisType),
                       color: '#000' }
            }
  };  

  return (
    <>
      { productInfo 
        ? 
          <ol className={styles.infoTiles}>  
            { Object.keys(productInfo).map((itemKey) => {
                return (
                  productInfo[itemKey].value
                    ? <li key={`${productId}-${itemKey}`} style={productInfo[itemKey].style}> 
                        {/* <div className={styles.label}>{itemKey}</div> */}
                        <div className={styles.value}>{productInfo[itemKey].value}</div> 
                      </li>
                    : null  
                )
              })
            }
          </ol>
        : null
      }
    </>  
  )
}

ProductInfoTiles.propTypes = {
  productId: PropTypes.string.isRequired,
  thcVal: PropTypes.string,
  thcUnits: PropTypes.string,
  cbdVal: PropTypes.string,
  cbdUnits: PropTypes.string,
  cannabisType: PropTypes.string,
}

export default  ProductInfoTiles;