import React from 'react';

const BagIcon = ({fillColor='#272a3e', size='30px'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
         enableBackground="new 0 0 24 24" 
         viewBox="0 0 24 24" 
         fill={fillColor} width={size} height={size}>
      <g><rect fill="none" height="24" width="24"/>
        <path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M10,10c0,0.55-0.45,1-1,1s-1-0.45-1-1V8h2V10z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M16,10c0,0.55-0.45,1-1,1 s-1-0.45-1-1V8h2V10z"/>
      </g>
    </svg>
  );
}

export default BagIcon;
