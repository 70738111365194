import { commerceAnalyticsEndpoint } from '../../constants/api-endpoints';
import { SHOPPING_MILESTONES } from '../analytics/CommerceLoggingProvider';
import { trackEvent } from './tracking';
import { devLog } from '../util/util';

// Log analytics for use in dispensary app (eCommerce)
export const commerceLoggingRequest = (user, dispensaryId, body, controller) => {
  if (user) {
    user.getIdToken(/* no need to force refresh */ false).then(idToken => {
      const endpoint = commerceAnalyticsEndpoint.replace(/\[dispId\]/,dispensaryId); 
      // Once MENU_INTERACTED is included method is PATCH
      const method = body[SHOPPING_MILESTONES.MENU_INTERACTED] ? 'PATCH' : 'POST'; 
      fetch(endpoint, {
        method,
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        signal: controller.signal
      }).then(response => {
        if (![201,204].includes(response.status)) {
          trackEvent(`commerce_logging_error_${response.status}`);
          devLog(`commerce_logging_error_${response.status}`);
        } else {
          devLog(`commerce_logging_success_${Object.keys(body).join('+')}`); 
        }  
      }).catch(error => devLog(error.message)); 
    });
  }
};
