import * as React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import { getOrderDate } from './timeslot-utils';
import { trackEvent } from '../analytics/tracking';
import PropTypes from 'prop-types';

import styles from '../deliveryWindows/SelectDayCarousel.module.css';

/**
 * This day selection carousel will re-render when viewing a different day
 */
const SelectDayCarousel = ({
  displayDayIdx,
  displayDayLabelIdx,
  timeSlotsByDay=[],
  currentTimeMS=new Date().getTime(),
  handleDayClick,  // re-render a different day
  trackingModule='pickup_day_carousel' 
}) => {

  const { theme } = React.useContext(ThemeContext);

  const themeStyleSelected = {
    color: '#fff',
    backgroundColor: theme.primaryBackgroundColor
  };

  const daysOfWeekAbbrev = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  
  const getDayLabel = (offset) => {
    if (offset === 0) {
      return 'TODAY';
    }
    const weekdayNum = (displayDayLabelIdx + offset) % 7;
    return daysOfWeekAbbrev[weekdayNum];
  };
  
  const dayClick = (idx) => {
    trackEvent(trackingModule, 'select_day_click', getDayLabel(idx));
    handleDayClick(idx);
  }

  return (
    timeSlotsByDay.length 
    ? <ol className={styles.selectDay}>
        { timeSlotsByDay.map((day, idx) => {
          const selectedStyle = idx === displayDayIdx ? themeStyleSelected : {};
          return  <li className={styles.dayItem}
                      style={selectedStyle}
                      key={`day_${idx}`}
                      onClick={() => dayClick(idx)}>
                    {getDayLabel(idx)}
                    <span>{getOrderDate(idx, currentTimeMS)}</span>
                  </li>
        })}   
      </ol>
    : null
  )
}

SelectDayCarousel.propTypes = {
  displayDayIdx: PropTypes.number.isRequired,
  displayDayLabelIdx: PropTypes.number.isRequired,
  timeSlotsByDay: PropTypes.array.isRequired,
  currentTimeMS: PropTypes.number.isRequired, 
  handleDayClick: PropTypes.func.isRequired,
  trackingModule: PropTypes.string 
};

export default SelectDayCarousel;