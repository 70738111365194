import * as React from 'react';

import ProductInfoTiles from './ProductInfoTiles';
import StaffPick from './StaffPick';
import { SEOLinkWrapper } from '../routing/router-utils';
import CroppedProductImage  from './CroppedProductImage';
import PriceWithWeightButton from './PriceWithWeightButton';
import InCartCheck from './InCartCheck';
import ToolTip from '../common/ToolTip';
import BulkPricingTable from './BulkPricingTable';
import discountIcon from '../../assets/discountRedIcon.png'
import PropTypes from 'prop-types';

import styles from './ProductCardForGrid.module.css';

const ProductCardForGrid = ({
  Id, 
  Name,
  categoryName,
  brand, 
  imageInfo, 
  cost_usa_cents,
  is_featured,
  leaf_logix_pricing_tier,
  thc_lab_value,
  thc_lab_units,
  cbd_lab_value,
  cbd_lab_units,
  cannabisType,
  grams,
  dispensaryId, 
  orderQuantity, 
  showOrderQuantity,
  isFullWidth,
  handleCartClick,
  handleClick
}) => {
  
  const formattedPrice = parseFloat(cost_usa_cents/100).toFixed(2) || 'N/A';

  // The deep link for Google
  const deepLink = `/dispensaryProduct/${dispensaryId}/${Id}`;
  // display in Your Orders etc.
  const widthStyle = isFullWidth ? styles.fullWidth : '';

  // These are all strings
  const showProductTiles = cbd_lab_value || thc_lab_value || cannabisType;
  
  const displayOrderQuantity = showOrderQuantity && orderQuantity > 0;
     
  return (
    <div className={`${styles.productCardWrap} ${widthStyle}`}  key={`Inline${dispensaryId}${Id}`}>
      <SEOLinkWrapper deepLink={deepLink} key={Id} styleClass={styles.productCard} handleClick={handleClick}>
        <div className={styles.productImageWrap}>
          <CroppedProductImage
            productName={Name}
            src={imageInfo.productImage}
            category={imageInfo.productCategory}
          />
        </div>
      
        <div className={styles.productBody}>
          { is_featured &&
            <div className={styles.staffPickWrap}>
              <StaffPick />
            </div>
          }
          { brand &&
            <div className={styles.brand}>
              {brand}
            </div>
          }
          
          <div className={styles.productName}>
            {Name}
          </div>
          { showProductTiles &&
            <div className={styles.productInfo}>
              <ProductInfoTiles
                productId={Id}
                thcVal={thc_lab_value}
                thcUnits={thc_lab_units}
                cbdVal={cbd_lab_value}
                cbdUnits={cbd_lab_units}
                cannabisType={cannabisType} />
            </div>
          }
        </div>
        <div className={styles.productPriceWrap}>
          <div className={styles.quickAddWrap}>
            <PriceWithWeightButton 
              formattedPrice={formattedPrice}
              grams={grams}
              categoryName={categoryName}
              bulkPricing={false} /* temp disable icon */
              handleClick={handleCartClick}
            />
            
            { leaf_logix_pricing_tier?.pricingTierData &&
              <div className={styles.bulkTipWrap}>
                <ToolTip
                  buttonIcon={discountIcon}
                  buttonText="Bulk Discounts"
                  buttonStyle={{color:'var(--zrPromoRed)'}} 
                  tipTitleIcon={discountIcon} 
                  tipTitle="Bulk Discount Pricing"
                  footerText="*Your discount will be calculated at checkout.">
                  <BulkPricingTable
                    grams={grams} 
                    pricingTierData={leaf_logix_pricing_tier.pricingTierData} /> 
                </ToolTip>
              </div>
            }

            {/**
              * showOrderQuantity also enables quickAdd feature
              * We suppress this when showing order history
              */}
            {!showOrderQuantity &&
              <div className={styles.addedWrapper}>
                <InCartCheck itemId={Id} handleCartClick={handleCartClick} />
              </div>
            }
          </div>
          {/* Quantity For previous order display only */}
          { displayOrderQuantity &&
            <span className={styles.orderQuantity}>(x{orderQuantity})</span>
          }
        </div>
      </SEOLinkWrapper>
    </div>     
  )
};

ProductCardForGrid.propTypes = {
  Id: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  brand: PropTypes.string,
  imageInfo: PropTypes.object.isRequired,
  cost_usa_cents: PropTypes.number,
  is_featured: PropTypes.bool,
  thc_lab_value: PropTypes.string,
  thc_lab_units: PropTypes.string,
  cbd_lab_value: PropTypes.string,
  cbd_lab_units: PropTypes.string,
  cannabisType: PropTypes.string,
  grams: PropTypes.number,
  quantity: PropTypes.number,
  dispensaryId: PropTypes.string.isRequired,
  handleCartClick: PropTypes.func.isRequired, /* quick add icon */
  handleClick: PropTypes.func.isRequired,
  showOrderQuantity: PropTypes.bool,
  isFullWidth: PropTypes.bool
};

export default ProductCardForGrid;