import * as React from 'react';

import { NotificationsContext } from './NotificationsProvider';
import { setReadNotifications } from '../notifications/notification-utils';
import DeliveryCreditNotifications from './DeliveryCreditNotifications';

const DisplayNotifications = ({dateJoined, recentOrders=[]}) => {
  
  const { notifications, viewedIds, setAllViewed } = React.useContext(NotificationsContext);
  const [markRead,setMarkRead] = React.useState();
  
  React.useEffect(() => {
    if (markRead) {
      setAllViewed(notifications);
      setReadNotifications({ids:notifications}, ()=>{/* debug log, etc. */}, new AbortController());
    }
  }, [notifications, setAllViewed, markRead]);

  return (
    <>
      <div>
        <DeliveryCreditNotifications 
          notifications={notifications} 
          viewedIds={viewedIds}
          recentOrders={recentOrders}
          updateViewedStatus={() => {
            setMarkRead(true);  
          }} 
          dateJoined={dateJoined} />
      </div>  
    </>
  )
}

export default DisplayNotifications;