import React from "react";

import TextField from '@material-ui/core/TextField';
import { errMsg } from '../registration/registration-errors';
import DeliveryBlacklistMessage  from '../address/DeliveryBlacklistMessage';
import BasicButton from '../styleguide/BasicButton';
/* Material UI overrides */
import '../registration/MaterialUI.module.css';
import styles from '../registration/Registration.module.css';
import accountStyles from '../account/YourAccount.module.css';

import PropTypes from 'prop-types';

class AddressForm extends React.Component {
  
  constructor(props) {
    super();
    // state item names must exactly match input names
    this.state = {
      street_address: props.street_address,
      street_addressErr: false,
      city: props.city,
      cityErr: false,
      state: props.state,
      stateErr: false,
      zip_code: props.zip_code,
      zip_codeErr: false,
      apartment_number: props.apartment_number,
      hasValidationError: false
    };
  }
 
  render() {
    /**
     * Validate a single form input
     * @param string item
     */
    const isValidItem = (regItem) => {
      const currentVal = this.state[regItem];
      const isValidData = currentVal !== null && currentVal.length > 0; 
      const stateJson = JSON.parse(`{ "${regItem}Err" : ${!isValidData} }`); 
      this.setState(stateJson);
      return isValidData;
    }; 

    /**
     * Validate required form inputs
     */
    const validate = () => {
      const validStreetAddress = isValidItem('street_address');
      const validCity = isValidItem('city');
      const validState = isValidItem('state');
      const validZipCode  = isValidItem('zip_code');
      const isValidData = validStreetAddress && validCity && validState && validZipCode; 
      this.setState({hasValidationError: !isValidData});
      return isValidData; 
    };  
  
    const {
      street_address,
      street_addressErr,
      city,
      cityErr,
      state,
      stateErr,
      zip_code,
      zip_codeErr,
      apartment_number
    } = this.state;

    /**
     * Update state with the form input values
     * 
     * NOTE: The way I have this, the state item names MUST match the form input names:
     *   "state.email" will update from <input id="email"  
     * 
     * @param {*} event 
     */
    const onChange = event => {
      const { id, value } = event.currentTarget;
      const stateJson = JSON.parse(`{ "${id}" : "${value}" }`); 
      this.setState(stateJson);
    }     
   
    /**
     * Collect the form data and run the provided handleSubmit script  
     */
    const handleSubmitClick = (event) => {
      event.preventDefault();
      const valid = validate();
      if (!valid) {
        return;
      }
      const updatedAddress = {
        street_address: this.state.street_address,
        city: this.state.city,
        state: this.state.state,
        zip_code: this.state.zip_code,
      };
      // endpoint will not accept a blank value
      if ( this.state.apartment_number ) {
        updatedAddress.apartment_number = this.state.apartment_number;  
      }
      const { sectionRef, msgHandler } = this.props;
      this.props.handleSubmit({ location:updatedAddress }, sectionRef, msgHandler);
    };

    /* Material UI TextField Variant */
    const tF = 'filled'; 

    return (
      <form>
        {/* Street Address */}
        <TextField id="street_address" value={street_address} error={street_addressErr} helperText={errMsg('street_address',street_addressErr)} label="Street Address (for Delivery)" variant={tF} onChange={onChange} />
        
        {/* City / State */}
        <div className={styles.addressInputPair}>
          <TextField id="city" value={city} error={cityErr} helperText={errMsg('city',cityErr)} label={"City"} variant={tF} onChange={onChange} />
          <TextField id="state" value={state} error={stateErr} helperText={errMsg('state',stateErr)} label={"State"} variant={tF} onChange={onChange} />
        </div> 
        {/* Apartment / Zip Code */}
        <div className={styles.addressInputPair}>    
          <TextField id="apartment_number" value={apartment_number} label="Apt, Suite, Bldg" variant={tF} onChange={onChange} /> 
          <TextField id="zip_code" value={zip_code}  
                     inputProps={{ pattern:"[0-9]*" }}
                     error={zip_codeErr} helperText={errMsg('zip_code', zip_codeErr)} 
                     label={"Zip Code"} variant={tF} onChange={onChange} />
        </div>
        
        {/* alert if undeliverable */}
        <DeliveryBlacklistMessage 
          street_address={street_address}
          city={city}
          state={state}
          zip_code={zip_code}
        />

        <div className={accountStyles.updateBtnWrap}>
          <BasicButton text="Update Address" handleClick={handleSubmitClick} /> 
          { this.props.updateMsg && 
            <span className={accountStyles.updateMsg}>{this.props.updateMsg}</span> 
          }
        </div> 
      </form>
    );
  }  
};

AddressForm.propTypes = {
  street_address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip_code: PropTypes.string.isRequired,
  apartment_number: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  sectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  msgHandler: PropTypes.func,
  updateMsg: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired
};

export default AddressForm;
