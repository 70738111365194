import config from '../config';

/** CUSTOMER **/

// registration
export const newCustomerEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/customer`;
// customer info
export const customerInfoEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/me`;
// email availability check
export const emailAvailabilityEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/email_availability?email=`;
// password strength
export const passwordStrengthEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/password_strength?password=`;
// delivery address blacklist
export const addressBlackListEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/delivery_address_blacklist`;
// customer cart save/restore
export const customerCartEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/cart`;
// notifications read
export const notificationsReadEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/notifications_read`;

/** DISPENSARIES **/

// all dispensaries
export const dispensariesEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary`;

// single dispensary ( includes delivery slots )
export const dispensaryDetailEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]?include_delivery_order_options=true`;

// dispensary pickup window/timeslots
export const pickupWindowsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]/pickup_options`;

// dispensary delivery options/timeslots 
export const deliveryOptionsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]/delivery_options`;

// meta data ( total price calcs / fee structure )
export const metadataEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/metadata`;

// promo text
export const promoTextEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/dispensary/[dispId]/promo_text`;

// commerce analytics ( not Firebase )
export const commerceAnalyticsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/session_tracking/dispensary/[dispId]`;

/** PRODUCTS **/

// all dispensary products
export const dispensaryProductsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]/products`; 
// single dispensary product details used with ?product_id=id
export const dispensaryProductDetailEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]/product`;     
// products by categories across all dispensaries
export const productsByCategoryEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/products`; 

/** ORDERS **/

// Validate order pre-payment (aeropay)
export const validateOrderEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/dispensary/[dispId]/validity`;
// Submit Order: append dispensary id
export const newOrderEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/dispensary/`;
// Submit Guest Pickup order: append dispensary id
export const guestOrderEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/guest/dispensary/`;
// Retrieve Orders: append auth.uid
export const retreiveOrdersEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/customer/`;
// Retrieve a users AeroPay account info (if exists) or empty array
export const aeropayLinkedAccountEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/linked_bank_accounts/`;
// GET tokens for AeroPay account selection widget / POST selected account(s) bank to Zip Run 
export const aeropayCredentialsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/bank_account_linking_credentials/`;

/** TOOLS ENDPOINTS  */

// Update product display_info
export const productInfoEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/dispensary/[dispId]/products/[prodId]`;
// Retrieve valid/configured catagories
export const productCategoriesEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/product_categories`;
// Add a new category
export const updateProductCategoriesEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/admin/product_categories`;
// Database flush
export const databaseTestingEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/admin/database`;
// Enable Leaf Logix for dispensary
export const enableLeafLogixEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/dispensary/[dispId]/products/leaf_logix?make_primary_source=true`; 

/** TELEPHONE VERIFICATION */
export const telephoneVerificationEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/telephone_verification`;
