import * as React from 'react';

import { CartItemsContext } from '../checkout/CartItemsProvider';
import Spinner from '../common/Spinner';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import PropTypes from 'prop-types';

import styles from './AddedToCart.module.css';

/**
 * This component renders the quickAdd product card functionality with quantity +/- icons
 * 
 * @param {string} itemId - the product Id 
 */

const InCartCheck = ({itemId, handleCartClick}) => {

  // The currently carted items
  const { cartItems, increaseQuantity, decreaseQuantity } = React.useContext(CartItemsContext); 

  const [cartCount, setCartCount] = React.useState(0);
  const [showToolBar, setShowToolBar] = React.useState();
  const [showTransitionBar, setShowTransitionBar] = React.useState();
  
  const MOUSE_LEAVE_CLOSE_MS = 1000;

  const addItem = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();  
    handleCartClick(1);
    toggleTransitionBar();
  };

  const adjustCount = (evt, adjustBy) => {
    evt.preventDefault();
    evt.stopPropagation();  
    if (adjustBy === 1) {
      increaseQuantity(itemId, 1);
    } else if (adjustBy === -1) {
      decreaseQuantity(itemId, 1);
      if (cartCount === 1) {
        toggleTransitionBar();      
      }
    }
  };

  const toggleToolBar = (evt, isShow) => {
    evt.preventDefault();
    evt.stopPropagation();
    setShowToolBar(isShow);  
  };

  // On Desktop, close toolbar on mouseleave  
  let closeTask;
  const delayedClose = () => {
    window.clearTimeout(closeTask);
    closeTask = window.setTimeout(() => {
      setShowToolBar(false);
    }, MOUSE_LEAVE_CLOSE_MS);
  };
  const cancelClose = () => {
    window.clearTimeout(closeTask);
  };

  // When first adding an item, we preview the toolbar with a spinner
  // We also show it when the item has been removed from cart
  const toggleTransitionBar = () => {
    setShowTransitionBar(true);
    window.setTimeout(() => {
      setShowTransitionBar(false);
    }, 1000);
  }; 

  const TransitionBar = () => {
    return (
      <div className={`${styles.quickAddBarItems} ${styles.transitionBar}`}>
        <div className={styles.barDecrement}>
          <DeleteRoundedIcon style={iconStyle} />
        </div>
        <div className={styles.barCount}>
          <Spinner inSmallLayout size={16} color="#000" style={{display:'block', margin:'-18px 0'}} />
        </div>
        <div className={styles.barIncrement}>
          <AddRoundedIcon style={iconStyle} />
        </div>
      </div> 
    )
  };

  React.useEffect(() => {
    if (cartItems?.length) {
      // Determine if this item is carted
      const [inCart] = cartItems.filter(item => item.id === itemId);
      if (inCart) {
        setCartCount(inCart.quantity);
      } else {
        setCartCount(0);
        setShowToolBar(false);
      }
    } else {
      setCartCount(0);
      setShowToolBar(false);
    }
  }, [itemId, cartItems, cartCount, setCartCount]);

  // Styles for MUI Icons
  const iconStyle = {
    color: '#000',
    fontSize: '20px',
    verticalAlign: '-4px'
  }
 
  const closeIconStyle = {
    color: '#999',
    fontSize: '20px',
    verticalAlign: '-4px'
  }

  return ( showTransitionBar
    ? <TransitionBar />
    :  showToolBar // User is interracting with +/-
      ? <div className={styles.quickAddToolBar} onMouseLeave={delayedClose} onMouseEnter={cancelClose}>
          <div className={styles.quickAddBarItems}>
            <div className={styles.mobileClose} onClick={(event) => { toggleToolBar(event, false)}}>
              <HighlightOffRoundedIcon style={closeIconStyle} />
            </div>
            <div className={styles.barDecrement} onClick={(event) => {
              adjustCount(event, -1);
              setCartCount(cartCount - 1);
            }}>
              { cartCount > 1 
                ? <RemoveRoundedIcon style={iconStyle} />
                : <DeleteRoundedIcon style={iconStyle} />
              }
            </div>
            <div className={styles.barCount}>{cartCount}</div>
            <div className={styles.barIncrement} onClick={(event) => {
              adjustCount(event, 1);
              setCartCount(cartCount + 1);
            }}>
              <AddRoundedIcon style={iconStyle} />
            </div>
          </div> 
        </div>
    : cartCount > 0   
      ? <div className={styles.inCartCheck} onClick={(event) => {
          toggleToolBar(event, true);  
        }}>
          <span className={styles.text}>
            <span className={styles.tinyX}>x</span>
            {cartCount}
          </span>
        </div>
      : <div className={styles.quickAdd}>
          <button className={styles.quickAddButton} onClick={(event) => {
            addItem(event);
          }}>
            <AddRoundedIcon style={iconStyle} />
          </button>
        </div> 
  )
}

InCartCheck.propTypes = {
  itemId: PropTypes.string.isRequired,
  handleCartClick: PropTypes.func.isRequired
};

export default InCartCheck;