import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FooterLogo from '../../assets/footerLogo.png'; 

/* Not enough CSS to warrant a module */
const useStyles = makeStyles({
  powerdByWrapper: {
    display:'flex', 
    alignItems:'center', 
    justifyContent:'center',
    borderTop: '1px solid rgb(188, 202, 210)',
    boxShadow: 'rgb(0 0 0 / 4%) 0px -2.62px 2px', 
    fontSize: '.75rem',
    marginBottom:'3rem',
    height: '2rem',
    lineHeight: '2rem'
  },
  powerdByLogo: {
    position: 'relative',
    height: '1.375rem',
    top: '1px',
    margin: '0 .25rem'
  }
});

const PoweredBy = () => {

  const classes= useStyles();

  return (
    <div className={classes.powerdByWrapper}>
      Powered by <img className={classes.powerdByLogo} alt="Zip Run" src={FooterLogo} />
    </div>  
  );
};

export default PoweredBy;