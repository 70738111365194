import * as React from 'react';

import isbot from 'isbot';
import { devLog } from '../util/util';
import { UrlParamsContext } from '../providers/UrlParamsProvider';
import { UserContext } from '../providers/UserProvider';
import { OrderTypeContext } from '../providers/OrderTypeProvider';
import { useOnVisible } from './useOnVisible';
import { commerceLoggingRequest } from './analytics-utils';
import PropTypes from 'prop-types';

export const CommerceLoggingContext = React.createContext();

// required in request body
const analytics_context = 'embedded_menu';

export const SHOPPING_MILESTONES = {
  MENU_VISIBLE: "menu_visible",
  MENU_INTERACTED: "menu_interacted", 
  ITEM_CARTED: "item_carted",
  CHECKOUT_VIEWED: "checkout_viewed",
  ORDER_PLACED: "order_placed",
};

// Updates sent to the API will replace existing user data for session.
const UPDATE_BODY = {
  "menu_visible" : { analytics_context },
  "menu_interacted" : { menu_interacted:true },
  "item_carted"  : { menu_interacted:true, item_carted:true }, 
  "checkout_viewed" : { menu_interacted:true, item_carted:true, checkout_viewed:true },
  "order_placed" : { menu_interacted:true, item_carted:true, checkout_viewed:true, order_placed:true }
};

const CommerceLoggingProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  const { dispensaryId } = React.useContext(UrlParamsContext);
  const { trackingOrderType } = React.useContext(OrderTypeContext);

  /**
   * Firebase sessions can be long-lived. To emulate a shopping session
   * we'll use the Firebase user.uid + a date stamp
   */
  const [commerceSessionId, setCommerceSessionId] = React.useState();
  // Log each action only once per session  
  const [actionsLogged, setActionsLogged] = React.useState([]); 

  const debounceRef = React.useRef();

  /**
   * Each action only needs to be logged once per session
   *
   * (and only one delivery order can be placed per day)
   */ 
  const logCommerceAction = React.useCallback((milestone, order_value_cents) => {
    if (user && commerceSessionId && !actionsLogged.includes(milestone)) {
      const body = UPDATE_BODY[milestone];
      body.id = commerceSessionId;
      body.order_type = trackingOrderType;
      if (milestone === SHOPPING_MILESTONES.ORDER_PLACED && order_value_cents) {
        body.order_value_cents = order_value_cents;
      }
      // Note that action has been logged
      setActionsLogged([...actionsLogged, milestone]);
      // Debounce!
      window.clearTimeout(debounceRef.current);
      debounceRef.current = window.setTimeout(() => {
        commerceLoggingRequest(user, dispensaryId, body, new AbortController()); 
        devLog(`ANALYTICS (${milestone}): ${JSON.stringify(body)}`);
      }, 1000);
    }
  }, [user, commerceSessionId, dispensaryId, actionsLogged, trackingOrderType]); 

  // We define "menu_visible" as when the header has been 100% in view for 1 second
  const [isVisible] = useOnVisible('#header', 1, 1000);
  React.useEffect(() => {
    if (commerceSessionId && isVisible) {
      logCommerceAction(SHOPPING_MILESTONES.MENU_VISIBLE);
    }
  }, [commerceSessionId, isVisible, logCommerceAction]); 

  // We define "menu_interacted " as when the usser clicks on something
  React.useEffect(() => {
    const onMenuClick = () => {
      logCommerceAction(SHOPPING_MILESTONES.MENU_INTERACTED);
      document.body.removeEventListener('click', onMenuClick);
    }
    document.body.addEventListener('click', onMenuClick);
    return () => { document.body.removeEventListener('click', onMenuClick) };
  }, [logCommerceAction]);

  // Create the commerceSessionId 
  React.useEffect(() => {
    const isBot = isbot(window.navigator.userAgent);
    if(!isBot && !commerceSessionId && user?.uid) { 
      const date = new Date();
      const encodedDate = window.btoa(`${date.toLocaleDateString()}-${date.getMilliseconds()}`);
      setCommerceSessionId(`${user.uid}-${encodedDate}`);
      devLog(`new commerce id: ${user.uid}-${encodedDate}`);
    } else {
      devLog(`CommerceLoggingProvider ReRender`);
    }
  }, [user, commerceSessionId]);

  return <CommerceLoggingContext.Provider value={{logCommerceAction}}>
           {children}
         </CommerceLoggingContext.Provider>
}

CommerceLoggingProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default CommerceLoggingProvider;