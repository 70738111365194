import * as React from 'react';

import { logWithIntercom } from './intercom-utils';

/**
 * This hook logs actions (Intercom 'updates') for visibility 
 * in the Intercom Dashboard 
 */
const useIntercomLogging = (user, actionKey, actionValue) => {
  // Registered user info  
  const { displayName, email, metadata } = user || {};
  const { creationTime } = metadata || {};

  React.useEffect(() => {
    // check for registered user
    if (displayName) {
      const actionObj = {};
      actionObj[actionKey] = actionValue;
      console.log(`Intercom: ${actionKey}`);
      logWithIntercom(displayName, email, creationTime, actionObj);
    }   
  }, [displayName, email, creationTime, actionKey, actionValue]);

  return null; 
}

export default useIntercomLogging;