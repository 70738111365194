import * as React from "react";

import BasicButton from '../styleguide/BasicButton';
import ErrorBoundary from '../common/ErrorBoundary';

import useBankAccountId from '../payment/useBankAccountId';
import LinkAccountsWithFastLink from '../payment/LinkAccountsWithFastLink';
import aeroPayLogo from '../../assets/aeroPayLogoAndName.png';

import styles from './YourAccount.module.css';

/**
 * Update AeroPay account linkage 
 */
const PaymentForm = () => {

  const buttonTextDefault = 'Link Bank Account';
  const buttonTextUpdate = 'Update Linked Bank Acccount';

  const [showAeroPayWidget, setShowAeroPayWidget] = React.useState();
  const [linkButtonText, setLinkButtonText] = React.useState(buttonTextDefault);

  // Adjust view depending on whether or not the user is already linked
  const DisplayLinkedAccount = ({setButtonText}) => {
    const controller = new AbortController();
    /* eslint-disable-next-line no-unused-vars */
    const [ accountId, accountHint, setBankAccountId ] = useBankAccountId(controller);
    
    const accountHintRef = React.useRef();

    React.useEffect(() => {
      if (accountHintRef.current !== accountHint) {      
        accountHintRef.current = accountHint;
        // Set button text: Set or Update
        setButtonText(accountHint ? buttonTextUpdate : buttonTextDefault); 
      }
      return () => { controller.abort() };
    },[accountHint, setButtonText, controller])
    
    return ( 
      <div className={styles.withBtmMargin}>
        <div className={styles.withBtmMargin}>
          <img alt="AeroPay" height="24" src={aeroPayLogo} />
        </div>
        { accountHint
          ? <>
              AeroPay is currently linked to your bank account ending in ...{accountHint}
            </>
          : <>
              Link your bank account with AeroPay to make purchases on Zip Run
            </>  
        }
      </div>  
    )
  }; 
  
  React.useEffect(() => {
    // This is required to keep the button text updated
  }, [linkButtonText]);

  return (
    <ErrorBoundary>
      { showAeroPayWidget
          ? <>
              <div className={styles.withBtmMargin}>
                <div className={styles.withBtmMargin}>
                  <img alt="AeroPay" height="24" src={aeroPayLogo} />
                </div>
                Select an account to link using your online banking credentials   
              </div>
              <LinkAccountsWithFastLink 
                onSuccess={() => {setShowAeroPayWidget(false)}} 
                onFailure={() => {setShowAeroPayWidget(false)}} />
            </>
          : <>
              <DisplayLinkedAccount setButtonText={setLinkButtonText} />
              <BasicButton text={linkButtonText} handleClick={() => {setShowAeroPayWidget(true)}} />
            </>
      }      
    </ErrorBoundary>
  );
}

export default PaymentForm;