import * as React from 'react';

import ErrorIcon from '@material-ui/icons/Error';

import styles from './Stylesheet.module.css';

const ErrorMessage = ({text, isInline}) => {
 
  const inlineStyle = isInline ? { marginTop: 0} : {};

  return (
    <div className={styles.errorMessage}  style={inlineStyle}>
      <ErrorIcon style={{color:'#d80303', fontSize: 20, verticalAlign:'middle'}}/> {text}
    </div>  
  )
}

export default ErrorMessage;