import * as React from 'react';
import { auth } from '../../firebase';
import { aeropayCredentialsEndpoint, aeropayLinkedAccountEndpoint } from '../../constants/api-endpoints';
import { validateDispensaryOrder } from '../checkout/order-utils';
import ErrorMessage from '../styleguide/ErrorMessage';
import { trackEvent } from '../analytics/tracking';

/**
 * Retrieve user's linked bank accounts, if any 
 * 
 * @param calllback - the function that handles the response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const fetchPreferredFastLinkAccount = async (callback, controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(aeropayLinkedAccountEndpoint, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    }).then(response => response.json())
      .then(data => {
        if (data.bank_accounts?.length) {
          const [preferredAccount] = data.bank_accounts.filter((acct) => {
            return acct.isSelected === '1';
          }); 
          callback(preferredAccount);
        } else {
          callback({});
        }
      })
      .catch(error => { 
        callback({ error: error.message });
      });
  });
};

/**
 * Retrieve user's linked bank accounts, if any 
 * 
 * @param calllback - the function that handles the response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const fetchFastLinkCredentials = async (callback, controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(aeropayCredentialsEndpoint, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        callback(body);
      })
      .catch(error => { 
        callback({ error: error.message });
      });
  });
};

/**
 * Set a user's linked bank accounts ( keyed by user phone number in Firebase ) 
 * 
 * @param accountInfo - the account linking info returned by AeroPay/Yodlee widget
 * @param calllback - the function that handles the account linking response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const postFastLinkCredentials = async (accountInfo, callback, controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  
  /* 
    {
      "token": "string",
      "accountId": "string",
      "externalBankAccountId": "string",
      "providerAccountId": 0
    }

  */
  
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(aeropayCredentialsEndpoint, { 
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(accountInfo),
      signal: controller.signal
    }).then(response => {
      if (response.status === 200) {
        callback(true);   
      } else {
        response.json().then(json => {
          callback(false, json);
        });
      }
    })
      .catch(error => { 
        callback({ error: error.message });
      });
  });
};

/**
 * We need to verify that the order will succeed prior submitting
 * We need to make sure that
 * 1) Customer has no current day orders
 * 2) Product is still available
 * 3) Delivery Window is still valid/available
 * 4) The app-calculated price is the same as what was calculated by the API  
 *  
 * @param {object} response - the cost detail returned by the API order/validity endpoint
 * @param {function} callback - function handling the validation response
 */
const validateOrderCallback = (response, totalPrice, onSuccess, setResponseMessage) => {
  setResponseMessage('');
  if (response.costs ) {
    // get the diff in pennies
    const costDiff = parseInt(response.costs.total, 10) === parseInt(totalPrice, 10);
    // allow $1 wiggle room.
    if (costDiff > -100 && costDiff < 100) {
      onSuccess();
    } else {
      // Show cost calc pricing problem
      const costDiff = response.costs.total - totalPrice;
      setResponseMessage(<ErrorMessage 
        text={`Sorry, there was a pricing problem with your order: total off by $${parseFloat(costDiff/100).toFixed(2)}`} />); 
    }
    if (costDiff !== 0) {
      trackEvent('checkout_pricing_error', costDiff.toString());
    }
  } else {
    // Show server error: Item not available etc.
    setResponseMessage(<ErrorMessage text={response.error}/>);
  }
};

export const validateAndPlaceOrder = (
  dispensaryId,
  isPickup,
  addedItems,
  totalPrice,
  deliveryWindow,
  pickupTimeSlot, 
  onSuccess, 
  setResponseMessage, 
  controller) => {
    validateDispensaryOrder(
      dispensaryId,
      isPickup,
      addedItems,
      deliveryWindow,
      pickupTimeSlot,
      (response) => {
        validateOrderCallback(response, totalPrice, onSuccess, setResponseMessage)
      }, 
      controller  
    );   
};
