import * as React from 'react';

import { auth } from "../../firebase";
import { UserContext } from "../providers/UserProvider";
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { navigateTop } from '../routing/router-utils';
import ErrorBoundary from '../common/ErrorBoundary';
import Account from '@material-ui/icons/AccountCircleRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import PropTypes from 'prop-types';
import styles from './BurgerMenu.module.css';

const MenuItems = ({ open, setOpen }) => {
  
  const { user } = React.useContext(UserContext);
  const { dispensary } = React.useContext(DispensaryContext);
  const { emptyCart } = React.useContext(CartItemsContext);
  
  const realUser = user && !user.isAnonymous;
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  const menuRef = React.useRef(null); 

  const handleLogout = () => {
    auth.signOut().then(() => {
      // remove cart items and user address etc.
      // provider
      emptyCart();
      // close modal
      setOpen(false);
      // return user to home page (don't leave them in /placeOrder)
      navigateTop(`/menu/${dispensary?.id}`);
      // TODO add a modal for errors
      }).catch(error => console.log(error)
    );
  }

  // Close menu on click outside menu
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if ( menuRef && !menuRef.current.contains(event.target) && !event.target.closest('#burgerIcon')) {
        setOpen(false);
      }
    };
    if (open) {  
      document.addEventListener('mousedown', handleClickOutside);
    }
    // remove handler on component unmout
    return () => { 
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, setOpen])

  const openStyle = open ? styles.open : ''; 
  
  const iconStyle = {
    color: 'black',
    backgroundColor: 'white',
    width: 20,
    height: 20,
    lineHeight: '22px',
    display: 'inline-block',
    verticalAlign: '-3px',
    textAlign: 'center'
  } 

  const navigateAndCloseMenu = (path, options) => {
    setOpen(false);  
    navigateTop(path, options);
  }

  return (
    <ErrorBoundary>  
      <div ref={menuRef} className={`${styles.menuItems} ${openStyle}`} aria-hidden={!isHidden}>
      {/* links for logged in state */}
      { realUser
        ? <>
            <span className={styles.menuItem}  
              tabIndex={tabIndex} onClick={() => {
                navigateAndCloseMenu(`/menu/${dispensary?.id}/account`, {});
            }}>
              <Account style={iconStyle}/> Your Account
            </span> 
            <span className={styles.menuItem}  
              tabIndex={tabIndex} onClick={() => {
                navigateAndCloseMenu(`/menu/${dispensary?.id}/orders`, {});
            }}>
              <ListAltRoundedIcon style={iconStyle}/> Your Orders
            </span>
            <span className={styles.menuItem} tabIndex={tabIndex} onClick={handleLogout}>
              <HighlightOffRoundedIcon style={iconStyle}/> Sign Out
            </span> 
          </>
        : <>
            <span className={styles.menuItem} tabIndex={tabIndex}
              onClick ={() => {
                navigateAndCloseMenu(`/menu/${dispensary?.id}/signUp`, {state: { returnToPath: window.location.pathname }});
              }}>
              Sign Up
            </span>
            <span className={styles.menuItem} tabIndex={tabIndex} onClick={() => {
              navigateAndCloseMenu(`/menu/${dispensary?.id}/signIn`, {state: { returnToPath: window.location.pathname }});
            }}>
              Sign In
            </span>
          </>
      }
      </div>
    </ErrorBoundary>
  )
}

MenuItems.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default MenuItems;
