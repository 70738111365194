import React from 'react';

import ErrorMessage from '../styleguide/ErrorMessage';
import PropTypes from 'prop-types';

export const MAX_MA_FLOWER_GRAMS = 28;

const QuantityWarning = ({gramsInCart=0}) => {
  return ( 
    <>
      { gramsInCart > MAX_MA_FLOWER_GRAMS
        ? <ErrorMessage text="Sorry, it looks like items in your cart exceed the 28gram (1oz) Massachusetts state limit for Cannabis. 
              You'll need to reduce your product quantities prior to placing your order." />
        : <></>
      }  
    </>
  )
}

QuantityWarning.propTypes = {
  gramsInCart: PropTypes.number.isRequired
}

export default QuantityWarning;