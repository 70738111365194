import React from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import { getDeliveryWindowLabel, getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getEarliestOrderTimeMS, getLatestOrderTimeMS } from './timeslot-utils';
import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

const SelectTimeSlot = ({
  dayIdx=0,
  slotIdx=0,
  displayDayIdx,
  timeSlots=[],
  currentTimeMS,
  handleSelect
}) => {

  const { theme } = React.useContext(ThemeContext);

  const themeStyleChoose = {
    color: theme.primaryColorDark,
    borderColor: theme.primaryColorDark
  };
  
  const themeStyleSelected = {
    color: '#fff',
    borderColor: theme.primaryBackgroundColor,
    backgroundColor: theme.primaryBackgroundColor
  };

  // Return selection to DisplayPickupSlots
  const onSelect = (event) => {
    const slotIdx = parseInt(event.target.value, 10);
    handleSelect(slotIdx);
  }

  return (
    <div className={styles.selectTimeSlot}>
      { timeSlots.length 
         ? timeSlots.map((slot,idx) => {
            const slotCloseMS = getLatestOrderTimeMS(displayDayIdx, slot, currentTimeMS);
            const isExpired = slotCloseMS <= currentTimeMS;
            const slotOpenMS = getEarliestOrderTimeMS(displayDayIdx, slot, currentTimeMS);
            const isOpen = currentTimeMS >= slotOpenMS;
            
            const openAtDate = new Date(slotOpenMS);
            const todaysDate = new Date(currentTimeMS);
            const willOpenToday = openAtDate.getDay() === todaysDate.getDay(); 

            const radioId = `day_${displayDayIdx}_slot_${idx}`;
            const isChecked = displayDayIdx === dayIdx && idx === slotIdx;
            const isDisabled = isExpired || !isOpen;
            const slotLabel = getDeliveryWindowLabel(slot.hours);
            const selectedStyle = isChecked ? styles.selected : '';
            const disabledStyle = isDisabled ? styles.disabledFldSet : '';

            return (
              <fieldset key={radioId} 
                        className={`${styles.fldSet} ${selectedStyle} ${disabledStyle}`}
                        data-open-at={slotOpenMS}
                        data-closed-at={slotCloseMS}>
                <input type="radio" name="pickupSlot" id={radioId} value={idx} 
                       onChange={onSelect} checked={isChecked} 
                       disabled={isDisabled} />
                <label htmlFor={radioId}>
                  {slotLabel} 
                  <span className={styles.chooseButton} style={themeStyleChoose}>Choose</span> 
                  <em style={themeStyleSelected}>Selected</em>
                  <span className={styles.notAvailable}>
                    { displayDayIdx === 0
                      ? willOpenToday
                        ? <>Available at {getFormattedTimeLabel(openAtDate.getHours(), openAtDate.getMinutes())}</>
                        : <>No Longer Available</>
                      : <>Not Yet Available</>
                    }
                  </span>
                </label>
              </fieldset>
            )  
          })
        : null
      }
    </div>
  )
}

SelectTimeSlot.propTypes = {
  dayIdx: PropTypes.number.isRequired,
  slotIdx: PropTypes.number.isRequired,
  displayDayIdx: PropTypes.number.isRequired,
  timeSlots: PropTypes.array.isRequired,
  currentTimeMS: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired
};

export default SelectTimeSlot;