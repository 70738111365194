import * as React from "react";

import { UserContext } from '../providers/UserProvider';
import { UrlParamsContext } from "../providers/UrlParamsProvider";
import { loadDispensaryProducts, groupByStandardCategories } from './product-utils';
import { addSampleSubCategories } from './product-utils';
import { trackEvent } from '../analytics/tracking';

export const ProductPreviewContext = React.createContext({});
/**
 * This provider is used for menu home product carousels
 *  
 * NOTE: This provider works from category.display_name
 * rather than category.name.  This is related to optionally
 * pulling the category from the url (/Edibles).
 * 
 * @param {string} dispensaryId - The id of the current dispensary 
 */
const ProductPreviewProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  const { dispensaryId } = React.useContext(UrlParamsContext); 

  // All dispensary products keyed by category name
  const productsByCategoryMap = React.useRef();
  const [loading, setLoading] = React.useState();
  
  const controllerRef = React.useRef(new AbortController());

  // INITIAL PRODUCT FETCH
  const productsCallback = React.useCallback((response) => {
    console.log('ProductPreviewProvider callback!');
    // SAMPLE DATA: add some subcategories/brands in dev
    if (window.tests_enabled) {
      addSampleSubCategories(response.data, 'edibles');
      // addSampleBrands(response.data, 'edibles');
    }
    const byCategory = groupByStandardCategories(response);
    productsByCategoryMap.current = byCategory;
    setLoading(false);
  }, [setLoading]);

  // Handle initial product data fetch
  React.useEffect(() => {
    const controller = controllerRef.current || new AbortController();
    // Load all products for the dispensary
    if (user && dispensaryId && !productsByCategoryMap.current) {
      setLoading(true);
      try {
        loadDispensaryProducts(dispensaryId, productsCallback, controller);
      } catch(error) {
        setLoading(false);
        trackEvent('error_fetching_preview_products',`${dispensaryId}:${error}`);
      }
    }
    return () => {
      // controller.abort();
      setLoading(false);
    }  
  }, [user, dispensaryId, productsCallback]);

  return (
    <ProductPreviewContext.Provider value={{
      productsByCategory: productsByCategoryMap.current,
      loading
    }}>
      {children}
    </ProductPreviewContext.Provider>
  );
};

export default ProductPreviewProvider;