import * as React from 'react';

/**
 * Determine if an element has been visible for a period of time
 * 
 * @param {string} selector 
 * @param {number} ratio - 1 for 100%
 * @param {number} time - in MS
 * @returns boolean
 */

export const useOnVisible = (selector, ratio, minimumVisibleTime) => {
 
  // Meets ratio + time visibility criteria
  const [ isVisible, setIsVisible] = React.useState(false);
  // We'll set isVisible=true after the minimumVisibleTime has been reached
  const timeOutRef = React.useRef();

  React.useEffect(() => {
    const callback = (entries) => {
      const [ entry ] = entries;
      const inView = entry.intersectionRatio >= ratio;
      if (inView) {
        // start the timer
        timeOutRef.current = window.setTimeout(() => {
          setIsVisible(true);
        }, minimumVisibleTime);
      } else {
        window.clearTimeout(timeOutRef.current);
      }
    };
    
    const observer = window.IntersectionObserver 
      ? new IntersectionObserver(callback, { threshold: ratio })
      : null;
    
    const target = document.querySelector(selector);
    
    if (target && observer) {
      observer.observe(target);
    }

    return () => {
      if (target && observer) observer.unobserve(target);
    }
  },[selector, ratio, minimumVisibleTime]);

  return [isVisible];
};
