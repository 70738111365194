import React from 'react';
import PropTypes from 'prop-types';

import styles from './Stylesheet.module.css';

const SecondaryHeader = ({text, isCentered, withStyle={}, children}) => {
  
  const centerStyle = isCentered ?  { textAlign: 'center'} : {};
  const headerStyle = Object.assign(centerStyle, withStyle); 

  return (
    <div className={styles.secondaryHeader} style={headerStyle}>
      {text}
      {children} 
    </div>
  );
};

SecondaryHeader.propTypes = {
  text: PropTypes.string,
  isCentered: PropTypes.bool,
  withStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
}

export default SecondaryHeader;