import * as React from 'react';

import config from '../../config';
import { OrderTypeContext } from '../providers/OrderTypeProvider';
import { navigateTop } from '../routing/router-utils';
import useMenuNavigation from '../routing/useMenuNavigation';
import ButtonWithPrice from '../styleguide/ButtonWithPrice';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import QuantityWarning from '../checkout/QuantityWarning';
import OrderPickupMap from '../pickup/OrderPickupMap';
import PropTypes from 'prop-types';

import styles from '../checkout/Cart.module.css';

/**
 * Display Checkout button with price for pickup orders
 */
const CheckOutButton = ({ 
  dispensaryId,
  dispensaryName,
  itemTotal,
  itemTotalDisplay,
  minOrderPickup=0,
  closeMenuFn }) => {
  
  const [minOrderMet, setMinOrderMet] = React.useState();
  // Format for display
  const minOrder = parseFloat(minOrderPickup/100).toFixed(2);
  // Check config for disabled/paused ordering
  const isOrderingPaused = config.PAUSED_DISPENSARIES.includes(dispensaryId);

  const menuNavigateTop = useMenuNavigation();

  // Proceed if logged in
  const proceedToCheckout = () => {
    // close slide in cart menu
    if ( closeMenuFn ) {
      closeMenuFn(); 
    }
    menuNavigateTop(`/menu/${dispensaryId}/checkOut`);
  };
 
  React.useEffect(() => {
    setMinOrderMet(itemTotal >= minOrderPickup);
  }, [itemTotal, minOrderPickup])

  return(
    <div className={styles.toCheckoutForm}>
      { isOrderingPaused
        ? <DisabledButtonWithReason text="Checkout" isFullWidth 
              reason={`Online ordering is currently paused for ${dispensaryName}`} /> 
        : minOrderMet 
          ? <ButtonWithPrice isFullWidth text="Checkout" price={itemTotalDisplay} handleClick={proceedToCheckout} />
          : <div className={styles.disabledMsg}>
              <ButtonWithPrice isFullWidth text="Checkout" disabled price={itemTotalDisplay} handleClick={proceedToCheckout} />
              <sup className={styles.minOrderSup}>*</sup> 
              Minimum pickup order: ${minOrder}
            </div> 
      }
    </div>
  )
}

const EmbeddedCartHeader = ({
  dispensaryId,
  dispensaryName,
  dispensaryLocation={},
  itemTotal,
  itemTotalDisplay,
  flowerGrams=0,
  minOrderPickup,
  closeMenuFn
}) => {

  const { currentService } = React.useContext(OrderTypeContext);
  
  return (
    <>  
      <div className={styles.cartHeader}>
        <div className={styles.cartDetails}>
          <div className={styles.dispensaryDetail}>
            <div className={styles.shoppingAt}>SHOPPING AT:</div>
            <div className={styles.dispensaryName}>{dispensaryName}</div>  
          </div>
          <div className={styles.orderTypeDetail}>
            { currentService }
          </div>
        </div>
        
        {/* Don't confuse the user with a checkout button on the /placeOrder page */}
        { window.location.pathname.indexOf('placeOrder') === -1 && 
          <CheckOutButton
            dispensaryId={dispensaryId}
            dispensaryName={dispensaryName}
            itemTotal={itemTotal}
            itemTotalDisplay={itemTotalDisplay}
            minOrderPickup={minOrderPickup} 
            closeMenuFn={closeMenuFn} />
        }
        <QuantityWarning gramsInCart={flowerGrams} />
      </div> 

      { dispensaryLocation.street_address &&
        <OrderPickupMap 
          dispensaryId={dispensaryId}
          dispensaryName={dispensaryName} 
          dispensaryLocation={dispensaryLocation}/>
      }
    </>
  )
}

EmbeddedCartHeader.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string,
  dispensaryLocation: PropTypes.object,
  flowerGrams: PropTypes.number.isRequired,
  minOrderPickup: PropTypes.number.isRequired,
  closeMenuFn: PropTypes.func.isRequired
}            

export default EmbeddedCartHeader;