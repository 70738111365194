import * as React from "react";

import { ModalType } from './ModalProvider';

/**
 * Custom hook used with ModalProvider to display modal content
 *
 * @returns 
 *   @param {ModalType} modalTye - Map, Login etc.
 *   @paran {object} modalProps - Props for use in modal component(s)
 *   @param {func} displayModal - Function used to display/hide modal
 */

const useModal = () => {
  
  const [modalType, setModalType] = React.useState(ModalType.NONE);
  const [modalProps, setModalProps] = React.useState({}); 

  // Modal is hidden via ModalType.NONE
  const displayModal = (modalType, modalProps={}) => {
    setModalType(modalType);
    setModalProps(modalProps);
  };

  return { modalType, modalProps, displayModal };
};

export default useModal;