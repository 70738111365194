import React from "react";

import { auth } from '../../firebase';
import DetourButton from "../styleguide/DetourButton";
import PropTypes from 'prop-types';

import styles from './CheckoutUserFlow.module.css';

const RegisteredUserInfo= ({
  registeredUser
}) => {
  
  return (
    <>
      <div className={styles.hdrPair}>
        <span className={`${styles.hdrPairLft} ${styles.titleText}`}>REGISTERED USER CHECKOUT</span>
        <span className={`${styles.hdrPairRgt} ${styles.signInText}`}>
          <span className={styles.signInPrompt}>Not you?</span>
          <DetourButton text="Sign Out" isSmall handleClick={() => { auth.signOut() }} />
        </span>  
      </div>
    
      <div className={`${styles.inlineRegFlow} ${styles.inlineRegFlowGuest}`}>
        <div className={styles.registeredUserDetail}><span className={styles.detailLabel}>Name:</span>{registeredUser.displayName}</div>
        <div className={styles.registeredUserDetail}><span className={styles.detailLabel}>Email:</span>{registeredUser.email}</div>
      </div>
    </>
  );
};

RegisteredUserInfo.propTypes = {
  registeredUser: PropTypes.object.isRequired
}

export default RegisteredUserInfo;