import { auth, analytics } from '../../firebase';

const PRODUCTION_HOSTS = [
  'menu.ziprun.com', 
  'ziprun-prod-menu.firebaseapp.com',
  'ziprun-prod.menu.web.app'
];

/**
 * trackEvent - log an event with Firebase/Google Analytics 
 *  
 * NOTE: We only seem to be getting the "action" in the Firebase dashboard.
 * 
 * @param {string} action --- The click, impression or error source 
 * @param {string} context --- The source of the click, impression or error 
 * @param {string} id --- The id of the dispensary/product/user
 */
export const trackEvent = (action, context, id) => {
  // Add platform to action
  const action_label = `menu_${action}`;

  // Disable tracking for internal Zip Run users for better analytics 
  const isProduction =  PRODUCTION_HOSTS.includes(window.location.host);
  const isInternalUser = auth.currentUser?.email?.toLowerCase().indexOf('ziprun') > -1; 
  if (isProduction && isInternalUser) {
    console.log(`Event suppressed: ${action_label}, ${context}, ${id || ''}`);
    return;    
  } 

  // Suppress logging in development to limit accidental noise
  if (window.location.host.indexOf('localhost') > -1) {
    console.log(`Event logged: ${action_label}, ${context}, ${id || ''}`);
    // skip actual logging in dev
    return;
  } else if (window.location.host.indexOf('qa') > -1) {
    console.log(`Event logged: ${action_label}, ${context}, ${id || ''}`);
  }  
  analytics.logEvent(action_label, { context, id });
};  
