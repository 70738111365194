import * as React from 'react';

import { auth } from '../../firebase';
import { getCustomerOrders } from '../checkout/order-utils'; 
import { trackEvent } from '../analytics/tracking';

import PropTypes from 'prop-types';

export const OrderContext = React.createContext();

const OrderProvider = ({children}) => {
  
  const MAX_RECENT_ORDERS = 10;
  // Active orders are orders that are not status: 'completed' || 'canceled'
  const ACTIVE_STATUSES = ['requested', 'accepted', 'ready-for-pickup', 'in-transit']; 
  
  // In-progress orders
  const [activeOrders, setActive] = React.useState(); 
  // Most recent 10 orders
  const [recentOrders, setRecent] = React.useState();
  // Don't promote reordering when it's not possible 
  const [disableReorder, setDisableReorder] = React.useState(true);

  /**
   * The API fetch will return an array
   *
   * @param {object} - with customer order data:[]  
   */
  const callback = React.useCallback((response) => {
    console.log('Order Provider callback');
    if (Array.isArray(response.data)) {
      const orders = response.data;
      if (orders.length > 0) {
        // Sort by date
        orders.sort((a, b) => a.created_at > b.created_at ? -1 : 1);
        // Recent Orders 10
        setRecent(orders.slice(0,MAX_RECENT_ORDERS));
        // Active orders
        const active = orders.filter((order) => {
          return ACTIVE_STATUSES.includes(order.status); 
        });
        setActive(active);
        // Enable reorder promos if reordering is possible
        if (active.length === 0) {
          setDisableReorder(false);
        }
      } else {
        setRecent([]);
        setActive([]);
      }
    } else {
      trackEvent(`order_provider_error_${response.status}`);
    } 
  },[ACTIVE_STATUSES]);

  // Wait for user session if necessary
  const displayName = auth.currentUser?.displayName;

  // Set / Update orders
  const setRecentOrders = React.useCallback((controller) => {
    if (controller?.signal && auth.currentUser && !auth.currentUser.isAnonymous) {
      getCustomerOrders(callback, controller);
    }
  }, [callback]);

  // Handled delayed authentication / sign in
  const authHandlerAddedRef = React.useRef();
  if (!authHandlerAddedRef.current) {
    auth.onAuthStateChanged(async user => {
      if(auth.currentUser && !auth.currentUser.isAnonymous) {  
        setRecentOrders(new AbortController());
      }
    });
    authHandlerAddedRef.current = true;    
  }

  const controllerRef = React.useRef(new AbortController());

  React.useEffect(() => {
    const controller = controllerRef.current;
    if (controller?.signal && typeof recentOrders === "undefined") {
      setRecentOrders(controller);   
    }
    return () => controller?.abort();
  }, [displayName, recentOrders, setRecentOrders]);

  return <OrderContext.Provider value={{
      activeOrders, 
      recentOrders,
      disableReorder, 
      setRecentOrders
    }}>
    {children}
  </OrderContext.Provider>
}

OrderProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default OrderProvider;