import * as React from 'react';

import { fetchPreferredFastLinkAccount } from '../payment/payment-utils';

/**
 * This hook provides the user's bank account linking details if the user has a linked account. 
 * The accountId is a proxy that's used on the back end to reference the linked account. 
 * 
 * @param {AbortController} controller 
 */
const useBankAccountId = (controller) => {
  // Store the proxy account number
  const [accountId, setAccountId] = React.useState(); 
  // Store the last 4 digits of the real bank account number
  const [accountHint, setAccountHint] = React.useState();

  /**
   * The API fetch will return an account details object or {} 
   *
   * @param {object} - account details or {}  
   */
  const callback = React.useCallback((account) => {
    const { bankAccountId, accountLast4 } = account || {};
    if (bankAccountId) {
      setAccountId(bankAccountId);
      setAccountHint(accountLast4);
    } 
  },[]);

  // external calls provide a controller
  const setBankAccountId = React.useCallback((controller) => {
    if (controller?.signal) {
      fetchPreferredFastLinkAccount(callback, controller);
    }
  }, [callback]);

  React.useEffect(() => {
    if (!accountId && controller?.signal) {
      setBankAccountId(controller);   
    }
    return () => controller?.abort();
  }, [accountId, setBankAccountId, controller]);

  return [ accountId, accountHint, setBankAccountId ]; 
}

export default useBankAccountId;