import * as React from 'react';

import ErrorIcon from '@material-ui/icons/Error';
import styles from './Stylesheet.module.css';

const SimpleErrorMessage = ({text, isCentered}) => {
 
  const msgStyle = isCentered
    ? { display: 'inline-flex'}
    : { display: 'flex'}

  return (
    <div style={{textAlign:'center'}}>
    <div className={styles.simpleErrorMessage} style={msgStyle}>
      <div className={styles.icon}>  
        <ErrorIcon style={{color:'#d80303', fontSize: 24, verticalAlign:'middle'}} />
      </div>
      <div className={styles.text}>
        {text}
      </div>
    </div>
    </div>  
  )
}

export default SimpleErrorMessage;