import * as React from "react";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'; 
import PropTypes from 'prop-types';

import styles from './ProgressCircle.module.css';

const ProgressCircle = ({percentageInt=0, useTestData, onGreen}) => {

  // This code is just for testing behavior
  const testValues = React.useRef([5,17,26,48,75,100]);
  const [testValue, setTestValue] = React.useState(0);
  React.useEffect(() => {
    if (useTestData && testValue < 100) {
      window.setTimeout(() => {
        setTestValue(testValues.current.shift() || 100);
      }, 100);     
    } 
  }, [testValue, testValues, useTestData]);

  // component colors
  const pathColor = onGreen ? '#fff' : '#5eb70b';
  const textColor = onGreen ? '#fff' : '#5eb70b';
  const trailColor = onGreen ? '#bda' : '#d6d6d6;';

  const value = useTestData ? testValue : percentageInt;

  return (
    <div className={styles.progressWrap}>
      <CircularProgressbar value={value} maxValue={100} text={`${value}%`} 
        styles={buildStyles({
          // Start rotation at bottom of circle
          rotation: 0.5,
          // Edge of indicator - can use 'butt' or 'round'
          strokeLinecap: 'round',
          // Text size
          textSize: '1.5rem',
          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: .5,
          // Colors
          pathColor, 
          textColor,
          trailColor,
          backgroundColor: '#d6d6d6'
        })}
      />
    </div>
  );
};

ProgressCircle.propTypes = {
  percentageInt: PropTypes.number.isRequired,
  useTestData: PropTypes.bool,
  onGreen: PropTypes.bool
};

export default ProgressCircle;