import React from "react";

import config from "../../config";
import { isValidPassword, isValidEmail } from './validation-utils';
import { getStoredValue, userAddressKey } from '../storage/storage-utils';
import { checkEmailAvailability, checkPasswordStrength } from './registration-utils';
import { errMsg } from './registration-errors';
import { RegistrationContext } from "./RegistrationProvider";
import PrimaryHeader from "../styleguide/PrimaryHeader";
import BackLink from "../common/BackLink";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import AddressSearchAndUpdate from './AddressSearchAndUpdate';
import PhoneNumberInline from "./PhoneNumberInline";
import PhotoIdInline from "./PhotoIdInline";
import VerifyPhoneAndComplete from "./VerifyPhoneAndComplete";
import DisplayModal from "../common/DisplayModal";
import ZipRunPrivacyPolicy from '../../docs/ZipRunPrivacyPolicy';
import ZipRunTermsOfService from '../../docs/ZipRunTermsOfService';
import InputMask from 'react-input-mask';

import PropTypes from 'prop-types';

/* Material UI overrides */
import './MaterialUI.module.css';
import styles from './SignUpInline.module.css';

const SignUpInline = ({
  cancelLinkText='Back',
  onCancel
}) => {
  
  const {
    name,
    setName,
    nameErr,
    setNameErr,
    email,
    setEmail,
    emailErr,
    setEmailErr,  /* needed for onBlur email-exists check */
    password,
    setPassword,
    passwordErr,
    setPasswordErr,  /* needed for onBlur strong-password check */
    location,
    setLocation,
    addressErr,
    apartmentNumber,
    setApartmentNumber,
    birthDate,
    setBirthDate,
    birthDateErr,
    setBirthDateErr,
    phoneNumber,
    setPhoneNumber,
    phoneNumberErr,
    setPhoneNumberErr,
    setPrimaryPhotoId,
    setSecondaryPhotoId,
    acceptTerms,
    setAcceptTerms,
    acceptTermsErr,
    setAcceptTermsErr,
    acceptMarketing,
    setAcceptMarketing
  } = React.useContext(RegistrationContext);

  // Check for stored address ( e.g. from marketing site)
  const storedAddress = React.useRef(getStoredValue(userAddressKey));

  React.useEffect(() => {
    if (storedAddress.current && !location.street_address) {
      setLocation(JSON.parse(storedAddress.current));
    }     
  }, [location, setLocation]);

  const [showTerms, setShowTerms] = React.useState();
  const [showPrivacy, setShowPrivacy] = React.useState();

  // Validate these onBlur:
  const [emailAvailable, setEmailAvailable] = React.useState();
  const [strongPassword, setIsStrongPassword] = React.useState();

  const handleEmailOnBlur = (emailAvailable) => {
    // valid email
    setEmailErr(!isValidEmail(email));
    // email available
    setEmailAvailable(emailAvailable);
  }

  const handlePasswordOnBlur = (isStrongPassword) => {
    setIsStrongPassword(isStrongPassword);
    setPasswordErr(!isStrongPassword);      
  }

  const getPasswordErrorMsg = (password) => {
    if (isValidPassword(password)) {
      return '';
    } else if (password.length < 8) {
      return '\u26A0 Minimum length: 8 characters';
    } else {
      return '\u26A0 Please use uppercase, lowercase and number(s)';
    }
  }; 

  /**
   * @param {*} event 
   * @param {func} input value setter
   * @param {func} input value Error setter
   */
  const onChange = (event, setter, errorSetter) => {
    const { id, value, checked } = event.currentTarget;
    const inputValue = event.currentTarget.type === "checkbox"
          ? checked
          : value;
    if (setter) {
      setter(inputValue);
    }
    if (errorSetter) {
      errorSetter(false);
    }
    // reset email  error
    if (id === "email") {
      setEmailAvailable(null);
    }
  };
  
  /* Material UI TextField Variant */
  const tF = 'filled'; 

  const dateInputRef = React.useRef();

  return (
    <div className={styles.inlineRegFlow}>
      { onCancel &&
        <BackLink backToText={cancelLinkText} onClick={onCancel} />
      }
      <PrimaryHeader isCentered text="Sign Up" />
      {/* Name */}
      <TextField id="name" value={name} label="Full Name" error={nameErr} helperText={errMsg('name',nameErr)} 
                 autoFocus variant={tF} onChange={(event)=>onChange(event, setName, setNameErr)} />
          
      {/* Display email availability notice after value is set true/false */}
      <div className={styles.inputFeedback}>
        { emailAvailable === true && !emailErr &&
          <div className={styles.inputSuccess}>&#10003; Account available</div>
        }
        { emailAvailable === false &&
          <div className={styles.inputFailure}>&#9888; An account exists for this email address</div>
        }
      </div>
      
      {/* Email */}
      <TextField id="email" value={email} label="Email" error={emailErr} helperText={errMsg('email', emailErr)} 
                 onBlur={() => checkEmailAvailability(email, emailAvailable => handleEmailOnBlur(emailAvailable))}
                 variant={tF} onChange={(event)=>onChange(event, setEmail, setEmailErr)} />
          
      {/* Display password strength notice after value is set true/false */}
      <div className={styles.inputFeedback}>
        { strongPassword === true &&
          <div className={styles.inputSuccess}>&#10003; Strong password</div>
        }
        { strongPassword === false &&
          <div className={styles.inputFailure}>{ getPasswordErrorMsg(password) }</div>
        }
      </div>
      
      {/* Password */}
      <TextField id="password" type="password" value={password} error={passwordErr} helperText={errMsg('password',passwordErr)} 
                onBlur={() => checkPasswordStrength(password, isStrongPassword => handlePasswordOnBlur(isStrongPassword))} 
                label="Password" variant={tF}
                onChange={(event)=>onChange(event, setPassword, setPasswordErr)} />
      
      <AddressSearchAndUpdate 
        location={location}
        addressErr={addressErr}
        setLocation={setLocation} />
      
      <div className={styles.inputPair}>
        {/* Apartment */}
        <TextField id="aptNumber" value={apartmentNumber} label="Apartment Number (optional)" 
                   variant={tF} onChange={(event)=>onChange(event, setApartmentNumber)} /> 
          
        {/* DOB - there is currently a findDOMNode error coming from react-input-mask */}
        <InputMask mask="99/99/9999" value={birthDate} onChange={(event)=>onChange(event, setBirthDate, setBirthDateErr)}>
          {(inputProps) => <TextField id="birthDate" inputRef={dateInputRef} { ...inputProps}
                    label="Date of Birth (MM/DD/YYYY)" variant={tF} error={birthDateErr} helperText={errMsg('birthDate', birthDateErr)} />}
        </InputMask> 
      </div>
      
      <PhoneNumberInline
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        phoneNumberErr={phoneNumberErr}
        setPhoneNumberErr={setPhoneNumberErr}
      />

      { email && config.ENABLE_ID_UPLOAD === true 
        ? <PhotoIdInline
            email={email} 
            setPrimaryPhotoId={setPrimaryPhotoId}
            setSecondaryPhotoId={setSecondaryPhotoId}
          />
        : <></>
      }

      <div id="terms" className={styles.termsBox}>
        <FormControlLabel 
          control={<Checkbox 
            color="default" 
            id="acceptTerms" 
            checked={acceptTerms} 
            onChange={(event)=>onChange(event, setAcceptTerms, setAcceptTermsErr)} />}
            label={<>I hereby agree to the Zip Run 
            {' '}<span className={styles.contentLink} onClick={(e) => { e.stopPropagation();  e.preventDefault(); setShowTerms(true)}}>Terms&nbsp;of&nbsp;Service</span>
            {' '}and 
            {' '}<span className={styles.contentLink} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShowPrivacy(true)}}>Privacy&nbsp;Policy</span></>}
        />
      </div>
          
      { acceptTermsErr && (
        <div className={styles.termsError}>
          { errMsg('accept_terms', true) }
        </div>
      )}

      <div className={styles.termsBox}>
        <FormControlLabel 
          control={<Checkbox 
            color="default" 
            id="acceptMarketing" 
            checked={acceptMarketing} 
            onChange={(event)=>onChange(event, setAcceptMarketing)} />}
          label="Yes, send me updates and promotions via email or SMS message (you can update this in your account settings)"
        />
      </div>  
   
      {/* Validate form data, verify phone, then submit */}
      <VerifyPhoneAndComplete />

      { showTerms &&
        <DisplayModal scrollingInner title="Terms of Service" closeFn={() => setShowTerms(false)}>
          <ZipRunTermsOfService />
        </DisplayModal>
      }
  
      { showPrivacy &&
        <DisplayModal scrollingInner title="Zip Run Privacy Policy" closeFn={() => setShowPrivacy(false)}>
          <ZipRunPrivacyPolicy />
        </DisplayModal>
      }
    </div>
  );
};

SignUpInline.propTypes = {
  cancelLinkText: PropTypes.string,
  onCancel: PropTypes.func.isRequired
}

export default SignUpInline;