import * as React from 'react';

import { daysOfWeek, getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { fetchDeliveryOptions } from '../dispensary/dispensary-utils';
import { getMaxDaysOut, getDefaultSlotForToday, getEarliestOrderTime, getLatestOrderTimeMS } from './timeslot-utils';
import { CurrentTimeContext } from './CurrentTimeProvider';

export const DeliverySlotsContext = React.createContext({});

export const STORE_NOT_OPEN_YET = -999;

const DeliverySlotsProvider = ({dispensaryId, children}) => {

  const currentTimeMS = React.useContext(CurrentTimeContext);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = React.useState();

  // Valid timeSlots to display (given day of week + days out)
  const [timeSlotsByDay, setTimeSlotsByDay] = React.useState([]);
  // Default time slot, -1 when unset
  const [defaultSlot, setDefaultSlot] = React.useState(-1);
  const [selectionExpired, setSelectionExpired] = React.useState(); 
  
  // Handle ordering not available at the current time
  const [ordersDisabled, setOrdersDisabled] = React.useState();
  const [ordersEnabledTime, setOrdersEnabledTime] = React.useState('');
  const [ordersEnabledDay, setOrdersEnabledDay] = React.useState('');  
  
  const getDayLabel = React.useCallback((offset) => {
    const dayOfWeek = new Date(currentTimeMS).getDay();
    if (offset === 0) {
      return 'TODAY';
    } else if (offset === 1) {
      return 'TOMORROW';
    }
    // Use Monday, Tuesday, etc.
    const weekdayNum = (dayOfWeek + offset) % 6;
    const label = daysOfWeek[weekdayNum];
    return label.charAt(0).toUpperCase() + label.slice(1);
  }, [currentTimeMS]);

  const isExpiredSlot = (dayIdx, slotIdx, timeSlots) => {
    try {
      if (currentTimeMS && 
          timeSlots?.length > dayIdx &&
          timeSlots[dayIdx].length > slotIdx ) {
        const slot = timeSlots[dayIdx][slotIdx];
        return currentTimeMS > getLatestOrderTimeMS(dayIdx, slot, currentTimeMS);
      }
    } catch (e) {
      console.log('slot expiration check error');
    }
    return false;
  }

  /**
   * Determine the valid/available delivery time slots
   */
   const timeSlotCallback = React.useCallback((response) => {
    // e.g. 2 for Tuesday
    const todayNum = new Date(currentTimeMS).getDay();     
    if (response.delivery_time_slots_for_week) {
      const slotsByDay = response.delivery_time_slots_for_week
      // e.g. starting Tuesday
      const weeklySlotsStartingToday = [ 
        slotsByDay[daysOfWeek[todayNum]],
        slotsByDay[daysOfWeek[(todayNum + 1) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 2) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 3) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 4) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 5) % 7]],
        slotsByDay[daysOfWeek[(todayNum + 6) % 7]]
      ];
      
      // Display only bookable days
      const maxDaysOut = getMaxDaysOut(weeklySlotsStartingToday, currentTimeMS);
      const validSlots =  weeklySlotsStartingToday.slice(0, maxDaysOut);
      setTimeSlotsByDay(validSlots);
     
      // Get first available slot or -1
      const defaultSlot = getDefaultSlotForToday(validSlots[0], currentTimeMS);  
      
      // Handle SAME-DAY ORDERING ONLY when we're outside ordering hours
      if (maxDaysOut <= 1) {
        // Loop thru days until we find a day/timeslot when ordering is available
        let openTime, dayIdx = 0;
        while (!openTime && dayIdx < weeklySlotsStartingToday.length) {
          openTime = getEarliestOrderTime(weeklySlotsStartingToday[dayIdx], dayIdx, currentTimeMS);    
          if (!openTime) {
            dayIdx++;  
          }   
        }
        if ((validSlots.length === 0 && defaultSlot === -1) ||  /* store not open yet */
             dayIdx === 1) {                                    /* store closed for day  */
          const openAtDate = new Date(openTime);
          setOrdersEnabledTime(getFormattedTimeLabel(openAtDate.getHours(), openAtDate.getMinutes()));
          setOrdersEnabledDay(getDayLabel(dayIdx));          
          setOrdersDisabled(true);
        }
      }
      // Store doesn't accept orders outside of biz hours and store is not open yet.
      if (validSlots.length === 0 && defaultSlot === -1) {
        setDefaultSlot(STORE_NOT_OPEN_YET);
      } else {
        setDefaultSlot(defaultSlot);
      }
      // reset selectionExpired
      setSelectionExpired(false);
    }
  }, [currentTimeMS, getDayLabel]);  

  const abortController = new AbortController();

  // Fetch Delivery Options after timezone offset is available
  React.useEffect(() => {
    const controller = abortController;
    if (currentTimeMS) {
      // Normal initialization, excepting biz-hours-only scenario where length === 0 
      if (!timeSlotsByDay.length && defaultSlot !== STORE_NOT_OPEN_YET) {  
        fetchDeliveryOptions(dispensaryId, timeSlotCallback, controller);
      } else if (selectionExpired) {
        fetchDeliveryOptions(dispensaryId, timeSlotCallback, controller);
      }
    }
    // cancel fetch on unmount
    return () => controller.abort();
  }, [currentTimeMS, dispensaryId, defaultSlot, selectionExpired, timeSlotsByDay.length, timeSlotCallback, abortController]);

  return (
    <DeliverySlotsContext.Provider value={{
      ordersDisabled,
      ordersEnabledTime,
      ordersEnabledDay, 
      defaultSlot,
      timeSlotsByDay,
      selectedDeliverySlot,
      setSelectedDeliverySlot,
      setSelectionExpired,
      isExpiredSlot
    }}>
      {children}
    </DeliverySlotsContext.Provider>
  );
};

export default DeliverySlotsProvider;