import * as React from "react";

import { auth } from "../../firebase";
import TextField from '@material-ui/core/TextField';
import { errMsg } from '../registration/registration-errors';
import { isValidEmail } from '../registration/validation-utils';
import Button from '../styleguide/Button';
import BackLink from '../common/BackLink';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './ResetPassword.module.css';

const SendResetEmailForm = ({isInline=false, handleCancel}) => {
  
  const [email, setEmail] = React.useState();
  const [emailErr, setEmailErr] = React.useState(false);
  const [emailHasBeenSent, setEmailHasBeenSent] = React.useState(false);
  const [errorSendingEmail, setErrorSendingEmail] = React.useState(false);
  
  const sendResetEmail = event => {
    event.preventDefault();
 
    if(!isValidEmail(email)) {
      setEmailErr(true);
      return;  
    }

    auth.sendPasswordResetEmail(email)
        .then(() => {
          setErrorSendingEmail(false);
          setEmailHasBeenSent(true);
          window.setTimeout(() => {
            setEmailHasBeenSent(false)  
          }, 3000);
        })
        .catch(() => {
          setErrorSendingEmail(true);
          setEmailHasBeenSent(false);
        });
  };

  const handleEmailOnFocus = () => {
    setEmailHasBeenSent(false);
    setErrorSendingEmail(false);
    setEmailErr(false);      
  };

  const handleEmailOnBlur = () => {
    setEmailErr(!isValidEmail(email));      
  };

  /**
   * Update email as it's entered
   * @param {event} event 
   */
  const onChange = event => {
    const { name, value:email } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(email);
    } 
  };     
   
  return (
    <div className={styles.plainForm}>
      { !isInline &&
        <>
          { handleCancel &&
            <BackLink backToText="Sign In" onClick={handleCancel} />
          }
      
          <div className={styles.headerWrap}> 
            <PrimaryHeader isCentered text="Reset Password" />
          </div>
        </>
      }
      <form action="" className={styles.plainInputs}>
        <TextField id="userEmail" name="userEmail" value={email} label="Your Email" error={emailErr} helperText={errMsg('email',emailErr)} 
                   onBlur={handleEmailOnBlur}
                   onFocus={handleEmailOnFocus} 
                   variant={'filled'}  onChange={onChange} />
        
        { emailHasBeenSent && (
          <div className={styles.emailSuccess}>
            An email has been sent to you!
          </div>
        )}
        {errorSendingEmail && (
          <div className={styles.emailError}>
            <ErrorIcon /> Error sending password reset email
          </div>
        )}
        
        <Button 
          isCentered 
          withStyle={{width:'100%', fontSize:'1.25rem'}}
          text="Email a password reset link" 
          handleClick={sendResetEmail} />
        
        { isInline && handleCancel &&
          <BackLink backToText="Sign In" onClick={handleCancel} />
        }

      </form>
    </div>
  );
};

export default SendResetEmailForm;