import * as React from "react";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

import styles from './YourAccount.module.css';

/**
 * Update email/message preferences 
 */
const SubscribeForm = ({
  emailEnabled,  /* current email setting returend by API */
  textEnabled,   /* current text setting returned by API */
  handleSubmit,  /* handle update to API */
  msgHandler,    /* callback to set success/fail message */
  sectionRef,    /* element ref for using in fade animation */
  updateMsg      /* The update response message */  
}) => {

  const [emailChecked, setEmailChecked] = React.useState(false);
  const [messageChecked, setMessageChecked] = React.useState(false);

  const onChange = (event) => {
    const updateInfo = {};
    if (event.currentTarget.type === "checkbox") {
      const { id, checked } = event.currentTarget;
      if (id === 'subEmail') {
        setEmailChecked(checked);
        updateInfo.marketing_email_opted_in = checked;
      } else {
        setMessageChecked(checked);
        updateInfo.marketing_sms_text_opted_in = checked;
      }
      // Make API call
      handleSubmit(updateInfo, sectionRef, msgHandler);
    }
  }   
  
  React.useEffect(() => {
    if (emailEnabled) {
      setEmailChecked(true);
    }
    if (textEnabled) {
      setMessageChecked(true);
    }
  }, [emailEnabled, textEnabled]);

  return (
    <div id="SUBSCRIBE" className={styles.subscribe}>
      <div className={styles.subscribeItem}>
        <FormControlLabel
          label={`Enable promotions and announcements via email (${emailChecked ? 'ENABLED' : 'DISABLED' })`}
          control={<Switch checked={emailChecked} onChange={onChange} id="subEmail" />}
        />
      </div>
      <div className={styles.subscribeItem}>
        <FormControlLabel
          label={`Enable promotions and announcements via text message (${messageChecked ? 'ENABLED' : 'DISABLED' })`}
          control={<Switch checked={messageChecked} onChange={onChange} id="subMessage" />}
        />
      </div>
      {/* success / failure */}
      { updateMsg &&   
        <div className={styles.updateMsg}>{updateMsg}</div> 
      }
    </div>
  );
}

SubscribeForm.propTypes = {
  emailEnabled: PropTypes.bool.isRequired,
  textEnabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  msgHandler: PropTypes.func.isRequired,
  sectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  updateMsg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default SubscribeForm;