import { SET_FEE_STRUCTURE, SET_BULK_DISCOUNT } from './action-types/cart-actions'

// set the values (fees/taxes) used in the total price calc
export const setFeeStructure = (cannabis_tax_percent, sales_tax_percent, delivery_fee_cents, delivery_service_fee_percent, pickup_fee_cents, pickup_service_fee_percent, in_store_payment_fee_percent) => {
  return {
    type: SET_FEE_STRUCTURE,
    cannabis_tax_percent, /* new */
    sales_tax_percent,    /* new */
    delivery_fee_cents,
    delivery_service_fee_percent,
    pickup_fee_cents,
    pickup_service_fee_percent,
    in_store_payment_fee_percent
  }
}

// set the bulk discount for trhe current order
export const setBulkDiscount = (discount_cents, discount_label) => {
  return {
    type: SET_BULK_DISCOUNT,
    discount_cents,
    discount_label
  }
}
