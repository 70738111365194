import * as React from 'react';

import { configureAutoComplete, destroyAutoComplete } from './google-maps-utils';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const SearchLocationInput = ({
  placeholder="Enter your address",
  onSelection=()=>{},
  disableAutoFocus
}) => {
  
  const autoCompleteRef = React.useRef(null);
  const [inputVal, setInputVal] = React.useState("");
 
  React.useEffect(() => {
    // load the api only once
    configureAutoComplete(autoCompleteRef, setInputVal, onSelection); 
    // important! destroy the autocomplete on component unmount
    return () => {
      destroyAutoComplete();
    }     
  }, [onSelection]);

  return (
    <TextField
      autoFocus={!disableAutoFocus}
      inputRef={autoCompleteRef}
      label="Enter your address"
      placeholder={placeholder}
      variant="filled"
      onChange={event => setInputVal(event.target.value)}
      value={inputVal}
    />
  );
}

SearchLocationInput.propTypes = {
  placeholder: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  disableAutoFocus: PropTypes.bool
}

export default SearchLocationInput;