import * as React from 'react';

import { auth } from '../../firebase';
// Used to update orders for my orders view
// import { OrderContext } from '../orders/OrderProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CommerceLoggingContext, SHOPPING_MILESTONES } from '../analytics/CommerceLoggingProvider';
import useIntercomLogging from '../intercom/useIntercomLogging';
import { trackEvent } from '../analytics/tracking';
import { getFormattedAddress } from '../googlemaps/google-maps-utils';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Helmet from 'react-helmet';
import GoogleMap from '../googlemaps/GoogleMap';
import PrimaryHeader from '../styleguide/PrimaryHeader';
import OrderDispensaryInfo from '../orderConfirmation/OrderDispensaryInfo';
import OrderDetails from '../orderConfirmation/OrderDetails';
import { OrderType } from '../checkout/order-utils';
import OrderItem from '../orderConfirmation/OrderItem';
import ValidateOrderResults from '../debug/ValidateOrderResults';
import BagIcon from '../checkout/BagIcon';
import Message from '@material-ui/icons/Message';
import { ZIP_RUN_GREEN } from '../../constants/colors';

import styles from '../orderConfirmation/OrderConfirmation.module.css';
import Spinner from '../common/Spinner';

// 'location' is the router 'navigate' props (the order info)
const EmbeddedOrderConfirmation = ({
  location={} 
}) => {
 
  const { dispensary, loading } = React.useContext(DispensaryContext); 
  
  const { logCommerceAction } = React.useContext(CommerceLoggingContext);

  // Update OrderProvider after order submission
  //const { setRecentOrders } = React.useContext(OrderContext);
  const [order, setOrder] = React.useState();
  
  /* We need to force a re-render on window resize for the map */
  let resizeRef = React.useRef();
  const [resizeCount, setResizeCount] = React.useState(0);
  const mapHeight = window.innerWidth > 960 ? '100%' : '225px';
  
  // Differences between App and API cost calcs
  const { costCalcDiffs=[] } = location.state || {};
  
  // Set the order info
  React.useEffect(() => {
    const handleResize = () => {
      window.clearTimeout(resizeRef.current);
      resizeRef.current = window.setTimeout(() => setResizeCount(resizeCount + 1), 500);    
    }
    window.addEventListener('resize', handleResize);   
    
    if (!order) {
      const orderInfo = location.state;
      if (orderInfo && orderInfo.id) {
        setOrder(orderInfo); 
      }
      if (orderInfo?.costs?.subtotal) {
        logCommerceAction(SHOPPING_MILESTONES.ORDER_PLACED, orderInfo.costs.subtotal);
      } else {
        trackEvent('error_analytics_order_amount');
      }
    }
    
    return  () => window.removeEventListener('resize', handleResize);   
  },[location.state, order, resizeCount, logCommerceAction]);

  const iconStyle = { verticalAlign: 'middle', marginTop: '1rem', fontSize:24, color:ZIP_RUN_GREEN }

  // Log order event with Intercom for Dashboard visibility
  const logAction = location.state?.type === OrderType.PICKUP
    ? 'Order_Pickup'
    : 'Order_Delivery';
  useIntercomLogging(auth.currentUser, logAction, true);
  
  return (
    loading
      ? <Spinner />
      :<BodyWrapper pageLayout={LayoutTypes.FullBleed}>
      <Helmet>
        <title>Zip Run - Order Confirmation</title>
      </Helmet>
      {/* Map grid on Desktop */}
      <div className={styles.mapGrid}>
        <div className={styles.mapWrapper}>
          { dispensary && 
            <GoogleMap address={getFormattedAddress(dispensary.location)} height={mapHeight} />
          }
        </div>       
  
        <div className={styles.orderConfirmationWrap}>
          <div className={styles.orderSection}>
            <PrimaryHeader text="Order Confirmed" withStyle={{margin:'.75rem 0 0',fontSize:'1.5rem'}}>
              <div className={styles.orderSubHead}>
                <OrderDispensaryInfo dispensary={dispensary} order={order} />
              </div>
            </PrimaryHeader>
          </div>

          <div className={styles.orderSection}>
            <div className={styles.sectionHdr}>Order Details</div>
            <div className={styles.sectionContent}>
              {order && 
                <OrderDetails order={order} />
              }
            </div>
          </div>

          <div className={styles.orderSection}>
            <div className={styles.sectionHdr}>Pickup instructions</div>
            <div className={styles.sectionContent}>
               Once you arrive, etc. etc. Parking is limited at this dispensary, details on where to park <u>here.</u> 
            </div>
            <div className={styles.sectionContent}>
              <ul className={styles.updateItems}>
                <li className={styles.updateItem}>
                  <div><Message  style={iconStyle} /></div>
                  <p>You'll receive SMS messages on the status of your order.</p>
                </li>
                <li className={styles.updateItem}>
                  <div><BagIcon fillColor={ZIP_RUN_GREEN} size="24px" /></div>
                  <p>Your products will be bagged and waiting for you at the dispensary.</p>
                </li>
              </ul>
            </div>
          </div>
  
          <div className={styles.orderSection}>
            <div className={styles.sectionHdr}>Products</div>
            <ul className={styles.cartItems}>
              { order?.items?.length &&
                order.items.map(item => {
                  return <OrderItem
                    key={item.id}
                    Id={item.id}
                    quantity={item.quantity_requested}
                    { ...item.display_info}
                  />
                })
              }
            </ul>
          </div>
  
          {/* displays calc diffs in dev only */}
          <ValidateOrderResults costCalcDiffs={costCalcDiffs} /> 
        </div>
      </div> {/* close map grid */}
    </BodyWrapper>
  )
}

export default EmbeddedOrderConfirmation;
